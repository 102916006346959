import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { Color } from '../assets/color';


const style = theme => ({
    root: {
        border: '1.5px solid #E8E8E8',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'none',
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            height: 136,
            padding: '24px 16px',
        },
        [theme.breakpoints.up('tabletv')]: {
            width: '85%',
            padding: 32,
            height: 284,
        },
        [theme.breakpoints.up('tableth')]: {
            height: 316,
        },
        [theme.breakpoints.up('largedesktop')]: {
            height: 284,
        },
    },
    cardContent:{
        [theme.breakpoints.up('mob')]: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },
        [theme.breakpoints.up('tabletv')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
    }
})

const JobCard = props => {
    const { classes, img, title, content } = props;

    return(
        <Card classes={{root: classes.root}}>
            <div className={classes.cardContent}>
                <img src={img} alt="compamy img" style={{width: 108, height: 108}}></img>
                <div style={{  display: 'flex',flexDirection: 'column',alignItems: 'center',}}>
                    <h3 style={{textAlign:'center'}}>{title}</h3>
                    <p className={`normal`}>{content}</p>
                </div>
              
            </div>
            
        </Card>
    )
}

export default withStyles(style)(JobCard);