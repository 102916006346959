export const Color = {
    intelligentBlue: '#377DED',
    lightBlue: '#EBF2FD',
    navy: '#5C5E6B',
    darkGrey: '#333333',
    black: '#000000',
    midGrey: '#666666',
    grey: '#999999',
    borderGrey: '#E8E8E8',
    lightGrey: '#F8F8F8',
    white: '#FFFFFF',
    errorRed: '#FA0F00',
    successGreen: '#00C844'
}