import Blog1 from '../../assets/blog/Blog1.jpg';
import Blog2 from '../../assets/blog/Blog2.jpg';
import Blog3 from '../../assets/blog/Blog3.jpeg';
import Blog4 from '../../assets/blog/Blog4.png';
import Blog5 from '../../assets/blog/Blog5.jpeg';
import Blog6 from '../../assets/blog/Blog6.jpg';
import Blog7 from '../../assets/blog/Blog7.png';
import Blog8 from '../../assets/blog/Blog8.png';
import Blog9 from '../../assets/blog/Blog9.jpeg';
import Blog10 from '../../assets/blog/Blog10.jpeg';
import Blog12 from '../../assets/blog/blog12/Cover.jpg';
import Blog13 from '../../assets/blog/blog13/cover.PNG';
import Blog14 from '../../assets/blog/blog14/cover.PNG';
import Blog15 from '../../assets/blog/blog15/cover.jpg';
import Blog16 from '../../assets/blog/blog15/cover2.jpg';
import Blog17 from '../../assets/blog/blog17/cover.jpg';
import Blog18 from '../../assets/blog/blog18/cover.PNG';
import Blog19 from '../../assets/blog/blog19/cover.PNG';
import Blog20 from '../../assets/blog/blog20/cover.png';
import Blog21 from '../../assets/blog/blog21/cover.png';
import Blog22 from '../../assets/blog/blog22/cover.jpeg';
import Blog23 from '../../assets/blog/blog23/cover.png';
import Blog24 from '../../assets/blog/blog24/cover.png';
import Blog25 from '../../assets/blog/blog25/cover.png';
import Blog26 from '../../assets/blog/blog26/cover.png';
import Blog27 from '../../assets/blog/blog27/cover.jpg';
import Blog28 from '../../assets/blog/blog28/cover.jpg';
import Blog29 from '../../assets/blog/blog29/cover.jpg';
import Blog30 from '../../assets/blog/blog30/cover.png';
import Blog31 from '../../assets/blog/blog31/cover.PNG';
import Blog32 from '../../assets/blog/blog32/cover.JPEG';
import Blog33 from '../../assets/blog/blog33/1.jpg';
import Blog34 from '../../assets/blog/blog34/1.jpg';
import Blog35 from '../../assets/blog/blog35/1.jpg';
import Blog36 from '../../assets/blog/blog36/1.jpg';
import Blog37 from '../../assets/blog/blog37/1.JPEG';

import Cover from '../../assets/blog/blog12/Cover.jpg';
import Image1 from '../../assets/blog/blog12/1.JPG';
import Image3 from '../../assets/blog/blog12/3.jpg';
import Image4 from '../../assets/blog/blog12/4.JPG';
import Image5 from '../../assets/blog/blog12/5.JPG';
import Image6 from '../../assets/blog/blog12/6.JPG';
import Image7 from '../../assets/blog/blog12/7.JPG';
import Image8 from '../../assets/blog/blog12/8.JPG';
import blog13Image1 from '../../assets/blog/blog13/1.PNG';
import blog13Image2 from '../../assets/blog/blog13/2.PNG';
import blog13Image3 from '../../assets/blog/blog13/3.PNG';
import blog14Image1 from '../../assets/blog/blog14/1.PNG';
import blog14Image2 from '../../assets/blog/blog14/2.PNG';
import blog14Image3 from '../../assets/blog/blog14/3.PNG';
import blog14Image4 from '../../assets/blog/blog14/4.PNG';
import blog15Image1 from '../../assets/blog/blog15/1.PNG';
import blog15Image2 from '../../assets/blog/blog15/2.PNG';
import blog15Image3 from '../../assets/blog/blog15/3.PNG';
import blog15Image4 from '../../assets/blog/blog15/4.PNG';
import blog15Image5 from '../../assets/blog/blog15/5.PNG';
import blog17Image1 from '../../assets/blog/blog17/1.PNG';
import blog18Image1 from '../../assets/blog/blog18/1.PNG';
import blog18Image2 from '../../assets/blog/blog18/2.PNG';
import blog18Image3 from '../../assets/blog/blog18/3.PNG';
import blog19Image1 from '../../assets/blog/blog19/1.PNG';
import blog19Image2 from '../../assets/blog/blog19/2.png';
import blog20Image1 from '../../assets/blog/blog20/1.png';
import blog20Video1 from '../../assets/blog/blog20/1.mp4';
import blog21Image1 from '../../assets/blog/blog21/1.png';
import blog21Image2 from '../../assets/blog/blog21/2.png';
import blog21Image3 from '../../assets/blog/blog21/3.jpg';
import blog21Image4 from '../../assets/blog/blog21/4.jpg';
import blog21Image5 from '../../assets/blog/blog21/5.jpg';
import blog22Image1 from '../../assets/blog/blog22/1.png';
import blog22Image2 from '../../assets/blog/blog22/2.png';
import blog22Image3 from '../../assets/blog/blog22/3.png';
import blog22Image4 from '../../assets/blog/blog22/4.jpeg';
import blog23Image1 from '../../assets/blog/blog23/1.jpg';
import blog23Image2 from '../../assets/blog/blog23/2.PNG';
import blog23Image3 from '../../assets/blog/blog23/3.PNG';
import blog23Image4 from '../../assets/blog/blog23/4.PNG';
import blog24Image1 from '../../assets/blog/blog24/1.JPEG';
import blog24Image2 from '../../assets/blog/blog24/2.JPEG';
import blog24Image3 from '../../assets/blog/blog24/3.JPEG';
import blog24Image4 from '../../assets/blog/blog24/4.JPEG';
import blog24Image5 from '../../assets/blog/blog24/5.JPEG';
import blog24Image6 from '../../assets/blog/blog24/6.JPEG';
import blog26Image1 from '../../assets/blog/blog26/1.jpg';
import blog26Image2 from '../../assets/blog/blog26/2.jpg';
import blog26Image3 from '../../assets/blog/blog26/3.jpg';
import blog26Image4 from '../../assets/blog/blog26/4.jpg';
import blog27Image2 from '../../assets/blog/blog27/2.jpg';
import blog28Image1 from '../../assets/blog/blog28/1.PNG';
import blog28Image2 from '../../assets/blog/blog28/2.PNG';
import blog28Image3 from '../../assets/blog/blog28/3.PNG';
import blog28Image4 from '../../assets/blog/blog28/4.PNG';
import blog28Image5 from '../../assets/blog/blog28/5.PNG';
import blog28Image6 from '../../assets/blog/blog28/6.PNG';
import blog28Video1 from '../../assets/blog/blog28/1.mp4';
import blog29Image1 from '../../assets/blog/blog29/1.jpg';
import blog29Image2 from '../../assets/blog/blog29/2.JPG';
import blog29Image3 from '../../assets/blog/blog29/3.JPG';
import blog29Image4 from '../../assets/blog/blog29/4.png';
import blog30Image1 from '../../assets/blog/blog30/1.png';
import blog30Video1 from '../../assets/blog/blog30/1.mp4';
import blog31Image1 from '../../assets/blog/blog31/1.PNG';
import blog31Image2 from '../../assets/blog/blog31/2.PNG';
import blog31Image3 from '../../assets/blog/blog31/3.PNG';
import blog31Image4 from '../../assets/blog/blog31/4.PNG';
import blog31Image5 from '../../assets/blog/blog31/5.PNG';
import blog31Image6 from '../../assets/blog/blog31/6.PNG';
import blog32Image1 from '../../assets/blog/blog32/1.JPEG';
import blog32Image2 from '../../assets/blog/blog32/2.JPEG';
import blog32Video1 from '../../assets/blog/blog32/1.mp4';
import blog33Image1 from '../../assets/blog/blog33/1.jpg';
import blog33Image2 from '../../assets/blog/blog33/2.jpg';
import blog33Image3 from '../../assets/blog/blog33/3.jpg';
import blog34Image1 from '../../assets/blog/blog34/1.jpg';
import blog34Image2 from '../../assets/blog/blog34/2.jpg';
import blog34Image3 from '../../assets/blog/blog34/3.jpg';
import blog34Image4 from '../../assets/blog/blog34/4.jpg';
import blog34Image5 from '../../assets/blog/blog34/5.jpg';
import blog35Image1 from '../../assets/blog/blog35/1.jpg';
import blog35Image2 from '../../assets/blog/blog35/2.png';
import blog35Image3 from '../../assets/blog/blog35/3.png';
import blog35Image4 from '../../assets/blog/blog35/4.jpg';
import blog36Image1 from '../../assets/blog/blog36/1.jpg';
import blog36Image2 from '../../assets/blog/blog36/2.jpg';
import blog36Image3 from '../../assets/blog/blog36/3.jpg';
import blog37Image1 from '../../assets/blog/blog37/1.JPEG';
import blog37Image2 from '../../assets/blog/blog37/2.JPEG';
import blog37Image3 from '../../assets/blog/blog37/3.JPEG';
import blog37Image4 from '../../assets/blog/blog37/4.JPEG';


export const blogList = [
  {
    id: 1,
    title: 'The Ways in Which the Year of Resignation Changed Our Mindset: Looking forward to 2022',
    img: Blog1,
    imgContain: true,
    author: 'Sanja Pesich',
    datetime: 'December 23, 2021',
  },
  {
    id: 2,
    title: 'Job Satisfaction and Wellbeing',
    img: Blog2,
    author: 'Sanja Pesich',
    datetime: 'Feburary 22, 2022'
  },
  {
    id: 3,
    title: 'Preventing Job Burnout',
    img: Blog3,
    author: 'Sanja Pesich',
    datetime: 'March 30, 2022'
  },
  {
    id: 4,
    title: 'National Volunteer Month -   IntelliPro Employee Spotlight',
    img: Blog4,
    author: 'Gege Tan',
    datetime: 'April 29, 2022'
  },
  {
    id: 5,
    title: 'Celebrating Asian American Pacific Islander Month',
    img: Blog5,
    author: 'Gege Tan',
    datetime: 'May 27, 2022'
  },
  {
    id: 6,
    title: 'Rapidly Changing Job Market Trends and What is Actually Going On',
    img: Blog6,
    author: 'Gege Tan and Sanja Pesich',
    datetime: 'Jun 28, 2022'
  },
  {
    id: 7,
    title: 'Diversity, Inclusion, Bias, and the Market Place',
    img: Blog7,
    author: 'Sanja Pesich',
    datetime: 'Jul 28, 2022'
  },
  {
    id: 8,
    title: 'Are Performance Reviews Still a Thing?',
    img: Blog8,
    author: 'Gege Tan',
    datetime: 'Aug 30, 2022'
  },
  {
    id: 9,
    title: 'How to Help Employees through Times of Inflation',
    img: Blog9,
    author: 'Gege Tan',
    datetime: 'Sep 28, 2022'
  },
  // {
  //     id: 10,
  //     title: 'IntelliPro Group brings more job opportunities to Montreal',
  //     img: Blog10,
  //     author: 'Gege Tan',
  //     datetime: 'Dec 21, 2022'
  // },
  // {
  //     id: 11,
  //     title: "Le groupe IntelliPro offre de nouvelles possibilités d'emploi à Montréal",
  //     img: Blog10,
  //     author: 'Gege Tan',
  //     datetime: 'Dec 21, 2022'
  // },
  {
    id: 12,
    title: "Event Recap | Silicon Valley Elite Summit guides job seekers through a gloomy job market",
    img: Blog12,
    author: 'Gege Tan',
    datetime: 'Dec 21, 2022'
  },
  {
    id: 13,
    title: "Celebrating Young Entrepreneurs",
    img: Blog13,
    author: 'Wenjun Liu',
    datetime: 'Feb 24, 2023',
    slogan: 'Celebrating-Young-Entrepreneurs'
  },
  {
    id: 14,
    title: "Exploring Hesai Technology's Rapid Growth: Panel Insights",
    img: Blog14,
    author: 'Wenjun Liu',
    datetime: 'Feb 27, 2023',
    // slogan:'Celebrating-Young-Entrepreneurs'
  },
  {
    id: 15,
    title: "IntelliPro Montreal: Empowering Businesses and Job Seekers with Cutting-Edge HR Solutions",
    img: Blog15,
    author: 'Wenjun Liu',
    datetime: 'Mar 9, 2023',
    slogan: 'IntelliPro-Montreal'
  },
  {
    id: 16,
    title: `IntelliPro Montréal : Donner aux entreprises et aux candidats des solutions RH de pointe.`,
    img: Blog16,
    author: 'Wenjun Liu',
    datetime: 'Mar 9, 2023',
    slogan: 'IntelliPro-Montréal'
  },
  {
    id: 17,
    title: 'IntelliPro Partnership: A Success in SOW Staffing',
    img: Blog17,
    author: 'Wenjun Liu',
    datetime: 'Mar 16, 2023',
    slogan: 'IntelliPro-Partnership-A-Success-in-SOW-Staffing'
  }
  ,
  {
    id: 18,
    title: 'Empowering Diverse Communities: Highlights from the Ivy League Spring 2023 Online Job Fair',
    img: Blog18,
    author: 'Wenjun Liu',
    datetime: 'Mar 27, 2023',
    slogan: 'Empowering-Diverse-Communities'
  },
  {
    id: 19,
    title: 'Inside the UCLA and USC Career Fairs: Insights from Intellipro Group and Expert Tips for Job Seekers',
    img: Blog19,
    author: 'Wenjun Liu',
    datetime: 'Apr 10, 2023',
    slogan: 'Inside-the-UCLA-and-USC-Career-Fairs'
  },
  {
    id: 20,
    title: `Inspiring Innovation: Yisu Jin's Harvard College China Forum Speech on Technology, Talent, and Business Growth"`,
    img: Blog20,
    author: 'Wenjun Liu',
    datetime: 'Apr 25, 2023',
    slogan: 'Inspiring-Innovation'
  },
  {
    id: 21,
    title: `Intellipro's Shanghai Office: A Vibrant Hub of Talent and Innovation`,
    img: Blog21,
    author: 'Wenjun Liu',
    datetime: 'Apr 26, 2023',
    slogan: 'Intellipro\'s-Shanghai-Office'
  },
  {
    id: 22,
    title: `Meet the Powerhouse Behind IntelliPro's Business Development: Our Stellar Team`,
    img: Blog22,
    author: 'Wenjun Liu',
    datetime: 'Apr 25, 2023',
    slogan: 'Meet the Powerhouse Behind IntelliPro\'s Business Development: Our Stellar Team'
  },
  // {
  //     id: 23,
  //     title: `Ella Zheng: A Driving Force Behind IntelliPro Group's Operational Excellence and Global Expansion`,
  //     img: Blog23,
  //     imgContain: true,
  //     author: 'Wenjun Liu',
  //     datetime: 'Apr 25, 2023',
  //     slogan: 'Ella Zheng: A Driving Force Behind IntelliPro Group\'s Operational Excellence and Global Expansion'
  // },
  {
    id: 24,
    title: `Forging Connections and Collaborating for Success: IntelliPro at ClubVMSA Enterprise Roundtable`,
    img: Blog24,
    imgContain: true,
    author: 'Wenjun Liu',
    datetime: 'May 3, 2023',
    slogan: `Forging Connections and Collaborating for Success: IntelliPro at ClubVMSA Enterprise Roundtable`,
  },
  {
    id: 25,
    title: `Streamlining Your Hiring Process: The Advantages of Outsourcing Recruitment`,
    img: Blog25,
    imgContain: true,
    author: 'Wenjun Liu',
    datetime: 'May 12, 2023',
    slogan: `Streamlining Your Hiring Process: The Advantages of Outsourcing Recruitment`,
  },
  {
    id: 26,
    title: `Intellipro CEO Delivers Inspiring TEDx Talk at Tsinghua University`,
    img: Blog26,
    imgContain: true,
    author: 'Wenjun Liu',
    datetime: 'May 18, 2023',
    slogan: `Intellipro CEO Delivers Inspiring TEDx Talk at Tsinghua University`,
  },
  {
    id: 27,
    title: `Navigating Global Talent Acquisition Challenges: Strategies for Success`,
    img: Blog27,
    // imgContain: true,
    author: 'Melissa Neverson',
    datetime: 'Aug 15, 2023',
    slogan: `Navigating Global Talent Acquisition Challenges: Strategies for Success`,
  },
  {
    id: 28,
    title: `Chinese Tech Network: A Groundbreaking Global Collaboration`,
    img: Blog28,
    // imgContain: true,
    author: 'Wenjun Liu',
    datetime: 'Sep 22, 2023',
    slogan: `Chinese Tech Network: A Groundbreaking Global Collaboration`,
  },
  {
    id: 29,
    title: `IntelliPro Life Science Team at 2023 SAPA Annual Conference & Anniversary Celebration`,
    img: Blog29,
    imgContain: true,
    author: 'Wenjun Liu',
    datetime: 'Oct 3, 2023',
    slogan: `IntelliPro Life Science Team at 2023 SAPA Annual Conference & Anniversary Celebration`,
  },
  {
    id: 30,
    title: `IntelliPro CEO Jin Yisu Named Among Forbes China's "100Most Influential Chinese"`,
    img: Blog30,
    imgContain: true,
    author: 'Wenjun Liu',
    datetime: 'Oct 31, 2023',
    slogan: `IntelliPro CEO Jin Yisu Named Among Forbes China's "100Most Influential Chinese"`,
  },
  {
    id: 31,
    title: `IntelliPro Group's 2024 U.S. Business Expansion Guide: Insights for Chinese Companies Venturing into the U.S. Market`,
    img: Blog31,
    imgContain: true,
    author: 'Wenjun Liu',
    datetime: 'Nov 3, 2023',
    slogan: `IntelliPro Group's 2024 U.S. Business Expansion Guide: Insights for Chinese Companies Venturing into the U.S. Market`,
  },
  {
    id: 32,
    title: `IntelliPro at NMSDC 2023: Driving Innovation in Minority Business Empowerment`,
    img: Blog32,
    // imgContain: true,
    author: 'Wenjun Liu',
    datetime: 'Nov 16, 2023',
    slogan: `IntelliPro at NMSDC 2023: Driving Innovation in Minority Business Empowerment`,
  },
  {
    id: 33,
    title: `Elevating Business Success in 2024: Integrated Staffing and Global HR Management Strategies by IPG`,
    img: Blog33,
    // imgContain: true,
    author: 'Melissa Neverson',
    datetime: 'Jan 16, 2024',
    slogan: `Elevating Business Success in 2024: Integrated Staffing and Global HR Management Strategies by IPG`,
  },
  {
    id: 34,
    title: `HR Technology Innovations: Driving Business Efficiency and Insights`,
    img: Blog34,
    // imgContain: true,
    author: 'Melissa Neverson',
    datetime: 'Jan 23, 2024',
    slogan: `HR Technology Innovations: Driving Business Efficiency and Insights`
  },
  {
    id: 35,
    title: `The History and Impact of Black Innovators in Tech`,
    img: Blog35,
    // imgContain: true,
    author: 'Jasmine Tang',
    datetime: 'Feb 1, 2024',
    slogan: `The History and Impact of Black Innovators in Tech`
  },
  {
    id: 36,
    title: `Building the Workforce of the Future: The Power of Sustainable Talent Management`,
    img: Blog36,
    // imgContain: true,
    author: 'Jasmine Tang',
    datetime: 'Feb 22, 2024',
    slogan: `Building the Workforce of the Future: The Power of Sustainable Talent Management`
  },
  {
    id: 37,
    title: `Team IntelliPro at ProcureCon CS 2024`,
    img: Blog37,
    imgContain: true,
    author: 'Wenjun Liu',
    datetime: 'Apr 18, 2024',
    slogan: `Team IntelliPro at ProcureCon CS 2024`
  }
];

export const blogContent = [
  {
    id: 1,
    content: [
      {
        subtitle: null,
        text: [
          'As we are finishing a very exciting year for the IntelliPro Group, we asked our executives and leaders to reflect on 2021 and give us projections for 2022. Their insight paints a picture of a volatile 2021 with a lot of changes in the recruiting and staffing industry. Because of its unique culture, the IntelliPro Group was quick to adapt to the rapidly changing trends in the Year of Resignation and came out of it stronger and better prepared for new challenges. According to the IPG team, we expect more excitement in 2022.',
        ]
      },
      {
        subtitle: '2021 Reflections',
        text: [
          'A discussion with senior executives and leaders from IntelliPro reveals that the job market went through a tremendous transformation in 2021. This year is known as the Year of Resignation. Yisu Jin, IntelliPro’s chief executive officer, explains that people went from being scared of losing their jobs in 2020 to feeling excited about the new job opportunities created by emerging technologies. Many workers left their jobs in pursuit of more edifying opportunities. Jin adds that “the high demand and a relatively low supply of the talent market along with the COVID-19 impact have changed the employers’ mindset. Those who had earlier thought it was impossible for everyone to work remotely are now convinced of this possibility.”',
          'Jeev Dhillon, IntelliPro’s recruitment manager and Sultan Khan, IntelliPro’s global account management director, observe a lot of movement in the industry and comment on how companies and employees are adapting to the change. According to Dhillon, startup companies need a lot more support with their hiring as candidates now work with multiple hiring agencies and typically have three or four offers on the table. According to Khan, many companies have had a hard time filling critical roles in 2021 and IntelliPro stepped in as both a talent provider and a trusted advisor. “Being able to help companies filling the positions with engineers that can help them reach their milestones is a very rewarding feeling and goes a long way.”',
          'The year of 2021 was extremely successful for IntelliPro. “It was a year of rebound”, explains Antoine Brown, IntelliPro’s vice president of business engagement. “We actually grew a lot this year; we were hiring talent and we are looking to expand more in 2022.” Edwin Chang, IntelliPro’s global head of talent development, explains that the reason for IntelliPro’s success is in its methodical approach to recruitment and training. “We created a lot of standard procedures to help make everyone\'s work-life a lot more efficient.” Chang adds that the team culture feels like a family, “everyone is being very supportive and holding each other to a higher standard.”',
          'Lilian Wang, IntelliPro’s chief people’s officer who recently joined IntelliPro, also attributes IntelliPro’s success to its unique culture. “In terms of the organization’s culture, we really respect and trust each.” Another competitive advantage according to Wang is that IntelliPro offers comprehensive services that are not limited to hiring only, but also to training, consulting, development, leadership, and outsourcing services.',
          'The year of 2021 marked extraordinary growth for IntelliPro with new offices opened in six countries: Canada, the United Kingdom, Netherlands, Germany, Ireland, and Singapore. Moreover, not only was IntelliPro looking for opportunities to add more talent around the globe, but it was also expanding its operations in the United States. Kelly Law, practice head for IntelliPro’s bio team, formed a specialized team in early 2021. The team has already secured three major accounts as well as multiple specialized accounts. Their work focuses on helping bio companies build teams with the best talent that can support new drug and technology development to combat major and rare diseases. Law emphasizes that they have learned their clients’ trust. “Our team does a thorough analysis of clients\' needs and creates customized solutions.”'
        ]
      },
      {
        subtitle: '2022 Projections',
        text: [
          'The first half of 2022 will continue in a similar rhythm as 2021. “It’s not going to slow down, it’s going to get busier and pick up, “says Dhillon.',
          'Chang and Khan, who are based in Canada, project that more companies will be looking for solutions in Canadian major IT hubs; as a result the IntelliPro’s Canadian team will double up in size. “IntelliPro won’t be watching from the sidelines, we are going to be helping companies and also the talent where they want to go,” comments Khan. “Because of COVID-19, workers have had to make many emotional decisions,” adds Wang, and “COVID-19 changed the way we work.” Khan says that the transition has already happened. “People adapted to working from home and to the fact they will never return back to office. In 2022, a lot more things will change from temporary to permanent.”',
          'According to Jin, IntelliPro will continue to rely on its proprietary technology to help its clients. “We will continue to strengthen our software, our artificial intelligence, our deep-learning-based algorithm talent search and talent recommendation.” Other strategies will include forming alliances with other companies and increasing IntelliPro’s footprint around the world.',
          'Both Law and Chang talked about how IntelliPro will continue to offer its employees opportunities for growth and strengthen its processes. Unlike other companies, IntelliPro allows their employees to cross teams and pursue projects they feel passionate about. “Our team has an open source, if you want to learn, you can learn everything through our internal platform,” comments Law.',
          'Brown and Khan expect more stability in the second half of 2022. Companies that have now adapted to a remote-work model, will look for talent elsewhere. However, Chang warns that “it’s going to be even more competitive, and companies will need to learn to pay global market to their employees.”'

        ]
      },
      {
        subtitle: 'What do you look forward to in 2022?',
        text: [
          'Growth.  This was the key takeaway from all interviewees, they talked about both professional and personal growth.',
          '“I chose to be a part of the IntelliPro team when I moved in March and the reason is just growth and positivity that I see in our team. How supportive everyone is. So, I look to grow personally with that as well. I want to support more of my candidates, more of trainees, more of team members we are hiring and supporting, so being more available,” comments Dhillon.',
          'Kelly looks forward to the development of new technologies in the bio industry: “I am super excited about new technology and new areas. Oncology has been a hot market for five years. But few companies are focused on rare diseases, it is not that juicy, but still those people need help and need therapy. I look forward to the AI technology to help with rare diseases.”',
          'Wang, Khan, and Brown look forward to a greater stability in 2022 and for opportunities to meet clients and accelerate IntelliPro’s growth. “Our customers are now more aware of the ways in which we can help them,” concludes Khan. “They started utilizing our resources and expertise, not just recruitment, but our IT solutions.”',
          'Jin echoes a similar sentiment with an aspiration that “We want to let more companies know about the IntelliPro’s unique culture and our unique methodologies. As a strategic partner, we want to help our clients build their talent and develop their talent acquisition strategies globally.”'
        ]
      },
    ],
    reference: []
  },
  {
    id: 2,
    content: [
      {
        subtitle: null,
        text: [
          'On average, we spend one third of our lives working. Work is such an essential component of one’s life and it is no surprise that so much research is devoted to understanding how a work environment can impact people. For example, a team of researchers conducted an extensive study with 700 participants so that they can test whether there is a direct correlation between happiness and productivity. Oswald and colleagues (2015) determined that “there is a causal effect between human wellbeing and human performance.”',
          'At IntelliPro, our recruiters are proud of the company’s unique culture and passionate about their work. We recently talked with a few recruiters who just joined IntelliPro to learn their perspectives on why working as a recruiter is so rewarding. We were also curious to know why they exactly accepted a job offer from IntelliPro among different job opportunities that were presented to them.',

        ]
      },
      {
        subtitle: 'Why did you join the staffing and recruiting industry in the first place?',
        text: [
          'The reasons for joining the staffing and recruiting industry are varied. Many of the interviewees tried different careers before they recognized they were passionate about helping people. Xiao Yuan holds a PhD and had spent most of her career as an educator before she joined IntelliPro as a recruiter. Yuan felt she needed a faster pace and recognized that her good interpersonal skills can be well utilized in the recruiting industry. Gabriel Ribeiro, IntelliPro’s account director, had already worked in the recruiting and staffing industry for ten years before joining IntelliPro. For him, the greatest motivation is helping people at an important juncture of their lives. Ribeiro also shares that the dynamic nature of the job involves a 360-degree view of the situation and deep understanding of both personal and professional aspirations.',
          'Quynh Tran, IntelliPro’s senior technical recruiter, is passionate about helping people as well. She is an avid volunteer and spent part of her career doing sales and business development. Although Tran discovered recruiting by chance, she fell in love with meeting and helping people: “there is nothing better than calling someone and telling them they got a job.”',
          'Michelle Chuang and Jeff Chen, who both joined IntelliPro as recruiters a couple of months ago, echoed the same sentiment. They tried other careers in the past and recognized that being in a position to help people was the most rewarding experience for them.'
        ]
      },
      {
        subtitle: 'What makes a good recruiter?',
        text: [
          'The unanimous answer was that a good recruiter needs to be a great communicator and have a genuine interest in people. But there is more to it. According to Ribeiro, a good recruiter needs to be empathetic, “they need to understand people’s likes and fears” in order to help and advise job candidates on their job search.',
          ''
        ]
      },
      {
        subtitle: 'Why did you decide to join IntelliPro?',
        text: [
          'Chen felt that IntelliPro was very up to date and attuned to how COVID-19 affected workplace. With IntelliPro’s recruiters, he was able to be fully transparent about the gap in his employment and received full support.',
          'Meeting with top executives was a deal breaker for many of them. Tran was interviewing with multiple companies and had three offers on the table when she spoke with the IntelliPro’s executives, the chief executive officer, Yisu Jin, the chief operating office, Ella Zheng, and global head of talent development, Edwin Chang. Tran was amazed by their openness and their enthusiasm: “It was very much the passion that you see in them that makes you believe in the company.”',
          'Chuang had a similar experience: “I’ve never been at a company that is this supportive, like where a CEO talks to us. I’ve never been at a company like that.”'
        ]
      },
      {
        subtitle: 'What is IntelliPro’s unique culture?',
        text: [
          'IntelliPro is growing rapidly and gaining a bigger footprint in the IT market every day. According to Ribeiro, IntelliPro’s true advantage is its agility. Decisions are made fast, the CEO, Yisu Jin, comes from a strong entrepreneurial background and quickly responds to sound requests for improvements. Unlike other companies who have rigid cultures, IntelliPro is not risk adverse, decisions are made very fast if there is a sound technological or business reason to make the move. Another huge advantage according to Ribeiro is IntelliPro’s global presence: “we have a chance to exchange our ideas with colleges in China, Singapore, Europe and Canada, which is magnificent.” This gives IntelliPro’s employees ample opportunities to learn from different cultures as well as through challenges teams experience in various locations.',
          'All other interviewees also all agreed that the culture at IntelliPro is very supportive and allows for professional growth. The structure of the company is such that recruiters can freely choose what projects they can work on while receiving full support from their peers and mentors. ',
          'Most importantly, IntelliPro is committed to providing excellent experience to its candidates and clients. IntelliPro not only provides the best talent but serves as a trusted advisor. According to Ribeiro, IntelliPro’s recruiters often serve as business consultants and suggest staffing solutions to the clients that are most beneficial long term. IntelliPro’s recruiters are equally committed to advising candidates about what is best for them and their long-term career goals.'
        ]
      },
    ],
    reference: ['Oswald, J. Andrew, Eugenio Proto and Daniel Sgroi. 2015. “Happiness and Productivity.” Journal of Labor Economics 33 (4): 789 – 822.']
  },
  {
    id: 3,
    content: [
      {
        subtitle: null,
        text: [
          'Burnout cannot be classified as a medical condition, but we are all aware that job burnout can have severe impact on one’s health and wellbeing. According to Bakusic and colleagues (2017), “burnout is a prolonged response to chronic occupational stress and is defined as a combination of (emotional) exhaustion, cynicism, and lack of professional efficacy”. ',

        ]
      },
      {
        subtitle: 'What can cause burnout?',
        text: [
          'According to an article published by LinkedIn, there are multiple reasons that can cause job burnout and many of them are psychological. The reasons could be fear of losing a job or frustrations over daily tasks and disagreements with co-workers. ',
          'Burnout can manifest in profoundly concerning ways. Because of the work-induced fatigue, people can start isolating from work-related activities, they may start avoiding both work and coworkers. The emotional exhaustion can turn into physical exhaustion, leaving those impacted by burnout with little energy to perform their tasks. ',
        ]
      },
      {
        subtitle: 'So, what can employees do to prevent burnout?',
        text: [
          '1. Scheduling your day and adhering to the schedule',
          '2. Letting go',
          '3. Taking care of one’s health',
          '“The way to avoid burnout is by making sure you schedule your days appropriately” – says Khan. He advises that by scheduling your week ahead of time, employees can reduce stress involving the uncertainty over what tasks are coming next. Khan is an avid advocate of planning one’s day so well that the work calendar includes both work-related tasks as well as time allocated for breaks, exercise, and proper nutrition. ',
          'Scheduling your calendar is the first step, but a bigger challenge is adhering to the schedule. Khan insists it is important to adhere to the allocated time at all costs. “It may happen that after an allocated hour for a task, you are not done with your task. It is important to put aside that work and move on with another task” – explains Khan. Unless you are able to exercise that kind of discipline, your day will be seriously disrupted. And according to Khan “you may still be able to finish your day effectively, but likely not as efficiently.” ',
          'It is imperative to structure one’s breaks equally well. The break-time should be used for self-care such as short and effective stretching exercises, a brief walk around the neighborhood or to consume nourishing snacks and plenty of water. With demanding jobs such as recruiting, not losing sight of our emotional and physical needs can play a big role in burnout prevention. ',
          'Another important aspect of protecting oneself from burnout in jobs that involve frequent human interaction is being able to let go. “A good recruiter has a very driven mindset, this is nothing but an easy job” – says Khan. And people can often be unpredictable. “The only way to go past is to tell yourself: I can’t control what I can’t control” – adds Khan. ',
          'Finally, Khan emphasizes the importance of good health, particularly amidst the COVID-19 pandemic when many of us are either working from home or doing a hybrid model - “if you are not managing your health properly, you are not able to work as effectively.” If you are putting long work hours, Khan recommends taking breaks between tasks even if they are for 5 -10 minutes. ',
          'Nourishing your body with healthy food is another emphasis. Khan admits that he was not as rigorous about following his own advice at the beginning of the COVID-19 pandemic and that impacted his energy level and his mood. He took measures to address it and be more mindful about striking a better balance between his wellbeing and work assignments.  Therefore, Khan strongly advises to prioritize health in order to stay focused and energized at work long term.',
          'LinkedIn also advises protecting one’s downtime to avoid burnout. Lunch hour and breaks should not be used to catch up on work, but as time to rest and replenish energy. Other things that can prevent burnout is being aware of your language, increasing your workplace fun, and cultivating meaningful relationships outside of work. Negative thoughts can affect how we feel about work and life. That’s why it is important to replace negative thoughts with more positive attitudes. For example, instead of saying “I can’t handle this any longer” try saying “I’ll break this job into smaller, achievable chunks” and make sure you celebrate those milestones. At work as well as outside of work, do things that are meaningful and fulfilling. Decorating your desk with fresh flowers could improve your mood. Planning and looking forward to exciting trips can provide the drive for working hard. ',
        ]
      },
      {
        subtitle: 'What can employers do to prevent burnout?',
        text: [
          'The first step is to acknowledge that burnout can happen and examine the aspects of company culture that could contribute to burnout. The best way to lead is leading by example. If you want to have a high-performing culture, then your leadership team should demonstrate enthusiasm that can inspire employees to do their best work. ',
          'According to Forbes, it is important to make progression a priority. Without having a prospect of a promotion, employees can feel discouraged and “stuck” in a dead-end job. The progression path should provide clear steps to advance one’s career within the company. ',
          'Most importantly, set values that everyone can get behind and prioritize your people. Work with your staff collaboratively to identify shared values. Incorporating shared values and goals creates a sense of unity. A motivated workforce will likely not experience burnout. Companies should make it a priority to invest in their staff, create an environment in which employees have the freedom to grow. To accomplish that, promote from within, communicate with staff and recognize and reward success. '
        ]
      },
    ],
    reference: [
      'Bakusic, Jelena, Wilmar Schaufeli, Stephen Claes, and Lode Godderis. 2017.  “Stress, Burnout and Depression: A systematic review on DNA Methylation Mechanisms.” Journal of Psychosomatic Research 92: 33 -34. '
    ]
  },
  {
    id: 4,
    content: [
      {
        subtitle: '',
        text: [
          'April is a National Volunteer Month; we celebrate the impact volunteers have on our lives and encourage active volunteerism in generations to come. Most often unpaid, volunteers donate a generous part of their lives to charitable events. As we appreciate their unwavering service, this month, IntelliPro Group spotlights three employees: Quyhn Tran, De Forest Kirkwood, Sanja Pesich, and IntelliPro\'s CEO, Yisu Jin, on their volunteer stories.',
        ]
      },
      {
        subtitleArray: ['"Give youth the confidence to achieve more."', '— Quyhn Tran'],
        text: [
          'Quyhn Tran is a senior recruiter at IPG Montreal Team. Before the pandemic hit, she had actively volunteered at Big Brother Big Sister of West Island, an organization that helps ignite the power and potential of children and youth by pairing each of them with an adult mentor.',
          '"Mentors see them regularly like once a week, once every two weeks whatever your schedule allows. A lot of these relationships last until the child turns 18 and then even beyond that. You are not in the program after 18, but a lot of kids actually end up staying in touch with their mentors long afterwards," said Tran.',
          'Tran served as one of the board members, where her main responsibilities included helping make executive decisions, supporting the hiring process and fund-raising. Tran joined the organization in 2019 with a simple wish: she wanted to set a good example for her son. Tran would take her son to volunteer on holidays when he was only five years old because she believed it would open the child\'s eyes and teach the important message of how not to take things for granted. "It helps to bring awareness to them to say not everybody has the same luxuries as you do," said Tran.',
          'Today, Tran continues to support the organization as an advisor. She said she looks forward to showcasing IntelliPro’s volunteer spirit by taking the Montreal team to volunteer as a team building event.'
        ]
      },
      {
        subtitleArray: ['"Every child deserve the same chance."', '— De Forest Kirkwood'],
        text: [
          'De Forest Kirkwood, one of the Business Development Managers at IntelliPro, is an avid leader of Project 43 Team Post Centers in South L.A., which aims to empower underserved children, families, and individuals by delivering programs and services designed to enrich, educate and advocate for equitable opportunities in the Hyde Park region.',
          '“Project 43 have provided 16,000 meals since the market closed. We are looking for funding to open our kitchen that will be open 24 hours to make sure no one goes hungry,” said Kirkwood. “Donation can be made at project43la.org.”',
          'Kirkwood said the organization was founded upon the hope of continuing the vision of Nipsey Hussle, an American rap singer who passed away in 2019, with whom Kirkwood also went to high school.',
          'There are usually 20 to 50 volunteers throughout the week helping in different programs, giving out food and clothes and helping people get back to jobs. "[We] believe in the vision of the whole neighborhood to be in tranquility, and actually service the community with any type of positive things that can help us get to the next level," said Kirkwood.',
          'As today\'s world goes through rapid technological change, STEM education is widely recognized. While study programs are provided to young children at Project 43, Kirkwood said he would still hope to see more support from tech companies. “I would love to see, not just our company but other companies, help in any type of way. It doesn\'t have to always be funds, it could be like a workshop, coming down teaching kids and people of the community,” said Kirkwood.'
        ]
      },
      {
        subtitleArray: ['"Mental health is health."', '— Sanja Pesich'],
        text: [
          'IntelliPro’s Brand and Marketing Director Sanja Pesich has been teaching at NAMI (National Alliance in Mental Illness) Santa Clara County since 2012. In hopes of supporting a family member, Pesich first took a Family-to-Family class.',
          'Pesich describes mental illness as an "uncharted territory" for lacking enough scientific studies and public attention compared to other diseases. "We need to have a more holistic approach around it to give people living with a mental illness a chance," said Pesich.',
          'Pesich later became a class instructor and taught classes to families on how to develop most effective strategies and offer support to loved ones living with a mental illness. "It\'s a very rewarding experience," said Pesich, "When we start a class, family members are in a lot of distress; they may be weeping, feeling hopeless...by the end of eight weeks, there\'s a complete shift. From someone who is very sad and hopeless to someone who becomes very determined," said Pesich.',
          'NAMI Walks, a major fundraising event organized every fall, has become a great opportunity for tech companies and hospitals to take a stand. Pesich thinks that IntelliPro can create its own team for the next NAMI SCC Walk, she also thinks inviting presenters over to educate people on mental illness topics would benefit IntelliPro\'s employees.'
        ]
      },
      {
        subtitleArray: ['"Nurturing great leadership in all communities."', '— Yisu Jin'],
        text: [
          'Besides serving as IntelliPro\'s chief executive officer, Yisu Jin is also the founder of SVLC (Silicon Valley leadership Community), a non-profit organization that aims to connect American Chinese leaders in the tech industry and to inspire the younger generation of leaders through coaching and education.',
          'Jin spends his spare time during evening time and on weekends to share lessons from his journey in entrepreneurship to student entrepreneurs at SVLC. Moreover, IntelliPro supports SVLC through generous donations. According to Jin, IntelliPro donates one percent of our profit each year to SVLC.',
          'Jin founded SVLC in hopes of raising awareness of Asian American leaders and their potential to make meaningful contribution to tech companies. Currently, Asian American leaders are underrepresented in senior leadership roles. "I believe that American Asians work really hard, they work smart, and they have a lot of passion for technology. I believe that if Asian American leaders are granted more opportunities in senior leadership roles in the tech industry, this will benefit not only our local communities but all the people, all over the world." said Jin.',
          'Jin remarks that the SVLC membership grew to 500 members, with most of them being from the high-tech sector. "This makes my life a lot more meaningful, the fact I am giving to the community. And that I see a lot of young people and they appreciate the effort of SVLC," said Jin.',
          'When asked about his expectations for SVLC, Jin said he wishes SVLC to do more for a broader community and to inspire young people to find employment in the tech industry. Jin\'s wish is that SVLC connects with organizations across the United States, but also in the APAC countries, South Asia, Africa, and Latin America. He would like the SVLC\'s vision to remain strong to foster community and opportunities for world- class leaders in technology.'
        ]
      },
    ],
    reference: []
  },
  {
    id: 5,
    content: [
      {
        subtitle: null,
        text: [
          'The month of May marks Asian American Pacific Islander (AAPI) Heritage Month, during which we celebrate Asian Americans and Pacific Islanders in the United States and pay tribute to their ancestors who had greatly shaped the history of the country. As a Western Minority Supplier Development Council certified Minority Business Enterprise (MBE), we firmly believe that diversity is what makes us grow and thrive. In honor of AAPI month, we invited a few IPG employees to share their stories about what it means to be a member of the AAPI community.',
        ]
      },
      {
        subtitle: 'Michelle Chuang - Recruiter',
        text: [
          'Q: What is your experience growing up as an Asian American?',
          'A: Being born and raised in a place with a relatively low Asian population, it was a little hard for me. I was one of the only Asian kids in my elementary school and would get made fun of. But thankfully, these experiences are less common nowadays as the Asian population is growing and people are more accepting of other cultures.',
          'Q: What does AAPI Heritage Month mean to you?',
          'A: I think AAPI month is especially important to Asian Americans because a lot of us tried to disconnect with or distance ourselves from our culture in hopes of assimilation. But, AAPI month is a great month to reconnect with your culture. For example, I\'m Taiwanese American, and I would wonder "Why did my parents come to the US? What do I know about my family?" Driven by this curiosity, I want to do some research on the background of my ancestors.',
          'Q: Why do you feel you had a disconnect with your culture at one point? Looking back at it now, what do you wish you had done differently?',
          'A: I used to be able to speak way more Chinese when I was a kid, but now I don\'t understand it as much anymore. In retrospect, I want to go back in time and beat myself for not practicing my Chinese. I believe you can assimilate into American culture, but meanwhile still preserve your own culture.',
          'Q: What is the biggest success in your career growth?',
          'A: It was when I took the jump in my career. I didn\'t like my previous job, so I quit and decided to pursue recruiting. When I quit my previous position, my parents got really upset and my sister explained that because they were immigrants and were essentially in survival mode all the time. It was nailed in our heads that we needed to be constantly working to survive. But I’m glad I made that decision because I love what I do now.'
        ]
      },
      {
        subtitle: 'Peter Chien - Project Manager',
        text: [
          'Q: How do you celebrate AAPI Heritage Month?',
          'A: I like to celebrate with my friends, we would go out and drink soju every weekend during this month.',
          'Q: How do you think the AAPI community is being recognized and represented in the work place?',
          'A: I think that it depends on the field, I\'ve worked in construction before and we get stereotyped very easily. But I think in some "white-collar" jobs, like finance or programming, there\'s a lot of good representation for Asian Americans.',
          'Q: How does it feel working at IPG as an Asian American?',
          'A: It definitely feels a lot more comfortable; it makes me want to practice my Chinese again.',
          'Q: What does being a member of the AAPI community mean to you?',
          'A: Being Asian American for me is a pride point, I wouldn\'t be able to see myself be any other ethnicity. I\'m always very prideful of being who I was and I\'ve made sure that no matter where I am, I\'m giving my 100%, and I think that stems from my heritage.',
          'Q: What is the biggest success in your career growth?',
          'A: My parents had me very late in their lives. They weren\'t able to put me through college but they instilled a good work ethic into me. One month before graduating, my dad had to go to the emergency room after experiencing dementia. So, I had to leave school and go take care of him. Fortunately, I was able to meet with Asian business owners who didn\'t care about my degree but cared about my work ethic. Asians love hard workers, they respect commitment and that was something I could bring to the table. I worked with several CEOS and learned as much as I could, which led me to being able to start my own company Global Sales Association. We took on contracts to hire salesmen for seven different companies and some of them contracted us to train their salesmen too. At our peak, we were connecting 200 salesmen a month to these companies which allowed me to sell the company and make my way into IntelliPro.',
        ]
      },
      {
        subtitle: 'Ella Zheng - Chief Operating Officer',
        text: [
          'Q: How do you celebrate AAPI Heritage Month?',
          'A: Last week, I went to a dinner party with a few of my Chinese friends. We cooked a very hearty Chinese meal together and chatted all night. We all lived for different lengths of time in the US, but we share the same experience of coming to a foreign country, making new friends and building a new life from scratch. There have been ups and downs, but overall, we are all very happy and proud of our achievements. I guess gathering with your friends is a memorable way to celebrate AAPI month.',
          'Q: What does being a member of the AAPI community mean to you?',
          'A: First of all, I\'m very proud of my Asian heritage. I was born in China and have been nurtured by Chinese culture growing up. I think it has become an integral part of myself, as well as valuable wealth that I will forever possess. I\'ve always believed that the Asian people are hard-working and resilient. We uphold an enterprising and pragmatic spirit, and we are peace-loving. Asian people have made such a huge contribution to the peaceful development of the world.',
          'Q: What is the biggest success in your career growth?',
          'A: A lot of exciting things have happened in my life. I would say the biggest success was when I decided to come to the USA to study for an MBA at the age of 38. I was living in Shanghai, managing my own recruiting firm. I had to work during the day and study for the TOEFL test at night, often pulling an all-nighter. Six months later, I was admitted to Pepperdine University. During the 20-month MBA program in the US, I only slept 4-5 hours a day. Especially when I joined IntelliPro part time in my second year, I had no time to sleep. I had to work while completing my degree with excellent grades. These two years were the most challenging yet most rewarding years of my life. Not only did I learn to adapt to Western culture and lifestyle, but my English also improved by leaps and bounds. More importantly, I was able to fully grasp the recruiting industry. I was already well-versed in the Asia-Pacific market and began to learn about the North American and European markets. This experience greatly shaped my international perspective, which I believe has also allowed me to contribute to driving our company\'s global business development.'
        ]
      },
    ],
    reference: []
  },
  {
    id: 6,
    content: [
      {
        subtitle: null,
        text: [
          'We have been recently inundated in the news with reports of hiring freezes and layoffs in the US IT sector. According to Business Insider, "business growth is slowing, while labor costs are increasing. The combination is causing American companies across a variety of industries to slash headcount."',
          'As a global company, we like to inspect the situation in a holistic way. That is why we organized a webinar in June with our IntelliPro\'s leaders, Edwin Chang and Sultan Khan, to discuss the current job market trends. Edwin Chang is IntelliPro\'s Head of Global Talent Development responsible for attracting and training the best talent. Under Chang\'s guidance, our IntelliPro recruiting team doubled within a year. Sultan Khan is IntelliPro\'s Global Account Director with extensive experience in Information Technology and Services, Blockchain and CryptoCurrency, Autonomous Vehicles and Electric Vehicles, Finance/Capital Markets, and Engineering industries. Khan has supported the largest IntelliPro\'s business accounts including Forbes 500 companies as well as up-and-coming startup companies. Both Chang and Khan are based in Canada.'
        ]
      },
      {
        subtitle: 'A Snapshot of Current Job Market Trends',
        text: [
          'Hiring freezes and layoffs have been haunting the U.S. job market, but Khan says he has observed opposing trends. On the one hand, according to U.S. Labor Statistics, this April\'s national unemployment rate at 3.6% was 2.4% points lower than the rate in April of 2021. On the other hand, 15,000 tech workers in the U.S. lost their jobs in May (Mascarenhas & Silberling, 2022). Khan agrees with reports published by Forbes that tech companies may be facing a correction. C-executives are cutting back on hiring as a way to "adjust to the new circumstances." Khans adds that once the situation gets more stable again, we may see a recovery in the job market. ',
          'Both Khan and Chang remark on how IntelliPro continues to meet some very unique needs of truly innovative clients and how IntelliPro recruiters continue to fill demanding roles. IntelliPro\'s huge advantage is in its proprietary technology and fast retrieval of data with high accuracy. Moreover, IntelliPro\'s flat structure enables a fast exchange of information; it also helps that everyone works very collaboratively and supports each other with resources and advice. The culture of collaboration extends to the relationships IntelliPro cultivates with clients. "Our clients are also very open and they have so far not been sharing news that is super alarming," says Khan. He adds that IntelliPro never stopped adding new clients: "just a couple of days ago, we signed a deal with another Fortune 500 client."',
        ]
      },
      {
        subtitle: 'U.S. & Canada - A Merging Market',
        text: [
          'While the U.S. job market seems to be experiencing a slowdown, Chang points that several indicators show that the Canadian market continues to grow. In fact, Canada\'s unemployment rate had just hit a record low of 5.1% this month (Thevenot, 2022). According to the StatsCan’s Labour Force Survey "employment in professional, scientific and technical services increased faster than in the economy as a whole."  Another source shows that Canada added a whopping 337,000 new jobs in February (Boorman, 2022). ',
          'Although the trend used to be that U.S. companies would hire candidates in Canada with earning salaries below the U.S. market, Chang points out that the Canadian candidates are becoming aware of the gap and demand a higher compensation. This is a situation in which getting help from skilled recruiters is critical. They step in and help candidates negotiate for the most favorable package.'
        ]
      },
      {
        subtitle: 'Strategic Partnership is the Key to Success',
        text: [
          'This is not the first time IntelliPro has witnessed a slowdown in the job market. It’s important to note that during the past few volatile years, IntelliPro not only survived but thrived and experienced an amazing growth. Chang attributes this success to two things: technology and IntelliPro\'s extraordinary culture. IntelliPro\'s proprietary software has been what backs up the global recruiting team helping them pinpoint the right candidate to the right client, but it is the unique culture of the company committed to clients\' and candidates\' success that drives progress. "We are always people-centric first, we never just talk to a candidate and ignore them as soon as they\'re not the right fit," says Chang. "We build partnerships and sincerely listen to the needs of both our candidates and clients, " adds Chang. ',
          'Equally critical are strategic partnerships with clients. For example, Khan explains how in 2021 the IntelliPro team helped a company which was experiencing layoffs while at the same time they were looking to hire the right talent to stay competitive. Khan helped the company\'s executives re-evaluate their priorities and establish a sound strategy. With a better strategy in place, IntelliPro\'s team of recruiters attracted the right talent and helped the client recover and reach important production milestones.  '
        ]
      },
    ],
    reference: [
      'CIC News. “Canada Unemployment Rate Down to Record Low”. https://www.cicnews.com/2022/06/canadas-unemployment-rate-down-to-record-low-5-1-0626190.html ',
      'TechCrunch.” Tech Layoffs Top 15K in a Brutal May.” https://techcrunch.com/2022/05/27/tech-layoffs-top-15k-employees-in-a-brutal-may/',
      'Forbes. “Be Prepared for Hiring Freezes and Layoffs.” https://www.forbes.com/sites/jackkelly/2022/05/10/be-prepared-for-hiring-freezes-and-layoffs/?sh=1dc7f583c97e',
      'CanadaIM. “Canada Adds 337,000 Jobs in February 2022.” https://www.canadim.com/news/canada-adds-337000-jobs-in-february-2022/',
      'U.S. Bureau of Labor Statistics. “June 2022: State Employment and Unemployment Summary.” https://www.bls.gov/news.release/laus.nr0.htm'
    ]
  },
  {
    id: 7,
    content: [
      {
        subtitle: '',
        text: [
          '“Diversity—defined as differences in how we see the world, how we think about the world, how we try and solve problems, the analogies we use, the metaphors, the tools we acquire, the life experiences we have—makes us better at what we do.”',
          'Scott E. Page, Robert L. Harris, Jr, Advancements in Science Lecture, Cornell University, 22 April 2015',
          '',
          ''
        ],
      },
      {
        subtitle: '',
        text: [
          'Diversity and inclusion continue to be hot topics among both organizational leaders and legislators. Despite the progress that has been made over the last couple of decades, there is still room for improvement. During my discussion with IntelliPro’s executives, Antoine Brown and Kevin Sturge, we touched upon why companies should invest in training and why diversity is beneficial for both employee morale as well as the bottom line.',
          'According to Kevin Sturge, IntelliPro’s VP of Customer Experience, diverse teams tackle difficult problems more efficiently, “if you don’t have a diverse workforce, you really start introducing blind spots into the company.” According to Sturge, a truly diverse company should be a small microcosm of the community in which it is situated. As a global company, IntelliPro’s employees put an extra effort into understanding and respecting the cultural norms and lifestyles of their team members, and they sometimes consider practical solutions. For example, IntelliPro’s teams are respectful of each other’s varied time zones and work on accommodating one another’s schedules.',
          'Sturge commented about how the workforce in the United States has changed over the years towards a better diversity and inclusion. Most large companies today have a diversity and inclusion program, but the biggest change Sturge has observed is that more progressive companies have measurable goals in place regarding their collaboration with diverse suppliers, which he believes is a promising trend.',
          'One of the ways companies can get on the right track towards a better inclusion and diversity is by going through a process of certification with a trusted institution. Antoine Brown, IntelliPro’s VP of Business Engagement, explained that IntelliPro has been certified as a minority-owned company by the Western Regional Minority Supplier Development Council. WRMSDC “provides minority businesses with a voice to be heard while connecting you to buyers, organizing events and offering match-making opportunities.” This partnership allowed IntelliPro to enter the space with companies who have shared values and opened multiple opportunities for collaboration and business growth.',
          'Brown warns that managers and leaders should be particularly careful about recognizing the possibility for an unconscious bias, which is difficult to identify. Acknowledging unconscious bias requires more diligence and a special training. IntelliPro mandates diversity and inclusion and bias awareness training for all employees. Brown recommends that companies utilize a number of resources that are nowadays widely available. Another area where bias can surface is job descriptions. Both Brown and Sturge agree that there is a lot of room for improvement in how job descriptions are created. If not considered well, job descriptions may portray an organization as favoring people who are willing to endure stress, demonstrate heightened assertiveness, or have the stamina to work long hours. Sturge argues that some of these job descriptions reveal patriarchal values, which is not a popular choice. This requires that a company undergoes a serious structural and organizational transformation. Both Brown and Sturge urge companies to spend time and resources to make sure that bias is acknowledged and addressed to increase chances for a more diverse talent pool.',
          'Being a diverse and inclusive workplace has multple benefits. According to an extensive research conducted by Forbes, diversity is “a key driver of innovation in the workplace.” Multiple indicators show that job seekers are actively looking for companies that have diverse and inclusion-rich cultures. According to Glassdoor, 76% of job seekers and employees claim that a diverse workforce is one of the main factors in evaluating where they would like to work.',
          'When asked how companies can foster diversity and inclusion, Brown and Sturge offered several practical steps:'
        ],
      },
      {
        numberedText: [
          {
            boldText: 'Be relationship-focused rather than matrix focused. ',
            inline: true,
            text: 'Organizational leaders should demonstrate that they are committed to individual growth and community growth.'
          },
          {
            boldText: 'Institute programs to give back. ',
            inline: true,
            text: ' Help your communities with expertise and encourage employees to participate in community-based projects that are meaningful to them.'
          },
          {
            boldText: 'Create a safe place.',
            inline: true,
            text: '  Foster an environment in which employees can bring their “authentic self” to work. Allow for exchange of ideas and respectful debates.'
          },
          {
            boldText: 'Utilize available resources.',
            inline: true,
            text: ' Tap into government-funded resources and research university programs to create standards for your organization to foster diversity and inclusion.'
          },
          {
            boldText: 'Be mindful about your job postings.',
            inline: true,
            text: ' Consider consulting with a wider range of people from different backgrounds to check on the language and screen for potential bias. Utilize available software.'
          },
          {
            boldText: 'Ask yourself what else can be done.',
            inline: true,
            text: ' Explore technical, educational, and communication solutions to include more people who could be marginalized. Actively engage in challenging discussions. Be a good listener.'
          },
        ]
      },
    ],
    referenceWithLink: [
      {
        beforeLink: 'IntelliPro’s YouTube Channel. 2022. Diversity, Bias, and the Market Place Webinar. Retrieved from the internet on July 25, 2022: ',
        link: {
          text: 'https://www.youtube.com/watch?v=01qUf0NYfA8&t=114s.',
          link: 'https://www.youtube.com/watch?v=01qUf0NYfA8&t=114s.'
        }
      },
      {
        beforeLink: 'Helfrich, Thomas. 2021. “How Diversity Can Help with Business Growth.” Retrieved from the internet on July 26, 2022.',
        link: {
          text: ' How Diversity Can Help With Business Growth (forbes.com)',
          link: 'https://www.forbes.com/sites/forbestechcouncil/2021/11/12/how-diversity-can-help-with-business-growth/?sh=48c1e69f52ad'
        }
      }
    ]
  },
  {
    id: 8,
    content: [
      {
        subtitle: '',
        text: [
          'Performance reviews might not sound like the most exciting thing ever. When done poorly, they can cause stress in the workplace. The controversy over P.I.P, Performance Improvement Plan, in the tech industry in particular, has created a huge dislike against performance reviews. But the most important question is whether performance reviews are still important. Can the benefits they bring to companies as a whole cancel out their drawbacks?',
        ],
      },
      {
        subtitle: 'Performance reviews are beneficial',
        text: [
          'A performance review is a formal assessment during which a manager evaluates an employee’s work performance, identifies strengths and weaknesses, gives feedback, or even sets goals for future performance. When implemented properly, performance reviews can have impactful benefits and this is the main reason why companies continue to do them. Conducting regular performance reviews can help a company track and maintain goals, prevent miscommunication and further foster growth and engagement. Other benefits regular performance reviews offer would be promoting employees and raising compensations more frequently; this can take pressure off of the year-end performance reviews. Finally, discussions with employees around performance reviews can help managers to get insight into how they can be better managers.',
        ],
      },
      {
        subtitle: 'The efficient way to conduct performance reviews',
        text: [
          'Although there are common drawbacks to performance evaluations such as being biased or rigid, studies have shown that strength-based performance appraisals can have a significant impact on improving employee\'s motivation. According to Forbes, around a quarter of the people who took a motivation survey are driven by achievement. For achievement-driven employees, a frequent performance review is vital for monitoring their progress and keeping themselves motivated. Therefore, it is not a good idea to ditch performance reviews altogether. Other ways to help managers better conduct performance reviews include:',
        ],
      },
      {
        list: [
          {
            boldText: '',
            text: 'Conducting performance reviews face-to-face if possible. Face-to-face communication is always the most efficient way. If the work is remote, book a one-one-one virtual meeting to talk with your employee.'
          },
          {
            boldText: '',
            text: 'Spend some time every week keeping track of each employee\'s performance. It will help you save time, so you don\'t have to cram before giving the actual performance review. Remember to take notes.'
          },
          {
            boldText: '',
            text: 'Give appraise while noting opportunities for improvements, avoid listing all the negatives unless you want to discourage your employees. This is especially crucial for achievement-driven employees.'
          },
          {
            boldText: '',
            text: 'Use clear and actionable language.'
          },
          {
            boldText: '',
            text: 'Set goals for future performance at the end. The goal of performance reviews is to foster improvement, so set goals for your employees with encouraging notes.'
          },

        ]
      },
      {
        subtitle: 'The controversial P.I.P.',
        text: [
          'The most controversial performance review is probably the P.I.P., Performance Improvement Plan, which is widely adopted by many major tech companies. Similar to regular performance reviews, a P.I.P. is a formal company document stating in detail performance or behavioral issues that an employee has exhibited as well as goals the employee must achieve in a specified time period to avoid being terminated. In theory, P.I.Ps are meant to help the people who are experiencing productivity issues to get back on track and succeed at their jobs. But, the plan has become notorious as it has been used as a box-checking mechanism for employers looking for legal protection before firing someone. However, a P.I.P. doesn\'t always mean termination. Here are some tips on how to respond to it in the best way possible.',
        ],
      },
      {
        subtitle: 'Have a positive attitude',
        text: [
          'Although panic might be your first response, keep in mind that it\'s not the end of the world. First, focus on what you can do next that is in your control, stay calm and react in a professional manner. Your attitude is important as it shows the employer you\'re taking their suggestions seriously and approaching your tasks with determination.',
        ],
      },
      {
        subtitle: 'Listen and take notes',
        text: [
          'Make sure you read the P.I.P. very closely. A well-made P.I.P. should be full of specific details and actionable goals. While your manager goes over the document with you, listen intently and take notes. Before you sign the document, make sure that you have fully understood the content and everything is clarified.',
        ],
      },
      {
        subtitle: 'Ask for help',
        text: [
          'Besides asking your manager how you can meet or even exceed the goals cited in the document, you can also talk to your colleagues and coworkers for specific advice on areas you need improvement in. Moreover, don\'t be afraid to book a one-on-one meeting with HR, it\'s always good to hear from more people.',
        ],
      },
      {
        subtitle: 'Check in regularly',
        text: [
          'Check in with your manager regularly to show your employer that you\'re dedicated to making improvement. It can also help you stay on track with the P.I.P progress and better understand if the steps you\'re taking to achieve the goals are actually working.',
          'As the old saying goes, "Don\'t throw the baby out with the bathwater," when implemented efficiently, performance reviews can be a great evaluation tool that brings motivation to employees. Not only should companies not ditch them, but more managers should actually start conducting performance reviews with their teams. However, if you ever end up with bad luck having to deal with a P.I.P., always remember there are solutions. Practice the tips mentioned above to help yourself get through it.'
        ],
      },
    ],
    referenceWithLink: [
      {
        beforeLink: 'Gnepp, Jackie, Joshua Klayman, Ian O Williamson, and Sema Barlas. June 19, 2020. “The Future of Feedback: Motivating Performance Improvement Through Future-Focused Feedback.” PloS one. Public Library of Science. ',
        link: {
          text: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7304587/',
          link: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7304587/'
        }
      },
      {
        beforeLink: 'Copple, Kara. October 27, 2021. “How to Survive a Performance Improvement Plan.” I Will Teach You to Be Rich.',
        link: {
          text: 'https://www.iwillteachyoutoberich.com/blog/how-to-crush-your- performance-improvement-plan/',
          link: 'https://www.iwillteachyoutoberich.com/blog/how-to-crush-your- performance-improvement-plan/'
        }
      },
      {
        beforeLink: 'Van Woerkom, Marianne, and Brigitte Kroon. January 31, 2020. “The Effect of Strengths-Based Performance Appraisal on Perceived Supervisor Support and the Motivation to Improve Performance.” Frontiers. Frontiers.',
        link: {
          text: 'https://www.frontiersin.org/articles/10.3389/fpsyg.2020.01883/full',
          link: 'https://www.frontiersin.org/articles/10.3389/fpsyg.2020.01883/full'
        }
      },
      {
        beforeLink: 'Murphy, Mark. November 5, 2018. “Here\'s Why You Still Need to Conduct Performance Reviews with Your Employees.” Forbes. Forbes Magazine.',
        link: {
          text: 'https://www.forbes.com/sites/markmurphy/2018/11/04/heres-why-you-still-need-to-conduct- performance-reviews-with-your-employees/?sh=50a4db1019f8.',
          link: 'https://www.forbes.com/sites/markmurphy/2018/11/04/heres-why-you-still-need-to-conduct- performance-reviews-with-your-employees/?sh=50a4db1019f8.'
        }
      },
    ]
  },
  {
    id: 9,
    content: [
      {
        subtitle: 'An uncertain economy',
        text: [
          'Nothing has impacted the 2022 global economy more than inflation. The pandemic, supply shortages, combined with the recent Russian-Ukraine War, have sent the price of goods and services soaring across the world. While employers are forced to find ways to cut costs to turn a profit, workers, on the other hand, are asking for higher pay to combat the rising cost of living. So, the question to consider is, how should employers navigate while supporting their employees? ',
        ],
      },
      {
        subtitle: 'What is inflation and its effects?',
        text: [
          'Inflation, put simply, is a broad rise in the prices of goods and services across the economy over time. Most people feel the effects of cost-of-living increases in their daily lives. For example, gas prices hit over $6 per gallon in California for the first time. Food delivery prices and the cost of groceries will all increase 10% to 11% by the end of 2022 (USA Today). According to the U.S. Department of Labor, as of June 2022 the inflation rate in the U.S. was 9.1/%. This was the largest annual increase in inflation since 1981 and the second year in a row for higher-than-average inflation (7% in 2021). ',
        ],
      },
      {
        subtitle: 'Here are some actions employers can take',
        text: [
          'With the cost of living being at a record-high, according to a study done by the Society for Human Resource Management (SHRM), 87% of HR professionals are worried about inflation and said their highest concern was the effect on the lives of their employees (EHS). On the other hand, 43% of employees say they want financial action to be taken to offset inflation, according to a March 2022 Mercer survey.',
          'The good news is that the majority of employers care about their employees and are actually taking action to increase salaries.  About 63% of organizations say that they’re going to adjust wages in response to inflation, according to Gartner research. And a survey of employers by compensation data firm Salary.com finds that most U.S. organizations (73%) are targeting a payroll budget increase of 4% or more this year.',
          'However, the amount of salary increase doesn\'t seem to be enough to perfectly offset the extraordinarily high rate of inflation. Data from the Bureau of Labor Statistics monthly jobs report shows that although wages have increased by 5.6% over the past 12 months, the cost of most basic necessities (groceries, rent, gas, and mortgage payments) have risen between 10% to 48%. This has put pressure on employers to risk losing their talents to competitors if they don\'t increase salaries. When salary increases are not in line with inflation, what else can employers do financially to assist their employees? Here are some alternative compensation approaches to help employees ease the burden of the high cost of living:',
        ],
      },
      {
        list: [
          {
            boldText: 'Bonuses',
            text: 'Bonuses are a great form of compensation that can come in many shapes and sizes. There are annual bonuses, spot bonuses, signing bonuses, retention bonuses, etc.; or simply offer performance-based incentive plans to empower employees to earn more.'
          },
          {
            boldText: 'Benefits',
            text: 'Ensure employees understand and have access to their financial wellness benefits, this can greatly help them budget money for emergencies. Employers can also consider investing in other types of benefits such as student loan assistance, daycare subsidies, or fertility benefits.'
          },
          {
            boldText: 'Allow for remote work/transportation stipends',
            text: 'Allowing remote work removes the cost of gas and parking, as well as reduces spending for items like coffee and eating out. Fully remote employees might even be able to live further from the office and lower their rent or mortgage payments. In cases where employees must come to the workplace, consider adding a stipend, such as gas cards, to help them cover commuting costs.'
          },
          {
            boldText: 'Hold off on premium increases',
            text: 'As healthcare is rising at an even faster pace than inflation, employers should hold off on increasing employees’ share of their premiums.'
          },
        ]
      },
      {
        subtitle: 'What has IntelliPro done to help our employees combat inflation?',
        text: [
          'According to a Great Place to Work®, which analyzed companies from 2006 to 2021, including during the Great Recession, it is shown that companies that delivered consistently great workplace experiences suffered the smallest losses and were the quickest to rebound post-recession. Moreover, companies that value diversity, equity and inclusion (DEI) and keep improving the experience of marginalized employees, tend to thrive during economic downturns (Kitterman). ',
          'Here at IntelliPro, we are proud to be certified as a Great Place to Work, as we are committed to joining efforts to help our employees combat high inflation. Being a normal part of a business cycle, recession could be a great opportunity to see whether a company is able to stand the test. This is not the first time IntelliPro has witnessed a slowdown in the job market. During the past few volatile years, IntelliPro not only survived but thrived and experienced amazing growth. We are determined to eliminate unconscious bias in the workplace by mandating DEI awareness training for all employees. By offering a diverse and inclusive environment, our workplace is filled with creativity and innovation, which is ultimately why we are able to have a strong employee retention rate even during the period of economic downturn. ',
        ],
      },
      {
        subtitle: 'Future is bright',
        textWithLink: [
          {
            beforeLink: 'Looking at the big picture, the inflationary moment we\'re in right now won\'t last forever and the economy will eventually go back to a healthy state again. As mentioned in our previous post.',
            link: {
              text: ` 'Rapidly Changing Job Market Trends and What is Actually Going On' `,
              link: 'https://www.intelliprogroup.com/blog/6'
            },
            afterLink: ', the job market is going through unprecedented change and while some companies have implemented hiring freezes, the demand for top talent still remains strong. It\'s important to keep in mind that inflation does not only affect the working class; employers are also going out of their way to help their employees cope during these times. If they don’t, they run the risk of having distracted, stressed and unproductive workers —and worse yet, they risk losing employees to better-paying jobs—which is especially possible in today’s competitive job market.'
          }
        ],
      },
    ],
    referenceWithColoredText: [
      {
        beforeLink: <>“StackPath.” n.d. <span
          style={{ color: 'rgb(55, 125, 237)' }}>www.ehstoday.com.</span> Accessed September 30, 2022.</>,
        link: {
          text: 'https://www.ehstoday.com/health/article/21246445/companies-worry-about-effect-of-inflation-on-employees-wellbeing.',
          link: 'https://www.ehstoday.com/health/article/21246445/companies-worry-about-effect-of-inflation-on-employees-wellbeing.'
        }
      },
      {
        beforeLink: 'Kitterman, Ted. n.d. “How to Support Employees as They Face Rising Inflation.” Great Place to Work®.',
        link: {
          text: 'https://www.greatplacetowork.com/resources/blog/how-to-support-employees-with-rising-inflation',
          link: 'https://www.greatplacetowork.com/resources/blog/how-to-support-employees-with-rising-inflation'
        }
      },
      {
        beforeLink: '“Inflation Moderates for Second Month; Used Car Prices Stop Rising.” n.d. Kiplinger. ',
        link: {
          text: 'https://www.kiplinger.com/economic-forecasts/inflation',
          link: 'https://www.kiplinger.com/economic-forecasts/inflation'
        }
      },
      {
        beforeLink: 'McDermid, Bailey Schulz, Phillip M. Bailey, Ken Tran, Elisabeth Buchwald and Riley Gutiérrez. n.d. “No More Steak. Ordering out Less. Here’s How Inflation Is Squeezing American Diets.” USA TODAY. Accessed September 30, 2022.',
        link: {
          text: 'https://www.usatoday.com/story/money/2022/09/22/inflation-daily-life-average-american/10382646002/?gnt-cfr=1',
          link: 'https://www.usatoday.com/story/money/2022/09/22/inflation-daily-life-average-american/10382646002/?gnt-cfr=1'
        }
      },
    ]
  },
  {
    id: 10,
    content: [
      {
        subtitle: null,
        text: [
          'Founded in 2009, IntelliPro is one of the fastest-growing talent acquisition and HR solutions firms in the world, with over 100 recruiters in North America and over 500 consultants worldwide. Since setting foot in Montreal in 2021, IntelliPro Montreal has continued to be the No. 1 staffing choice for top global corporations and up-and-coming tech startups. ',
        ],
      },
      {
        subtitle: null,
        text: [
          'As a comprehensive talent acquisition firm that made $100+ million in revenue in 2022, IntelliPro Group offers agile delivery in a wide range of services: full-time/part-time recruitment, Recruitment Process Outsourcing (RPO), payroll outsourcing, and Global Employment Outsourcing (GEO), featuring project oriented client management and single service level agreements.',
        ],
      },
      {
        subtitle: null,
        text: [
          '"We would like to impact the city of Montreal in helping foreign businesses establish themselves and also offer more hiring options to local businesses," said Guillaume Dion, manager of IntelliPro Montreal. "We also strive to help people find better jobs and provide great candidate experience through our processes and tips."',
        ],
      },
      {
        subtitle: null,
        text: [
          "Gabriel Ribeiro, Account Director at IntelliPro Montreal, has worked as a pro-fessional recruiter for 10 years with a specialization in IT, Sales, Marketing, and C-Level headhunting. \"I want to spark local and global interest in Montreal’s multicultural talent, to help bridge the gap of talent scarcity, and also make Montreal a powerhouse in terms of economic investment,\" said Ribeiro. \nRibeiro also said his goal is to help companies with their recruitment strategy, from where and how to source the best talent, to candidate onboarding and talent retention. IntelliPro Montreal's recruitment expertise includes technology, pharmaceuticals, corporate (HR, TA, finance, procurement, etc.), sales and marketing, C-Level headhunting, and staff augmentation. "
        ],
      },
      {
        subtitle: null,
        text: [
          "IntelliPro's rapidly growing footprint enables the organization to expertly serve clients with any role in all industries in all corners of the world. With professional recruiters working in over 40 cities in 13 countries, IntelliPro's global delivery makes them a truly multicultural brand that has a global vision in terms of recruitment strategies. \"Our team also speaks a variety of languages including Mandarin, Cantonese, Korean, Japanese, French, and Spanish,\" said Dion."
        ]
      },
      {
        subtitle: null,
        text: [
          "About IntelliPro Group: \nHeadquartered in Silicon Valley, USA, IntelliPro Group's mission is to create top talent solutions by providing the best tailored and individualistic services.Leading with market expertise and powerful proprietary technology, we create equal opportunities for our clients and candidates with their best interests in mind."
        ]
      },
    ],
    reference: []
  },
  {
    id: 11,
    content: [
      {
        subtitle: null,
        text: [
          "Fondée en 2009, IntelliPro constitue l'une des entreprises d'acquisition de talents et de solutions pour les ressources humaines (RH) qui connaît la plus forte croissance au monde, comptant plus de 100 recruteurs en Amérique duNord ainsi que plus de 500 consultants dans le monde. Depuis son arrivée à Montréal en 2021, IntelliPro Montréal est devenu le choix numéro 1 en matière de recrutement pour les grandes entreprises internationales et les jeunes entreprises technologiques."
        ],
      },
      {
        subtitle: null,
        text: [
          "En tant qu'entreprise d'acquisition de talents ayant réalisé un chiffre d'affaires de plus de 100 millions de dollars en 2022, le groupe IntelliPro offreune prestation agile dans une large gamme de services: recrutement à temps plein/partiel, externalisation du processus de recrutement (RPO), externalisation de la paie et externalisation de l'emploi global (GEO), avec une gestion des clients centrée sur les projets et des accords de niveau de service uniques."
        ],
      },
      {
        subtitle: null,
        text: [
          "\"Nous aimerions avoir un impact sur la ville de Montréal en favorisant l'établissement d'entreprises étrangères et également offrir plus d'options d'embauche aux entreprises locales\", a déclaré Guillaume Dion, directeur d'IntelliPro Montréal. \"Nous nous efforçons également d'aider les gens à trouver de meilleurs emplois et à offrir une excellente expérience aux candidats grâce à nos processus et à nos conseils.\""
        ],
      },
      {
        subtitle: null,
        text: [
          "Gabriel Ribeiro, directeur de compte chez IntelliPro Montréal, travaille comme recruteur professionnel depuis 10 ans, avec une spécialisation en informatique, en ventes, en marketing et dans le recrutement. \"Je veux attirer l'attention locale et internationale sur les talents multiculturels de Montréal, aider à combler le manque de personnel qualifié et faire de Montréal une puissance en termes d'investissement économique\", a déclaré M. Ribeiro. \nM. Ribeiro a également déclaré que son objectif est d'aider les entreprises dans leur stratégie de recrutement, depuis l'endroit et la manière de trouver les meilleurs talents jusqu'à l'accueil des candidats et la rétention des talents. L'expertise d'IntelliPro Montréal en matière de recrutement comprend la technologie, les produits pharmaceutiques, les entreprises (RH, AT, finances, achats, etc.), les ventes et le marketing, les recrutements au niveau C et l'augmentation du personnel."
        ],
      },
      {
        subtitle: null,
        text: [
          "La croissance rapide de la présence d'IntelliPro permet à l'organisation de servir de manière experte les clients dans tous les secteurs d'activité et danstous les coins du monde. Avec des recruteurs professionnels travaillant dans plus de 40 villes dans 13 pays, la prestation globale d'IntelliPro en fait une marque véritablement multiculturelle qui a une vision globale en termes de stratégies de recrutement. \"Notre équipe parle également plusieurs langues,dont le mandarin, le cantonais, le coréen, le japonais, le français et l'espagnol\", précise M. Dion."
        ]
      },
      {
        subtitle: null,
        text: [
          "À propos du groupe IntelliPro: \nAyant son siège social dans la Silicon Valley, aux États-Unis, la mission du groupe IntelliPro consiste à créer des solutions de recrutement de haut niveau en fournissant les meilleurs services personnalisés et individualisés. En nous appuyant sur notre expertise du marché et sur une technologie propriétaire puissante, nous créons des opportunités égales pour nos clients et nos candidats en gardant à l'esprit leurs meilleurs intérêts."
        ]
      },
    ],
    reference: []
  },
  {
    id: 12,
    content: [
      {
        subtitle: null,
        text: [
          "After Elon Musk drastically cut 50% of Twitter's staff in November, Meta and Amazon’s layoffs also hit one after another. Layoffs in November were estimated to be as high as 46,000 in the technology industry. These layoffs, coupled with the impact of the epidemic, are causing North American job seekers to struggle."
        ],
      },
      {
        subtitle: "Silicon Valley Elite Club",
        text: [
          "Amidst the cooling job market, IntelliPro & Uoffer held a Silicon Valley summit in Santa Clara, which aimed to help those affected by the layoffs, and new graduates to find jobs or make career plans for the future.  "
        ],
      },
      {
        subtitle: "",
        image: Image1,
      },
      {
        subtitle: "",
        text: [
          "This has been the largest offline job fair held in the Silicon Valley since the pandemic. Participation from American to Chinese companies included Meta, Amazon, Coinbase, Tencent, Meituan, Kuaishou, TCL, Thoughtworks, PingCAP, RERam, Bluesky, StarRocks and several other IT companies. Together we are determined to help students and professionals overcome difficulties that are ongoing in the current job market.",
          "The Silicon Valley Elite Summit attracted over 300 outstanding students and young talents from well-known companies such as Google and Apple, as well as from top universities such as Stanford and UCB to participate in the 4-hour-long summit. Industry leaders, such as the CEOs or founders of Bluesky, CelerData, PingCAP, Coinbase, Meta, and Amazon shared valuable workplace experience and expressed their own unique views on the current environment. "
        ],
      },
      {
        subtitle: "",
        image: Cover,
      },
      {
        subtitle: "",
        text: [
          "This summit facilitated a warm atmosphere as industry leaders, young professionals and students shared each others experiences and concerns.",
          "As for a quick recap!"
        ],
      },
      {
        subtitle: "Wonderful moment",
        text: [
          "In anticipation of the summit, many people arrived at the venue in advance. The booths were bustling with job seekers.",
        ],
      },
      {
        subtitle: "",
        image: Image3,
      },
      {
        subtitle: "",
        text: [
          "During the exchange meeting, the founder of IntelliPro and Uoffer, Yisu Jin, shared his job-hunting strategy and mentality regarding the recent layoff trends, and how he went from a software engineer to a Silicon Valley CEO. ",
        ],
      },
      {
        subtitle: "",
        image: Image4,
      },
      {
        subtitle: "",
        text: [
          "Additionally, Jin shared how international students can efficiently find IT jobs in Silicon Valley, such as how to respond, make sufficient preparations and finally successfully get their dream offer. ",
          '“To find an ideal job, in addition to a positive attitude, you also need to ensure that your comprehensive strength can match the market demand. Prove to the interviewer that the team needs talents like you, and then step by step win this job-hunting battle."',
          'Co-Founder & CEO, Mingsheng Hong from Bluesky also expressed his own unique views, which is that we can look at the instability of the job market in North America from different angles.',
          'Crisis often breeds new opportunities. Many well-known large companies, such as Uber, WhatsApp, and Airbnb, were born in crisis. "The only way to achieve the impossible is to believe that it is possible." ',
        ],
      },
      {
        subtitle: "",
        image: Image5,
      },
      {
        subtitle: "",
        text: [
          "CelerData & StarRocks COO, Qian Ye, believes that to achieve success in the workplace, you need to have two necessary conditions: you must be in a rising industry and be a patient employee. In our daily work, we must always pay attention to industry trends and form our own judgments. Ye also emphasized that a career is a long-distance running track, what we need is step-wise growth, and not be discouraged by the storm of layoffs, stay proactive and seek more opportunities.",
          "Siwei Shen, Senior Director of Engineering from Coinbase, also shared his perspectives."
        ],
      },
      {
        subtitle: "",
        image: Image6,
      },
      {
        subtitle: "",
        text: [
          'During the interactive sharing meeting, students actively participated and voiced their concerns with the current market. Yisu shared: "The darkest moment will eventually pass, I hope we can tide over the difficulties together and welcome the transformation!"',
        ],
      },
      {
        subtitle: "",
        image: Image7,
      },
      {
        subtitle: "",
        text: [
          '',
        ],
      },
      {
        subtitle: "",
        image: Image8,
      },
    ],
    reference: []
  },
  {
    id: 13,
    content: [
      {
        subtitle: 'Hurun U30 Summit Brings Together Global Thought Leaders',
        text: [
          "On January 14th, the Hurun U30 Award Ceremony and East Coast Summit took place in Boston, MA marking a highly-anticipated event. The summit, organized by Hurun, a well-known research, media, and investment group that has been empowering entrepreneurs for over two decades, celebrated the accomplishments of young entrepreneurs under 30 who have made remarkable contributions to their respective industries. This year, the event was expanded to 10 countries, including the United States, China, India, Canada, Germany, Spain, South Korea, the UAE, Australia, and the UK."],
        image: blog13Image1,

      },
      {
        subtitle: `Sub-forum "Profession Choices and Transitions".`,
        text: [
          "IntelliPro x Uoffer, as the exclusive career partner of the Hurun Report, assembled career development experts from various fields in the sub-forum \"Profession Choices and Transitions.\" The panel aimed to offer unique insights and share experiences on career choice and transition. IntelliPro's CEO Yisu Jin participated in the panel discussion as a guest speaker, alongside other thought leaders from leading industries such as Meta’s Engineer of VP and Bluesky’s CEO. The panelists shared their perspectives on various career paths, including finance, technology, and entrepreneurship, and provided attendees with a platform to explore different career opportunities.",
          `Furthermore, Yisu gave a speech at the event, sharing his professional journey and highlighting the importance of technology and company culture in driving business success. When explaining what has led to IntelliPro Group's success, Yisu stressed that "Technology only contributes 5% to our work. The rest of the 95% is culture. We make everyone feel like they are the owner of this company. We value the opinions of our customers and employees."`
        ],
        images: [blog13Image2, blog13Image3]

      },
      {
        // subtitle: 'Future is bright',
        textWithLink: [
          {
            isBold: true,
            beforeLink: 'Check out the ',
            link: {
              text: `link here`,
              link: 'https://www.linkedin.com/posts/intellipro_intelliprogroup-careersuccess-technology-activity-7026931726299893760-udGf?utm_source=share&utm_medium=member_desktop'
            },
            afterLink: ' to see a preview of Yisu Jin\'s speech at the Hurun U30 Award Ceremony and East Coast Summit!'
          }
        ],
      },
      {
        subtitle: `About Hurun U30`,
        text: [
          "The Hurun U30 Award Ceremony and East Coast Summit is organized by Hurun, a research, media, and investment group that has been promoting entrepreneurship since 1999. Established in the UK, Hurun has a major presence in China, India, France, Australia, Japan, and Luxembourg. In 2020, Hurun generated 8 billion combined impressions both online and offline, a year-on-year increase of 50%, on the back of providing lists and research reports. With over 22 years of experience in China and 6 years globally, 2022 is the year Hurun officially entered the North American market.",
          `The Hurun U30s Awards have been held in China since 2017 and this year were held across 10 countries, including China, India, the US, Canada, Germany, Spain, South Korea, UAE, Australia, and the UK. The awards recognize the achievements of young entrepreneurs under the age of 30 who have made significant contributions to their respective industries. The summit provides a unique platform for young entrepreneurs and industry experts to connect, share their experiences, and learn from each other, all while being surrounded by the energy and excitement of like-minded individuals.`
        ],
      }

    ],
    reference: []
  },
  {
    id: 14,
    content: [
      {
        subtitle: '',
        text: [
          `Hesai Technology, a prominent manufacturer of LIDAR products used in autonomous vehicles and advanced assisted driving systems, recently hosted a panel session on February 13th to discuss their rapid growth and success in the industry. With over $200 million in funding and a market capitalization of more than $2.6 billion, Hesai has established itself as a leader in the market, shipping more than 10,000 units per month.`,
          `Moderated by Yisu Jin, CEO and founder of IntelliPro, and Siwei Shen, Senior Director of Engineering at Coinbase, the session featured three of Hesai's top executives, CEO Yifan Li, Chief Scientist Kai Sun, and CTO Shaoqing Xiang, who shared their insights on the company's success journey. Each of these leaders has an impressive background, with a deep expertise in their respective fields, which has helped position Hesai as a market leader in the LIDAR industry.`
        ],
        images: [blog14Image1, blog14Image2],

      },
      {
        subtitle: `Meet the Founders of Hesai Technology: Driving Growth in the LIDAR Industry`,
        text: [
          `Yifan Li, the CEO of Hesai, is a remarkable leader whose impressive achievements have gained him global recognition. Armed with a Ph.D. in Robotics from UIUC, Yifan has made significant contributions to the field of Mechanical Engineering and Robotics. He has been honored with several prestigious awards, including being recognized as a "2021 Global Young Leader" by the World Economic Forum, a coveted spot on Fortune's "40 Under 40 Business Elite in China," and a Red Dot Design Award from Germany. Before co-founding Hesai, Yifan served as the Chief Engineer at Western Data Group, where he honed his skills and developed a passion for building innovative solutions.`,
          `Kai Sun, Hesai's Chief Scientist, is a laser and new detection technology expert with a Ph.D. in Mechanical and Electrical Engineering from Stanford University. He has a background in Mechanical & Power Engineering from Shanghai Jiao Tong University and his research has earned him the Best Paper Award from Measurement Science and Technology.`,
          `Shaoqing Xiang, Hesai's CTO, is a mastermind in circuit system design, with a Bachelor's degree in Precision Instruments and Mechanics from Tsinghua University, and dual Master's degrees in Electrical Engineering and Mechanical Engineering from Stanford University. He has a strong track record of innovation and played a significant role in the prototype design, technology development, and production line setup of multiple generations of the iconic iPhone. Prior to joining Hesai, Shaoqing was the head of next-generation consumer product concept R&D at Samsung Research Center, where he honed his expertise in bringing cutting-edge technology to life.`
        ],
        images: [blog14Image3, blog14Image4]

      },
      {
        subtitle: `Creating a Strong Vision for Success`,
        text: [
          `During the panel session, Hesai's three founders provided valuable insights into the company's growth and success from their unique perspectives. They shared their entrepreneurial journey and how they formed a cohesive team to establish Hesai as a market leader in LIDAR technology. The panelists highlighted the importance of having a strong vision and how they communicated it to their team and supporters, which has been critical to Hesai's sustained growth. The session provided an illuminating and insightful exposition of Hesai Technology's remarkable success story and the visionary individuals behind it. With their innovative products and dedicated team, Hesai Technology is poised for continued success in the LIDAR industry.`
        ]
      },
      {
        subtitle: `The Future of LIDAR Technology in the Autonomous Vehicle Market`,
        text: [
          `In summary, the Hesai Technology panel session offered a fascinating look into the company's evolution into a leading force in the LIDAR industry. Through a combination of a robust vision, cutting-edge products, and a dedicated team, Hesai has achieved impressive growth and success. The founders' entrepreneurial spirit and passion for their mission are a source of inspiration for other industry players. As the autonomous vehicle market continues to expand, Hesai Technology is poised to make even more significant contributions to the field of LIDAR technology.`
        ]
      },
    ],
    reference: []
  },
  {
    id: 15,
    content: [
      // {
      //     subtitle: '',
      //     text: [],
      //     image: blog15Image1,
      //
      // },
      {
        subtitle: '',
        text: [
          {
            isRichText: true,
            content: `<div>
<a href="https://www.intelliprogroup.com/aboutus" style="color: rgb(55, 125, 237)" target="_blank" rel="noopener noreferrer" aria-label="reference link">IntelliPro Group</a> is a talent acquisition and HR solutions firm that was founded in 2009. With over 100 recruiters in North America and more than 500 consultants worldwide, IntelliPro is one of the fastest-growing companies in this field. Since establishing its presence in Montreal in 2021, IntelliPro Montreal has become the top staffing choice for both leading global corporations and emerging tech startups.
</div>`
          },
          {
            isRichText: true,
            content: `<div>
In 2022, IntelliPro Group generated over $100 million in revenue by providing a wide range of agile delivery services, including <a href="https://www.intelliprogroup.com/recruiting" style="color: rgb(55, 125, 237)" target="_blank" rel="noopener noreferrer" aria-label="reference link">full-time and contingent workforce recruitment</a>,  RPO, BPO, Consulting, and <a href="https://www.intelliprogroup.com/service" style="color: rgb(55, 125, 237)" target="_blank" rel="noopener noreferrer" aria-label="reference link">a variety of HR services</a> such as GEO, PEO, and EOR solutions to help businesses streamline operations and improve efficiency. Their services feature project-oriented client management and single-service level agreements.
</div>`
          },
          {
            isRichText: true,
            content: `<div>
Since opening, the Montreal team has successfully developed a strong and thriving culture of IntelliPro Recruiters and Account Managers under the leadership of <a href="https://www.linkedin.com/in/gdion/" style="color: rgb(55, 125, 237)" target="_blank" rel="noopener noreferrer" aria-label="reference link">Guillaume Dion</a>. This team is dedicated to serving businesses and people in Montreal and greater areas by providing top-notch HR solutions and job opportunities.
</div>`
          },
          {
            isRichText: true,
            content: `<div>
<b>"We would like to impact the city of Montreal in helping foreign businesses establish themselves and also offer more hiring options to local businesses,"</b> said Guillaume Dion, manager of IntelliPro Montreal. <b>"We also strive to help people find better jobs and provide great candidate experience through our processes and tips."</b>
</div>`
          },
          {
            isRichText: true,
            content: `<div>
<a href="https://www.linkedin.com/in/gabriel-ribeiro1984/" style="color: rgb(55, 125, 237)" target="_blank" rel="noopener noreferrer" aria-label="reference">Gabriel Ribeiro</a>, Account Director at IntelliPro Montreal, has been a professional recruiter for 10 years, specializing in IT, Sales, Marketing, and C-Level headhunting. Gabriel hopes to <b>attract local and global interest in Montreal's multicultural talent, bridge the gap of talent scarcity, and make Montreal a powerhouse in terms of economic investment</b>. Additionally, Gabriel aims to assist companies with their recruitment strategy, from sourcing the best talent to candidate onboarding and talent retention. IntelliPro Montreal's recruitment expertise includes technology, pharmaceuticals, corporate (HR, TA, finance, procurement, etc.), sales and marketing, C-Level headhunting, and staff augmentation.
</div>`
          },
        ],
      },
      {
        subtitle: 'Our Partnership',
        text: [
          `IntelliPro Group forged alliances with Montreal International and Montreal New Tech in 2022 to accelerate their growth by tapping into the expertise and resources of these two organizations. By aligning with the vision of Montreal International and Montreal New Tech to foster innovation and support high-tech businesses in Montreal, IntelliPro Group's focus on AI-powered HR solutions becomes a strategic move that positions them as a frontrunner in the HR technology domain while contributing to the economic advancement of the region.`
        ]

      },
      {
        subtitle: 'About Intellipro',
        text: [`IntelliPro Group's global presence, with professional recruiters in over 40 cities and 13 countries, enables the organization to provide expert services to clients in any industry, anywhere in the world. The company's global vision and recruitment strategies make it a truly multicultural brand. According to Dion, the team speaks a variety of languages, including Mandarin, Cantonese, Korean, Japanese, French, and Spanish.`],
        fullImage: blog15Image2,
      },
      {
        text: [`IntelliPro Group's mission is to create top talent solutions by providing the best tailored and individualistic services. With market expertise and powerful proprietary technology, the company creates opportunities for both clients and candidates with their best interests in mind. With headquarters in Silicon Valley, USA, IntelliPro Group is at the forefront of innovation and continuously seeks to improve its services to remain an industry leader.`]
      },
      {
        subtitle: 'Meet Our Team',
        members: [
          {
            photoLink: blog15Image3,
            name: 'Yisu Jin',
            title: 'CEO',
            social: {
              type: 'linkedin',
              link: 'https://www.linkedin.com/in/yisujin/'
            }
          },
          {
            photoLink: blog15Image4,
            name: 'Guillaume Dion',
            title: 'Manager of IntelliPro Montreal',
            social: {
              type: 'linkedin',
              link: 'https://www.linkedin.com/in/gdion/'
            }
          },
          {
            photoLink: blog15Image5,
            name: 'Gabriel Ribeiro',
            title: 'Account Director at IntelliPro Montreal',
            social: {
              type: 'linkedin',
              link: 'https://www.linkedin.com/in/gabriel-ribeiro1984/'
            }
          }
        ]
      },
      {
        text: [
          {
            isRichText: true,
            content: `<b>To find out more about IntelliPro Group, please <a href="https://www.intelliprogroup.com/files/_IPG Global Solutions 2023 (EN).pdf" style="color: rgb(55, 125, 237)" target="_blank" rel="noopener noreferrer" aria-label="file">click here</a>.</b>`,

          }
        ]
      }

    ],
    reference: []
  },
  {
    id: 16,
    content: [
      {
        subtitle: '',
        text: [
          {
            isRichText: true,
            content: `<div>
<a href="https://www.intelliprogroup.com/aboutus" style="color: rgb(55, 125, 237)" target="_blank" rel="noopener noreferrer" aria-label="reference link">IntelliPro Group</a> est une entreprise qui fournit des solutions en ressources humaines et acquisition de talents depuis 2009. Avec plus de 100 recruteurs en Amérique du Nord et plus de 500 consultants dans le monde, elle est l'une des entreprises les plus en croissance dans son domaine. Depuis 2021, IntelliPro Montréal est devenue un choix de premier plan pour les grandes entreprises mondiales ainsi que les startups technologiques émergentes.
</div>`
          },
          {
            isRichText: true,
            content: `<div>
En 2022, IntelliPro Group a généré plus de 100 millions de dollars de revenus en fournissant une large gamme de services de livraison agile, notamment le <a href="https://www.intelliprogroup.com/recruiting" style="color: rgb(55, 125, 237)" target="_blank" rel="noopener noreferrer" aria-label="reference link">recrutement de travailleurs à temps plein et temporaires</a>, la RPO, la BPO, le consulting, <a href="https://www.intelliprogroup.com/service" style="color: rgb(55, 125, 237)" target="_blank" rel="noopener noreferrer" aria-label="reference link">ainsi qu'une variété de services RH</a> tels que les solutions GEO, PEO et EOR pour aider les entreprises à rationaliser leurs opérations et à améliorer leur efficacité. Leurs services sont caractérisés par une gestion de projet axée sur le client et des accords de niveau de service uniques.
</div>`
          },
          `Depuis son ouverture, l'équipe de Montréal a développé avec succès une culture forte et prospère de recruteurs et de gestionnaires de compte IntelliPro sous la direction de Guillaume Dion. Cette équipe est dédiée à servir les entreprises et les personnes de Montréal et des zones environnantes en fournissant des solutions RH de qualité supérieure et des opportunités d'emploi.`,
          `«Nous souhaitons avoir un impact sur la ville de Montréal en aidant les entreprises étrangères à s'implanter et en offrant également plus d'options d'embauche aux entreprises locales», a déclaré Guillaume Dion, directeur d'IntelliPro Montréal. «Nous nous efforçons également d'aider les gens à trouver de meilleurs emplois et à offrir une excellente expérience candidat grâce à nos processus et conseils.»`,
          {
            isRichText: true,
            content: `<div>
<a href="https://www.linkedin.com/in/gabriel-ribeiro1984/" style="color: rgb(55, 125, 237)" target="_blank" rel="noopener noreferrer" aria-label="reference link">Gabriel Ribeiro</a>, directeur de compte chez IntelliPro Montréal, est un recruteur professionnel depuis 10 ans, spécialisé dans les domaines de l'informatique, des ventes, du marketing et de la chasse de cadres supérieurs. Gabriel espère attirer l'intérêt local et mondial pour le talent multiculturel de Montréal, combler le fossé de la rareté des talents et faire de Montréal une puissance en termes d'investissement économique. De plus, Gabriel vise à aider les entreprises dans leur stratégie de recrutement, de la recherche des meilleurs talents à l'intégration des candidats et à la rétention des talents. L'expertise en recrutement d'IntelliPro Montréal comprend la technologie, les produits pharmaceutiques, le secteur des entreprises (RH, TA, finances, achats, etc.), les ventes et le marketing, la chasse de cadres supérieurs et l'augmentation de personnel.</div>`
          },
        ],
      },
      {
        subtitle: 'Notre partenariat',
        text: [
          `En 2022, IntelliPro Group s'est associé avec Montreal International et Montreal New Tech pour accélérer leur croissance en exploitant l'expertise et les ressources de ces deux organisations. En se alignant avec la vision de Montreal International et Montreal New Tech pour favoriser l'innovation et soutenir les entreprises de haute technologie à Montréal, IntelliPro Group devient un leader dans le domaine de la technologie RH tout en contribuant à l'avancement économique de la région.`
        ]

      },
      {
        subtitle: 'À propos d\'IntelliPro',
        text: [
          `La présence mondiale d'IntelliPro, avec des recruteurs professionnels dans plus de 40 villes et 13 pays, permet à l'organisation de fournir des services d'experts à des clients dans n'importe quelle industrie, partout dans le monde. La vision globale et les stratégies de recrutement de l'entreprise en font une marque vraiment multiculturelle. L'équipe parle une variété de langues, notamment le mandarin, le cantonais, le coréen, le japonais, le français et l'espagnol.`,
          `La mission d'IntelliPro est de créer des solutions de talents de haut niveau en fournissant les meilleurs services sur mesure et individualisés. Avec une expertise du marché et une technologie exclusive puissante, l'entreprise crée des opportunités pour les clients et les candidats en ayant leurs meilleurs intérêts en tête. Avec son siège social dans la Silicon Valley, aux États-Unis, IntelliPro Group est à l'avant-garde de l'innovation et cherche continuellement à améliorer ses services pour rester un leader de l'industrie.`
        ],
        fullImage: blog15Image2,
      },
      {
        text: [
          `La mission d'IntelliPro Group est de créer des solutions de talents de haut niveau en fournissant les meilleurs services sur mesure et individualisés. Avec une expertise du marché et une technologie exclusive puissante, l'entreprise crée des opportunités pour les clients et les candidats en ayant leurs meilleurs intérêts en tête. Avec son siège social dans la Silicon Valley, aux États-Unis, IntelliPro Group est à l'avant-garde de l'innovation et cherche continuellement à améliorer ses services pour rester un leader de l'industrie.`]
      },
      {
        subtitle: 'Rencontrez notre équipe',
        members: [
          {
            photoLink: blog15Image3,
            name: 'Yisu Jin',
            title: 'PDG et Fondateur, Intellipro Group',
            social: {
              type: 'linkedin',
              link: 'https://www.linkedin.com/in/yisujin/'
            }
          },
          {
            photoLink: blog15Image4,
            name: 'Guillaume Dion',
            title: 'Directeur, IntelliPro Montreal',
            social: {
              type: 'linkedin',
              link: 'https://www.linkedin.com/in/gdion/'
            }
          },
          {
            photoLink: blog15Image5,
            name: 'Gabriel Ribeiro',
            title: 'Directeur de compte, IntelliPro Montreal',
            social: {
              type: 'linkedin',
              link: 'https://www.linkedin.com/in/gabriel-ribeiro1984/'
            }
          }
        ]
      },
      {
        text: [
          {
            isRichText: true,
            content: `<b>To find out more about IntelliPro Group, please <a href="https://www.intelliprogroup.com/files/IPG Global Solutions 2023 (FR).pdf" style="color: rgb(55, 125, 237)" target="_blank" rel="noopener noreferrer" aria-label="file">click here</a>.</b>`,

          }
        ]
      }

    ],
    reference: []
  },
  {
    id: 17,
    content: [
      {
        fullImage: blog17Image1
      },
      {
        subtitle: 'Overview',
        text: [`A Fortune 500 Telecommunications company joined forces with IntelliPro to recruit software engineers and lab research assistants for two critical projects: the Device Cycle and APP Test Automation Program, and the T-Product Statement of Work (SOW) Project. This strategic alliance was crucial to secure the required skilled workforce for the project's success. Through its partnership with IntelliPro, the company gained access to a vast talent pool, streamlining the recruitment process and increasing its efficiency and effectiveness.`]
      },
      {
        subtitle: 'Challenges',
        text: [
          `The recruitment process for the company was not without its hurdles, as they faced three significant challenges.`
        ],
        list: [
          {
            boldText: 'Location Restraints: ',
            text: `Difficulties in hiring due to a shift in its strategy and a limited global talent pool, exacerbated by a shift in on-site location requirements. This challenge posed a significant obstacle to hiring the right talent, as the company needed professionals with the right skillset who were also willing to work on-site.`,
            inline: true
          },
          {
            boldText: 'Passive Recruitment: ',
            text: `Intense competition for top talent, given that many qualified professionals were already employed and not actively seeking new job opportunities. This challenge required a creative approach to recruitment, ensuring that the company attracted the attention of the most suitable candidates for the role.`,
            inline: true,
          },
          {
            boldText: 'Specialized Technical Knowledge: ',
            text: `The company required software engineers with specialized technical knowledge in Android and Java, as well as lab research assistants with Automation Testing and Radio Testing skills. However, due to the competitive nature of the industry, it proved challenging for the company to attract candidates with these specific skills.`,
            inline: true
          }
        ]
      },
      {
        subtitle: 'Project Details',
        text: [],
        numberedText: [
          {
            boldText: 'Device Cycle & APP Test Automation Program:',
            text: 'The team designed TCAP, an internal website that provided clients with valuable insights into various metrics, including call drop rate, frequency, bandwidth, lab week devices, and ta-letters. The website enabled engineers to identify and address issues with greater efficiency, resulting in a more streamlined and effective process.'
          },
          {
            boldText: 'T-Products (T-CAP, T-SAIL, T-SPDR):',
            text: `As part of the T-Product Statement of Work (SOW) Project, the team developed a comprehensive dashboard based on specific instructions from the automation team lead. This dashboard included advanced features such as device cycle categorization, DIGITS, SMR, MR categories, and the top 5 failures per device. Other features included test environment downtime, passed execution results and status, among others. This dashboard provided a wealth of information that enabled the company to optimize its processes and ensure efficient project delivery.`
          }
        ]

      },
      {
        subtitle: 'Outcome',
        list: [
          {
            text: 'Achieved 75% interview rate; received submissions within 24-48 hrs',
          },
          {
            text: `Hired 5 skilled software engineers and research assistants`
          },
          {
            text: `80% retention rate over 12 months`
          }
        ]
      },
      {
        text: ['By partnering with IntelliPro, the Fortune 500 Telecommunications Company was able to overcome its recruitment challenges and secure the specialized talent it needed for its Device Cycle and APP Test Automation Program and T-Product Statement of Work (SOW) Project. These exceptional hires were instrumental in driving the success of the project and meeting its demands.']
      }
    ],
    reference: []
  },
  {
    id: 18,
    content: [
      {
        text: [
          `On February 3, 2023, the Harvard Chinese Students and Scholars Association hosted the Ivy League Spring 2023 Online Job Fair in partnership with IntelliPro Group and Uoffer. The event brought together over 700 students, job seekers, and senior professionals from top universities and leading domestic and foreign companies.`,
          `The event was designed to help connect top young scholars and leaders with Uoffer's resources in Silicon Valley, Wall Street, and Greater China. Attendees received valuable insights and guidance on job searching and career development from high-profile guests, including Ed Flynn, Chairman of the Boston City Council. The job fair also featured senior guests from leading companies such as Cisco, AWS, Workday, and Meta, who shared their work experience and provided valuable references for students looking for jobs.`,
          `IntelliPro Group, the largest bilingual headhunting and HR solutions company in North America, was recognized with the "Asian Business and Professional Growth Pioneer Award" for their special contributions as Asian business leaders and commitment to advancing the career of talent through service, innovative leadership, and the power of technology. CEO Yisu Jin received the "Asian Leadership & Cultural Innovator Award" for his outstanding business achievements over the past 13 years with the pioneering spirit and leadership representative of the Asian community.`
        ],
        fullImage: blog18Image1

      },
      {
        text: [
          `"We recognize you on staffing, consulting, and the influence in shaping the future. You brought diverse communities together, giving them the opportunities to work and to contribute to society," says Ed Flynn.`,
          `"It’s such an honor receiving the awards from the Boston City Council. The awards are an encouragement not only to me, to my company, but also to the entire Asian American society in the United States. This is an assurance that as long as we, the minority, work hard together and support each other, we can make our community and this country better and better," says Yisu.`,
          `The job fair was an opportunity for attendees to learn more about the cutting-edge technologies and innovative solutions offered by IntelliPro and Uoffer. As a company that is committed to driving growth and innovation in the technology industry, IntelliPro Group recognizes the critical role of talent development and community co-prosperity. They believe in empowering individuals with the skills and knowledge they need to succeed in their chosen fields. This belief is reflected in their efforts to organize job fairs and other initiatives that promote career growth and development.`
        ],
        images: [blog18Image2, blog18Image3]
      },
      {
        text: [
          `The Ivy League Spring 2023 Online Job Fair was a platform to showcase job prospects and guide the professional growth of the Asian demographic. It was a great opportunity for attendees to connect with industry leaders, gain insights into the latest trends and developments in the technology sector, and learn from the experience and lessons of successful professionals. The event exemplified the importance of empowering talent through technology and innovation and demonstrated how the commitment to community co-prosperity can contribute to the success of individuals and organizations alike.`
        ]
      }
    ],
    reference: []

  },
  {
    id: 19,
    content: [
      {
        subtitle: 'Elevate Your Career Through University Career Fairs',
        text: [
          `University career fairs serve as a vital hub for both job seekers and employers, providing a platform for networking and exploring potential opportunities. Recently, IntelliPro Group and Uoffer participated in two prestigious university career fairs hosted by UCLA and USC. In this article, we offer insights into our experiences at both events and share valuable advice to help job seekers optimize their career fair experience.`
        ]
      },
      {
        subtitle: 'Recap: A Closer Look at the UCLA and USC Career Fairs',
        text: [
          `The recent career fairs hosted by UCLA and USC on March 27th and April 7th were a great success. With over 40 companies participating and thousands of attendees, it provided an excellent platform for individuals to explore various industries and connect with potential employers.`,
          `Intellipro representatives were among the many companies in attendance and had the opportunity to network with numerous talented individuals. As a result, they were able to establish potential collaborations, internships, and even full-time positions with some of the attendees.`
        ],
        images: [
          blog19Image1,
          blog19Image2
        ]
      },
      {
        text: [
          `If you're planning to attend a career fair in the future, here are some tips to help you prepare:`
        ]
      },
      {
        subtitle: 'Essential Tips for Job Seekers: Preparing for Your Next Career Fair',
        numberedText: [
          {
            text: `Research the participating companies: Review the list of companies attending the fair and research their values, mission, and job openings. This will help you identify companies that align with your career goals and ensure you're well-prepared to engage with their representatives.`
          },
          {
            text: `Prepare your resume and elevator pitch: Ensure your resume is updated, polished, and tailored to the type of job you're seeking. Additionally, practice your elevator pitch, a brief summary of your skills and experience, to introduce yourself to employers.`
          },
          {
            text: `Dress professionally: Dress professionally to make a good first impression and show that you're serious about your career.`
          },
          {
            text: `Follow up: After the event, follow up with the representatives you met to express your interest in their company and thank them for their time. This will help you stay top of mind and potentially lead to further opportunities.`
          },
          {
            text: `Network with other attendees: Take the opportunity to network with other attendees, as they may be able to provide valuable insights and connections to help you advance your career.`
          },
          {
            text: `Attend informational sessions: Many career fairs offer informational sessions and workshops where you can learn about industry trends and job search strategies. Attend these sessions to gain new insights and perspectives.`
          },
          {
            text: `Be prepared to ask questions: Come prepared with questions for the representatives you meet, such as the company's culture, career advancement opportunities, and job requirements. This will show your interest in the company and help you better assess whether it's a good fit for you.`
          },
          {
            text: `Be confident: Remember to be confident and enthusiastic throughout the event. Employers are looking for candidates who are passionate about their careers and eager to learn.`
          },
        ]
      },
      {
        text: [`In conclusion, attending a career fair can be a valuable opportunity to explore different career paths and connect with potential employers. By following these tips, you can optimize your experience and increase your chances of securing your dream job.`]
      }
    ],
    reference: []

  },
  {
    id: 20,
    content: [
      {
        text: [`On April 8th Yisu Jin , the Founder & CEO of IntelliPro and Uoffer,  delivered a captivating speech at the Harvard College China Forum! This annual event is held at the prestigious Harvard Sanders Theater, which has a 150-year history of hosting speeches by luminaries such as Churchill and Martin Luther King.`],
        video: blog20Video1
      }, {
        text: [
          `Yisu's speech touched on a range of topics, drawing from his experiences as the CEO of IntelliPro and Uoffer, and his unique perspective on the intersection of technology, talent, and innovation. He shared insights on the latest trends in the tech industry, emphasizing the importance of staying ahead of the curve in a rapidly changing landscape. Yisu also spoke about the role of talent in driving innovation, and how organizations can create a culture of innovation to foster growth and success. Drawing from his experience leading both established and startup companies, he provided valuable advice on building and scaling successful businesses. Overall, Yisu's speech was thought-provoking and inspiring, exploring the opportunities and challenges facing the tech industry today.`,
          `Yisu was joined on the same stage by other prominent guests, including Jian Ding from GSR Ventures China, Yusen Dai from ZhenFund, John Oyler, the CEO of BeiGene USA, Grace King, SVP of Morgan Stanley, and Dinda Elliott, Executive Director of the Fairbank Center for Chinese Studies.`
        ],
        fullImage: blog20Image1
      },
      {
        text: [
          `IntelliPro's participation in the Harvard China Forum reflects our commitment to thought leadership and innovation in the HR solutions consulting industry. It also highlights our strong reputation and influence in the global business community.`,
          `We would like to express our gratitude to the organizers of the Harvard China Forum and EY's Managing Partner, Xiaoqing Zhang, for their gracious invitation. We are also deeply grateful to Facebook's Senior Director Xin Fu, Doordash's Senior Director Jun Cao, EY's Managing Director Annie, and all those who contributed to the success of this event through their enthusiastic participation and unwavering support.`,
          `As a global leader in HR solutions consulting, IntelliPro and Uoffer remain committed to staying ahead of the curve and providing innovative solutions to our clients. Our participation in the Harvard China Forum is just one example of our dedication to thought leadership and excellence in the industry. We look forward to continuing to provide our clients with the best possible solutions to their workforce challenges.`
        ]
      }
    ],
    reference: []
  },
  {
    id: 21,
    content: [
      {
        text: [
          `Intellipro, a leading global integrated talent solution provider with a diverse culture, operates in 15 countries with more than 300 senior consultants and over 1000 global contractors. The Shanghai office, located in the heart of the city, not only boasts an ideal location and modern facilities, but also houses a dynamic team of young and talented professionals. In this blog post, we'll take you on a virtual tour of Intellipro's Shanghai office and introduce you to the energetic team that drives the company's success in the region.`
        ],
        fullImage: blog21Image1
      },
      {
        subtitle: `The K11 Building: A Prime Location for Business and Culture`,
        text: [
          `Intellipro's Shanghai office is located in the K11 Building, a prestigious property situated in the central area of Huangpu District. This strategic location offers convenient transportation options, making it easy for employees to commute and clients to visit. The K11 Building is a comprehensive complex that combines art, culture, and business, providing an inspiring environment for companies and their employees.`,
          `Furthermore, the building is equipped with advanced property management and security systems, ensuring everyone can enjoy a safe and comfortable office environment. This combination of factors makes the K11 Building an ideal location for Intellipro's Shanghai office.`
        ],
        images: [blog21Image2, blog21Image3]
      },
      {
        subtitle: `A Dynamic and Multilingual Team`,
        text: [
          `One of the key strengths of Intellipro's Shanghai office is its young, dynamic team of consultants. With an impressive 80% of them being proficient in English, the team is well-equipped to connect with international businesses and support Intellipro's diverse clientele. This linguistic prowess also contributes to the company's global outlook and reinforces its diverse culture.`,
          `The Shanghai team possesses an open-minded mindset, strong execution ability, and excellent learning abilities. These qualities enable them to maintain high service quality and continuously improve the customer experience, even in the face of the rapidly changing market.`
        ],
        images: [blog21Image4, blog21Image5]
      },
      {
        subtitle: `Embracing Diversity and Global Outlook`,
        text: [
          `Intellipro takes pride in being a global company that embraces a diverse culture. The Shanghai office is a testament to this commitment, with its team of professionals boasting international experience and collaborating effectively to drive the company's success. This diverse and inclusive environment fosters collaboration, innovation, and growth, helping the company to stay ahead in the competitive marketplace.`
        ]
      },
      {
        subtitle: ' ',
        text: [
          `Intellipro's Shanghai office, located in the K11 Building, is a thriving hub of talent and innovation. The young, dynamic, and multilingual team at the Shanghai office brings a unique blend of skills and perspectives that contribute to the company's global outlook and diverse culture. As Intellipro continues to expand its presence around the world, the Shanghai office stands as a shining example of the company's dedication to providing exceptional service and fostering a diverse and inclusive work environment.`
        ]
      }
    ],
    reference: []
  },
  {
    id: 22,
    content: [
      {
        text: [
          `At IntelliPro, we owe our success to the exceptional talent and experience of our team members. In this blog, we are excited to introduce the Business Development Team, a group of diverse and dynamic professionals who are dedicated to cultivating relationships, managing accounts, and unlocking new opportunities for our clients. This team consists of outstanding members who bring a wealth of experience and expertise to the table, and we look forward to introducing them to you.`,
          ' '
        ],
        members: [
          {
            photoLink: blog22Image1,
            name: 'Antoine Brown',
            title: 'Global Vice President, Business Engagement',
            social: {
              type: 'linkedin',
              link: 'https://www.linkedin.com/in/antoine-brown-22729231/'
            }
          }
        ]
      },
      {
        text: [
          `Antoine Brown has earned his place as a powerhouse in the staffing solutions industry, boasting 15 years of experience. His extensive background has enabled him to establish valuable relationships with the world's top Fortune 1000 companies. Antoine's expertise across direct, indirect, and OEM & MBE Partner channels has been instrumental in propelling businesses to extraordinary heights within the tech industry.`,
          ' ', ' '
        ],
        members: [
          {
            photoLink: blog22Image2,
            name: 'Duane Mack',
            title: 'Senior Business Engagement Manager',
            social: {
              type: 'linkedin',
              link: 'https://www.linkedin.com/in/duanejmack/'
            }
          }
        ]
      },
      {
        text: [
          `With nearly three decades of diverse experience in technology sales and management, Duane Mack has become a master navigator of the intricate tech landscape. His vast knowledge encompasses various sectors, from Paging and Wireless to Staffing and Managed Services. Duane's proven track record in direct, indirect, and OEM & MBE Partner channels has empowered numerous businesses to achieve exceptional success.`,
          ' ', ' '
        ],
        members: [
          {
            photoLink: blog22Image3,
            name: 'Sam Kapoor',
            title: 'Senior Business Engagement Manager',
            social: {
              type: 'linkedin',
              link: 'https://www.linkedin.com/in/shishir-sam-kapoor/'
            }
          }
        ]
      },
      {
        text: [
          `Sam Kapoor's invaluable expertise in managing MSP/VMS accounts has made him an essential asset to the IntelliPro team. His strong connections with Fortune 1000 organizations and close collaboration with our Recruiting team have led to fruitful partnerships with MSP industry leaders like KellyOCG, Agile1, Magnit, ACRO, Pinnacle, AGS etc. Sam's remarkable relationship-building skills, clear communication, and effective team leadership have played a crucial role in driving success for our clients.`,
          ' ', ' '

        ],
        members: [
          {
            photoLink: blog22Image4,
            name: 'Dan Schreiber ',
            title: 'Senior Business Engagement Manager',
            social: {
              type: 'linkedin',
              link: 'https://www.linkedin.com/in/dan-schreiber-bio/'
            }
          }
        ]
      },
      {
        text: [
          `Dan Schreiber's unique background in Biology and Chemistry, combined with over 15 years of professional staffing experience, brings a fresh perspective to the business development team. His wide-ranging expertise in staffing positions, from full desk recruiter to Regional Sales Director for National Accounts, has honed his proficiency in the Pharma, BioTech, and Med Device industries. Dan's specialty lies in working with MSP programs and implementing customized strategies for businesses of all sizes, ensuring our clients have access to unparalleled opportunities.`,
          ' ',
          `Our dedicated Business Development Team is focused on driving our clients' success. With a wealth of expertise and experience, IntelliPro is well-positioned to deliver exceptional service and support in today's competitive business landscape. Contact us today to discover how our team can help take your business to new heights!`
        ]
      }
    ],
    reference: []
  },
//     {
//         id: 23,
//         content: [
//             {
//                 text: [],
//                 fullHeightImage: blog23Image1
//             },
//             {
//                 text: [
//                     {
//                         isRichText: true,
//                         content: `<div>
// Six years ago, IntelliPro Group welcomed <a href="https://www.linkedin.com/in/ellazheng/" style="color: rgb(55, 125, 237)" target="_blank" rel="noopener noreferrer" aria-label="reference link">Ella Zheng</a>. With her remarkable background in human resources and leadership, she has become a trusted partner for numerous leading global organizations across North America, Europe, China, and the Asia Pacific. Since joining our team, Ella has expertly orchestrated operations and processes across various countries and territories, including the US, Canada, Mexico, China, India, Singapore, Philippines, UK, Netherlands, and Germany. As IntelliPro's Global COO, she is accountable for optimizing the performance of all business divisions by employing her hands-on approach to operations management, talent management, performance evaluation, and business growth.</div>`
//                     },
//                     `Before joining IntelliPro, Ella co-founded Berry Consulting, serving as the General Manager for the executive search firm specializing in talent acquisition and professional recruitment across Hi-Tech, Internet, Industrial, Finance, and Healthcare industries. Her former role as the Regional Director for Manpower Group in Shanghai, China, enabled her to adeptly collaborate with clients, empowering them to achieve competitive advantages in their respective markets through strategic talent management, industry insights, and fostering enduring professional connections.`,
//                     `Ella's vast experience in global operations management has given her unique insights into the challenges faced by businesses in diverse regions. She leverages her knowledge and proficiency to devise streamlined and effective strategies that boost productivity and propel growth. Ella's exceptional leadership qualities and relationship-building abilities have earned the esteem and admiration of colleagues and clients alike.`,
//                     `As IntelliPro's Global COO, Ella is an indispensable driving force behind the company's remarkable outcomes and expansion across various countries and territories. With her hands-on management style, technical expertise, and exceptional leadership skills, she plays an instrumental role in operations, talent development, performance assessment, and business growth, making significant contributions to the business landscape for years to come.`
//                 ]
//             },
//             {
//                 subtitle: `Messages from Ella's Colleagues:`,
//                 membersComments: [
//                     {
//                         photoLink: blog23Image2,
//                         name: 'Steve Kou',
//                         title: 'Partner, IntelliPro Group',
//                         comments: [
//                             `"Ella, I want to extend my warmest congratulations on your 6th anniversary with our company. This is a momentous occasion, and one that deserves recognition and celebration.`,
//                             `Over the years, we've been through a lot together - ups and  downs. But through it all, your dedication and commitment to our team and company have been a constant source of inspiration and motivation. You've been a driving force behind our success; we couldn't have done it without you.`,
//                             `We've weathered many challenges, from tough market conditions to internal restructuring, but you've always led the charge and kept us on track. Your vision, guidance, and hard work have been instrumental in helping us navigate these choppy waters and emerge stronger and more resilient than ever before.`,
//                             `Congratulations on your six-year anniversary. I want to express deep gratitude for all you've done for our team and our company. You truly are an invaluable asset and leader, and we are so fortunate to have you with us. Here's to many more years of growth, success, and innovation together."`,
//                             ` `,
//                         ]
//                     },
//                     {
//                         photoLink: blog23Image3,
//                         name: 'Lilian Wang',
//                         title: 'Chief People Officer',
//                         comments: [
//                             `"Ella shows immense care, protection, and endurance towards everyone she works with. Her passion and genuine love for our industry and company are truly inspiring. She leads and inspires us to keep striving for greater success."`
//                         ]
//                     },
//                     {
//                         photoLink: blog23Image4,
//                         name: 'Edwin Chang',
//                         title: 'Head\u00A0of\u00A0Talent\u00A0Development',
//                         comments: [
//                             `"Meeting Ella was a blessing; she was the catalyst that brought me to IPG.  I've known her now for over two years; she's a great colleague, an amazing friend, and a wonderful person.  I look forward to working with her every day and wish her all the success in her future."`
//                         ]
//                     }
//                 ]
//
//             }
//         ]
//     },
  {
    id: 24,
    content: [
      {
        text: [`IntelliPro was honored to participate in the exclusive ClubVMSA Enterprise Roundtable event held on April 26-27, bringing together suppliers and buyers from diverse industries to discuss the current landscape and collaborate on finding solutions. We were thrilled to be among the 13 sponsors and to contribute to the event's intimate business atmosphere.`],
        images: [blog24Image1, blog24Image2]
      },
      {
        text: [
          `The event welcomed leaders from various sectors, including High Tech, Consumer Goods, Bio Tech Health Care, Hospitality, Manufacturing, Energy, and Finance. Despite the wide range of industries, the focal point was the contingent workforce. Participants addressed the challenges they faced in this area and how they were working to resolve them. It was intriguing to observe the potential applicability of these solutions across sectors and the collaborative efforts by attendees.`,
          `A standout moment was the fireside chat titled "Can't we all just get along: How TA and Procurement team together." Duane, IntelliPro's Senior Business Engagement Manager, shared his thoughts on the discussion: "This fireside chat was interesting in the fact that it's not an industry-specific issue. There often seems to be a real push and pull between TA and Procurement. The panelists described a situation where their approach to the same issue was very different, ultimately creating friction between the two departments, where it was vital to work together." The conversation emphasized the importance of communication, mutual understanding, and establishing clarity in each party's approach. This foundation was crucial for effective collaboration, with both parties working in unison to achieve shared goals.`
        ],
        images: [blog24Image3, blog24Image4]
      },
      {
        text: [
          `Breakout sessions delved into various subjects, such as "Early Adoption," "Change Management," "Stakeholder Engagement," and "Communication." These sessions were relevant to all industries, offering valuable insights on addressing challenges in the extended workforce space.`,
          `A key takeaway from the event was that the issues discussed transcended industry boundaries. Attendees contributed valuable perspectives on tackling these challenges. The gathering provided an excellent networking opportunity, fostering connections with industry professionals. Antoine Brown, IntelliPro's Global VP of Business Engagement, and Duane Mack, IntelliPro's Senior Business Engagement Manager, had the chance to forge valuable relationships and gain insights into the latest trends and challenges in the extended workforce arena.`
        ],
        images: [blog24Image5, blog24Image6]
      },
      {
        text: [
          `Antoine reflected on the experience, saying, "We made valuable connections with industry professionals and gained insights into the latest trends and challenges in the extended workforce space. We're grateful for this opportunity and excited to continue our partnership with ClubVMSA."`,
          `In summary, the ClubVMSA Enterprise Roundtable event proved to be a resounding success, and we are proud to have been involved. The event created a platform for industry professionals to unite, share experiences and insights, and develop solutions for challenges related to the extended workforce arena. We look forward to furthering our partnership with ClubVMSA and contributing to the growth and advancement of the extended workforce space.`
        ]
      }
    ],
    reference: []
  },
  {
    id: 25,
    content: [
      {
        text: [
          `In today's dynamic business environment, attracting and retaining top-tier talent is an imperative for maintaining competitive advantage. With the rapid pace of change and increasing business complexities, many organizations are exploring different strategies to optimize their hiring process. One such strategy that has gained significant traction is the outsourcing of recruitment. At IntelliPro, we understand the implications of this trend and today we aim to share some of the key benefits that outsourcing recruitment can bring to your business.`,
          ` `
        ]
      },
      {
        subtitle: 'Conserving Time and Resources',
        text: [
          `Efficient resource management lies at the core of any successful business. By outsourcing recruitment, you can achieve significant improvements in this area. The burden of creating compelling job postings, meticulously screening resumes, conducting interviews, and managing the onboarding process is lifted from your shoulders. Entrusting these activities to a professional recruitment agency allows your team to focus on core business functions and strategic initiatives, ultimately optimizing your operations.`
        ]
      },
      {
        subtitle: `Broadening Your Talent Pool`,
        text: [
          `Recruitment agencies possess a unique advantage when it comes to accessing a wide pool of talent. Their extensive networks and resources enable them to reach potential candidates beyond the limitations of traditional recruitment methods. By outsourcing recruitment, your business gains access to this broader talent pool, increasing the chances of finding the perfect fit for your specific needs.`
        ]
      },
      {
        subtitle: `Minimizing Hiring Risks`,
        text: [
          `Making a wrong hiring decision can have significant consequences for your business, both in terms of time and finances. Recruitment agencies specialize in identifying and vetting candidates, utilizing various tools and techniques to ensure that the candidates they recommend align with your business culture and job requirements. This expert approach greatly reduces the risk of making hiring mistakes.`
        ]
      },
      {
        subtitle: `Enhancing Employer Branding`,
        text: [
          `Outsourcing recruitment also presents an opportunity to boost your employer branding efforts. Recruitment agencies can assist in crafting engaging job postings and provide support throughout the hiring process. This creates a positive candidate experience that reflects well on your brand, regardless of whether the candidate is ultimately hired or not.`
        ]
      },
      {
        subtitle: `Offering Scalability and Flexibility`,
        text: [
          `The growth trajectory and size of a business significantly influence its recruitment needs. Outsourcing recruitment allows you the flexibility to adjust your hiring efforts according to your business requirements. This scalability ensures that your business can quickly adapt to changing needs and market conditions, without the need to invest in additional internal recruitment resources.`
        ]
      },
      {
        subtitle: `Cost-Effectiveness`,
        text: [
          `Outsourcing recruitment is a cost-effective solution applicable to businesses of all sizes. It eliminates the need to hire and train additional in-house recruitment staff, resulting in substantial cost savings. Moreover, it minimizes the potential financial implications of hiring mistakes, which can be significant in the long run.`
        ]
      },
      {
        text: [` `,
          `In conclusion, outsourcing recruitment offers a myriad of benefits tailored to help your business maintain a competitive edge in today's fast-paced and ever-evolving business landscape. By conserving resources, expanding your talent pool, mitigating hiring risks, bolstering employer branding, and providing cost-effective and scalable solutions, outsourcing recruitment can be a game-changer for your business.`,
          `At IntelliPro, we are dedicated to helping you navigate through these changing tides and empowering your business to reach its full potential. Contact us today to learn more about our outsourcing recruitment services and how we can assist you in streamlining your hiring process.`
        ]
      }
    ],
    reference: []
  },
  {
    id: 26,
    content: [
      {
        text: [
          `On May 18th, Tsinghua University hosted the highly anticipated TEDx THU event, boasting an incredible lineup of innovators and thought leaders from diverse fields, including China's first Winter Olympics gold medalist Yang Yang and Founding Director and Chair Professor of Tsinghua Medicine, Tien Yin Wong, among others. Standing tall amidst this illustrious ensemble was Yisu Jin, Founder and CEO of IntelliPro Group, who delivered a TEDx Talk that not only captivated the audience but also left them inspired and motivated.`
        ],
        images: [blog26Image1, blog26Image2]
      },
      {
        text: [
          `Yisu's remarkable journey, starting from humble beginnings to leading IntelliPro Group, is a true testament to his resilience, hard work, and unwavering dedication. As a continuous entrepreneur, the co-founder of SVLC, and the founder of several other ventures, Yisu embodies the essence of leadership and social responsibility.`,
          `During his engaging TEDx Talk, Yisu shared three pivotal life stories, each emphasizing moments that shaped his path to success. The first story recounted his college years, a time when his physical strength was challenged. Despite initial setbacks, Yisu's determination led him to excel in Physical Education, breaking records and proving his mettle.`,
          `His second story pivoted to his entrepreneurial journey, a chapter that began in 1997 after he moved to the United States to pursue his master's degree. Despite being armed with limited resources and lacking a concrete plan, Yisu plunged into the world of entrepreneurship. This venture was marked by relentless efforts, countless cold calls, and inevitable rejections. However, it was a single successful phone call that brought him his first Fortune 500 client and kicked off the success of IntelliPro. Today, IntelliPro is a global powerhouse, boasting a team of over 1,000 professionals  across 15 countries, and demonstrating remarkable resilience and growth, even amid economic uncertainties.`,
          `The final story Yisu shared took place at an industry conference where he stood out as one of the few minority attendees. Unfazed by this, Yisu utilized his unique background as a strength, seizing the opportunity to make a lasting impact. This proactive approach earned him the respect and recognition of his industry peers.`
        ],
        images: [blog26Image3, blog26Image4]
      },
      {
        text: [
          `Yisu's Tedx Talk resonated profoundly with the audience, highlighting his resilience, leadership acumen, and commitment to social responsibility. His journey serves as an invaluable blueprint for those aspiring to overcome adversity and effect meaningful change.`,
          `Under Yisu's visionary leadership, IntelliPro continues to uphold its commitment to being a leading human resources provider in North America. Despite being the smallest bar on the signal, as Yisu puts it, the company withstands the most, staying true to its founding mission and delivering top-notch services to clients and job seekers alike.`,
          `As Yisu steers the IntelliPro Group forward, exploring new avenues and breaking new ground, his experiences and insights have become an essential resource for the wider business community. His leadership reflects the company's core values of resilience, diligence, and innovation. With Yisu at the helm, IntelliPro aims to maintain its growth trajectory, challenge the status quo, and make a positive contribution to the tech industry.`
        ]
      }
    ],
    reference: []
  },
  {
    id: 27,
    content: [
      {
        text: [
          `In an era of unprecedented connectivity and globalization, businesses are no longer confined by geographical boundaries. With the world at their fingertips, companies now have the opportunity to tap into a diverse pool of global talent. However, along with this opportunity comes a unique set of challenges that must be navigated to successfully acquire and retain top-tier international professionals.`
        ],
        image: Blog27

      },
      {
        subtitle: `The Global Talent Landscape`,
        text: [
          `The global talent landscape is evolving at an astonishing pace. As organizations expand their reach beyond borders, the demand for skilled professionals is reaching new heights. This increasing demand is met with a limited supply of specialized talent, leading to heightened competition and a candidate-driven market. Whether you're a multinational corporation or a budding startup, the journey of acquiring global talent is fraught with complexities.`
        ],
      },
      {
        subtitle: `Navigating the Challenges`,
        numberedText: [
          {
            boldText: 'Cultural Nuances and Diversity 🌎',
            text: `One of the foremost challenges in global talent acquisition is understanding and embracing cultural diversity. Differences in work culture, communication styles, and expectations can influence the success of an international hire. Effective cross-cultural collaboration requires cultural sensitivity and the ability to foster an inclusive work environment.`
          },
          {
            boldText: 'Sourcing Strategies for Success 🔎',
            text: `Traditional sourcing strategies may fall short when targeting international talent. To overcome this challenge, businesses must adopt innovative approaches to identify and engage with potential candidates across borders. This may involve leveraging global job boards, social media platforms, and international networking events to create a robust talent pipeline.`
          },
          {
            boldText: 'Navigating Legal and Regulatory Complexities 📝',
            text: `Each country has its own set of employment laws, regulations, and visa requirements. Navigating these legal complexities can be a daunting task, especially for businesses expanding into new territories. Partnering with an Employer of Record (EOR) or Professional Employer Organization (PEO) can provide invaluable support in ensuring compliance and minimizing legal risks.`
          },
          {
            boldText: 'Remote Work and Virtual Collaboration 💻',
            text: `In a rapidly digitizing world, remote work has become a norm, allowing organizations to tap into global talent without the need for physical relocation. However, effective remote work and virtual collaboration demand the implementation of robust communication tools, remote management strategies, and a strong company culture that transcends physical boundaries.`
          },
        ],
        image: blog27Image2
      },
      {
        subtitle: `Strategies for Success`,
        numberedText: [
          {
            boldText: 'Cultural Integration and Training 🌐',
            text: `Invest in cultural integration programs and training to bridge cultural gaps and ensure smooth collaboration within a diverse workforce. Encouraging open dialogue and mutual understanding can enhance team cohesion and productivity.`
          },
          {
            boldText: 'Global Employer Branding 🌟',
            text: `Develop a strong global employer brand that resonates with international talent. Highlight your organization's commitment to diversity, inclusion, and growth opportunities, showcasing your company as an attractive destination for top professionals.`
          },
          {
            boldText: 'EOR/PEO Partnership 🤝',
            text: `Consider partnering with an EOR or PEO to navigate legal complexities, manage compliance, and facilitate international hires. These partnerships provide a seamless and compliant process for hiring and managing talent across borders.`
          },
          {
            boldText: 'Virtual Onboarding and Collaboration Tools 📲',
            text: `Invest in advanced virtual onboarding processes and collaboration tools that empower remote workers to thrive in a digital workspace. Foster a sense of connection and belonging through virtual team-building activities and communication platforms.`
          },
        ],
      },
      {
        subtitle: `Embrace the Global Talent Opportunity`,
        text: [
          `While global talent acquisition presents its share of challenges, the rewards are substantial. By understanding and addressing these challenges head-on, businesses can unlock the potential of a diverse and skilled workforce that drives innovation, creativity, and success on a global scale.`,
          `At IntelliPro, we specialize in helping businesses navigate the complexities of global talent acquisition. Our comprehensive services and expertise in EOR/PEO solutions ensure a seamless and compliant process for expanding your workforce across borders. Contact us today to learn how we can assist you in building a truly global team that propels your organization toward international success.`
        ]
      },
      {
        subtitle: `Conclusion`,
        text: [
          `Acquiring top global talent is an endeavor that requires careful planning, adaptability, and a deep understanding of the unique challenges presented by the global marketplace. By embracing diversity, leveraging innovative sourcing strategies, partnering with EOR/PEO providers, and embracing remote work, businesses can successfully navigate these challenges and build a global team that propels them to new heights of success.`]
      }
    ],
    reference: []
  },
  {
    id: 28,
    content: [
      {
        text: [
          `In our rapidly globalizing era, technological advancements and innovations are accelerating at a pace we've never witnessed before. Recognizing this wave, IntelliPro was proud to support the transformative Chinese Tech Network Event "Innovative Genesis · Incubating the Future: A Premier US-China Investment Dialogue", brought to life by the influential Plug and Play on September 20th.`,
          ' ',
          `The event achieved a significant first: real-time dialogues across three prime locations - the dynamic heart of tech, Silicon Valley; the bustling hub of Beijing with IntelliPro; and the innovative epicenter of Shanghai at Plug and Play China. By doing so, we convened elite tech experts, forward-thinking entrepreneurs, and seasoned investors to forecast the future landscape of tech innovations and investments.`
        ],
        image: blog28Image1

      },
      {
        subtitle: `Global Audience, Global Reach`,
        text: [
          `On the ground, almost a thousand tech enthusiasts joined us across the three cities. Digitally, we had an audience of over 10,000. This wasn’t just a cross-time-zone event; it was a groundbreaking international collaboration.`
        ],
      },
      {
        subtitle: `Spotlight: Silicon Valley`,
        text: [
          `At the heart of tech, Silicon Valley’s event saw Yisu Jin from IntelliPro Group leading a dynamic "Tech Innovation Fireside Chat". Alongside AI expert Yangqing Jia, Jian Kang of Tech PE-IEF Partners, Ker Gibbs, former president of The American Chamber of Commerce in Shanghag , and Vincent Wu, COO of NewsBreak, they dug deep into tomorrow’s tech trends.`
        ],
        images: [blog28Image2, blog28Image3],
      },
      {
        text: [' '],
        image: blog28Image4
      },
      {
        subtitle: `Beijing's Power Minds`,
        text: [
          `In Beijing, notable names like Chenyang Wei from Tsinghua University, Yubo Liang of tech fame, Wei Wei from China's Small Business Association, and IntelliPro's Global VP&Partner Vera Tang, came together. `,
          `Their interactions with the live stream from Silicon Valley, combined with insightful discussions on the fusion of technology and globalization, sparked lively debates and enthusiasm among the attendees.`,
          `The overwhelming response across Silicon Valley, Beijing, and Shanghai, both in-person and online, spotlighted the event's success. As champions of tech innovation, IntelliPro is heartened to see such enthusiasm, fostering a rich ecosystem for knowledge sharing and investment growth.`,
        ],
        images: [blog28Image5, blog28Image6],
      },
      {
        subtitle: `Thank You for Joining Us`,
        text: [
          `Concluding the Chinese Tech Network Event, we extend our profound gratitude for the overwhelming support and enthusiastic participation. At IntelliPro Group, we remain committed to pioneering such platforms, eagerly awaiting future collaborations.`
        ],
        video: blog28Video1,
      },
    ],
    reference: []
  },
  {
    id: 29,
    content: [
      {
        text: [
          `The 2023 SAPA Annual Conference & 30-Year Anniversary Celebration stood out not only as a flagship event but also marked the inauguration of the visionary One-SAPA conference. This initiative accentuated the essence of collaboration, seamlessly integrating SAPA's central hub with its regional affiliates. Through its diverse array of sessions, the conference pledged an enriching journey into the evolving dynamics of the pharmaceutical and biotechnology world.`,
        ],
        fullImage: blog29Image1

      },
      {
        text: [
          `This year, SAPA organized a standout event, attracting over 70 experts from various fields. Participants enjoyed a mix of engaging talks, discussions, hands-on exhibitions, and a unique on-site job fair. This variety of activities promoted business partnerships, shared knowledge, and provided networking opportunities.`
        ],
        images: [blog29Image2, blog29Image3]
      },
      {
        text: [
          `Among the participants were IntelliPro's Life Science team--Weitong Yin and Rong Wang. Sharing her experience, Weitong noted: "I am deeply inspired by the wealth of knowledge shared and the passion that permeated every discussion. The energy and enthusiasm in the room were contagious, leaving me with a sense of excitement for the future." Rong added: "It was an enriching and valuable experience for me and our life science team. I was impressed by the caliber of speakers and presenters. Their expertise and passion for their respective fields were evident in their presentations, making every session a source of valuable knowledge."`
        ],
        image: blog29Image4,
      },
      {
        text: [
          `With a deep understanding of the intricacies and nuances of the pharmaceutical and biotechnology industries, IntelliPro excels in fulfilling specific staffing needs, especially for roles requiring niche expertise. Our robust HR solutions are designed to bridge talent gaps, ensuring companies have the right professionals to drive innovation and excellence.`,
          ` `,
          `For organizations in pharmaceutical and biotechnology sectors that prioritize quality, expertise, and precision in their staffing needs, IntelliPro emerges as the go-to partner. As the SAPA conference celebrated the past and looked toward the future, IntelliPro positioned itself as a catalyst for growth, driving success through its unparalleled HR expertise in the industry.`,
          ` `
        ],
      },
      {
        textWithLink: [
          {
            beforeLink: 'If your organization seeks a partner with unmatched experience in staffing and HR solutions tailored for the pharmaceutical and biotechnology industries, ',
            link: {
              text: `contact us`,
              link: 'https://www.intelliprogroup.com/contactus'
            },
            afterLink: ' now to collaborate and elevate your success!'
          }
        ],
      },
    ],
    reference: []
  },
  {
    id: 30,
    content: [
      {
        subtitle: `Forbes Most Influential Chinese Selection:`,
        text: [
          `After a six-month selection process, Forbes China has unveiled its "2023 Forbes China Most Influential Chinese Selection," recognizing 100 outstanding Chinese individuals in two key categories: "Influence" and "Growth," based on the pillars of "Business" and "Culture and Scientific Research." Among the honorees, Yisu Jin, the CEO and founder of IntelliPro Group, has been distinguished as one of the top influencers. This annual accolade highlights notable figures through comprehensive evaluations, nominations, and final assessments, celebrating their significant impact in their respective fields.`,
          ` `,
          `In the "2023 Forbes China Most Influential Chinese TOP100" list, the "Influence" category celebrates individuals who have not only achieved significant milestones in the current year but have also wielded substantial global influence in their respective industries. To qualify for this category, their affiliated companies needed to be valued at over 3 billion RMB or have revenues exceeding 1 billion RMB (excluding cultural and investment institutions). Remarkably, this category includes 10 companies that are part of the esteemed 2023 Forbes Global 2000, with an additional 25 enterprises either listed or poised for entry into the capital market.`

        ],
        fullImage: blog30Image1

      },
      {
        subtitle: `Award Ceremony: A Night to Remember`,
        text: [
          `At the award ceremony held on October 27th, Yisu Jin was among 40 luminaries, exemplifying the pinnacle of China's business leadership. His fellow honorees included industry stalwarts such as Meta's SVP of Engineering Bill Jia, Apple's VP for Greater China Yue Ge, Amazon Global's VP Jingfei Dai, JD's CEO Ling Lei, and Asiainfo Tech's SVP Ye Ouyang.`
        ],
      },
      {
        subtitle: `Global Vision at "Tech Leaders Dialogue"`,
        text: [
          `During the event, Yisu actively participated in the "Tech Leaders Dialogue." He delved into pressing topics, including the globalization aspirations of Chinese enterprises, the digital economy's evolving talent ecosystems, and transformative HR strategies. Expressing his insights, Yisu highlighted the growing trend of Chinese businesses, regardless of size, eyeing global expansion. He emphasized, "The challenge of global market integration and overseas talent strategy is now at the forefront. As a global HR solution provider, IntelliPro Group pledges its unwavering support to Chinese businesses in their international pursuits."`,
          ` `,
          `Reflecting on IntelliPro's meteoric rise, Yisu expressed profound gratitude towards Forbes and each member of the IntelliPro team for their relentless efforts over the past 14 years. He reiterated the company's commitment to consistently enhance its talent and HR offerings to its global clientele, aligning with the international aspirations of the Chinese community.`,
          ` `,
          `Yisu Jin's acknowledgment by Forbes magnifies IntelliPro Group's dedication to excellence in a dynamic global landscape, spotlighting their present achievements and the promising journey ahead.`,
        ],
        video: blog30Video1,
      },
    ],
    reference: []
  },
  {
    id: 31,
    content: [
      {
        text: [
          `In the rapidly evolving global marketplace, the U.S. emerges as a beacon of opportunity. Yet, for many Chinese enterprises, deciphering the ins and outs of such an expansive market remains a challenge. Sensing this need, the IntelliPro Group, with its deep Silicon Valley roots since 2009, proudly unveiled its detailed "2024 Business Expansion White Paper (U.S. Edition): Navigating Challenges and Uncertainties" during a riveting online press conference on October 19.     `,
        ],
        fullImage: blog31Image1

      },
      {
        subtitle: `Why the 2024 U.S. Business Expansion White Paper is an Indispensable Tool for Chinese Enterprises`,
        text: [
          `Every market has its unique pulse, and the U.S., with its intricate tapestry of business dynamics, is no exception. This is where the White Paper by IntelliPro becomes invaluable:`
        ],
        list: [
          `Decoding the U.S. Landscape: Beyond just statistics, the White Paper paints a vivid picture of the U.S. market's socio-economic fabric, enabling Chinese businesses to understand their target audience better and craft more relatable brand narratives.`,
          `Aiding Global Ambitions amidst Stiff Competition: As China’s internal business landscape becomes increasingly saturated, the Paper provides a strategic gateway for businesses seeking international diversification, especially in the robust U.S. ecosystem.`,
          `Risk Minimization in Foreign Terrain: Unexpected hurdles can derail any business venture. This guide meticulously breaks down potential challenges, from nuanced legalities to cultural business practices, ensuring firms have a proactive response strategy.`,
          `Enabling Precision in Strategy: With insights spanning various sectors and regions within the U.S., the White Paper becomes a critical tool in the strategic arsenal of Chinese businesses, ensuring decisions are data-driven and contextually informed.`,
        ]
      },
      {
        subtitle: `Highlights and Takeaways from the Press Conference:`,
        list: [
          `Yisu Jin's Visionary Address: The CEO of IntelliPro Group, Yisu Jin, passionately detailed the evolving global scenario, reinforcing the imperative of the White Paper. He also underscored the mission behind the guide – to foster seamless, effective, and impactful transitions for Chinese enterprises into the U.S. marketplace.`,
          `Kiana Duan’s Comprehensive Overview: Offering a sneak peek into the rich content of the guide, Kiana Duan emphasized how the U.S., with its multifaceted business culture and unparalleled economic resilience, remains a prime destination for international ventures.`,
        ],
        image: blog31Image2,
      }, {
        list: [
          `Expert Perspectives on U.S. Employment Dynamics: Vera Tang and Richard Zhuge delved deep, bringing forth intricate details about the U.S. hiring environment, illuminating the pathways and pitfalls of talent acquisition, local U.S. policies, and strategic alignment.`
        ],
        fullImages: [blog31Image3, blog31Image4]
      }, {
        list: [
          `Legal and Financial Labyrinths Unraveled: Yuji Sun and Jie Zhu provided a masterclass on U.S. legal and tax structures, sharing invaluable tips and insights essential for compliant and optimized operations in the U.S.`
        ],
        fullImages: [blog31Image5, blog31Image6],
      },
      {
        subtitle: `IntelliPro Group: Bridging Global Aspirations with Tangible Success:`,
        text: [
          `Founded in 2009 in the innovation hub of Silicon Valley, IntelliPro Group has emerged as a global HR behemoth. With a footprint spanning 160 countries, they've catalyzed the global ambitions of over 500 elite businesses. Their primary aim? Facilitating effortless international transitions, thereby spearheading global business growth.    `,
          `For a more in-depth exploration of global business dynamics and to tap into IntelliPro's vast reservoir of expertise, ensure you follow IntelliPro Services's official WeChat account.`,
        ],
      },
    ],
    reference: []
  },
  {
    id: 32,
    content: [
      {
        subtitle: `IntelliPro at NMSDC 2023: Driving Innovation in Minority Business Empowerment`,
        text: [
          `Elevating the Conversation on Minority Business Growth in Baltimore`,
          `The 2023 National Minority Supplier Development Council (NMSDC) Annual Conference, in partnership with the Minority Business Development Agency (MBDA), was a landmark event, setting a new standard for industry conferences. As the most extensive gathering of its kind, it attracted over 5,000 attendees, including key corporate CEOs, procurement executives, and leading minority business owners. At the center of this vibrant event was IntelliPro, making a significant impact as an exhibitor. Our role extended beyond showcasing our commitment to minority business growth, offering a stage for unveiling our advanced technological innovations and affirming IntelliPro's position as a leader in the industry.`
        ],
        fullImage: blog32Image1,
        imageAlt: `(Photo credit: NMSDC)`

      },
      {
        subtitle: `Baltimore: A Center for Change and Minority Business Innovation`,
        text: [
          `In Baltimore, the conference united industry leaders, entrepreneurs, and policymakers, providing a fertile ground for discussions about economic equity and minority business empowerment. Notably, the conference featured esteemed speakers such as Rosie Allen-Herring (United Way), Dr. Heather Boushey (White House), Calvin Butler (Exelon), Donald R. Cravins, Jr. (MBDA), Pete Buttigieg (U.S. Department of Transportation), Les Brun (Ariel Alternatives, LLC), Ivy McGregor (BeyGOOD Foundation), and Stephen Benjamin (White House). The presence of these influential figures highlighted the importance of the event in shaping the future of business and diversity.`
        ],
        fullImage: blog32Image2,
        imageAlt: `(Photo credit: NMSDC)`
      },
      {
        subtitle: `Spotlight on IntelliPro's APN Software`,
        text: [
          `A key highlight from IntelliPro was the presentation of APN, IntelliPro's internally developed, AI-driven software. Jeremy Li, IntelliPro's Director of Product Management, articulated the significance of this opportunity."I feel honored to present APN, receiving valuable feedback and engaging in discussions about the future of AI and the recruiting industry," he said. This session highlighted IntelliPro's role as an innovator, not only within the minority business community but also in the broader recruiting industry.`
        ],
      }, {
        subtitle: `Building Impactful Connections`,
        text: [
          `At IntelliPro'sbooth (905-LC), the team created a hub of interaction and idea exchange. The team engaged with a diverse audience, ranging from budding entrepreneurs to experienced business leaders, fostering discussions on the challenges and future prospects of minority businesses and the recruitment sector.`
        ],
      },
      {
        subtitle: `A Journey of Learning and Growth`,
        text: [
          `Reflecting on IntelliPro's first exhibit at the NMSDC conference, Melissa Neverson, Director of Diversity, Equity, Inclusion and Brand Communications, shared her thoughts.  "This being our first exhibit at the NMSDC conference, we're walking away with so much knowledge about what people are looking for in terms of diversity, equity, and inclusion initiatives, strategies, and support. It's been such a great learning experience for us. We can't wait to see what happens outside of this conference."`
        ],
      },
      {
        subtitle: `IntelliPro: Spearheading Inclusive Economic Development`,
        text: [
          `IntelliPro's participation in the NMSDC Annual Conference 2023 was more than mere attendance. We actively shaped the conversation around minority business empowerment and economic equity. Through engaging discussions and showcases like APN, IntelliPro solidified its role as a catalyst for business growth and technological progress in the minority business sector.`
        ],
      },
      {
        subtitle: `Looking Ahead with Anticipation`,
        text: [
          `Reflecting on this successful event, we are filled with anticipation for future opportunities to engage, learn, and innovate. IntelliPro is excited to continue this journey, eager to participate in upcoming events and looking forward to our next appearance at the NMSDC Annual Conference. Our commitment to empowering minority businesses through innovation and collaboration remains steadfast.`
        ],
      },
      {
        subtitle: 'Join IntelliPro in Fostering Minority Business Empowerment',
        video: blog32Video1
      }
    ],
    reference: []
  },
  {
    id: 33,
    content: [
      {
        subtitle: `Introduction:`,
        text: [
          `In the dynamic business landscape of 2024, companies are confronted with evolving challenges that necessitate innovative solutions. At the forefront of this transformative era stands IPG, a global staffing and HR management agency dedicated to propelling businesses to new heights through seamlessly integrated services. As we step into the new year, the synergies between staffing solutions and global HR management takes center stage, proving to be a critical element for sustained success.`
        ],
        fullImage: blog33Image1,
      },
      {
        subtitle: `New Year, New Strategies: Synergizing Global HR Management and Staffing Solutions`,
        text: [
          `In a world where adaptability and efficiency are paramount, IPG recognizes the imperative of a comprehensive approach. By seamlessly intertwining global HR management solutions like Employer of Record (EOR), Professional Employer Organization (PEO), and Human Resources Outsourcing (HRO) with its staffing services, IPG empowers businesses to maintain agility and competitiveness.`
        ],
      },
      {
        subtitle: `Leveraging Employer of Record (EOR) for Strategic Staffing`,
        text: [
          `Visualize Company X, a rapidly expanding tech firm from the United States, eager to establish a foothold in the European market. IPG steps in with its EOR services, ensuring not only compliance with international employment regulations but also facilitating a smooth onboarding process for Company X's newly hired European staff. This integration of EOR with staffing solutions allows Company X to concentrate on its core operations while IPG takes care of the intricate HR details, seamlessly blending global HR compliance with strategic staffing.`
        ],
      },
      {
        subtitle: `PEO: Crafting Collaborative Success Stories`,
        text: [
          `Consider the journey of Company Y, a startup in the healthcare sector with aspirations to scale its operations globally. IPG's integrated approach involves PEO services seamlessly aligning with staffing solutions. As Company Y expands its team across different countries, IPG's PEO not only adeptly handles HR functions but also provides strategic workforce solutions. This collaborative effort results in Company Y efficiently scaling its operations without compromising on compliance or workforce quality, showcasing the harmonious integration of global HR management and staffing.`
        ],
      },
      {
        subtitle: `HRO: Maximizing Efficiency in HR and Staffing Operations`,
        text: [
          `Enter Company Z, a manufacturing giant grappling with HR and staffing challenges due to decentralized operations. IPG introduces HRO services, strategically streamlining HR processes and optimizing staffing efficiency. The result? Company Z experiences improved productivity, cost-effectiveness, and a cohesive workforce strategy across its global locations. IPG's integrated HRO seamlessly enhances both HR and staffing operations, showcasing the agency's prowess in delivering holistic solutions.`
        ],
        fullImage: blog33Image2
      },
      {
        subtitle: `The IPG Advantage: Unifying Global HR and Staffing Solutions`,
        text: [
          `IPG's strength lies in its ability to provide customized solutions that comprehensively address both staffing and HR needs. Through a fusion of cutting-edge technology, global expertise, and a client-centric approach, IPG distinguishes itself in delivering integrated services that drive success. The agency's ability to seamlessly blend staffing excellence with HR management efficiency is the cornerstone of its competitive advantage.`
        ],
      },
      {
        subtitle: `Future-Forward Strategies: Anticipating and Addressing HR and Staffing Challenges`,
        text: [
          `As we look ahead to 2024, IPG remains committed to staying ahead of industry trends and challenges. Proactive strategies, continuous innovation, and a deep understanding of the evolving landscape position IPG and its clients for sustained success.`
        ],
      },
      {
        subtitle: 'Conclusion',
        text: [
          `In a world where businesses are defined by their ability to adapt, IPG emerges as a partner committed to navigating the complexities of global HR and staffing. The integrated approach not only addresses current challenges but also positions businesses for a future where success is synonymous with adaptability, efficiency, and strategic collaboration.`
        ],
        fullImage: blog33Image3
      }
    ],
    reference: []
  },
  {
    id: 34,
    content: [
      {
        subtitle: `Introduction:`,
        text: [
          `Human Resources (HR) technology has undergone significant advancements in recent years, reshaping the way organizations manage their workforce and gain valuable insights into employee performance. In this article, we will delve into three key HR technology innovations that are driving business efficiency and providing valuable insights into the workforce.`
        ],
        fullImage: blog34Image1,
      },
      {
        subtitle: `1. Artificial Intelligence (AI) and Machine Learning in HR`,
        text: [
          `One of the most prominent innovations in HR technology is the integration of artificial intelligence (AI) and machine learning (ML) algorithms. These technologies enable HR professionals to streamline and automate various processes, from recruitment to performance management.`,
          `AI-driven recruitment tools are revolutionizing the hiring process by analyzing resumes, assessing candidate suitability, and even conducting initial interviews. These systems can learn from past hiring decisions to identify patterns and preferences, leading to more effective candidate shortlisting. `,
          `In performance management, AI and ML contribute to a more data-driven approach. These technologies can analyze employee performance data, identify trends, and provide insights into areas for improvement. By leveraging predictive analytics, HR professionals can anticipate potential challenges and proactively address them.`,
        ],
        fullImage: blog34Image2,
      },
      {
        subtitle: `2. Employee Experience Platforms (EXPs)`,
        text: [
          `Employee Experience Platforms (EXPs) are another game-changer in HR technology. These platforms focus on creating a positive and engaging workplace environment by enhancing communication, collaboration, and overall employee satisfaction.`,
          `EXP solutions typically offer features such as employee feedback tools, recognition programs, and internal communication platforms. By consolidating these functionalities into a single platform, organizations can provide employees with a seamless experience, improving overall satisfaction and productivity.`,
          `Moreover, EXPs often incorporate real-time analytics to measure employee engagement and sentiment. This data can be invaluable for HR teams seeking to understand the factors that contribute to a positive work environment and address any issues promptly.`,
        ],
        fullImage: blog34Image3,
      },
      {
        subtitle: `3. Blockchain for HR and Payroll Management`,
        text: [
          `Blockchain technology, known for its secure and transparent nature, is finding its way into HR and payroll management systems. Blockchain ensures the integrity and accuracy of employee data, reducing the risk of errors and fraudulent activities.`,
          `In HR processes, blockchain can be utilized for secure verification of credentials and certifications during the hiring process. This not only speeds up recruitment but also ensures the authenticity of the information provided by candidates.`,
          `In payroll management, blockchain can enhance transparency and accuracy. Smart contracts, powered by blockchain, automate payroll processes, ensuring that employees are paid accurately and on time. This not only reduces the administrative burden on HR professionals but also minimizes the likelihood of payroll errors.`,
        ],
        fullImage: blog34Image4,
      },
      {
        subtitle: 'Conclusion',
        text: [
          `The evolution of HR technology is reshaping the way businesses manage their workforce and gain insights into employee performance. Artificial intelligence, employee experience platforms, and blockchain are just a few examples of innovations that are driving business efficiency in HR processes.`
        ],
        fullImage: blog34Image5
      }
    ],
    reference: []
  },
  {
    id: 35,
    content: [
      {
        subtitle: `Introduction:`,
        text: [
          `As we celebrate Black History Month, it's an opportune time to reflect on the significant contributions of Black innovators in the technology sector. These individuals are not just technologists; they are visionaries who transcended racial and societal barriers to imprint their legacy on the tech world. Their innovations and perseverance have reshaped our understanding of technology's potential and its impact on society. In this exploration, we delve into their stories, revealing the depth and breadth of their influence on an industry often marked by a lack of diversity.`
        ],
        fullImage: blog35Image1,
      },
      {
        subtitle: `The Legacy of Black Technological Pioneers`,
        text: [
          `Black innovators have played a pivotal role in the evolution of technology, often overcoming significant barriers to bring their visions to life. From the early days of computing to the modern era of digital innovation, these trailblazers have made lasting impacts.`
        ],
        list: [
          {
            boldText: 'Dr. Mark Dean: ',
            text: `A vanguard in computer engineering, Dr. Mark Dean is most renowned for his significant role in developing IBM's personal computer. His pioneering work during the 1980s catalyzed the widespread adoption of personal computers, radically transforming the application of technology in everyday life and various industries. Holding multiple patents in PC design and functionality, Dean's contributions are integral to the fabric of modern computing.`,
            inline: true
          },
          {
            boldText: 'Roy L. Clay Sr.: ',
            text: `Roy L. Clay Sr., often hailed as the "Godfather of Silicon Valley," is a seminal figure in the tech industry. His crucial role in establishing Hewlett-Packard's computer division marked a significant milestone in Silicon Valley's tech evolution. Clay's efforts laid the foundation for HP's computing success and helped position Silicon Valley as a global hub of technological innovation.`,
            inline: true
          },
          {
            boldText: 'Janet Emerson Bashen: ',
            text: `Janet Emerson Bashen, an illustrious inventor and entrepreneur, holds the distinction of being the first African American woman to receive a U.S. patent for software. Her pioneering software, LinkLine, assists organizations in adhering to equal employment opportunity compliance, demonstrating a profound impact in both the corporate and legal sectors.`,
            inline: true
          },

        ],
        fullImage: blog35Image2,
      },
      {
        subtitle: `Breaking Barriers and Challenging Norms`,
        text: [
          `The journey of Black innovators in tech is not just a tale of individual brilliance but also a story of breaking barriers and challenging societal norms. Historically, the technology field has seen limited diversity, making the accomplishments of these pioneers even more remarkable. Their success has not only advanced technology but also opened doors for future generations of diverse talent to enter and thrive in the tech industry.`
        ],
        list: [
          {
            boldText: 'Dr. James E. West: ',
            text: `Celebrated for his invention of the electret microphone, Dr. James E. West's innovation is now a staple in almost every electronic communication device. His development has significantly enhanced sound quality and accessibility, making him a pivotal figure in the advancement of audio technology.`,
            inline: true
          },
          {
            boldText: 'Marian Croak: ',
            text: `A key figure in the development of Voice over Internet Protocol (VoIP) technologies. Marian Croak has been instrumental in transforming voice data into digital signals for internet transmission. Her innovations have redefined telecommunications, extending beyond traditional phone lines and laying the groundwork for modern communication methods like video conferencing and internet calling.`,
            inline: true
          },
        ],
        fullImage: blog35Image3,
      },
      {
        subtitle: `Reflection & Looking Forward`,
        text: [
          `Reflecting on the contributions of these Black innovators, it becomes evident that their impact transcends specific technologies. They have challenged the status quo and championed a more inclusive and diverse future in tech. Their legacy lies not only in the technologies they pioneered but also in fostering a cultural shift towards valuing diversity in innovation.`,
          ` `,
          `The diverse perspectives brought by Black innovators are vital in driving forward-thinking solutions in an industry that thrives on creativity. Looking forward, it is imperative that the tech industry not only acknowledges but actively champions diversity, ensuring that the next generation of tech innovators reflects the rich tapestry of our global society. By doing so, we not only honor the legacy of these trailblazers but also fortify the foundation for a more innovative, inclusive, and equitable technological future.`
        ],
        fullImage: blog35Image4,
      }
    ],
    reference: []
  },
  {
    id: 36,
    content: [
      {
        subtitle: `Introduction:`,
        text: [
          {
            isRichText: true,
            content: `<div>In today's dynamic business world, enterprises navigate through the turbulence of stiff market competition, rapid technological advances, and the complexities of globalization. Amid these challenges, the secret to sustained success transcends mere innovation in products or services; it crucially hinges on effectively nurturing the organization's most invaluable asset—the workforce. Sustainable talent management emerges as a pivotal strategy, fostering employee growth and satisfaction while significantly contributing to corporate social responsibility. The evolving landscape demands a shift in skills and behaviors, as evidenced by <a href="https://www.bain.com/insights/a-talent-strategy-for-sustainability-skills-matter-but-mindset-is-everything-ceo-sustainability-guide-2023/" style="color: rgb(55, 125, 237)" target="_blank" rel="noopener noreferrer" aria-label="reference link">a Bain survey</a>: 63% of respondents recognize the need for different skills to meet their employer's ESG ambitions, yet only 45% of non-managers believe they have the right reskilling opportunities for internal mobility.</div>`
          },

        ],
        fullImage: blog36Image1,
      },
      {
        subtitle: `Key Strategies of Sustainable Talent Management`,
        numberedText: [
          {
            boldText: 'Continuous Learning and Development',
            richText: `<div>At the heart of sustainable talent management lies the commitment to provide continuous opportunities for skill refinement and personal growth. Beyond traditional professional skills, the focus extends to cultivating soft skills such as teamwork, leadership, and innovative thinking. <a href="https://go.manpowergroup.com/hubfs/TalentShortage%202018%20(Global)%20Assets/PDFs/MG_TalentShortage2018_lo%206_25_18_FINAL.pdf" style="color: rgb(55, 125, 237)" target="_blank" rel="noopener noreferrer" aria-label="reference link">The ManpowerGroup Talent Shortage research</a> underscores this need, with 72.8% of employers struggling to find skilled candidates. By leveraging internal training programs, online learning resources, career development workshops, and expert-led seminars, companies can ignite a passion for learning among employees. Offering educational subsidies or support for obtaining professional certifications further bolsters employee loyalty and elevates the company’s competitive stance.</div>`
          },
          {
            boldText: 'Clear Career Pathways',
            richText: `<div>Having a clear career development path can help employees see their growth space and prospects within the organization. Companies should collaborate with employees to plan their career trajectories, clearly demonstrating the skills and experiences required for advancement at different stages. Regular performance feedback and career planning meetings are crucial in providing opportunities for employees to reflect on their growth and adjust their career directions. Through such transparent and open communication, employees feel valued for their personal development, thereby strengthening their sense of belonging and loyalty to the organization. The shift towards remote work and global talent pools as observed in <a href="https://www.trebound.com/blog/how-to-build-sustainable-talent-management-model-in-2022" style="color: rgb(55, 125, 237)" target="_blank" rel="noopener noreferrer" aria-label="reference link">2024's talent management trends</a> demands adaptability in career planning, where companies focus more on future contributions rather than past performance, facilitating career growth beyond geographic limitations.</div>`
          },
          {
            boldText: 'Competitive Compensation and Benefits',
            text: `Compensation and benefits policies are key to attracting and retaining top talent. A competitive compensation structure should reflect market levels as well as individual contributions and fairness within the organization. Moreover, a comprehensive benefits plan, including health insurance, retirement plans, stock option schemes, and flexible vacation policies, can effectively enhance employee satisfaction and loyalty. By regularly reviewing and adjusting the compensation and benefits structure to adapt to market changes and employee needs, companies can maintain their attractiveness and retain key talent.`

          },
          {
            boldText: 'Supportive Work Environment',
            text: `A supportive work environment is another crucial component of sustainable talent management. This includes creating an inclusive, diverse, and discrimination-free workplace where all employees feel respected and valued. Measures to achieve this goal include establishing clear diversity and inclusion policies, offering flexible work arrangements to accommodate different employees' life needs, and initiating health and wellness programs, such as mental health support and physical activities. Such an environment enables employees to perform at their best while fostering collaboration and innovation among teams.`

          }
        ],
        fullImage: blog36Image2,
      },
      {
        subtitle: `Advantages of Sustainable Talent Management`,
        text: [
          `Sustainable talent management delivers dual benefits: enhancing operational efficiency and fulfilling corporate social obligations. It directly improves employee satisfaction and retention, facilitating professional development and enabling employees to more effectively contribute to organizational goals. A motivated and skilled workforce bolsters productivity and competitive edge, driving corporate growth.`,
          {
            isRichText: true,
            content: `<div>Moreover, it allows companies to positively impact broader societal and environmental issues. <a href="https://www.forbes.com/sites/forbeshumanresourcescouncil/2022/12/21/how-your-talent-acquisition-team-can-help-create-a-sustainable-talent-strategy/?sh=1c21f1ca7d75" style="color: rgb(55, 125, 237)" target="_blank" rel="noopener noreferrer" aria-label="reference link">According to Forbes</a>, fostering a culture of sustainable talent mobility contributes to overall sustainability, aligning talent management with efforts to create organizational stability and growth, thus enhancing the company’s public image and appeal to socially conscious consumers and partners.</div>`
          },
        ],
      },
      {
        subtitle: `Conclusion`,
        text: [
          `Sustainable talent management is essential for companies aiming for long-term success and societal impact. Embedding these strategies into their operations not only enriches employees' careers but also positions companies as leaders in social responsibility. The commitment to continuous investment in talent development promises a prosperous future for the organization and its employees, paving the way for a more sustainable and inclusive business ecosystem.`,
        ],
        fullImage: blog36Image3,
      }
    ],
    reference: []
  },
  {
    id: 37,
    content: [
      {
        text: [
          'This April, Los Angeles set the stage for ProcureCon Contingent Staffing 2024, a pivotal event in the staffing and procurement sectors. IntelliPro proudly sponsored and played an integral role,  bringing significant expertise and leadership to pivotal discussions and workshops.'
        ]
      },
      {
        subtitle: 'Unpacking the Dynamic Environment of ProcureCon CS',
        text: [
          `ProcureCon 2024 was remarkable for its vibrant mix of thought leadership and practical insights. The event stood out for fostering an environment where professionals could engage in candid, in-depth discussions about the future of the workforce. It was particularly notable for its emphasis on diversity, equity, and inclusion (DE&I), marking it as a critical platform for championing meaningful change within the industry.`
        ]
      },
      {
        subtitle: 'IntelliPro\'s Interactive Showcase',
        text: [
          `Our booth became a hub of activity throughout the conference, serving as a vital point for engaging with industry peers and showcasing IntelliPro’s comprehensive service offerings. From Managed Service Provider (MSP) solutions to executive search and Employer of Record (EOR) services, we highlighted how our innovative approaches, particularly in integrating robotics into contingent staffing, are paving the way for enhanced efficiency and effectiveness in workforce management.`
        ],
        fullImages: [blog37Image1, blog37Image2]
      },
      {
        subtitle: `IntelliPro Moderates DE&I Track`,
        text: [
          `The DE&I track was a highlight of ProcureCon, with Melissa Neverson,  IntelliPro's head of Diversity, Equity, and Inclusion and Director of Brand Communications, attending as the Track chairwoman and event moderator.`,
          `This track included several key sessions that delved into the nuances of implementing effective DE&I strategies within contingent workforce programs:`
        ],
        list: [
          'Business Case Or Fairness Case: Led by Golie-Lorenzo Green, this session examined the strategic and moral imperatives of DE&I, sparking crucial dialogues about the complexities of integrating these initiatives successfully.',
          'Strategic Collaboration for Inclusivity: Insights from industry veterans like Tinisha Bookhart and Kellum Thomas and Pratik Patel highlighted the transformative impact of collaborative efforts on workplace inclusivity.',
          'Key Performance Indicators for DE&I: Under Melissa\'s moderation, a panel discussion on using metrics and benchmarks to measure the success of diversity and inclusion efforts provided attendees with tools to track and enhance their DE&I strategies.'
        ],
        fullImages: [blog37Image3, blog37Image4]
      },
      {
        subtitle: 'Reflecting on Our Journey and Looking Ahead',
        text: [
          `ProcureCon Contingent Staffing 2024 was not just an opportunity to share and gain knowledge but also a testament to IntelliPro’s leadership in the staffing industry. The conference underscored our dedication to not only participating in but actively shaping the dialogues that will define the future of workforce management.`,
          `As we reflect on the wealth of insights and new connections forged at ProcureCon, we are excited about the potential to integrate these learnings into our strategic approaches.`
        ]
      }
    ],
    reference: []
  }
]