import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { withStyles } from '@material-ui/core/styles';
import SwiperCore, { Autoplay, Pagination } from 'swiper/core';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import './style.css';
import PrevButton from '../../assets/icons/event-left.png';  // 导入自定义的左箭头 SVG
import NextButton from '../../assets/icons/event-right.png';  // 导入自定义的右箭头 SVG

SwiperCore.use([Autoplay, Pagination]);

const styles = (theme) => ({
  img: {
    width: '85%',
    padding: '5%',
    margin: '5px 0px',
    [theme.breakpoints.up('largedesktop')]: {
      width: '85%',
      padding: '5%',
      margin: '10px 0px',
    },
  },
  customNav: {
    position: 'absolute',
    zIndex: 10,
    top: '50%',
    transform: 'translateY(-50%)',
    width: '40px',
    height: '40px',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
    },
    '& img': {
      width: '50%',
      height: 'auto',
    },
  },
  prevButton: {
    left: '10px',
  },
  nextButton: {
    right: '10px',
  },
});

class EventSliders extends React.Component {
  render() {
    const { classes, pics } = this.props;

    return (
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        className="mySwiper"
      >
        {pics.map((pic, index) => (
          <SwiperSlide key={index}>
            <img src={pic} alt={`event-${index}`} className={classes.img} />
          </SwiperSlide>
        ))}
        <div className={`${classes.customNav} ${classes.prevButton}`}>
          <img src={PrevButton} alt="Prev Button" />
        </div>
        <div className={`${classes.customNav} ${classes.nextButton}`}>
          <img src={NextButton} alt="Next Button" />
        </div>
      </Swiper>
    );
  }
}

export default withStyles(styles)(EventSliders);
