import * as SparkMD5 from 'spark-md5';
//const _devHost = 'http://192.168.8.45:8081';
const _devHost = 'https://api-staging.hitalentech.com:8443';
const devHost = process.env.REACT_APP_API_HOST || _devHost;

const ipgSDK = {
    async getJobs(keyword) {
        const url = devHost + '/apnpublic/api/v1/search-jobs/ipg';
        const config = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                location: '',
                keywords: keyword || '',
                page: 0,
                size: 1000,
                redirect: 'follow'
            })
        };
        // according to reponse message to compenstate content
        return fetch(url, config)
            .then(_handleResponseToJson)
            .then(({ response }) => {
                localStorage.searchTime = Date.now();
                return response;
            });
    },

    async getJob(jobId) {
        const url = devHost + `/apnpublic/api/v1/jobs/${jobId}`;
        const config = {
            method: 'GET',
            headers: {},
            redirect: 'follow'
        };
        return fetch(url, config)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else return Promise.reject(response.json())
            })
    },

    async applyJob({ firstName, lastName, email, jobId, jobName, uuid, address, type, phone }) {
        let url = devHost + '/apnpublic/api/v1/campus/ipg-jobs/apply'
        let myHeaders = new Headers();
        let formData = new FormData();

        formData.append("firstName", firstName);
        formData.append("lastName", lastName,);
        formData.append("email", email);
        formData.append("jobId", jobId);
        formData.append("jobName", jobName);
        formData.append("uuid", uuid);
        formData.append("address", address);
        formData.append("type", type);
        formData.append("phone", phone);

        let config = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: formData
        };
        return fetch(url, config)
    },

    async comment(body) {
        let url = devHost + "/apnpublic/api/v1/comment";
        const config = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json', },
        }
        return fetch(url, config)
    },
    async bugBounty(body) {
        let url = devHost + "/apnpublic/api/v1/report";
        const config = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json', },
        }
        return fetch(url, config)
            .then(_handleResponseToJson)
            .then(({ response }) => {
                return response
            })
    }
}


export const _handleResponseToJson = response => {
    // console.log('_handleResponseToJson');

    if (!response.ok) {
        if (response.status >= 500) {
            throw {
                status: response.status,
                statusText: response.statusText
            };
        }

        if (response.status >= 400) {
            return response.json().then(
                data => {
                    data.status = response.status;
                    data.statusText = response.statusText;
                    return Promise.reject(data);
                },
                () =>
                    Promise.reject({
                        status: response.status,
                        statusText: response.statusText
                    })
            );
        }
    }

    if (response.status === 204) {
        return 'OK';
    }

    return response.json().then(
        json => ({ response: json, headers: response.headers }),
        () => 'OK'
    );
};




export const Request = {
    sendV2NotToken(endPoint, config) {
        const url = `${devHost}/apnpublic/api/v2${endPoint}`;
        return (this.refreshing || Promise.resolve(''))
            .then(() => fetch(url, config))
            .then(_handleResponseToJson)
            .catch(err => {
                throw err;
            });

    },
}





  //未登录查询简历解析状态接口
  export const getParserStatusAsyncNotToken = async (uuid, fileName, contentType) => {
    const config = {
      method: 'GET',
      headers: {}
    };
    return Request.sendV2NotToken(
      `/parsers/resume/status-without-token?fileName=${encodeURIComponent(
        fileName
      )}&uuid=${uuid}&priority=0&contentType=${contentType}`,
      config
    );
  };

   //未登录查询简历解析结果
   export const getParseDataResultNotToken = async uuid => {
    const config = {
      method: 'GET',
      headers: {}
    };
    return Request.sendV2NotToken(`/parsers/resume/result-without-token/${uuid}`, config);
  };
  
  //未登录查询简历解析结果status
  export const getParseResultStatusNotToken = async uuid => {
    const config = {
      method: 'GET',
      headers: {}
    };
    return Request.sendV2NotToken(`/parsers/resume/result-status/${uuid}`, config);
  };


//未登录简历解析触发
export const parseJDNotToken = async (resumeFile) => {
    let type = getUploadFileType(resumeFile);
    if (!type) {
      throw new Error(`The file format is incorrect`);
    }
    const uuid = await getFileUuid(resumeFile);
    const { response } = await getParserStatusAsyncNotToken(
      uuid,
      resumeFile.name,
      resumeFile.type
    );
  
    if (response.status == 'NONE') {
      //返回值若为 NONE  则调上传接口
      const res = await ParseResumeAsync(resumeFile, uuid, response.postPolicy);
      if (res) {
        //上传成功就继续轮训status接口
        return getParseDataStatusNotToken(uuid, resumeFile);
      }
    } else if (response.status == 'STARTED' || response.status == 'QUEUED') {
      //轮训status接口
      return getParseDataStatusNotToken(uuid, resumeFile);
    } else if (response.status == 'NOT_ACCEPT') {
      throw new Error(
        `Sorry, we are currently unable to process your request.We're trying our bestto optimize this feature, please don't re-upload`
      );
    } else if (response.status == 'ERROR') {
      throw new Error(`Fail to parse resume!`);
    } else if (response.status == 'FINISHED' || response.status == 'EDIT') {
      //文件解析结束，应停止GET status，转而GET datasleep
      return _handleParseDateStatusNotToken(response.status, uuid, resumeFile);
    }
  };
  
  
  //未登录轮巡 查询resume解析结果接口 事件
  export const getParseDataStatusNotToken = async (uuid, resumeFile) => {
    let { response: parseOutputCache } = await getParseResultStatusNotToken(uuid);    
    if (!parseOutputCache.status) {
      throw { status: 404, message: 'Resume is not exist!' };
    }
    let count = 0;
    while (
      count < 14 &&
      (parseOutputCache.status === 'QUEUED' ||
        parseOutputCache.status === 'STARTED')
    ) {
      await sleep(2000);
      parseOutputCache = (await getParseResultStatusNotToken(uuid)).response;
      count++;
    }
    //轮训次数不大于40次  且 状态不再为 STARTED || QUEUED
    return _handleParseDateStatusNotToken(parseOutputCache.status, uuid, resumeFile);
  };
  
  export const _handleParseDateStatusNotToken = async (status, uuid, resumeFile) => {
    let parseData = null;
    switch (
      status //判断staus接口状态 是否从result接口拿数据
    ) {
      case 'EDIT':
      case 'FINISHED':
        const { response } = await getParseDataResultNotToken(uuid);
        parseData = response;
        break;
      case 'ERROR':
        throw new Error(`Fail to parse resume!`);
      case 'NOT_ACCEPT':
        throw new Error(
          `Sorry, we are currently unable to process your request.We're trying our bestto optimize this feature, please don't re-upload`
        );
      default: {
        if (!status || status == 'STARTED' || status == 'QUEUED') {
          throw new Error('Fail to parse resume!');
        } else {
          try {
            const { response } = await getParseDataResultNotToken(uuid);
            parseData = response;
            return parseData;
          } catch (e) {
            throw new Error('Fail to parse resume!!');
          }
        }
      }
    }
  
    switch (
      parseData.status //拿到result接口数据 判断result接口
    ) {
      case 'EDIT': {
        return {
            uuid:parseData.uuid,
            name: parseData.fileName,
            url: parseData.displayLink,
            parseOutput:parseData.data && JSON.parse(parseData.data)
          }
      }
      case 'FINISHED': {
        return {
            uuid:parseData.uuid,
            name: parseData.fileName,
            url: parseData.displayLink,
            parseOutput:parseData.data && JSON.parse(parseData.data)
        };
      }
      case 'ERROR':
      case 'NOT_ACCEPT':
      default: {
        if (!parseData.fileName || !parseData.displayLink || !parseData.uuid) {
          throw new Error('Fail to parse resume!');
        } else {
          try {
            return {
                uuid:parseData.uuid,
                name: parseData.fileName,
                url: parseData.displayLink,
                parseOutput:parseData.data && JSON.parse(parseData.data)
            };
          } catch (e) {
            throw new Error('Fail to parse resume!!');
          }
        }
      }
    }
  };


  //上传简历并解析接口
  export const ParseResumeAsync = async (resumeFile, uuid, postPolicy) => {
    let attempts = 0;
    let response = null;
    while (attempts < 3) {
      try {
        response = await uploadPostPolicyS3(resumeFile, postPolicy, uuid);
        if (response) {
          return response;
        }
      } catch (error) {
        attempts++;
        if (attempts === 3) {
          console.log('erro1234', error);
          throw error;
        }
      }
      await sleep();
    }
  };
  
  // 通过 s3 link post policy 直接到s3
  export const uploadPostPolicyS3 = (resumeFile, postPolicy, uuid) => {
    const requestBody = new FormData();
    const file = resumeFile;
    const key = uuid;
    const {
      policy,
      url,
      'x-amz-date': date,
      'x-amz-signature': signature,
      'x-amz-algorithm': algorithm,
      'x-amz-credential': credential
    } = postPolicy;
    const contentType = resumeFile.type;
  
    requestBody.append('Content-Type', contentType); // 获取文件的 MIME 类型
    requestBody.append(
      'Content-Disposition',
      `filename="${encodeURIComponent(file.name)}"`
    );
    requestBody.append('x-amz-date', date); // 获取时间戳
    requestBody.append('x-amz-signature', signature); // 获取后端提供的签名字符串
    requestBody.append('x-amz-algorithm', algorithm);
    requestBody.append('x-amz-credential', credential);
    requestBody.append('Policy', policy); //获取后端提供的 S3 Policy 字符串
    requestBody.append('key', key); // 获取要上传的对象键
    requestBody.append('file', file); // 获取要上传的文件数据
  
    const config = {
      method: 'POST',
      headers: {},
      body: requestBody
    };
  
    return fetch(url, config).then(_handleResponseToS3Link);
  };
  
  function _handleResponseToS3Link(response) {
    if (response && !response.ok) {
      const parser = new DOMParser();
      return response.text().then(
        xml => {
          const xmlDoc = parser.parseFromString(xml, 'application/xml');
          const errorCode = xmlDoc.getElementsByTagName('Code')[0].textContent;
          const errorMessage = xmlDoc.getElementsByTagName('Message')[0]
            .textContent;
          return Promise.reject({
            status: errorCode,
            message: errorMessage
          });
        },
        () =>
          Promise.reject({
            status: response.status,
            statusText: response.statusText
          })
      );
    }
    return response.text().then(
      text => ({ message: text }),
      () => 'OK'
    );
  }



  //登录后 根据uuid直接修改talent信息的接口
  export const changeTalentInfoByUuid = (uuid) => {
    const config = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
    };
    return Request.send(`/hitalent/talent/info/${uuid}`, config);
  };
 


  export const getUploadFileType = file => {
    let index = file.name.lastIndexOf('.');
    let fileType = file.name.substring(index + 1, file.name.length);
    console.log('fileType ext:', fileType);
    //判断文件类型是否符合上传标准
    return fileTypes.includes(fileType.toLowerCase());
  };
  
  export const sleep = timeout => {
    timeout = timeout || 400;
    return new Promise(resolve => {
      setTimeout(() => resolve(), timeout);
    });
  };

  export const getFileUuid = file => {
    return file.arrayBuffer().then(SparkMD5.ArrayBuffer.hash);
  };
  const fileTypes = [
    'doc',
    'pdf',
    'docx',
    'txt',
    'dotc',
    'docm',
    'htm',
    'html',
    'dot',
    'jpg',
    'jpeg',
    'png'
  ];



export default ipgSDK;
