import React from 'react';
import {  withStyles } from '@material-ui/core/styles';
import { Color } from '../../assets/color';
import H1XS from '../../assets/home/H1XS.jpeg';


const mobStyle = theme => ({
    root:{
        marginTop: 140,
        [theme.breakpoints.up('tabletv')]: {
            display: 'none'
         },
    },
    header: {
        width: '100%',
        [theme.breakpoints.up('mob')]: {
            height: 288,
            background: `url("${H1XS}") no-repeat`,
            backgroundSize: 'cover',
            marginTop: 80
        },
        [theme.breakpoints.up('tabletv')]: {
            display: 'none'
         },
    },
    blockmob: {
        background: 'white',
        [theme.breakpoints.up('mob')]: {
            display: 'flex',
            margin: '0px 24px',
        },
        [theme.breakpoints.up('tabletv')]: {
            display: 'none'
         },
    },
})

const MobHeader = props => {
    const { classes } = props;

    return(
        <div className={classes.root}>
             <div className={classes.blockmob}>
                <title>Your Ultimate <br/> Source for Talent Solutions</title>
            </div>
            <div className={classes.header}></div>
        </div>
        
    )
}

export default withStyles(mobStyle)(MobHeader);