import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Color } from '../../assets/color';
import SqrBtnMain from '../../components/squareBtnMain';
import ServiceDetailCard from '../../components/serviceDetailCard';
import CardSliders from '../../components/sliders/cardSliders';
import SchoolSwiper from '../../components/sliders/sliders';
import HiringForm from '../../components/hiringForm';
import { recentJobs } from '../../constant/index'

import RecruitingImg from '../../assets/recruiting/Recruiting.jpeg';
import ico_down from '../../assets/icons/ico_arrow_down.svg';
import ico_talent from '../../assets/icons/ico_talent.svg'
import ico_consulting from '../../assets/icons/ico_consulting.svg'
import ico_recruitment from '../../assets/icons/ico_recruitment.svg'
import ico_eor from '../../assets/icons/ico_eor.svg'
import Adobe from '../../assets/recruiting/Adobe_Symbol.jpg'
import Airbnb from '../../assets/recruiting/Airbnb_Symbol.jpg'
import Google from '../../assets/recruiting/Google_Symbol.jpg'
import Linkedin from '../../assets/recruiting/LinkedIn_Symbol.jpg'
import Twitter from '../../assets/recruiting/Twitter_Symbol.jpg'
import Berkeley from '../../assets/swiper/school/Berkeley_Wordmarks.jpg'
import Cambridge from '../../assets/swiper/school/Cambridge_Wordmarks.jpg'
import CMU from '../../assets/swiper/school/CMU_Wordmarks.jpg'
import  Columbia from '../../assets/swiper/school/Columbia_Wordmarks.jpg'
import Cornell from '../../assets/swiper/school/Cornell_Wordmarks.jpg'
import Georgia from '../../assets/swiper/school/Georgia _Tech_Wordmarks.jpg'
import Harvard from '../../assets/swiper/school/Harvard_Wordmarks.jpg'
import Illinois from '../../assets/swiper/school/Illinois_Wordmarks.jpg'
import Michigan from '../../assets/swiper/school/Michigan_Wordmarks.jpg'
import MIT from '../../assets/swiper/school/MIT_Wordmarks.jpg'
import NTU from '../../assets/swiper/school/Nanyang_Technological_University_Wordmarks.jpg'
import NUS from '../../assets/swiper/school/National_University_Of_Singapore_Wordmarks.jpg'
import Oxford from '../../assets/swiper/school/Oxford_Wordmarks.jpg'
import Princeton from '../../assets/swiper/school/Princeton_Wordmarks.jpg'
import Purdue from '../../assets/swiper/school/Purdue_Wordmarks.jpg'
import Stanford from '../../assets/swiper/school/Stanford_Wordmarks.jpg'
import CUH from '../../assets/swiper/school/The_Chinese_University_Of_HongKong_Wordmarks.jpg'
import HKUST from '../../assets/swiper/school/The_HongKong_University_Of_Science_And_Technology_Wordmarks.jpg'
import UHK from '../../assets/swiper/school/The_University_Of_HongKong_Wordmarks.jpg'
import Toronto from '../../assets/swiper/school/Toronto_Wordmarks.jpg'
import UCL from '../../assets/swiper/school/UCL_Wordmarks.jpg'
import UW from '../../assets/swiper/school/UW_Wordmarks.jpg'
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const style =theme => ({
    header:{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        [theme.breakpoints.up('mob')]: {
        },
        [theme.breakpoints.up('tabletv')]: {
            height: 480
        },
        [theme.breakpoints.up('tableth')]: {
            height: 640
        },
        [theme.breakpoints.up('desktop')]: {
            height: 800
        },
        [theme.breakpoints.up('largedesktop')]: {
            
        },
    },
    headerImg: {
        objectFit: 'cover',
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            height: 288
        },
        [theme.breakpoints.up('tabletv')]: {
            width: '50%',
            height: 480
        },
        [theme.breakpoints.up('tableth')]: {
            height: 640
        },
        [theme.breakpoints.up('desktop')]: {
            height: 800
        },
    },
    headerTitle: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
       
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            height: 288,
            justifyContent: 'center',
        },
        [theme.breakpoints.up('tabletv')]: {
            width: '50%',
            height: 480
        },
        [theme.breakpoints.up('tableth')]: {
            height: 640
        },
        [theme.breakpoints.up('desktop')]: {
            height: 800,
            justifyContent: 'flex-start',
        },
    },
    recruitingTitle: {
        [theme.breakpoints.up('mob')]: {
            width: '100%'
        },
        [theme.breakpoints.up('tableth')]: {
            width: 600
        },
        [theme.breakpoints.up('desktop')]: {
            width: 480
        },
        [theme.breakpoints.up('largedesktop')]: {
            width: 600,
        },
    },
    title:{
        [theme.breakpoints.up('mob')]: {
            padding: '60px 24px',
            width: 240
        },
        [theme.breakpoints.up('tabletv')]: {
            padding: '0px 32px',
            width: 'calc(100% - 64px)'
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '0px 32px',
            width: 360
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '208px 120px',
            width: '70%',
            maxWidth: 480
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '240px 240px 0px',
        },
    },
    button: {
        [theme.breakpoints.up('mob')]: {
            display:'none'
        },
        [theme.breakpoints.up('desktop')]: {
            display:'flex',
            position: 'absolute',
            bottom: 0,
            right: 0
        }
    },
    main: {
        [theme.breakpoints.up('mob')]: {
            padding: '0px 24px'
        },
        [theme.breakpoints.up('tabletv')]: {
            padding: '0px 32px'
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '0px 32px'
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '0px 120px'
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '0px 240px',
        },
    },
    block: {
        display:'flex', 
        flexDirection:'row', 
        justifyContent:'space-between', 
    },
    cardBlock: {
        display:'flex',
        flexDirection:'column',
        [theme.breakpoints.up('mob')]: {
            paddingTop: 80,
        },
        [theme.breakpoints.up('tableth')]: {
            paddingTop: 120
        },
        [theme.breakpoints.up('desktop')]: {
            paddingTop: 200,
        },
    },
    cardGroup: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.up('mob')]: {
            paddingTop: 20,
            overflowY: 'auto'
        },
        [theme.breakpoints.up('tabletv')]: {
            paddingTop: 40,
            overflowY: 'hidden'
        },
        [theme.breakpoints.up('tableth')]: {
            paddingTop: 60,
            overflowY: 'hidden'
        },
        [theme.breakpoints.up('desktop')]: {
            paddingTop: 80,
            overflowY: 'hidden'
        },
    },
    jobTitle: {
       
        flexWrap:'wrap',
        alignItems: 'baseline',
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            paddingBottom: 32,
            display:'flex',
        },
        [theme.breakpoints.up('tableth')]: {
            width: 'calc(50% - 32px)',
            marginRight: 32,
            paddingBottom: 0,
            display:'block',
        },
    },
    campusTitle: {
        marginBottom: 12, 
        [theme.breakpoints.up('mob')]: {
           marginRight: 8
        },
        [theme.breakpoints.up('tableth')]: {
            marginRight: 0
        },
    }
})

const cards = [
    {img: Google, title: 'A search company', content:'Senior Technical Copy Writer & Team Lead' },
    {img: Airbnb, title: 'Airbnb', content:'Senior Software Engineer' },
    {img: Google, title: 'A search company', content:'User Experience Design Manager'},
    {img: Linkedin, title:'LinkedIn' , content:'Product Manager II' },
    {img: Linkedin, title:'LinkedIn' , content:'Product Manager II' },
    {img: Adobe, title:'Adobe' , content:'User Experience Design Manager' },
    {img: Twitter, title:'Twitter' , content:'Senior Technical Copy Writer & Team Lead' },
    {img: Airbnb, title: 'Airbnb', content:'Senior Software Engineer' }
] 

const pics = [ CMU, MIT, Stanford, Berkeley, Illinois, Cornell, Columbia, Harvard, Purdue, UW, Cambridge, Georgia, Princeton, Michigan, UCL, Toronto, Oxford, UHK, NTU, NUS,  ]

class Recruiting extends React.Component {

    constructor() {
        super()
    }

    render() {
        const {classes, menuClicked, history} = this.props;

        return(
            <div>
                <Helmet>
                    <title>Recruiting & Staffing </title>
                    <meta property="og:title" content="Full-service talent acquisition, customized consulting solutions, campus talent recruiting "/>
                    <meta property="og:description" content="Through our proprietary database of 160 million job candidates, we are able to quickly identify best-suited candidates for roles ranging from administrative staff to C-suite executives. In addition to full-time jobs, we offer a worldwide network of pre-evaluated consulting experts. Most of our consultants hold advanced degrees and have specialized knowledge of fields in demand such as AI, cloud computing, machine learning, and operations management. "/>
                    <meta name="description" content="Through our proprietary database of 160 million job candidates, we are able to quickly identify best-suited candidates for roles ranging from administrative staff to C-suite executives. In addition to full-time jobs, we offer a worldwide network of pre-evaluated consulting experts. Most of our consultants hold advanced degrees and have specialized knowledge of fields in demand such as AI, cloud computing, machine learning, and operations management. "/>
                </Helmet>
                <div className={classes.header}>
                    <div className={classes.headerTitle}>
                        <title className={classes.title}>Boost Your Business</title>
                        <div className={classes.button}>
                            <a href='/recruiting#form'>
                                <SqrBtnMain icons={ico_down} content={'Hiring? Tell Us Your Needs'} />
                            </a>
                        </div>
                    </div>
                    <img src={RecruitingImg} alt="Recruiting & Staffing" className={classes.headerImg}></img>
                </div>
                <div className={classes.main}>
                    <div className={`${classes.block} ${classes.cardBlock}`}>
                        <div className={`h1 ${classes.recruitingTitle}`} role="heading" aria-level="1">
                            Recruiting & Staffing Services
                        </div>
                    </div>
                    <div className={classes.cardGroup}>
                        <ServiceDetailCard icons={ico_talent} title={'Direct Hire'} 
                                content={"Our expert consultants has an average of eight years' industry experience. We offer tailored recruitment solutions to source and screen top talent for your specific needs."}/>
                        <ServiceDetailCard icons={ico_eor} title={'Executive Search'} content={"With 15 years of experience and a strong global talent net work"}/>
                    </div>
                    <div className={classes.cardGroup}>
                        <ServiceDetailCard icons={ico_consulting} title={'Flexible Staffing'} 
                                content={'IntelliPro provides tailored workforce management solutions, offering partners access to a vast candidate pool and a mix of high-tech and human-focused strategies. We manage every step, from candidate search to payroll and admin.'}/>
                        <ServiceDetailCard icons={ico_recruitment} title={'MSP supplier'} content={"Are you a Managed Service Provider? IntelliPro can be you trusted partner to help you source talents across industries "}/>
                    </div>
                    <div className={`${classes.block} ${classes.cardBlock}`} style={{flexWrap:'wrap', flexDirection:'row'}}>
                        <div className={`h1 ${classes.jobTitle}`} role="heading" aria-level="2">Jobs We Place</div>
                        <p className={`normal ${classes.jobTitle}`}>Following are just some of the opportunities we've successfully filled for our clients recently.</p>
                    </div>
                    <CardSliders cards={recentJobs} />
                    <div className={`${classes.block} ${classes.cardBlock}`} style={{flexWrap:'wrap', flexDirection:'row'}}>
                        <div className={classes.jobTitle}>   
                            <div className={`h1 ${classes.campusTitle}`} role="heading" aria-level="2">Campus Connections: </div>
                            <h2 style={{fontStyle:"italic"}}>Our Talent Pipeline</h2>
                        </div>
                       
                           
                        <p className={`normal ${classes.jobTitle}`}>Not only do we have a deep network of highly experienced professionals, we also maintain close connections with many of the top universities in the world. In fact, we have organized more than 200 large-scale campus recruiting events at leading institutions across the United States, Asia and Europe. This gives us access to the best and brightest up-and-coming talent -- many of whom have received advanced degrees and cutting-edge training, particularly in technology. Following are just some of the many esteemed colleges and universities IntelliPro partners with:</p>
                    </div>
                    <SchoolSwiper pics={pics}/>
                </div>
                <HiringForm title={'Hiring? Tell us your needs'} />
            </div>
        )
    }

}

export default withStyles(style)(withRouter(Recruiting));