import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import pdf from '../../assets/file/IntelliPro_Bio_Intro_Summer_2022.pdf';
import { Hidden } from '@material-ui/core';

const style = theme => ({
   root: {
        color: '#377DED',
        overflow: 'hidden',
        [theme.breakpoints.up('tableth')]: {
            margin: 0,
        },
        [theme.breakpoints.up('desktop')]: {
            margin: '0 120px'
        },
   },
   link: {
       padding: '24px 36px',
   }
})
const PDF = props => {
    const { classes } = props;
    return (
        <div className={classes.root}>
            <div className={classes.link}>
                <a href={pdf} >Click here if you have trouble viewing the file</a>
            </div>
            <div 
                dangerouslySetInnerHTML={{ __html: "<iframe width='100%' height='1000px' src='https://intelliprogroup.com/static/media/IntelliPro_Bio_Intro_Summer_2022.aadb878e.pdf' />"}} 
            />
        </div>
    );
};

export default withStyles(style)(PDF);
