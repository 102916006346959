import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import JobCard from '../../components/jobCard';
import {  withStyles } from '@material-ui/core/styles';
import { Color } from '../../assets/color';
import SwiperCore, { Autoplay, Pagination } from 'swiper/core';

import 'swiper/swiper.min.css';
import "swiper/components/pagination/pagination.min.css";
import './style.css'

SwiperCore.use([Autoplay, Pagination]);

const style = theme => ({

})


class Sliders extends React.Component {
    constructor() {
        super()
        this.state = { 
            largedesktop: false,
            desktop: false,
            tableth: false,
            tabletv: false,
            mob: false 
        };
    }
    componentDidMount() {
        
        this.getDimensions(); 
        
        //add dimensions listener for window resizing
        window.addEventListener('resize', this.getDimensions); 
    }
      
    //remove listener on page exit
    componentWillUnmount() {
        window.removeEventListener('resize', this.getDimensions); 
    }
    
    //actually set the state to the window dimensions
    getDimensions = () => {
        const query = ['(min-width: 1440px)', '(min-width: 1024px)', '(min-width: 768px)', '(min-width: 414px)', '(min-width: 0px)']
        const mql = query.map(q => window.matchMedia(q).matches) ;
        this.setState({ 
            largedesktop: mql[0],
            desktop: mql[1],
            tableth: mql[2],
            tabletv: mql[3],
            mob: mql[4] 
        }, () => {console.log(this.state)});
    }

    

    render() {
        const{ classes, cards } = this.props;

        return(
            <>
            <Swiper 
                slidesPerView={
                    this.state.largedesktop ? 4 : 
                    (this.state.tableth ? 3 : (
                        this.state.tabletv ? 2 : 1
                    ))
                } 
                slidesPerColumn={this.state.tabletv ? 2 : 4} 
                spaceBetween={
                    this.state.largedesktop ? 40 : 
                    (this.state.tableth ? 32 : (
                        this.state.tabletv ? 24: 24
                    ))
                }
                slidesPerGroup={
                    this.state.largedesktop ? 4 : 
                    (this.state.tableth ? 3 : (
                        this.state.tabletv ? 2 : 1
                    ))
                }
                slidesPerColumnFill="row"
                pagination={{
                    clickable: true
                }}
                autoplay={{
                    "delay": 2500,
                    "disableOnInteraction": false
                }}
                className="myJobCardSwiper"
            >
                {cards.map((card, index) => {
                    return(
                        <SwiperSlide key={index}>
                            <JobCard img={card.img} title={card.title} content={card.content}/>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            </>

        )
    }

}

export default withStyles(style)(Sliders);