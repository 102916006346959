import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { Color } from '../assets/color';


const style = theme => ({
    outer:{
        width: '50%',
        [theme.breakpoints.up('mob')]: {
            minWidth: 364,
            height: 700
        },
        [theme.breakpoints.up('tabletv')]: {
            height: 824,
        },
        [theme.breakpoints.up('tabletv')]: {
            height: 832,
        },
        [theme.breakpoints.up('desktop')]: {
            height: 704,
        },
        [theme.breakpoints.up('largedesktop')]: {
            height: 608,
            maxWidth: 900
        }
    },
    root: {
        // overflow:'scroll',
        boxShadow: 'none',
        border: '1.5px solid #E8E8E8',
        [theme.breakpoints.up('mob')]: {
            margin: 12,
            height:'calc(90% - 12px)',
        },
        [theme.breakpoints.up('tableth')]: {
            margin: 16,
            height:'calc(90% - 16px)',
        },
        [theme.breakpoints.up('desktop')]: {
            margin: 20,
            height:'calc(90% - 20px)',
        }
    },
    cardTitle:{
        [theme.breakpoints.up('mob')]: {
            padding: '24px 0px',
        },

        [theme.breakpoints.up('tabletv')]: {
            padding: '32px 0px',
        },
    },
    cardContent:{
        display:'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('mob')]: {
            padding: 24,
        },

        [theme.breakpoints.up('tabletv')]: {
            padding: 32,
        },

    },
    icon: {
        paddingBottom: 32,
        [theme.breakpoints.up('mob')]: {
            width: 64,
            paddingBottom: 0,
            paddingRight: '1.5%'
        },
        [theme.breakpoints.up('tabletv')]: {
            width: 80,
            paddingBottom: 8
        },
    }
})

const ServiceDetailCard = props => {
    const { classes, icons, title, content } = props;

    return(
        <div className={classes.outer}>
            <Card classes={{root: classes.root}}>
                <div className={classes.cardContent}>
                    <img src={icons} alt="icon" className={classes.icon}></img>
                    <h2 className={classes.cardTitle}>{title}</h2>
                    <p className={`normal`}>{content}</p>
                </div>
                
            </Card>
        </div>
        
    )
}

export default withStyles(style)(ServiceDetailCard);