import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import md5 from 'md5';
import ico_success from '../assets/icons/ico_success.svg';
import ico_error from '../assets/icons/ico_error.svg';
import { Color } from '../assets/color';
import ipgSDK from "../ipg-sdk/api";

const style = theme => ({
    root:{
        backgroundColor: Color.lightBlue,
        [theme.breakpoints.up('mob')]: {
            padding: '80px 24px',
            marginTop: 80,
        },
        [theme.breakpoints.up('tabletv')]: {
            padding: '80px 32px'
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '120px 32px',
            marginTop: 120,
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '200px 120px',
            marginTop: 200,
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '200px 240px',
        },
        display:'flex',
        flexDirection:'column',
    },
    recruitingTitle: {
        [theme.breakpoints.up('mob')]: {
            width: '100%'
        },
        [theme.breakpoints.up('tableth')]: {
            width: 600
        },
        [theme.breakpoints.up('desktop')]: {
            width: 480
        }
    },
    form: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        [theme.breakpoints.up('mob')]: {
            marginTop: 16
        },
        [theme.breakpoints.up('tabletv')]: {
            marginTop: 0
        },
        [theme.breakpoints.up('tableth')]: {
            marginTop: 20
        }
    },
    formInputField: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            padding: '12px 0px'
        },
        [theme.breakpoints.up('tabletv')]: {
            width: 'calc(50% - 20px)',
            padding: '30px 0px'
        },
        [theme.breakpoints.up('tableth')]: {
            width: 'calc(50% - 30px)'
        },
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'sofia-pro',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.02em',
        '& label': {
            fontWeight: 700,
            color: '#000000'
        },
        '& input': {
            borderRadius: 0,
            border: '2px solid #E8E8E8',
            height: 64,
            marginTop: 24,
            paddingLeft: 24,
            fontFamily: 'sofia-pro',
            fontSize: '16px',
            lineHeight: '24px',
            '&:hover': {
                border: '2px solid #377DED'
             },
            '&:focus': {
                border: '2px solid #377DED',
                boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)'
            },
            '&:active': {
                border: '2px solid #377DED',
            },
        }
    },
    messageField:{
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            paddingTop: 12,
            paddingBottom: 24,
        },
        [theme.breakpoints.up('tabletv')]: {
            paddingTop: 30,
            paddingBottom: 60,
        },
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'sofia-pro',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.02em',
    },
    messageBox: {
        [theme.breakpoints.up('mob')]: {
            height: 160
         },
         [theme.breakpoints.up('tabletv')]: {
             height: 288
         },
        borderRadius: 0,
        border: '2px solid #E8E8E8',
        marginTop: 24,
        padding: '20px 24px',
        fontFamily: 'sofia-pro',
        fontSize: '16px',
        lineHeight: '24px',
        '&:hover': {
            border: '2px solid #377DED'
         },
        '&:focus': {
            border: '2px solid #377DED',
            boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)'
        },
        '&:active': {
            border: '2px solid #377DED',
        },
    },
    button: {
        backgroundColor: Color.intelligentBlue,
        borderRadius: 0,
        border: 0,
        color: Color.white,
        fontFamily: 'sofia-pro',
        fontSize: '24px',
        lineHeight: '32px',
        marginRight: 40,
        marginBottom: 16,
        fontWeight: 600,
        width: 240,
        height: 64,
        display: 'flex',
        flexDirection:'column',
        justifyContent:'center',
        boxShadow:'0px 2px 8px rgba(0, 0, 0, 0.08)',
        '&:active': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #377DED'
        },
        '&:focus': {
            background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #377DED'
        },
        '&:hover': {
            cursor: 'pointer',
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #377DED',
            boxShadow:'0px 8px 24px rgba(55, 125, 237, 0.1)',
        }
    }
})

class HiringForm extends React.Component {
    constructor() {
        super()
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            msg: "",
            company: "",
            phone: "",
            position: "",
            remotePosition: false,
            err:"",
            success:"",
            userType:'EMPLOYER',
            source:'IPG_US_WEBSITE',
        }
    }

    handleFirstName = (e) => {
        this.setState({firstName: e.target.value})
    }

    handleLastName = (e) => {
        this.setState({lastName: e.target.value})
    }

    handleEmail = (e) => {
        this.setState({email: e.target.value})
    }

    handleMessage = (e) => {
        this.setState({msg: e.target.value})
    }

    handleCompany = (e) => {
        this.setState({company: e.target.value})
    }

    handlePhone = (e) => {
        this.setState({phone: e.target.value})
    }

    handlePosition = (e) => {
        this.setState({position: e.target.value})
    }


    submit = (e) => {
        let err = ";", valid = true;
        if (this.state.firstName.trim() === "") {
            err += "First name required; ";
            valid = false;
        }
        if (this.state.lastName.trim() === "") {
            err += "Last name required; ";
            valid = false;
        }
        if (this.state.company.trim() === "") {
            valid = false;
            err += "Company required; ";
        }
        if(this.state.phone.trim() === "") {
            valid = false;
            err += "Phone Number required; ";
        }else{
            let re =
            /^([\d+(-][-\d+\s\/)(*.·]{8,25}(\s*ext\s*\d{3,})?)$/i;
           if (!re.test(this.state.phone.trim())) {
            err += 'Invalid Phone Number;\n';
            valid = false;
        }
      }
        if (this.state.email.trim() === "") {
            err += "Work Email required; ";
            valid = false;
        }
        else {
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(this.state.email.trim())) {
                err += "Invalid email;\n";
                valid = false;
            }
        }
        
        if (valid) {
            let time = Date.now();
            let checkSum = md5(`thisissalt${time}`);
            let b = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                company: this.state.company,
                phone: this.state.phone,
                position: this.state.position,
                remotePosition: this.state.remotePosition,
                message: this.state.msg,
                email: this.state.email,
                source: 'IPG_US_WEBSITE',
                userType:'COMPANY',
                timestamp: time,
                checkSum
            };
            console.log(b);
            ipgSDK.comment(b)
              .then(response => {
                if (response.status >= 200 && response.status <= 206) {
                    this.setState({success: "Your message has been sent. Thanks for your interest.", err:""});
                    this.setState({
                        firstName: "",
                        lastName: "",
                        email: "",
                        msg: "",
                        subject: "",
                        company: "",
                        phone: "",
                        position: "",
                    })
                    // this.setState({open: true, valid, error: err.substring(1)});
                }
                else {
                    this.setState({err: 'Sorry someting went wrong. Please try again.'});
                }
            })
        }
        else {
            this.setState({ err: err.substring(1)}, () => {console.log(this.state)});
        }
    };

    render() {

        const { classes, title } = this.props;

        // const FormInputField = withStyles({
        //     root: {
        //         '& label': {
        //             fontFamily: 'sofia-pro',
        //             fontWeight: 700,
        //             fontSize: '16px',
        //             lineHeight: '24px',
        //             letterSpacing: '0.02em',
        //             color: '#000000'
        //         },
        //         '& .MuiInputBase-input': {
        //             fontFamily: 'sofia-pro',
        //             fontWeight: 700,
        //             fontSize: '16px',
        //             lineHeight: '24px',
        //             letterSpacing: '0.02em',
        //             color: '#333333'
        //         },
        //         '& .MuiOutlinedInput-root': {
        //             borderRadius: 0
        //         }
        //     }
        // })(TextField);

        return(
            <div className={classes.root}>
                <div className={`h1 ${classes.recruitingTitle}`} role="heading" aria-level="1">{title}</div>
                <div className={classes.form} id="form">
                    
                    <div className={classes.formInputField}>
                        <label htmlFor="firstname">
                            <span>First Name </span>
                            <span style={{color: Color.intelligentBlue}}>*</span>
                        </label>
                        <input type="text" required placeholder="First Name" id="firstname" name="firstname" onChange={e => this.handleFirstName(e)}></input>
                    </div>
                
                
                    <div className={classes.formInputField}>
                        <label htmlFor="lastname">
                            <span>Last Name </span>
                            <span style={{color: Color.intelligentBlue}}>*</span>
                        </label>
                        <input type="text" required placeholder="Last Name" id="lastname" name="lastname" onChange={e => this.handleLastName(e)}></input>
                    </div>

                    <div className={classes.formInputField}>
                        <label htmlFor="company">
                            <span>Company</span>
                            <span style={{color: Color.intelligentBlue}}>*</span>
                        </label>
                        <input type="text" required placeholder="Company Name" id="company" name="company" onChange={e => this.handleCompany(e)}></input>
                    </div>

                    <div className={classes.formInputField}>
                        <label htmlFor="email">
                            <span>Work Email</span>
                            <span style={{color: Color.intelligentBlue}}>*</span>
                        </label>
                        <input type="email" required placeholder="Email" id="email" name="email" onChange={e => this.handleEmail(e)}></input>
                    </div>

                    <div className={classes.formInputField}>
                        <label htmlFor="phone">
                            <span>Phone Number</span>
                            <span style={{color: Color.intelligentBlue}}>*</span>
                        </label>
                        <input type="text" required placeholder="Phone Number" id="phone" name="phone" onChange={e => this.handlePhone(e)}></input>
                    </div>

                    <div className={classes.formInputField}>
                        <label htmlFor="position">
                            <span>Position to fill</span>
                        </label>
                        <input type="text" placeholder="Position Name" id="position" name="position" onChange={e => this.handlePosition(e)}></input>
                    </div>

                    <div className={classes.messageField}>
                        <label htmlFor="message">
                            <span>Message</span>
                            <span style={{color: Color.intelligentBlue}}>*</span>
                        </label>
                        <textarea type="text" cols="40" rows="5" className={classes.messageBox} required placeholder="Please put your message here..." id="message" name="message" onChange={e => this.handleMessage(e)}></textarea>
                    </div>
                    <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
                        <button className={classes.button} style={this.state.success !== "" ? {display:'none'} : {display: 'flex'} } onClick={e => {this.submit(e)}}>Submit</button>
                        
                        <p className="normal" style={{alignItems:'flex-start'}}>
                            <img 
                                style={ this.state.success !== "" || this.state.err !== "" ? {display:'flex', width: 32, paddingRight: 8} :  {display:"none"}} 
                                src={this.state.success !== "" ? ico_success :  this.state.err !== "" ? ico_error : ""}
                                alt="icon"
                            ></img>
                            {this.state.success}{this.state.err}
                        </p>
                    </div>
                    
                </div>
            </div>
        )
    }

}

export default withStyles(style)(HiringForm);

