import React from 'react';
import { Helmet } from 'react-helmet';
import { withStyles } from '@material-ui/core/styles';
import { Color } from '../../assets/color';
import { blogList, blogContent } from './data';
import linkedin from '../../assets/icons/linkedin.svg';
import Facebook from '../../assets/icons/fb.svg';
import Twitter from '../../assets/icons/tw.svg';

const style = theme => ({
    header: {

        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: Color.intelligentBlue,
        [theme.breakpoints.up('mob')]: {
            display: 'none',
        },
        [theme.breakpoints.up('tableth')]: {
            height: 520,
            padding: '0px 32px',
            display: 'flex',
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '0px 120px'
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '0px 240px',
        },
    },
    mobHeader: {
        flexWrap: 'wrap',
        position: 'relative',
        justifyContent: 'center',
        backgroundColor: Color.intelligentBlue,
        [theme.breakpoints.up('mob')]: {
            height: 450,
            padding: '0px 24px',
            display: 'flex'
        },
        [theme.breakpoints.up('tableth')]: {
            display: 'none'
        },

    },
    headerInfo: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            paddingTop: 60,
        },
        [theme.breakpoints.up('tableth')]: {
            maxWidth: 750
        },
        [theme.breakpoints.up('desktop')]: {
            maxWidth: 900
        },
    },
    delimiter: {
        marginBottom: 16,
        [theme.breakpoints.up('mob')]: {
            marginTop: 24,
            width: 52
        },
        [theme.breakpoints.up('tableth')]: {
            marginTop: 40,
            width: 66
        },
        [theme.breakpoints.up('desktop')]: {
            width: 88
        },
        height: 1,
        backgroundColor: Color.white
    },
    main: {
        [theme.breakpoints.up('mob')]: {
            padding: '0px 24px',
            marginTop: 20,
        },
        [theme.breakpoints.up('tabletv')]: {
            padding: '0px 32px'
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '0px 32px'
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '0px 120px'
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '0px 240px',
        },
    },
    block: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    cardBlock: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('mob')]: {
            paddingTop: 80,
        },
        [theme.breakpoints.up('tableth')]: {
            paddingTop: 120
        },
        [theme.breakpoints.up('desktop')]: {
            paddingTop: 200,
        },
    },
    blogContent: {
        // display: 'relative',
        [theme.breakpoints.up('mob')]: {
            paddingTop: 20,
            paddingBottom: 80,
            display: 'flex',
            flexDirection: 'column-reverse'
        },
        [theme.breakpoints.up('tabletv')]: {
            paddingTop: 0,
        },
        [theme.breakpoints.up('tableth')]: {
            paddingTop: 40,
            paddingBottom: 120
        },
        [theme.breakpoints.up('desktop')]: {
            paddingTop: 120,
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        [theme.breakpoints.up('largedesktop')]: {
            paddingTop: 80,
        },

    },
    content: {
        [theme.breakpoints.up('mob')]: {
            width: '100%'
        },
        [theme.breakpoints.up('desktop')]: {
            width: '50vw',
            maxWidth: 1024
        },
    },
    para: {
        '&::marker': {
            color: '#377DED'
        },
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        fontSize: 24,
        lineHeight: '40px',
        marginBottom: 24,
        marginTop: 24
    },
    share: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            marginLeft: 0,
        },
        [theme.breakpoints.up('desktop')]: {
            display: 'block',
            width: 290,
            marginLeft: 50,
            marginTop: 24,
        },
    },
    blogSubtitle: {
        paddingBottom: 32,
        lineHeight: '48px',
        [theme.breakpoints.up('mob')]: {
            paddingTop: 40,
        },
        [theme.breakpoints.up('tabletv')]: {
            paddingTop: 60,
        },
    },
    blogCitation: {
        paddingBottom: 0,
        [theme.breakpoints.up('mob')]: {
            paddingTop: 60,
        },
        [theme.breakpoints.up('tabletv')]: {
            paddingTop: 80,
        },
    },
    blogImage: {
        width: '100%',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.up('mob')]: {
            height: 200,
        },
        [theme.breakpoints.up('tabletv')]: {
            height: 300,
        },
        [theme.breakpoints.up('desktop')]: {
            height: 400,
        },
    },
    blogImageFullHeight: {
        width: '100%',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.up('mob')]: {
            height: 200,
        },
        [theme.breakpoints.up('tabletv')]: {
            height: 300,
        },
        [theme.breakpoints.up('desktop')]: {
            height: 400,
        },
    },
    blogImagesContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    blogImage2: {
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        [theme.breakpoints.up('mob')]: {
            height: 200,
            width: '100%',
            marginBottom: 8
        },
        [theme.breakpoints.up('tabletv')]: {
            height: 250,
        },
        [theme.breakpoints.up('desktop')]: {
            width: '49%',
            marginBottom: 0
        },
    },
    blogImage3: {
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.up('mob')]: {
            height: 200,
            width: '100%',
            marginBottom: 8
        },
        [theme.breakpoints.up('tabletv')]: {
            height: 250,
        },
        [theme.breakpoints.up('desktop')]: {
            width: '49%',
            marginBottom: 0
        },
    },
    shareSubtitle: {
        // marginTop: 48,
        marginTop: 0,
        marginBottom: 16,
        [theme.breakpoints.up('mob')]: {
            display: 'none'
        },
        [theme.breakpoints.up('desktop')]: {
            display: 'block'
        },
    },
    title: {
        [theme.breakpoints.up('mob')]: {
            paddingBottom: 40,
        },
        [theme.breakpoints.up('tabletv')]: {
            paddingBottom: 60,
        },
    },
    button: {
        backgroundColor: Color.intelligentBlue,
        borderRadius: 0,
        border: 0,
        color: Color.white,
        fontFamily: 'sofia-pro',
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: 600,
        width: 240,
        height: 64,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
        '&:active': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #377DED'
        },
        '&:focus': {
            background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #377DED'
        },
        '&:hover': {
            cursor: 'pointer',
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #377DED',
            boxShadow: '0px 8px 24px rgba(55, 125, 237, 0.1)',
        },
        '&:disabled': {
            color: Color.grey,
            backgroundColor: Color.borderGrey
        }
    },
    socialiconGroup: {
        display: 'flex',
        flexDirection: 'row',
    },
    socialicon: {
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
        textTransform: 'none',
        lineHeight: '24px',
        letterSpacing: '0.02em',
        fontStyle: 'normal',
        fontFamily: 'sofia-pro',
        borderRadius: 48,
        width: 48,
        height: 48,
        display: 'flex',
        justifyContent: 'center',
        margin: 12,
        alignItems: 'center',
        backgroundColor: Color.white,
        '&:active': {
            backgroundColor: Color.intelligentBlue
        },
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: Color.lightBlue
        },
        '&:focus': {
            backgroundColor: Color.lightBlue
        }
    },
    referenceWithLink: {
        alignItems: 'center',
        letterSpacing: '0.04em',
        color: '#5C5E6B',
        display: 'block',
        margin: '10px 0',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        fontSize: 20,
        lineHeight: '32px',
        marginBottom: 24,
        marginTop: 24
    },
    teamMemberCard: {
        margin: '1em 0',
        flexDirection: 'column',
        '& $teamMemberName': { fontSize: 20, margin: 0 },
        '& $teamMemberTitle': {
            fontSize: 18, margin: '.4em 0',
            textAlign: 'center',
            [theme.breakpoints.up('tableth')]: {
                textAlign: 'start',
            },
        },
        [theme.breakpoints.up('tableth')]: {
            flexDirection: 'row',
            alignItems: 'start !important'
        },
        '& img': {
            objectFit: 'cover'
        }
    },
    teamMemberCard2: {
        marginBottom: '1em',
        flexDirection: 'column',
        '& $teamMemberName': { fontSize: 20, margin: 0 },
        '& $teamMemberTitle': {
            fontSize: 18, margin: '.4em 0',
            textAlign: 'center',
            [theme.breakpoints.up('tableth')]: {
                textAlign: 'start',
            },
        },
        [theme.breakpoints.up('tableth')]: {
            // flexDirection: 'row',
            alignItems: 'start !important'
        },
        '& img': {
            objectFit: 'cover'
        }
    },
    teamMemberInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up('tableth')]: {
            alignItems: 'flex-start',
        },
    },
    teamMemberName: {},
    teamMemberTitle: {}


});

const BlogDetail = props => {
    const { classes, match } = props;
    const { id } = match.params
    const data = blogList.find(b => b.id === Number(id));
    const content = blogContent.find(b => b.id === Number(id));

    console.log(content);

    const LinkedinShareHandler = () => {
        if (data) {
            window.open('https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent(document.URL) + '&title=' + data.title,
                '',
                '_blank, width=500, height=500, resizable=yes, scrollbars=yes');
        }
        return false;
    };

    const TwitterShareHandler = () => {
        if (data) {
            window.open('https://twitter.com/intent/tweet?text=' + data.title + encodeURIComponent(document.URL), '',
                '_blank, width=500, height=500, resizable=yes, scrollbars=yes');
        }
        return false;
    };

    const FacebookShareHandler = () => {
        if (data) {
            window.open('http://www.facebook.com/sharer.php?u=' + encodeURIComponent(document.URL) + '&t=' + data.title, '',
                '_blank, width=500, height=500, resizable=yes, scrollbars=yes')
        }
        return false;
    }

    return (
        <div>
            <Helmet>
                <title>{data && data.title}</title>
                <meta property="og:title" content={data && data.title} />
                <meta property="og:type" content="article"></meta>
                <meta property="og:image" content={data && data.img} />
                <meta property="og:description" content={data && data.title} />
                <meta property="og:url"
                      content={'https://www.intelliprogroup.com' + window.location.pathname + window.location.search} />
                <meta property='twitter:title' content={data && data.title} />
                <meta property='twitter:image' content={data && data.img} />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className={classes.header}>
                <div className={classes.headerInfo}>
                    <div className={`h1`} style={{ color: Color.white }} role="heading" aria-level="1">
                        {data && data.title}
                    </div>
                    <div className={classes.delimiter}></div>
                    <p className='normal'
                       style={{ color: Color.white }}>by {data && data.author} on {data && data.datetime}</p>
                </div>
            </div>
            <div className={classes.mobHeader}>
                <div className={classes.headerInfo}>
                    <div className={`h1`} style={{ color: Color.white }} role="heading" aria-level="1">
                        {data && data.title}
                    </div>
                    <div className={classes.delimiter}></div>
                    <p className='normal'
                       style={{ color: Color.white }}>by {data && data.author} on {data && data.datetime}</p>
                </div>
            </div>
            <div className={classes.main}>
                <div className={classes.blogContent}>
                    <div className={classes.content}>
                        {
                            content && content.content.map((item, index) => (
                                <div key={index}>
                                    {
                                        item.subtitle &&
                                        <h2 className={`${classes.blogSubtitle} hro`} key={index}>{item.subtitle}</h2>
                                    }
                                    {
                                        item.subtitleArray &&
                                        <h2 className={`${classes.blogSubtitle} hro`} key={index}>
                                            {
                                                item.subtitleArray.map(item => (
                                                    <> {item} <br></br> </>
                                                ))
                                            }
                                        </h2>
                                    }
                                    {
                                        item.text && item.text.map((text, index) => {
                                            if (text.isRichText) {
                                                return <p className={`normal ${classes.para}`}
                                                          dangerouslySetInnerHTML={{ __html: text.content }}
                                                          key={index}>
                                                </p>
                                            } else

                                                return (

                                                    <p
                                                        className={`normal ${classes.para}`}
                                                        key={index}
                                                    >
                                                        {text}
                                                    </p>
                                                )
                                        })
                                    }
                                    {
                                        item.numberedText && (
                                            <ol>
                                                {
                                                    item.numberedText.map((item, index) => (
                                                        <li className={`normal ${classes.para}`} key={index}>
                                                            {item.boldText && <strong>{item.boldText}</strong>}
                                                            {item.boldText && !item.inline && <br />}
                                                            {item.text }
                                                            {<p className={`normal ${classes.para}`}
                                                               dangerouslySetInnerHTML={{ __html: item.richText }}
                                                               key={index}>
                                                            </p>}
                                                            {!item.text && !item.richText && item}
                                                        </li>
                                                    ))
                                                }
                                            </ol>
                                        )
                                    }
                                    {
                                        item.list && (
                                            <ul>
                                                {
                                                    item.list.map((item, index) => (
                                                        <li className={`normal ${classes.para}`} key={index}>
                                                            {item.boldText && <strong>{item.boldText}</strong>}
                                                            {item.boldText && !item.inline && <br />}
                                                            {item.text||item}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        )
                                    }
                                    {
                                        item.textWithLink && item.textWithLink.map((text, index) => (
                                            <p
                                                className={`normal ${classes.para}`}
                                                key={index}
                                                style={{
                                                    display: 'block',
                                                    fontWeight: text.isBold ? 'bold' : 'normal'
                                                }}
                                            >
                                                {text.beforeLink}
                                                <a href={text.link.link} style={{ color: 'rgb(55, 125, 237)' }} title = "reference link"
                                                   target="_blank" rel="noopener noreferrer" >
                                                    {text.link.text}
                                                </a>
                                                {text.afterLink}
                                            </p>
                                        ))
                                    }
                                    {
                                        item.image &&
                                        <div
                                            style={{ backgroundImage: 'url(' + item.image + ')' }}
                                            className={classes.blogImage}
                                        >

                                        </div>
                                    }
                                    {
                                        item.fullHeightImage &&
                                        <div
                                            style={{ backgroundImage: 'url(' + item.fullHeightImage + ')' }}
                                            className={classes.blogImageFullHeight}
                                        >

                                        </div>
                                    }
                                    {
                                        item.fullImage &&
                                        <div>
                                            <img src={item.fullImage} width='100%' alt="" />
                                        </div>
                                    }
                                    {   item.imageAlt &&
                                        <div style={{margin:6,textAlign:'center',color:'#646a73'}}>
                                            {item.imageAlt}
                            </div>
                                    }
                                    {
                                        item.images &&

                                        <div className={classes.blogImagesContainer}>
                                            {item.images.map((image, index) => {
                                                return <div
                                                    key={index}
                                                    style={{ backgroundImage: 'url(' + image + ')' }}
                                                    className={classes.blogImage2}
                                                >

                                                </div>
                                            })}
                                        </div>

                                    }
                                    {
                                        item.fullImages &&

                                        <div className={classes.blogImagesContainer}>
                                            {item.fullImages.map((image, index) => {
                                                return <div
                                                    key={index}
                                                    style={{ backgroundImage: 'url(' + image + ')' }}
                                                    className={classes.blogImage3}
                                                >

                                                </div>
                                            })}
                                        </div>

                                    }
                                    {
                                        item.video &&
                                        <div>
                                            <video src={item.video} width='100%' controls loop autoPlay muted />
                                        </div>
                                    }
                                    {
                                        item.members && item.members.length > 0 && (

                                            item.members.map((member, index) => (
                                                <p className={`normal ${classes.teamMemberCard}`}>
                                                    <img
                                                        src={member.photoLink} width="190" height="186" />
                                                    <div style={{ width: 20, height: 10 }}>
                                                    </div>
                                                    <div className={classes.teamMemberInfoContainer}>
                                                        <h5 className={classes.teamMemberName}>{member.name}</h5>
                                                        <p className={classes.teamMemberTitle}>{member.title}</p>
                                                        {member.social && <div>
                                                            <a href={member.social.link}
                                                               className={classes.socialicon}
                                                               target="_blank" rel="noopener noreferrer" title="social icon">
                                                                <img style={{ height: 20 }}
                                                                     src={member.social.type === 'linkedin' && linkedin}
                                                                     alt="social icon" />
                                                            </a>
                                                        </div>}

                                                    </div>
                                                </p>
                                            ))

                                        )
                                    }
                                    {
                                        item.membersComments && item.membersComments.length > 0 && (

                                            item.membersComments.map((member, index) => (
                                                <p className={`normal ${classes.teamMemberCard}`}>

                                                    <p className={`normal ${classes.teamMemberCard2}`}>
                                                        <img
                                                            src={member.photoLink} width="240" height="240" />
                                                        <div style={{ width: 16, height: 10,flexShrink:0 }}/>
                                                        <div className={classes.teamMemberInfoContainer}>
                                                            <h5 className={classes.teamMemberName}>{member.name}</h5>
                                                            <p className={classes.teamMemberTitle}>{member.title}</p>
                                                        </div>
                                                    </p>
                                                    <div style={{ width: 20, height: 10,flexShrink:0 }}/>
                                                    <div style={{marginTop:-20}}>
                                                        {
                                                            member.comments && member.comments.map((text, index) => {
                                                                if (text.isRichText) {
                                                                    return <p className={`normal ${classes.para}`}
                                                                              dangerouslySetInnerHTML={{ __html: text.content }}
                                                                              key={index}>
                                                                    </p>
                                                                } else

                                                                    return (

                                                                        <p
                                                                            className={`normal ${classes.para}`}
                                                                            key={index}
                                                                        >
                                                                            {text}
                                                                        </p>
                                                                    )
                                                            })
                                                        }
                                                    </div>
                                                </p>
                                            ))

                                        )
                                    }
                                </div>
                            ))
                        }
                        {
                            content && content.reference && content.reference.length > 0 && (
                                <>
                                    <h3 className={`${classes.blogCitation} hro`}>Reference</h3>
                                    {
                                        content.reference.map((text, index) => (
                                            <p className={`normal ${classes.para}`} key={index}>
                                                {text}
                                            </p>
                                        ))
                                    }
                                </>
                            )
                        }
                        {
                            content && content.referenceWithLink && (
                                <>
                                    <h3 className={`${classes.blogCitation} hro`}>Reference</h3>
                                    {
                                        content.referenceWithLink.map((item, index) => (
                                            <p className={classes.referenceWithLink} key={index}>
                                                {item.beforeLink}
                                                <a href={item.link.link} style={{ color: 'rgb(55, 125, 237)' }}
                                                   target="_blank" rel="noopener noreferrer" title="reference link">
                                                    {` ` + item.link.text}
                                                </a>
                                                {item.afterLink}
                                            </p>
                                        ))
                                    }
                                </>
                            )
                        }
                        {
                            content && content.referenceWithColoredText && (
                                <>
                                    <h3 className={`${classes.blogCitation} hro`}>Reference</h3>
                                    {
                                        content.referenceWithColoredText.map((item, index) => (
                                            <p className={classes.referenceWithLink} key={index}>
                                                {item.beforeLink}
                                                <span style={{ color: 'rgb(55, 125, 237)' }}>
                                                        {` ` + item.link.text}
                                                    </span>
                                                {item.afterLink}
                                            </p>
                                        ))
                                    }
                                </>
                            )
                        }
                    </div>
                    <div className={classes.share}>
                        <h3 className={classes.shareSubtitle}>Share</h3>
                        <div className={classes.socialiconGroup}>
                            <a href='#' onClick={LinkedinShareHandler} className={classes.socialicon} title="social icon">
                                <div target="_blank" rel="noopener noreferrer" style={{ height: 20 }}>
                                    <img style={{ height: 20 }} src={linkedin} alt="social icon"></img>
                                </div>
                            </a>
                            <a href='#' onClick={FacebookShareHandler} className={classes.socialicon} title="social icon">
                                <div target="_blank" rel="noopener noreferrer" style={{ height: 20 }}>
                                    <img style={{ height: 20 }} src={Facebook} alt="social icon"></img>
                                </div>
                            </a>
                            <a href='#' onClick={TwitterShareHandler} className={classes.socialicon} title="social icon">
                                <div target="_blank" rel="noopener noreferrer" style={{ height: 20 }}>
                                    <img style={{ height: 20 }} src={Twitter} alt="social icon"></img>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
};

export default withStyles(style)(BlogDetail)