import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { Color } from '../assets/color';
import {HashLink as Link} from 'react-router-hash-link';

import Adobe from '../assets/jobCompany/Adobe_Symbol.jpg';
import Airbnb from '../assets/jobCompany/Airbnb_Symbol.jpg';
import Google from '../assets/jobCompany/Google_Symbol.jpg';
import Intellipro from '../assets/jobCompany/IntelliPro_Symbol.jpg';
import LinkedIn from '../assets/jobCompany/LinkedIn_Symbol.jpg';
import Twitter from '../assets/jobCompany/Twitter_Symbol.jpg';

import mapPin from '../assets/icons/ico_map_pin.svg';
import Time from '../assets/icons/ico_part_time.svg'
import fullTime from '../assets/icons/ico_full_time.svg';
import moment from 'moment';



const style = theme => ({

    root: {
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
       
        padding: 32,
        [theme.breakpoints.up('mob')]: {
            width: 'calc(100% - 64px)',
            height: 'calc(100% - 24px)',
            margin: 12,
            minHeight: 396,
            justifyContent: 'center',
        },
        [theme.breakpoints.up('tabletv')]: {
            justifyContent: 'space-between',
            minHeight: 300,
        },
        [theme.breakpoints.up('tableth')]: {
            width: 'calc(100% - 62px)',
            height: 'calc(100% - 32px)',
            margin: 16,
            minHeight: 220
        },
        [theme.breakpoints.up('largedesktop')]: {
            minHeight: 172
        },
        '&:hover': {
            cursor: 'pointer',
            boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)',
        }
    }
})

class JobListCard extends React.Component {
    constructor() {
        super()
        this.state = {
            card: false,
            tabletcard: false,
            mobcard: false,
            largedesktop: false,
            desktop: false,
            tableth: false,
            tabletv: false,
            mob: false  
        }
    }

    componentDidMount() {
        
        this.getDimensions(); 
        
        //add dimensions listener for window resizing
        window.addEventListener('resize', this.getDimensions); 
    }
      
    //remove listener on page exit
    componentWillUnmount() {
        window.removeEventListener('resize', this.getDimensions); 
    }
    
    //actually set the state to the window dimensions
    getDimensions = () => {
        const query = [794, 594, 0]
        const mql = query.map(q =>  { return document.querySelector('.jobListDetail').offsetWidth > q}) ;
        this.setState({ 
            card: mql[0],
            tabletcard: mql[1],
            mobcard: mql[2],
        });
        const queryWindow = ['(min-width: 1441px)', '(min-width: 1025px)', '(min-width: 769px)', '(min-width: 415px)', '(min-width: 0px)']
        const mqlWindow = queryWindow.map(q => window.matchMedia(q).matches) ;
        this.setState({ 
            largedesktop: mqlWindow[0],
            desktop: mqlWindow[1],
            tableth: mqlWindow[2],
            tabletv: mqlWindow[3],
            mob: mqlWindow[4] 
        });
    }


    render() {
        const { classes, id, company, name, type, location, logo, date } = this.props;

        const companyImg = (company) => {
            switch(company) {
                case "Intellipro Group Inc.":
                    return Intellipro;
                default:
                    return Intellipro;
            }
        }

        return(
            <Link to={{pathname: "/jobDetail", search:`?id=${id}`}}>
                {this.state.card ? 
                    <div className={`${classes.root} jobListDetail`}>
                        <div style={{display: 'flex', alignItems:'center'}}>
                            <img src={logo} alt="company logo" style={{width: 108, height: 108, paddingRight: 16}}></img>
                            <h3 style={{width: 128}}>{company}</h3>
                        </div>
                        <h3 style={{width: 258, maxHeight: 64, overflow:'hidden', textOverflow: 'ellipsis'}}>{name}</h3>
                        <div style={{display: 'flex', flexDirection:'column', width: 220}}>
                            <div style={{display:'flex', paddingBottom: 16}}>
                                <img src={fullTime} alt="icon" style={{width: 24, height: 24, paddingRight: 8}}></img>
                                <p className='cap'>{type}</p>
                            </div>
                            {location !== "" && 
                                <div style={{display:'flex', paddingBottom: 16}}>
                                    <img src={mapPin} alt="icon" style={{width: 24, height: 24, paddingRight: 8}}></img>
                                    <p className='cap'>{location}</p>
                                </div>
                            }
                            <div style={{display:'flex'}}>
                                <img src={Time} alt="icon" style={{width: 20, height: 21, paddingRight: 11, paddingLeft: 3}}></img>
                                <p className='cap'>{moment(date).format('ll')}</p>
                            </div>
                        </div>
                        <p className="boldcap" style={{color: Color.intelligentBlue}}>View Job Detail</p>        
                    </div> 
                    : 
                    this.state.tabletcard ? 
                        this.state.tableth ? 
                            <div className={`${classes.root} jobListDetail`}>
                                <div style={{display: 'flex', alignItems:'center'}}>
                                    <img src={logo} alt="company logo" style={{width: 108, height: 108, paddingRight: 16}}></img>
                                    <h3 style={{width: 128}}>{company}</h3>
                                </div>
                                <h3 style={{width: 258, maxHeight: 64, overflow:'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp:2}}>{name}</h3>
                                <div style={{display:'flex', flexDirection: 'column'}}>
                                    <div style={{display: 'flex'}}>
                                        <div style={{display:'flex', paddingBottom: 16, paddingRight: 16}}>
                                            <img src={fullTime} alt="icon" style={{width: 24, height: 24, paddingRight: 8}}></img>
                                            <p className='cap'>{type}</p>
                                        </div>
                                        {location !== "" && 
                                             <div style={{display:'flex', paddingBottom: 16, paddingRight: 16}}>
                                                <img src={mapPin} alt="icon" style={{width: 24, height: 24, paddingRight: 8}}></img>
                                                <p className='cap'>{location}</p>
                                            </div>
                                        }
                                        <div style={{display:'flex', paddingBottom: 16,}}>
                                            <img src={Time} alt="icon" style={{width: 20, height: 21, paddingRight: 11, paddingLeft: 3}}></img>
                                            <p className='cap'>{moment(date).format('ll')}</p>
                                        </div>
                                    </div>
                                    <p className="boldcap" style={{color: Color.intelligentBlue}}>View Job Detail</p>        
                                </div>
                                
                            </div> : 
                            <div className={`${classes.root} jobListDetail`}>
                                <div style={{display: 'flex', alignItems:'center'}}>
                                    <img src={logo} alt="company logo" style={{width: 108, height: 108, paddingRight: 16}}></img>
                                    <h3 style={{width: 128}}>{company}</h3>
                                </div>
                                <div style={{display:'flex', flexDirection: 'column', width: '100%'}}>
                                    <h3 style={{width: '100%', marginBottom: 16, maxHeight: 64, overflow:'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp:2}}>{name}</h3>
                                    <div style={{display: 'flex'}}>
                                        <div style={{display:'flex', paddingBottom: 16, paddingRight: 16}}>
                                            <img src={fullTime} alt="icon" style={{width: 24, height: 24, paddingRight: 8}}></img>
                                            <p className='cap'>{type}</p>
                                        </div>
                                        {location !== "" &&
                                            <div style={{display:'flex', paddingBottom: 16, paddingRight: 16}}>
                                            <img src={mapPin} alt="icon" style={{width: 24, height: 24, paddingRight: 8}}></img>
                                            <p className='cap'>{location}</p>
                                        </div>
                                        }
                                        <div style={{display:'flex', paddingBottom: 16,}}>
                                            <img src={Time} alt="icon" style={{width: 20, height: 21, paddingRight: 11, paddingLeft: 3}}></img>
                                            <p className='cap'>{moment(date).format('ll')}</p>
                                        </div>
                                    </div>
                                    <p className="boldcap" style={{color: Color.intelligentBlue}}>View Job Detail</p>        
                                </div>
                                
                            </div>
                        :
                        <div className={`${classes.root} jobListDetail`} style={{flexDirection: 'column', alignItems:'flex-start'}}>
                            <div style={{display: 'flex', alignItems:'center'}}>
                                <img src={companyImg(company)} alt="company logo" style={{width: 108, height: 108, paddingRight: 16}}></img>
                                <h3 style={{width: 128}}>{company}</h3>
                            </div>
                            <h3 style={{width: 278, margin: '24px 0px', maxHeight: 64, overflow:'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp:2}}>{name}</h3>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <div style={{display:'flex', paddingBottom: 16}}>
                                    <img src={fullTime} alt="icon" style={{width: 24, height: 24, paddingRight: 8}}></img>
                                    <p className='cap'>{type}</p>
                                </div>
                                {location !== "" &&
                                    <div style={{display:'flex',  paddingBottom: 16}}>
                                        <img src={mapPin} alt="icon" style={{width: 24, height: 24, paddingRight: 8}}></img>
                                        <p className='cap'>{location}</p>
                                    </div>
                                }
                                <div style={{display:'flex',  paddingBottom: 16}}>
                                    <img src={Time} alt="icon" style={{width: 20, height: 21, paddingRight: 11, paddingLeft: 3}}></img>
                                    <p className='cap'>{moment(date).format('ll')}</p>
                                </div>
                            </div>
                            <p className="boldcap" style={{color: Color.intelligentBlue}}>View Job Detail</p>        
                        </div> 
                }
            </Link>
           
        )
    }
}

export default withStyles(style)(JobListCard);

