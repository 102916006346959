import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { Color } from '../assets/color';
import mapPin from '../assets/icons/ico_map_pin.svg'
import { calc } from 'antd/es/theme/internal';

const style = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        // border: '1.5px solid #E8E8E8',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up('mob')]: {
            margin: 12,
            width: 'calc(100% - 24px)',
            height: 220
        },
        [theme.breakpoints.up('tableth')]: {
            margin: 15,
            width: 'calc(43% - 32px)',
            height: 260
        },
        [theme.breakpoints.up('desktop')]: {
            margin: 20,
            height: 260,
            width: 'calc(47% - 40px)'
        }
    },
    card:{
         justifyContent: 'center', 
         alignItems: 'center',
        [theme.breakpoints.up('mob')]: {
          // marginBottom: 20
           
        },
        [theme.breakpoints.up('tableth')]: {
          width: 450,
          height:340
        },
        [theme.breakpoints.up('desktop')]: {
            width: 430,
            height:340
        }

    }
})

class VideoCard extends React.Component {
    constructor() {
      super();
    }
  
    render() {
      const { classes, place, videoId } = this.props;
  
      const youtubeUrl = `https://www.youtube.com/embed/${videoId}`;
  
      return (
        <div className={classes.root}>
        
            <iframe
             className={classes.card}
              src={youtubeUrl}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube video"
            ></iframe>
          
        </div>
      );
    }
  }
export default withStyles(style)(VideoCard);