import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';

import md5 from 'md5';
import ico_success from '../assets/icons/ico_success.svg';
import ico_error from '../assets/icons/ico_error.svg';
import { Color } from '../assets/color';
import ipgSDK from '../ipg-sdk/api';
import service from '../pages/service';
import Select from 'react-select';


const style = (theme) => ({
  root: {
    // backgroundColor: Color.lightBlue,
    [theme.breakpoints.up('mob')]: {
      padding: '80px 24px',
      marginTop: 40,
    },
    [theme.breakpoints.up('tabletv')]: {
      padding: '80px 32px',
    },
    [theme.breakpoints.up('tableth')]: {
      // padding: '120px 32px',
      marginTop: 50,
    },
    [theme.breakpoints.up('desktop')]: {
      // padding: '200px 120px',
      marginTop: 70,
    },
    [theme.breakpoints.up('largedesktop')]: {
      padding: '200px 240px',
    },
    display: 'flex',
    flexDirection: 'column',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  recruitingTitle: {
    fontWeight: 'normal',
    [theme.breakpoints.up('mob')]: {
      width: '100%',
      fontSize: 20,
      paddingBottom: 20,
      paddingLeft: 40,
      paddingRight: 10,
      marginBottom: -40,
    },
    [theme.breakpoints.up('tableth')]: {
      width: 190,
      fontSize: 30,
    },
    [theme.breakpoints.up('desktop')]: {
      width: 250,
      fontSize: 40,
      paddingBottom: 20,
      paddingLeft: 80,
      paddingRight: 10,
      marginBottom: -40,
    },
  },
  active: {
    backgroundColor: Color.lightBlue,
    textDecoration: 'underline',
    fontWeight: 'bold',
    [theme.breakpoints.up('mob')]: {},
    [theme.breakpoints.up('tabletv')]: {
      textUnderlineOffset: '5px',
    },
    [theme.breakpoints.up('tableth')]: {
      textUnderlineOffset: '5px',
    },
    [theme.breakpoints.up('desktop')]: {
      textUnderlineOffset: '7px',
    },
  },
  form: {
    display: 'flex',
    backgroundColor: Color.lightBlue,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.breakpoints.up('mob')]: {
      marginTop: 16,
      padding: '20px 24px',
    },
    [theme.breakpoints.up('tabletv')]: {
      marginTop: 0,
      padding: '20px 24px',
    },
    [theme.breakpoints.up('tableth')]: {
      marginTop: 20,
      padding: '40px 32px',
    },
    [theme.breakpoints.up('desktop')]: {
      padding: '70px 120px',
    },
  },
  formInputField: {
    [theme.breakpoints.up('mob')]: {
      width: '100%',
      padding: '12px 0px',
    },
    [theme.breakpoints.up('tabletv')]: {
      width: 'calc(50% - 20px)',
      padding: '30px 0px',
    },
    [theme.breakpoints.up('tableth')]: {
      width: 'calc(50% - 30px)',
    },
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'sofia-pro',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.02em',
    '& label': {
      fontWeight: 700,
      color: '#000000',
    },
    '& input': {
      borderRadius: 0,
      border: '2px solid #E8E8E8',
      height: 64,
      marginTop: 24,
      paddingLeft: 24,
      fontFamily: 'sofia-pro',
      fontSize: '16px',
      lineHeight: '24px',
      '&:hover': {
        border: '2px solid #377DED',
      },
      '&:focus': {
        border: '2px solid #377DED',
        boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)',
      },
      '&:active': {
        border: '2px solid #377DED',
      },
    },
  },
  messageField: {
    [theme.breakpoints.up('mob')]: {
      width: '100%',
      paddingTop: 12,
      paddingBottom: 24,
    },
    [theme.breakpoints.up('tabletv')]: {
      paddingTop: 30,
      paddingBottom: 60,
    },
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'sofia-pro',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.02em',
  },
  messageBox: {
    [theme.breakpoints.up('mob')]: {
      height: 160,
    },
    [theme.breakpoints.up('tabletv')]: {
      height: 288,
    },
    borderRadius: 0,
    border: '2px solid #E8E8E8',
    marginTop: 24,
    padding: '20px 24px',
    fontFamily: 'sofia-pro',
    fontSize: '16px',
    lineHeight: '24px',
    '&:hover': {
      border: '2px solid #377DED',
    },
    '&:focus': {
      border: '2px solid #377DED',
      boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)',
    },
    '&:active': {
      border: '2px solid #377DED',
    },
  },
  button: {
    backgroundColor: Color.intelligentBlue,
    borderRadius: 0,
    border: 0,
    color: Color.white,
    fontFamily: 'sofia-pro',
    fontSize: '24px',
    lineHeight: '32px',
    marginRight: 40,
    marginBottom: 16,
    fontWeight: 600,
    width: 240,
    height: 64,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
    '&:active': {
      background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #377DED',
    },
    '&:focus': {
      background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #377DED',
    },
    '&:hover': {
      cursor: 'pointer',
      background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #377DED',
      boxShadow: '0px 8px 24px rgba(55, 125, 237, 0.1)',
    },
  },
});

class SwitchForm extends React.Component {
  constructor() {
    super();
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      msg: '',
      company: '',
      phone: '',
      position: '',
      serviceType:'',
      channelType:'',
      source:'IPG_US_WEBSITE',
      remotePosition: false,
      err: '',
      success: '',
      activeTab: 'EMPLOYER',
    };
  }

  handleFirstName = (e) => {
    this.setState({ firstName: e.target.value });
  };

  handleLastName = (e) => {
    this.setState({ lastName: e.target.value });
  };

  handleEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  handleMessage = (e) => {
    this.setState({ msg: e.target.value });
  };

  handleCompany = (e) => {
    this.setState({ company: e.target.value });
  };

  handlePhone = (e) => {
    this.setState({ phone: e.target.value });
  };

  handlePosition = (e) => {
    this.setState({ position: e.target.value });
  };

  handleServiceInterested = (selectedOption) => {
    this.setState({ serviceType: selectedOption });
  };
  
  handleKnowUsFrom = (selectedOption) => {
    this.setState({ channelType: selectedOption });
  };

  switchTab = (tab) => {
    this.setState({ activeTab: tab, err:'', success:'' });
    this.setState({
      firstName: '',
      lastName: '',
      email: '',
      msg: '',
      subject: '',
      company: '',
      phone: '',
      serviceType:'',
      channelType:'',
      userType: tab,
      position: '',
    });
  };

  submit = (e) => {
    let err = ';',
      valid = true;
    if (this.state.firstName.trim() === '') {
      err += 'First name required; ';
      valid = false;
    }
    if (this.state.lastName.trim() === '') {
      err += 'Last name required; ';
      valid = false;
    }
    // if (this.state.company.trim() === '' && this.state.activeTab == 'EMPLOYER') {
    //   valid = false;
    //   err += 'Company required; ';
    // }
    if (this.state.phone.trim() === '') {
      valid = false;
      err += 'Phone Number required; ';
    } else{
      let re =
      /^([\d+(-][-\d+\s\/)(*.·]{8,25}(\s*ext\s*\d{3,})?)$/i;
     if (!re.test(this.state.phone.trim())) {
      err += 'Invalid Phone Number;\n';
      valid = false;
    }
    }

    if ((!this.state.serviceType || !this.state.serviceType.value) 
      && this.state.activeTab == 'EMPLOYER') { 
      valid = false;
      err += 'Service Interested required; ';
    }
    if (this.state.email.trim() === '') {
      err += 'Work Email required; ';
      valid = false;
    } else {
      let re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(this.state.email.trim())) {
        err += 'Invalid email;\n';
        valid = false;
      }
    }

    if (valid) {
      let time = Date.now();
      let checkSum = md5(`thisissalt${time}`);
      let b 
      if (this.state.activeTab == 'EMPLOYER') {
        b= {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          // company: this.state.company,
          phone: this.state.phone,
          // position: this.state.position,
          // remotePosition: this.state.remotePosition,
          message: this.state.msg,
          email: this.state.email,
          serviceType: this.state.serviceType.value,
          channelType: this.state.channelType.value,
          userType: this.state.activeTab,
          source: 'IPG_US_WEBSITE',
          timestamp: time,
          checkSum,
        };
      } else{
        b= {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          // company: this.state.company,
          phone: this.state.phone,
          // position: this.state.position,
          // remotePosition: this.state.remotePosition,
          message: this.state.msg,
          email: this.state.email,
          // serviceType: this.state.serviceType.value,
          // channelType: this.state.channelType.value,
          userType: this.state.activeTab,
          source: 'IPG_US_WEBSITE',
          timestamp: time,
          checkSum,
        };
      }
     
      console.log(b);
      ipgSDK.comment(b).then((response) => {
        if (response.status >= 200 && response.status <= 206) {
          this.setState({
            success: 'Your message has been sent. Thanks for your interest.',
            err: '',
          });
          console.log(response);
          this.setState({
            firstName: '',
            lastName: '',
            email: '',
            msg: '',
            subject: '',
            company: '',
            phone: '',
            serviceType:'',
            channelType:'',
            userType:'EMPLOYER',
            position: '',
          });
          // this.setState({open: true, valid, error: err.substring(1)});
        } else {
          this.setState({
            err: 'Sorry someting went wrong. Please try again.',
          });
        }
      });
    } else {
      this.setState({ err: err.substring(1) }, () => {
        console.log(this.state);
      });
    }
  };

  render() {
    const { classes, title1, title2 } = this.props;
    const { activeTab } = this.state;
    const services = [
      { value: 'GENERAL_INQUIRIES', label: 'General Inquiries' },
      { value: 'STAFFING_RECRUITING', label: 'Staffing & Recruiting' },
      { value: 'EOR_HRO_PEO', label: 'EOR / HRO/ PEO' },
      { value: 'BUSINESS_PROCESS_OUTSOUCING', label: 'Business Process Outsoucing' },
      { value: 'DEI_CONSULTING', label: 'DEI Consulting' },

    ];

    const knowFrom = [
      { value: 'SOCIAL_MEDIA', label: 'Social Media' },
      { value: 'SEARCH_ENGINE', label: 'Search Engine' },
      { value: 'EVENT', label: 'Event' },
      { value: 'REFERRAL', label: 'Referral' },
      { value: 'OTHER', label: 'Other' },

    ];
    const customStyles = {
      control: (provided) => ({
        ...provided,
        marginTop: 24,
      }),
    };


    // const FormInputField = withStyles({
    //     root: {
    //         '& label': {
    //             fontFamily: 'sofia-pro',
    //             fontWeight: 700,
    //             fontSize: '16px',
    //             lineHeight: '24px',
    //             letterSpacing: '0.02em',
    //             color: '#000000'
    //         },
    //         '& .MuiInputBase-input': {
    //             fontFamily: 'sofia-pro',
    //             fontWeight: 700,
    //             fontSize: '16px',
    //             lineHeight: '24px',
    //             letterSpacing: '0.02em',
    //             color: '#333333'
    //         },
    //         '& .MuiOutlinedInput-root': {
    //             borderRadius: 0
    //         }
    //     }
    // })(TextField);

    return (
      <div className={classes.root}>
        <div className={classes.titleContainer}>
          <div
            className={`h1 ${classes.recruitingTitle} ${activeTab === 'EMPLOYER' ? classes.active : ''}`}
            role="heading"
            aria-level="1"
            onClick={() => this.switchTab('EMPLOYER')}
          >
            {title1}
          </div>
          <div
            className={`h1 ${classes.recruitingTitle} ${activeTab === 'EMPLOYEE' ? classes.active : ''}`}
            role="heading"
            aria-level="1"
            onClick={() => this.switchTab('EMPLOYEE')}
          >
            {title2}
          </div>
        </div>

        <div className={classes.form} id="form">
          {activeTab == 'EMPLOYER' && (
            <>
              <div className={classes.formInputField}>
                <label htmlFor="firstname">
                  <span>First Name </span>
                  <span style={{ color: Color.intelligentBlue }}>*</span>
                </label>
                <input type="text" required placeholder="First Name" id="firstname" name="firstname" onChange={(e) => this.handleFirstName(e)}></input>
              </div>

              <div className={classes.formInputField}>
                <label htmlFor="lastname">
                            <span>Last Name </span>
                            <span style={{color: Color.intelligentBlue}}>*</span>
                        </label>
                        <input type="text" required placeholder="Last Name" id="lastname" name="lastname" onChange={e => this.handleLastName(e)}></input>
              </div>

              {/* <div className={classes.formInputField}>
                <label htmlFor="company">
                  <span>Company</span>
                  <span style={{ color: Color.intelligentBlue }}>*</span>
                </label>
                <input type="text" required placeholder="Company" id="company" name="company" onChange={(e) => this.handleCompany(e)}></input>
              </div> */}

              <div className={classes.formInputField}>
                <label htmlFor="email">
                  <span>Email</span>
                  <span style={{ color: Color.intelligentBlue }}>*</span>
                </label>
                <input type="email" required placeholder="Email" id="email" name="email" onChange={(e) => this.handleEmail(e)}></input>
              </div>

              <div className={classes.formInputField}>
                <label htmlFor="phone">
                  <span>Phone Number</span>
                  <span style={{ color: Color.intelligentBlue }}>*</span>
                </label>
                <input type="text" required placeholder="Phone Number" id="phone" name="phone" onChange={(e) => this.handlePhone(e)}></input>
              </div>

              {/* <div className={classes.formInputField}>
                <label htmlFor="position">
                  <span>Title</span>
                </label>
                <input type="text" placeholder="Title" id="position" name="position" onChange={(e) => this.handlePosition(e)}></input>
              </div> */}
                  {/* <div className={classes.formInputField}>
                <label htmlFor="position">
                  <span>Service Interested</span>
                </label>
                <input type="text" placeholder="Service Interested" id="serviceInterested" name="serviceInterested" onChange={(e) => this.handlePosition(e)}></input>
              </div> */}
                <div className={classes.formInputField}>
               
                 <label htmlFor="serviceType">
                    <span>Service Interested <span style={{color: Color.intelligentBlue}}>*</span></span>
                 </label>
                 <Select
              value={this.state.serviceType}
              onChange={this.handleServiceInterested}
              options={services}
              styles={customStyles}
              placeholder="Service Interested"
            />
             
          </div>
          <div className={classes.formInputField}>
               
               <label htmlFor="channelType">
                  <span>How Did you Hear about us </span>
               </label>
               <Select
            value={this.state.channelType}
            onChange={this.handleKnowUsFrom}
            options={knowFrom}
            styles={customStyles}
            placeholder="How Did you Hear about us"
          />
           
        </div>

              <div className={classes.messageField}>
                <label htmlFor="message">
                  <span>Message</span>
                  <span style={{ color: Color.intelligentBlue }}>*</span>
                </label>
                <textarea
                  type="text"
                  cols="40"
                  rows="5"
                  className={classes.messageBox}
                  required
                  placeholder="Please put your message here..."
                  id="message"
                  name="message"
                  onChange={(e) => this.handleMessage(e)}
                ></textarea>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                }}
              >
                <button
                  className={classes.button}
                  style={this.state.success !== '' ? { display: 'none' } : { display: 'flex' }}
                  onClick={(e) => {
                    this.submit(e);
                  }}
                >
                  Send
                </button>

                <p className="normal" style={{ alignItems: 'flex-start' }}>
                  <img
                    style={this.state.success !== '' || this.state.err !== '' ? { display: 'flex', width: 32, paddingRight: 8 } : { display: 'none' }}
                    src={this.state.success !== '' ? ico_success : this.state.err !== '' ? ico_error : ''}
                    alt="icon"
                  ></img>
                  {this.state.success}
                  {this.state.err}
                </p>
              </div>
            </>
          )}

          {activeTab == 'EMPLOYEE' && (
            <>
              <div className={classes.formInputField}>
                <label htmlFor="firstname">
                  <span>First Name </span>
                  <span style={{ color: Color.intelligentBlue }}>*</span>
                </label>
                <input type="text" required placeholder="First Name" id="firstname" name="firstname" onChange={(e) => this.handleFirstName(e)}></input>
              </div>

              <div className={classes.formInputField}>
                <label htmlFor="lastname">
                            <span>Last Name </span>
                            <span style={{color: Color.intelligentBlue}}>*</span>
                        </label>
                        <input type="text" required placeholder="Last Name" id="lastname" name="lastname" onChange={e => this.handleLastName(e)}></input>
              </div>

              {/* <div className={classes.formInputField}>
                        <label htmlFor="company">
                            <span>Company</span>
                            <span style={{color: Color.intelligentBlue}}>*</span>
                        </label>
                        <input type="text" required placeholder="Company Name" id="company" name="company" onChange={e => this.handleCompany(e)}></input>
                    </div> */}

              <div className={classes.formInputField}>
                <label htmlFor="email">
                  <span>Email</span>
                  <span style={{ color: Color.intelligentBlue }}>*</span>
                </label>
                <input type="email" required placeholder="Email" id="email" name="email" onChange={(e) => this.handleEmail(e)}></input>
              </div>

              <div className={classes.formInputField}>
                <label htmlFor="phone">
                  <span>Phone Number</span>
                  <span style={{ color: Color.intelligentBlue }}>*</span>
                </label>
                <input type="text" required placeholder="Phone Number" id="phone" name="phone" onChange={(e) => this.handlePhone(e)}></input>
              </div>

              {/* <div className={classes.formInputField}>
                        <label htmlFor="position">
                            <span>Position to fill</span>
                        </label>
                        <input type="text" placeholder="Position Name" id="position" name="position" onChange={e => this.handlePosition(e)}></input>
                    </div> */}

              <div className={classes.messageField}>
                <label htmlFor="message">
                  <span>Message</span>
                  <span style={{ color: Color.intelligentBlue }}>*</span>
                </label>
                <textarea
                  type="text"
                  cols="40"
                  rows="5"
                  className={classes.messageBox}
                  required
                  placeholder="Please put your message here..."
                  id="message"
                  name="message"
                  onChange={(e) => this.handleMessage(e)}
                ></textarea>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                }}
              >
                <button
                  className={classes.button}
                  style={this.state.success !== '' ? { display: 'none' } : { display: 'flex' }}
                  onClick={(e) => {
                    this.submit(e);
                  }}
                >
                  Send
                </button>

                <p className="normal" style={{ alignItems: 'flex-start' }}>
                  <img
                    style={this.state.success !== '' || this.state.err !== '' ? { display: 'flex', width: 32, paddingRight: 8 } : { display: 'none' }}
                    src={this.state.success !== '' ? ico_success : this.state.err !== '' ? ico_error : ''}
                    alt="icon"
                  ></img>
                  {this.state.success}
                  {this.state.err}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(style)(SwitchForm);
