import React from 'react';
import {
  BrowserRouter, withRouter
} from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import './App.css';

import AppNav from './components/nav';
import BodyWrapper from './layouts/appContainer';
import { createMuiTheme, ThemeProvider, withTheme } from '@material-ui/core';
import Footer from './components/footer/index';

var theme = createMuiTheme({
  breakpoints: {
    values: {
      widedesktop: 1751,
      largedesktop: 1441,
      join: 1300,
      desktop: 1025,
      tableth: 769,
      jobDetail: 600,
      tabletv: 415,
      mob: 0
    }
  },
  spacing: 8
})

const styles = (theme) => ({
  mainWrapper: {
    margin: '0 auto',
    width: '100%',
  }
});


class ScrollToTopComp extends React.Component {
  componentDidUpdate(prevProps) {
    window.scrollTo(0, 0);
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.updatePath();
    }
  }

  render() {
    return this.props.children;
  }
}

let ScrollToTop = withRouter(ScrollToTopComp);

class App extends React.Component {
  state = {
    activeMenuIndex: 0
  };

  onMenuClicked = i => {
    this.setState({ activeMenuIndex: i })
  };

  updatePath = () => {
    let path = window.location.pathname.substring(1);
    // console.log(path);
    this.setState({
      activeMenuIndex:
        (
          (path === "" || path === "/") ? 0 :
            (path === "recruiting" || path === "recruiting/") ? 1 :
              (path === "service" || path === "service/") ? 2 :
                (path === "jobseeker" || path === "jobseeker/" || path === "jobDetail") ? 3 :
                  (path === "aboutus" || path === "aboutus/") ? 4 :
                    (path === "contactus" || path === "contactus/") ? 5 :
                    path.substring(0, 5) === "event" ? 6 :
                      path.substring(0, 4) === "blog" ? 7 :
                        (path === "join" || path === "join/") ? 8 :
                          path === "resource" || path === "verification" || path === "benefits" ? -1 : -1
        )
    })
  };

  componentWillMount() {
    this.updatePath();
  }

  addArialabel = () => {
    const MuiTouchList = [...document.getElementsByClassName('MuiTouchRipple-root')];
    MuiTouchList.forEach(node => {
        node.setAttribute('role', 'none')
    })
    const SwiperBulletList = [...document.getElementsByClassName('swiper-pagination-bullet')];
    SwiperBulletList.forEach(node => {
        node.setAttribute('role', 'button');
        node.setAttribute('aria-label', 'pagination button');
        node.setAttribute('tabindex', '0');
    }); 
    const jss37List = [...document.getElementsByClassName('jss37')];
    jss37List.forEach(node => {
      node.setAttribute('role', 'button');
      node.setAttribute('tabindex', '0');
    });  
    const jss42List = [...document.getElementsByClassName('jss42')];
    jss42List.forEach(node => {
      node.setAttribute('role', 'none');
    })
  
  }

  componentDidMount() {
    document.addEventListener("DOMContentLoaded", this.addArialabel);
  }

  componentWillUnmount() {
    window.removeEventListener("DOMContentLoaded", this.addArialabel);
  }

  render() {
    const { classes } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter onUpdate={() => {
          window.scrollTo(0, 0);
        }}>
          <div id='top' className={classes.mainWrapper}>
            <AppNav
              active={this.state.activeMenuIndex}
              menuClicked={this.onMenuClicked}
            />
            <ScrollToTop updatePath={this.updatePath}>
              <BodyWrapper menuClicked={this.onMenuClicked} className={classes.main}/>
            </ScrollToTop>
            <Footer menuClicked={this.onMenuClicked}/>
          </div>

        </BrowserRouter>
      </ ThemeProvider>

    );
  }
}

export default withStyles(styles, { withTheme: true })(App);
