import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Color } from '../assets/color';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';

import ico_fold from '../assets/icons/ico_fold_blue.svg';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        border: '1.5px solid #E8E8E8',
        [theme.breakpoints.up('mob')]: {
            margin: 16,
            width: 'calc(100% - 35px)',
        },
        [theme.breakpoints.up('tableth')]: {
            margin: 20,
            width: 'calc(50% - 43px)',
        },
    },
    card:{
        [theme.breakpoints.up('mob')]: {
            padding: '32px 24px',
        },
        [theme.breakpoints.up('tableth')]: {
            padding: 48,
        },
        position: 'relative',
       
    },
    cardTitle: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            paddingBottom: 24
        },
        [theme.breakpoints.up('tableth')]: {
            minWidth: '318px',
            width:'unset'
        },
    },
    inclusionList: {
        listStyleType: 'square',
        margin: 0,
        // [theme.breakpoints.up('mob')]: {
        //     padding: '32px 24px',
        // },
        // [theme.breakpoints.up('tableth')]: {
        //     padding: 48,
        // },
        paddingInlineStart: 22
    },
    inclusion: {
        color: Color.navy,
        fontWeight: 400,
        fontSize: 20,
        letterSpacing: '0.04em',
        lineHeight: '32px',
        '&::marker': {
            color: Color.intelligentBlue
        },
    },
    accordion: {
        boxShadow: 'none',
        '&::before': {
            backgroundColor: Color.white
        },
        '& .MuiAccordionSummary-root': {
            justifyContent: 'flex-start'
        },
        '& .MuiAccordionSummary-content': {
            margin: 0,
            flexGrow: 0
        },
    },
    accordionList: {
        paddingInlineStart: 25.5
    },
    accordionDetail: {
        padding: 0
    },
    expandIcon: {
        transform: "rotate(0deg) translateY(2px) translateX(2px)",
        "&$expanded": {
          transform: "rotate(90deg) translateY(-2px) translateX(2px)"
        }
      },
    expanded: {},
    accordionTitle: {
        color: Color.intelligentBlue,
        fontFamily: 'sofia-pro',
        fontWeight: 700,
        fontSize: 16,
        lineHeight: '24px',
        letterSpacing: '0.02rem'
    },
    inclusionListExpand: {
        listStyleType: 'square',
        margin: 0,
        paddingTop: '4px',
        paddingInlineStart: 22
    },
    inclusionExpand: {
        color: Color.navy,
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '0.02em',
        lineHeight: '24px',
        '&::marker': {
            color: Color.intelligentBlue
        },
    },
})

class VerificatioonProcess extends React.Component {

    constructor() {
        super()
    }

    render() {
        const { classes, title, para, wid} = this.props;

        return(
            <div className={classes.root}>
                 <div className={classes.card}>
                     <div style={{position:'absolute', left: -1, top: -1, width: wid, backgroundColor: Color.intelligentBlue, height: 8}}></div>
                     <div style={{display:'flex', alignItems:'center', flexWrap: 'wrap' }}>
                        <h2 className={`${classes.cardTitle}`}>{title}</h2>
                     </div>
                    <p className={`normal`}>{para}</p>
                    <ul className={classes.inclusionList} style={{paddingBottom: 12}}>
                        <li className={classes.inclusion}>Anyone who fills out this page for you will be our authorized representative, so please write Authorized Representative under Title of Employer. </li>
                        <li className={classes.inclusion}>Employer's name and address will be IPG’s name and address: Intellipro Group Inc., 3120 Scott Blvd, Ste 301, Santa Clara, CA 95054. </li>
                    </ul>
                    <Accordion classes={{root: classes.accordion}}>
                        <AccordionSummary style={{padding: 0}}
                            expandIcon={<img src={ico_fold} alt="icons" style={{width:12}} />}
                            classes={{
                                expandIcon: classes.expandIcon,
                                expanded: classes.expanded
                              }}
                        >
                            <p className={classes.accordionTitle}>U.S. citizen hires with U.S. passport</p>
                        </AccordionSummary>
                        <AccordionDetails classes={{root: classes.accordionDetail}}>
                            <div>
                                <p className={`cap`} style={{color:Color.navy}}>please fill out List A, page 2 with:</p>
                                <ul className={classes.inclusionListExpand}>
                                    <li className={classes.inclusionExpand}>Your U.S. passport, including passport number, passport expiration date, issuing authority (the issuing authority will be listed on the lower right of the photo page in the passport. (Ex: U.S. Department of State or National Passport Center or State Passport Agency)).</li>
                                </ul>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion classes={{root: classes.accordion}}>
                        <AccordionSummary style={{padding: 0}}
                            expandIcon={<img src={ico_fold} alt="icons" style={{width:12}} />}
                            classes={{
                                expandIcon: classes.expandIcon,
                                expanded: classes.expanded
                              }}
                        >
                            <p className={classes.accordionTitle}>U.S. citizen hires with SSN & Driver’s license</p>
                        </AccordionSummary>
                        <AccordionDetails classes={{root: classes.accordionDetail}}>
                            <div>
                                <p className={`cap`} style={{color:Color.navy}}>please fill out List A, page 2 with:</p>
                                <ul className={classes.inclusionListExpand}>
                                    <li className={classes.inclusionExpand}>Your driver’s license, including driver’s license number, expiration date, issuing authority (state from which the Driver’s License was issued (Ex: State of Florida, State of California, State of Pennsylvania, etc.))</li>
                                    <li className={classes.inclusionExpand}>Your social security card, including social security number, issuing authority (Listed on the Seal or ring in the middle of the Social Security Card (Ex: Social Security Administration or Department of Health and Human Services)).</li>
                                </ul>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion classes={{root: classes.accordion}}>
                        <AccordionSummary style={{padding: 0}}
                            expandIcon={<img src={ico_fold} alt="icons" style={{width:12}} />}
                            classes={{
                                expandIcon: classes.expandIcon,
                                expanded: classes.expanded
                              }}
                        >
                            <p className={classes.accordionTitle}>Permanent resident hires</p>
                        </AccordionSummary>
                        <AccordionDetails classes={{root: classes.accordionDetail}}>
                            <div>
                                <p className={`cap`} style={{color:Color.navy}}>please fill out List A, page 2 with:</p>
                                <ul className={classes.inclusionListExpand}>
                                    <li className={classes.inclusionExpand}>Your permanent resident card/green card, including your document number (number in the back start with three letters), expiration date, issuing authority (USCIS (United States Citizenship and Immigration Services)).</li>
                                </ul>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion classes={{root: classes.accordion}}>
                        <AccordionSummary style={{padding: 0}}
                            expandIcon={<img src={ico_fold} alt="icons" style={{width:12}} />}
                            classes={{
                                expandIcon: classes.expandIcon,
                                expanded: classes.expanded
                              }}
                        >
                            <p className={classes.accordionTitle}>F1 hires working with CPT</p>
                        </AccordionSummary>
                        <AccordionDetails classes={{root: classes.accordionDetail}}>
                            <div>
                                <p className={`cap`} style={{color:Color.navy}}>please fill out List A, page 2 with:</p>
                                <ul className={classes.inclusionListExpand}>
                                    <li className={classes.inclusionExpand}>Your foreign passport, including the passport number, expiration date, issuing authority (The Country from which the foreign passport was issued (Ex: China, France, Spain, etc.)).</li>
                                    <li className={classes.inclusionExpand}>Your I-94, including the I-94 number of 11 digits, issuing authority (U.S. Customs and Border Protection).</li>
                                    <li className={classes.inclusionExpand}>Your I-20, including the 11 digit number starting with N on the I-20, expiration date of work authorization on I-20, issuing authority (USCIS).</li>
                                </ul>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion classes={{root: classes.accordion}}>
                        <AccordionSummary style={{padding: 0}}
                            expandIcon={<img src={ico_fold} alt="icons" style={{width:12}} />}
                            classes={{
                                expandIcon: classes.expandIcon,
                                expanded: classes.expanded
                              }}
                        >
                            <p className={classes.accordionTitle}>F1 hires working with OPT, or J2s hires</p>
                        </AccordionSummary>
                        <AccordionDetails classes={{root: classes.accordionDetail}}>
                            <div>
                                <p className={`cap`} style={{color:Color.navy}}>please fill out List A, page 2 with:</p>
                                <ul className={classes.inclusionListExpand}>
                                    <li className={classes.inclusionExpand}>Your EAD card, including the EAD number (number in the back that begins with three letters), expiration date and issuing authority (DHS/USCIS).</li>
                                </ul>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion classes={{root: classes.accordion}}>
                        <AccordionSummary style={{padding: 0}}
                            expandIcon={<img src={ico_fold} alt="icons" style={{width:12}} />}
                            classes={{
                                expandIcon: classes.expandIcon,
                                expanded: classes.expanded
                              }}
                        >
                            <p className={classes.accordionTitle}>J1 hires</p>
                        </AccordionSummary>
                        <AccordionDetails classes={{root: classes.accordionDetail}}>
                            <div>
                                <p className={`cap`} style={{color:Color.navy}}>please fill out List A, page 2 with:</p>
                                <ul className={classes.inclusionListExpand}>
                                    <li className={classes.inclusionExpand}>Your foreign passport, including the passport number, expiration date, issuing authority (The Country from which the foreign passport was issued (Ex: China, France, Spain, etc.)).</li>
                                    <li className={classes.inclusionExpand}>Your I-94, including the I-94 number of 11 digits, issuing authority (U.S. Customs and Border Protection).</li>
                                    <li className={classes.inclusionExpand}>Your DS-2019, including the 11 digit number starting with N on the DS-2019, expiration date of work authorization on DS-2019, issuing authority (CICD).</li>
                                </ul>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(withRouter(VerificatioonProcess));