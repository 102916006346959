import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";

import {  withStyles } from '@material-ui/core/styles';
import { Color } from '../../assets/color';
import SwiperCore, { Autoplay, Pagination } from 'swiper/core';

import 'swiper/swiper.min.css';
import "swiper/components/pagination/pagination.min.css";
import './style.css'

SwiperCore.use([Autoplay, Pagination]);

const style = theme => ({
    // card:{
        
    //     [theme.breakpoints.up('mob')]: {
    //         width: '33%',
    //         minWidth: 364,
    //         height: 964,
    //     },
    //     [theme.breakpoints.up('tabletv')]: {
    //         width: '33%',
    //         minWidth: 432,
    //         height: 884,

    //     },
    //     [theme.breakpoints.up('tableth')]: {
    //         width: '33%',
    //         minWidth: 432,
    //         height: 884,

    //     },
    //     [theme.breakpoints.up('desktop')]: {
    //         width:'33%',
    //         height: 924,
    //         minWidth: 413
    //     },
    //     [theme.breakpoints.up('largedesktop')]: {
    //         width: 256,
    //         height: 160,
    //     }
    // },
    img:{
        border: '1.5px solid #E8E8E8',
        [theme.breakpoints.up('mob')]: {
            width: '85%',
            padding: '5%',
            margin: '10px 0px'
        },
        [theme.breakpoints.up('largedesktop')]: {
            width: '85%',
            padding: '5%',
            margin: '10px 0px'
        }
    }

})


class Sliders extends React.Component {
    constructor() {
        super()
        this.state = { 
            largedesktop: false,
            desktop: false,
            tableth: false,
            tabletv: false,
            mob: false 
        };
    }
    componentDidMount() {
        
        this.getDimensions(); 
        
        //add dimensions listener for window resizing
        window.addEventListener('resize', this.getDimensions); 
    }
      
    //remove listener on page exit
    componentWillUnmount() {
        window.removeEventListener('resize', this.getDimensions); 
    }
    
    //actually set the state to the window dimensions
    getDimensions = () => {
        const query = ['(min-width: 1441px)', '(min-width: 1025px)', '(min-width: 769px)', '(min-width: 415px)', '(min-width: 0px)']
        const mql = query.map(q => window.matchMedia(q).matches) ;
        this.setState({ 
            largedesktop: mql[0],
            desktop: mql[1],
            tableth: mql[2],
            tabletv: mql[3],
            mob: mql[4] 
        }, () => {console.log(this.state)});
    }

    

    render() {
        const{ classes, pics } = this.props;

        return(
            <>
            <Swiper 
                slidesPerView={
                    this.state.largedesktop ? 5 : 
                    (this.state.tableth ? 4 : (
                        this.state.tabletv ? 3 : 2
                    ))
                } 
                slidesPerColumn={this.state.tabletv ? 2 : 4} 
                spaceBetween={
                    this.state.largedesktop ? 20 : 
                    (this.state.tableth ? 16 : (
                        this.state.tabletv ? 12: 8
                    ))
                }
                slidesPerGroup={
                    this.state.largedesktop ? 5 : 
                    (this.state.tableth ? 4 : (
                        this.state.tabletv ? 3 : 2
                    ))
                }
                slidesPerColumnFill="row"
                pagination={{
                    clickable: true
                }}
                autoplay={{
                    "delay": 2500,
                    "disableOnInteraction": false
                }}
                className="mySwiper"
            >
                <SwiperSlide>
                    <img src={pics[0]} alt="company" className={classes.img}/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={pics[1]} alt="company" className={classes.img}/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={pics[2]} alt="company" className={classes.img}/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={pics[3]} alt="company" className={classes.img}/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={pics[4]} alt="company" className={classes.img}/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={pics[5]} alt="company" className={classes.img}/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={pics[6]} alt="company" className={classes.img}/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={pics[7]} alt="company" className={classes.img}/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={pics[8]} alt="company" className={classes.img}/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={pics[9]} alt="company" className={classes.img}/>
                </SwiperSlide>

                <SwiperSlide>
                    <img src={pics[10]} alt="company" className={classes.img}/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={pics[11]} alt="company" className={classes.img}/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={pics[12]} alt="company" className={classes.img}/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={pics[13]} alt="company" className={classes.img}/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={pics[14]} alt="company" className={classes.img}/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={pics[15]} alt="company" className={classes.img}/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={pics[16]} alt="company" className={classes.img}/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={pics[17]} alt="company" className={classes.img}/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={pics[18]} alt="company" className={classes.img}/>
                </SwiperSlide>

            </Swiper>
            </>

        )
    }

}

export default withStyles(style)(Sliders);