import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { Color } from '../assets/color';
import LinkButton from '../components/linkBtn';
import ico_right_b from '../assets/icons/ico_download.svg';




const style = theme => ({
    outer:{
        [theme.breakpoints.up('mob')]: {
            width:'100%',
        },
        [theme.breakpoints.up('tabletv')]: {
            width: '50%',
            height: 229,

        },
        [theme.breakpoints.up('tableth')]: {
            width: '50%',
            height: 262,
        },
        [theme.breakpoints.up('desktop')]: {
            width: '33%',
            height: 262,
        },
        [theme.breakpoints.up('largedesktop')]: {
            height: 312,
        }
    },
    root: {
        // backgroundColor: Color.lightBlue,
        boxShadow: 'none',
        borderRadius: 0,
        border: '1.5px solid #E8E8E8',
        [theme.breakpoints.up('mob')]: {
            margin: '12px 0px',
        },
        [theme.breakpoints.up('tabletv')]: {
            margin: 12,
            height:'calc(100% - 24px)',
        },
        [theme.breakpoints.up('tableth')]: {
            margin: 16,
            height:'calc(100% - 32px)',
        },
        [theme.breakpoints.up('desktop')]: {
            margin: 20,
            height:'calc(100% - 40px)',
        }
    },
    cardContent:{
        display:'flex',
        height: '70%',
        justifyContent:'space-between',
        [theme.breakpoints.up('mob')]: {
            padding: '32px 16px',
            flexDirection: 'column'
        },

        [theme.breakpoints.up('tabletv')]: {
            padding: 32,
            flexDirection: 'column',
        }
    },
    icon: {
        paddingBottom: 32,
        [theme.breakpoints.up('mob')]: {
            width: 64,
            paddingBottom: 0,
            paddingRight: '1.5%'
        },
        [theme.breakpoints.up('tabletv')]: {
            width: 80,
            paddingBottom: 8
        },
    },
    blogCardBottom: {
        display: 'flex',
        // justifyContent: 'flex-start',
        justifyContent: 'flex-start', 
        alignItems: 'flex-end', 
    
        // [theme.breakpoints.up('mob')]: {
        //     padding: '32px 24px',
        //     flexDirection: 'column',
        // },
        // [theme.breakpoints.up('tabletv')]: {
        //     padding: '32px 24px 16px',
        //     flexDirection: 'column',
        // },
        // [theme.breakpoints.up('desktop')]: {
        //     flexDirection: 'row',
        // },
    },
})

const DownloadCard = props => {
    const { classes, icons, title, content } = props;

    return(
        <div className={classes.outer}>
            <Card classes={{root: classes.root}}>
                <div className={classes.cardContent}>
                <h2 className={classes.cardTitle}>{title}</h2>
                <p className={`normal`}>{content}</p>
                <div className={classes.blogCardBottom}>
                        <LinkButton style={{display: 'flex', justifyContent: 'flex-start'}} >
                            <img src={ico_right_b} alt="icon" style={{height: 24, paddingRight: 10, paddingTop: 4}}></img>
                            <p className={classes.blogLinkText}>Download The File</p> 
                        </LinkButton>
                    </div>
                </div>
                
            </Card>
        </div>
        
    )
}

export default withStyles(style)(DownloadCard);