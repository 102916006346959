import React from 'react';
import { createMuiTheme, withStyles, ThemeProvider } from '@material-ui/core';
import { Color } from '../../assets/color';



const style = theme => ({
    header: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: Color.intelligentBlue,
        [theme.breakpoints.up('tabletv')]: {
            height: 288,
            padding: '0px 32px'
        },
        [theme.breakpoints.up('tableth')]: {
            height: 384,
            padding: '0px 32px'
        },
        [theme.breakpoints.up('desktop')]: {
            height: 480,
            padding: '0px 120px'
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '0px 240px',
        },
    },
    main: {
        [theme.breakpoints.up('mob')]: {
            padding: '0px 24px',
            marginTop: 122,
        },
        [theme.breakpoints.up('tabletv')]: {
            padding: '0px 32px'
        },
        [theme.breakpoints.up('jobDetail')]: {
            marginTop: 0,
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '0px 32px'
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '0px 120px'
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '0px 240px',
        },
    },
    block: {
        display:'flex', 
        flexDirection:'row', 
        justifyContent:'space-between', 
    },
    cardBlock: {
        display:'flex',
        flexDirection:'column',
        [theme.breakpoints.up('mob')]: {
            padding: '80px 0px',
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '120px 0px',
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '200px 0px',
        },
    },
    para: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
        },
        [theme.breakpoints.up('desktop')]: {
            maxWidth: 1024
        },
    },
    subTitle: {
        [theme.breakpoints.up('mob')]: {
           paddingTop: 40,
           paddingBottom: 32
        },
        [theme.breakpoints.up('tableth')]: {
            paddingTop: 60
        },
        [theme.breakpoints.up('desktop')]: {
            paddingTop: 120,
            paddingBottom: 48
        },
    },
    subPara: {
        [theme.breakpoints.up('mob')]: {
            paddingBottom: 32
         },
         [theme.breakpoints.up('desktop')]: {
             paddingBottom: 48
         },
    },
    inclusionList: {
        listStyleType: 'square',
        margin: 0
    },
    inclusion: {
        color: Color.navy,
        fontWeight: 400,
        fontSize: 20,
        letterSpacing: '0.04em',
        lineHeight: '44px',
        '&::marker': {
            color: Color.intelligentBlue
        },
    },
    link: {
        color: Color.navy,
        fontWeight: 400,
        fontSize: 20,
        letterSpacing: '0.04em',
        lineHeight: '44px',
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
            color: Color.intelligentBlue,
        }
    }
})

class Notice extends React.Component {

    constructor() {
        super()
    }

    render() {

        const{classes} = this.props;

        return(
            <div>
                <div className={classes.header}>
                        <div className={`h1`} style={{color: Color.white}} role="heading" aria-level="1">
                            Government Notice
                        </div>
                </div>
                <div className={classes.main}>
                    <div className={`${classes.cardBlock} ${classes.block}`}>
                        <div className={classes.para}>
                            <h2 className={classes.subPara}>
                                Notices of filing the LCA
                            </h2>
                            <ul className={classes.inclusionList}>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/11/Notice of filing the LCA(Santa Clara, CA)(Nov 19,2018-Nov 18,2021).docx"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">Notice of filing the LCA(Santa Clara, CA)(Nov 19th, 2018-Nov 18th,
                                        2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/09/Notice of filing the LCA @ HQ.docx"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">Notice of filing the LCA(Sunnyvale, CA)(Oct 26th, 2018
                                        - Oct 25th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/09/LCA Draft_Du, Peng.pdf" target="_blank" rel="noopener noreferrer" aria-label="reference link">DP_Notice
                                        of filing the LCA(Santa Clara, CA)(Oct 17th, 2018
                                        - Oct 16th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/07/Notice of filing the LCA_Wang, Qingyu.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">WQY_Notice of filing the LCA(Santa Clara, CA)(Sep 15th, 2018
                                        - Sep 15th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/07/Notice of Filing LCA_Dong, Jie.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">DJ_Notice of filing the LCA(Santa Clara, CA)(Sep 4th, 2018
                                        - Sep 3rd, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/07/Notice of Filing the LCA @ Co Hq_Zhou, Jie.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">ZJ_Notice of filing the LCA(Santa Clara, CA)(Aug 27th, 2018
                                        - Aug 26th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/07/Notice of Filing the LCA  Nian Ji.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">NJ_Notice of filing the LCA(Santa Clara, CA)(Oct 11st, 2018
                                        - Oct 10th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/07/Notice of Filing LCA @ Co HQ_Huang, Xiaotong.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">HXT_Notice of filing the LCA(Santa Clara, CA)(Aug 6th, 2018
                                        - Aug 5th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/07/Notice of filing the LCA @ HQ Pavan Kumar.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">PK_Notice of filing the LCA(Santa Clara, CA)(Oct 1st, 2018
                                        - Sep 30th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/07/Notice of filing the LCA @ HQ_Nian Ji.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">NJ_Notice of filing the LCA(Santa Clara, CA, CA)(Oct 11st, 2018
                                        - Oct 10th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/07/Notice of filing the LCA @ HQ_Qi Dai.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">QD_Notice of filing the LCA(Santa Clara, CA)(Sep 15th, 2018
                                        - Sep 14th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/07/Notice of filing the LCA @ HQ_PK.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">PK_Notice of filing the LCA(Santa Clara, CA)(Oct 1st, 2018
                                        - Sep 30th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/07/Notice of filing the LCA @ HQ_Jie Chen.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">JC_Notice of filing the LCA(Santa Clara, CA)(Sep 24th, 2018
                                        - Sep 23rd, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/07/Notice of filing the LCA @ Client worksite_Jer Hau Lin.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">JHL_Notice of filing the LCA(Santa Clara, CA)(Jul 12nd, 2018
                                        - Jul 11st, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/06/Notice of filing the LCA_ZY.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">ZY_Notice of filing the LCA(Santa Clara, CA)(Jul 6th, 2018
                                        - Jul 5th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/06/Notice of filing the LCA_AZ.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">AZ_Notice of filing the LCA(Santa Clara, CA)(Nov 20th, 2018
                                        - Nov 19th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/05/Notice of filing the LCA_HQ.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">HQ_Notice of filing the LCA(Mountain View, CA)(Jun 18th, 2018
                                        - Jun 17th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/05/Notice of filing the LCA_QM.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">QM_Notice of filing the LCA(Mountain View, CA)(May 28th, 2018
                                        - May 27th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/03/Notice of filing the LCA_JLW.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">JLW_Notice of filing the LCA(Gilbert, AZ)(Oct 1st, 2018
                                        - Sep 30th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/03/Notice of filing the LCA_WXC.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">WXC_Notice of filing the LCA(Santa Clara, CA)(Oct 1st, 2018
                                        - Sep 30th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/03/Notice of filing the LCA_WYC.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">WYC_Notice of filing the LCA(Gilbert, AZ)(Oct 1st, 2018
                                        - Sep 30th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/03/Notice of filing the LCA_HB_2.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">HB_Notice of filing the LCA(Santa Clara, CA)(Oct 1st, 2018
                                        - Sep 30th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/03/Notice of filing the LCA_HZ.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">HZ_Notice of filing the LCA(Santa Clara, CA)(Oct 1st, 2018
                                        - Sep 30th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/03/Notice of filing the LCA_YZ.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">YZ_Notice of filing the LCA(Sunnyvale, CA)(Oct 1st, 2018
                                        - Sep 30th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/03/Notice of filing the LCA_HB.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">HB_Notice of filing the LCA(Santa Clara, CA)(Oct 1st, 2018
                                        - Sep 30th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/03/Notice of filing the LCA_DZ.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">DZ_Notice of filing the LCA(Sunnyvale, CA)(Oct 1st, 2018
                                        - Sep 30th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/03/Notice of filing the LCA_YD.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">YD_Notice of filing the LCA(Mountain View, CA)(Oct 1st, 2018
                                        - Sep 30th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/03/Notice of filing the LCA_DW.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">DW_Notice of filing the LCA(Sunnyvale, CA)(Oct 1st, 2018
                                        - Sep 30th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/02/Notice of filing the LCA_AKR.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">AKR_Notice of filing the LCA(Mountain View, CA)(Oct 1st, 2018
                                        - Sep 30th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/02/Notice of filing the LCA_JW.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">JW_Notice of filing the LCA(Bellevue, WA)(Oct 1st, 2018 - Sep
                                        30th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/02/Notice of filing the LCA_WC.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">WC_Notice of filing the LCA(Santa Clara, CA)(Oct 1st, 2018 -
                                        Sep 30th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/02/Notice of filing the LCA_XL.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">XL_Notice of filing the LCA(Sunnyvale, CA)(Oct 1st, 2018 -
                                        Sep 30th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/02/Notice of filing the LCA_XW.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">XW_Notice of filing the LCA(Santa Clara, CA)(Oct 1st, 2018 -
                                        Sep 30th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/02/Notice of filing the LCA_WM.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">WM_Notice of filing the LCA(Santa Clara, CA)(Oct 1st, 2018 -
                                        Sep 30th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/02/Notice of filing the LCA_YB.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">YB_Notice of filing the LCA(Bellevue, WA)(Oct 1st, 2018 - Sep
                                        30th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/02/Notice of filing the LCA_XJ.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">XJ_Notice of filing the LCA(Santa Clara, CA)(Oct 1st, 2018 -
                                        Sep 30th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/01/Notice of filing the LCA_WG.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">WG_Notice of filing the LCA(Sunnyvale, CA)(Mar 12nd, 2018 -
                                        Mar 11st, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2018/01/Notice of filing the LCA_QM.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">QM_Notice of filing the LCA(Santa Clara, CA)(Jan 24th, 2018 -
                                        Jan 23rd, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2017/11/Notice of filing the LCA_JR.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">JR_Notice of filing the LCA(Santa Clara, CA)(Jan 15th, 2018 -
                                        Jan 14th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2017/11/Notice of filing the LCA_BG.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">BG_Notice of filing the LCA(Santa Clara, CA)(Jan 8th, 2018 -
                                        Jan 7th, 2021)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2017/11/Notice of filing the LCA_QM.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">QM_Notice of filing the LCA(Santa Clara, CA)(Dec 12nd, 2017 -
                                        Dec 11st, 2020)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2017/11/Notice of filing the LCA_SM.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">SM_Notice of filing the LCA(Santa Clara, CA)(Dec 1st, 2017 -
                                        Nov 30th, 2020)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2017/11/Notice of filing the LCA_HZ.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">HZ_Notice of filing the LCA(Santa Clara, CA)(Nov 20th, 2017 -
                                        Nov 19th, 2020)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2017/10/Notice of filing the LCA_YF.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">YF_Notice of filing the LCA(Santa Clara, CA)(Nov 20th, 2017 -
                                        Aug 21st, 2020)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2017/10/Notice of filing the LCA_DW.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">DW_Notice of filing the LCA(Santa Clara, CA)(Oct 30th, 2017 -
                                        Oct 29th, 2020)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2017/09/Notice of filing the LCA_RL.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">RL_Notice of filing the LCA(Santa Clara, CA)(Sep 1st, 2017 -
                                        Aug 31th, 2020)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2017/06/Notice of filing the LCA_WK.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">WK_Notice of filing the LCA(Santa Clara, CA)(Oct 1st, 2017 -
                                        Sep 30th, 2020)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2017/06/Notice of filing the LCA_SK.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">SK_Notice of filing the LCA(Santa Clara, CA)(Oct 1st, 2017 -
                                        Sep 30th, 2020)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2017/06/Notice of filing the LCA_JC_2.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">JC_Notice of filing the LCA(Santa Clara, CA)(Oct 1st, 2017 -
                                        Sep 30th, 2020)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2017/06/Notice of filing the LCA_MQ.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">MQ_Notice of filing the LCA(Santa Clara, CA)(Oct 1st, 2017 -
                                        Sep 30th, 2020)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2017/06/Notice of filing the LCA_TX.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">TX_Notice of filing the LCA(Santa Clara, CA)(Oct 1st, 2017 -
                                        Sep 30th, 2020)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2017/06/Notice of filing the LCA_ZY.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">ZY_Notice of filing the LCA(Santa Clara, CA)(Oct 1st, 2017 -
                                        Sep 30th, 2020)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2017/06/Notice of filing the LCA_GW.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">GW_Notice of filing the LCA(Santa Clara, CA)(Oct 1st, 2017 -
                                        Sep 30th, 2020)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2017/06/Notice of filing the LCA_JW.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">JW_Notice of filing the LCA(Santa Clara, CA)(Oct 1st, 2017 -
                                        Sep 30th, 2020)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2017/06/Notice of filing the LCA_JC.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">JC_Notice of filing the LCA(Santa Clara, CA)(Jun 26th, 2017 -
                                        Jun 25th, 2020)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2017/05/Notice of filing the LCA_HY.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">HY_Notice of filing the LCA(Santa Clara, CA)(Jun 1st, 2017 -
                                        May 30th, 2020)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2017/05/Notice of filing the LCA_WG.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">WG_Notice of filing the LCA(Santa Clara, CA)(May 20th, 2017 -
                                        May 19th, 2020)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2017/05/Notice of filing the LCA_JG.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">JG_Notice of filing the LCA(Santa Clara, CA)(May 20th, 2017 -
                                        May 19th, 2020)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2017/05/Notice of filing the LCA_JZ.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">JZ_Notice of filing the LCA(Santa Clara, CA)(May 20th, 2017 -
                                        May 19th, 2020)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2017/04/Notice of filing the LCA(Alameda,CA).pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">YF_Notice of filing the LCA(Alameda,CA)(Jun 1st, 2017 - Jun
                                        1st, 2020)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2017/01/Notice of Filing the LCA_JL.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">JL_Notice of filing the LCA(Santa Clara, CA)(Jan 30th, 2017 -
                                        Jan 29th, 2020)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2016/12/Notice of Filing the LCA_CS.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">CS_Notice of filing the LCA(Santa Clara, CA)(Dec 28th, 2016 -
                                        Dec 27th, 2019)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2016/08/Notice of Filing the LCA_Du, Peng.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">DP_Notice of filing the LCA(Bellevue, WA)(Oct 17th, 2016 -
                                        Oct 16th, 2019)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2016/08/Notice of Filing the LCA_Hua Zhang.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">HZ_Notice of filing the LCA(Santa Clara, CA)(Oct 10th, 2016 -
                                        Oct 9th, 2019)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2016/08/Notice of Filing the LCA_GM.pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">GM_Notice of filing the LCA(Santa Clara, CA)(Sep 26th, 2016 -
                                        Sep 25th, 2019)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2016/06/FJ_Notice of Filing the LCA(Santa Clara, CA).pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">FJ_Notice of Filing the LCA(Santa Clara, CA)(Oct 24th, 2016 -
                                        Oct 23rd, 2019)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2016/06/PP_Notice of Filing the LCA(Plano, TX).pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">PP_Notice of Filing the LCA(Plano, TX)(Oct 2nd, 2016 - Oct
                                        1st, 2019)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2016/06/XC_Notice of Filing the LCA(Santa Clara, CA).pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">XC_Notice of Filing the LCA(Santa Clara, CA)(Sep 8th, 2016 -
                                        Sep 7th, 2019)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2016/06/BQ_Notice of Filing the LCA(San Mateo, CA).pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">BQ_Notice of Filing the LCA(San Mateo, CA)(Jul 25th, 2016 -
                                        Jul 24th, 2019)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2016/06/SW_Notice of Filing the LCA(Washington, DC).pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">SW_Notice of Filing the LCA(Washington, DC)(Jul 18th, 2016 -
                                        Jul 17th, 2019)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2016/05/PS_Notice of Filing the LCA(Mountain View, CA).pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">PS_Notice of Filing the LCA(Mountain View, CA)(May 31st, 2016
                                        - May 30th, 2019)</a></li>
                                <li className={classes.inclusion}>
                                    <a className={classes.link}
                                    href="https://intelliprogroup.com/uploads/2016/05/ZQ_Notice of filing the LCA(San Francisco, CA).pdf"
                                    target="_blank" rel="noopener noreferrer" aria-label="reference link">ZQ_Notice of filing the LCA(San Francisco, CA)(May 23rd, 2016
                                        - May 22nd, 2019)</a>
                                </li>
                                </ul>
                        </div>  
                       
                    </div>
                </div>
            </div>
        )

    }

}

export default withStyles(style)(Notice)