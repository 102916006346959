import React from 'react';
import {  withStyles } from '@material-ui/core/styles';
import { Color } from '../../assets/color';
import H1XL from '../../assets/home/H1XL.jpeg';
import H1L from '../../assets/home/H1L.jpeg';
import H1M from '../../assets/home/H1M.jpeg';
import H1S from '../../assets/home/H1S.jpeg';
import H1XS from '../../assets/home/H1XS.jpeg';

const headerStyle = theme => ({
    header:{
        width: '100%',
        [theme.breakpoints.up('mob')]: {
            height: 288,
            background: `url("${H1XS}") no-repeat`,
            backgroundSize: 'cover',
            display: 'none'
            
        },
        [theme.breakpoints.up('tabletv')]: {
            height: 640,
            background: `url("${H1S}") no-repeat`,
            backgroundSize: 'cover',
            display: 'flex'
        },
        [theme.breakpoints.up('tableth')]: {
            height: 640,
            background: `url("${H1M}") no-repeat `,
            backgroundSize: 'cover',
            
        },
        [theme.breakpoints.up('desktop')]: {
            height: 800,
            background: `url("${H1L}") no-repeat `,
            backgroundSize: 'cover',
            
        },
        [theme.breakpoints.up('largedesktop')]: {
            height: 800,
            background: `url("${H1XL}") no-repeat `,
            backgroundSize: 'cover',
            
        }
    },
    block: {
        background: 'white',
        position: 'absolute',
        display:'flex',
        justifyContent:'center',
        alignItems: 'center',
        [theme.breakpoints.up('mob')]: {
            display: 'none'
        },
        [theme.breakpoints.up('tabletv')]: {
           height: 480,
           width: 360,
           margin: '80px 32px 0px',
           display: 'flex'
        },
        [theme.breakpoints.up('tableth')]: {
           height: 480,
           width: 400
        },
        [theme.breakpoints.up('desktop')]: {
           height: 640,
           width: 640,
           margin: '80px 120px 0px' 
        },
        [theme.breakpoints.up('largedesktop')]: {
            height: 640,
            width: 720,
            margin: '80px 240px 0px' 
         },
    },
    title:{
        [theme.breakpoints.up('tabletv')]: {
            width: 320,
        },
        [theme.breakpoints.up('desktop')]: {
            width: 440,
        },
    }
})

const HeaderSlide = props => {
    const { classes } = props;

    return(
        <div className={classes.header}>
            <div className={classes.block}>
                <title className={classes.title}>Your <br/> Ultimate Source for <br/>Talent <br/>Solutions</title>
            </div>
        </div>
    )
}

export default withStyles(headerStyle)(HeaderSlide);