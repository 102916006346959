import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Color } from '../assets/color';

import ico_right from '../assets/icons/ico_arrow_right_b.svg';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        border: '1.5px solid #E8E8E8',
        justifyContent: 'flex-start',
        width: 'calc(100% - 51px)',
        margin: '0px 24px'
    },
    card:{
        [theme.breakpoints.up('mob')]: {
            padding: 24,
        },
        [theme.breakpoints.up('tableth')]: {
            padding: 32,
        },
    },
    iconImg: {
        width: 80, 
        height: 80,
        [theme.breakpoints.up('mob')]: {
            paddingRight: 24,
            paddingBottom: 24
        },
        [theme.breakpoints.up('tableth')]: {
            paddingRight: 32,
            paddingBottom: 32
        },
    },
    cardTitle: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            paddingBottom: 24
        },
        [theme.breakpoints.up('tableth')]: {
            minWidth: '318px',
            width:'unset'
        },
    },
    hrOperation: {
        alignSelf: 'baseline',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'center',
        [theme.breakpoints.up('mob')]: {
            width: '100%',
        },
        [theme.breakpoints.up('tableth')]: {
            width: '48%',
        },
    },
    resourceImg: {
        width:'80%',
        backgroundColor: Color.lightBlue,
        margin: '24px 0px',
        [theme.breakpoints.up('mob')]: {
            padding: 24
        },
        [theme.breakpoints.up('tableth')]: {
            padding: 32
        },
    },
    inclusionList: {
        listStyleType: 'square',
        margin: 0,
        paddingInlineStart: 22
    },
    inclusion: {
        color: Color.navy,
        fontWeight: 400,
        fontSize: 20,
        letterSpacing: '0.04em',
        lineHeight: '32px',
        '&::marker': {
            color: Color.intelligentBlue
        },
    },
    link: {
        display: 'flex',
        paddingInlineStart: 22,
        '&:hover': {
            cursor: 'pointer'
        }
    }
})

class ResourceLargeCard extends React.Component {

    constructor() {
        super()
    }

    render() {
        const { classes, icon1, title1, para1, image1, image2, redirect1, redirect2, linkPara1, linkPara2, link1, link2 } = this.props;

        return(
            <div className={classes.root}>
                 <div className={classes.card}>
                     <div style={{display:'flex', alignItems:'center', flexWrap: 'wrap' }}>
                        <img src={icon1} alt="icon" className={classes.iconImg}></img>
                        <h2 className={`${classes.cardTitle}`}>{title1}</h2>
                     </div>
                    <p className={`normal`}>{para1}</p>
                    <div style={{display: 'flex', flexWrap: 'wrap', justifyContent:'space-between'}}>
                        <div className={classes.hrOperation}>
                            <div className={classes.resourceImg}>
                                <img src={image1} style={{width: '100%'}} alt='img'></img>
                            </div>
                            <ul className={classes.inclusionList}>
                                <li className={classes.inclusion}>{redirect1}</li>
                            </ul>
                            <a className={classes.link} href={link1} target="_blank" title="paragraph link">
                                <img src={ico_right} alt="icons" style={{width:24}}></img>
                                <p className="boldcap" style={{color: Color.intelligentBlue, paddingLeft: 8, lineHeight: '32px'}}>{linkPara1}</p>        
                            </a>
                        </div>
                        <div className={classes.hrOperation}>
                            <div className={classes.resourceImg}>
                                <img src={image2} style={{width: '100%'}} alt='img'></img>
                            </div>
                            <ul className={classes.inclusionList}>
                                <li className={classes.inclusion}>{redirect2}</li>
                            </ul>
                            <a className={classes.link} href={link2} target="_blank" title="paragraph link">
                                <img src={ico_right} alt="icons" style={{width:24}}></img>
                                <p className="boldcap" style={{color: Color.intelligentBlue, paddingLeft: 8, lineHeight: '32px'}}>{linkPara2}</p>        
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(withRouter(ResourceLargeCard));