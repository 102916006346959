import React from 'react';

import { Route } from 'react-router-dom';
import EventList from './event';
import EventDetail from './eventdetail';

class EventRoute extends React.Component {

    render() {

        return(
            <>  
                <Route exact path={'/event'} component={EventList} />
                <Route exact path={'/evnt/:id'} component={EventDetail} />
                <Route exact path={'/event/:id/:slogan'} component={EventDetail} />
            </>
        )
    }
}

export default EventRoute;

                        