
import React from "react";
import styled from "styled-components";
import {Switch, Route, withRouter,Redirect} from "react-router-dom";
import {TransitionGroup, CSSTransition} from "react-transition-group";

import Home from "../pages/home/index";
import Recruiting from '../pages/recruiting/index';
import Service from '../pages/service/index'; 
import AboutUs from "../pages/aboutUs/index";
import ContactUs from '../pages/contact/index';
import JobSeeker from '../pages/jobSeeker/index';
import JobDetail from "../components/jobDetail";
import Join from '../pages/join';
import CareerDetail from "../components/careerDetail";
import Terms from "../pages/terms";
import Notice from "../pages/notice";
import Resource from '../pages/resource/index';
import Verification from "../pages/resource/verification";
import Benefit from "../pages/resource/benefit";
import Blog from '../pages/blog/index';
import Event from '../pages/eventPage/index'
import PDF from '../pages/file/pdf';
import NotFound from '../pages/notfound/index';
import EventNMSDC from '../pages/event/EventNMSDC';
import ProcureconCS from '../pages/event/EventNMSDC2';
import EventFBIA from '../pages/event/EventFBIA';

function appContainer({location, menuClicked}) {
    return (
        <Wrapper>
            <TransitionGroup className="transition-group">
                <CSSTransition
                    key={location.key}
                    timeout={{enter: 300, exit: 300}}
                    classNames="fade"
                >
                    <section className="route-section" >
                        <Switch location={location} >
                            <Route exact path="/" component={() => {
                               return <Home menuClicked={menuClicked}/>
                            }}/>
                            <Route path="/recruiting" component={() => {
                               return <Recruiting menuClicked={menuClicked}/>
                            }}/>
                             <Route path="/service" component={() => {
                               return <Service menuClicked={menuClicked}/>
                            }}/>
                             <Route path="/aboutus" component={() => {
                               return <AboutUs menuClicked={menuClicked}/>
                            }}/>
                              <Route path="/contactus" component={() => {
                               return <ContactUs menuClicked={menuClicked}/>
                            }}/>
                             <Route path="/jobseeker" component={() => {
                               return <JobSeeker menuClicked={menuClicked}/>
                            }}/>
                            <Route path="/jobDetail" component={() => {
                               return <JobDetail menuClicked={menuClicked}/>
                            }}/>
                            <Route path="/join" component={() => {
                               return <Join menuClicked={menuClicked}/>
                            }}/>
                             <Route path="/careerDetail" component={() => {
                               return <CareerDetail />
                            }}/>
                            <Route path="/resource" component={() => {
                              return <Resource menuClicked={menuClicked}/>
                            }}/>
                            <Route path="/verification" component={() => {
                              return <Verification menuClicked={menuClicked}/>
                            }}/>
                            <Route path="/benefits" component={() => {
                              return <Benefit menuClicked={menuClicked}/>
                            }}/>
                             <Route path="/event" component={() => {
                              return <Event menuClicked={menuClicked}/>
                            }}/>
                            <Route path="/blog" component={() => {
                              return <Blog menuClicked={menuClicked}/>
                            }}/>
                            <Route path="/terms" component={Terms}/>
                            {/* <Route path="/notice" component={Notice}/> */}
                            <Route path="/biotech" component={PDF}/>
                          <Route exact path="/bountyprogram" component={() => {
                            return <Redirect to={{pathname:'/',search:'?openBountyProgram=true'}}/>
                          }}/>
                          <Route exact path="/bounty-program" component={() => {
                            return <Redirect to={{pathname:'/',search:'?openBountyProgram=true'}}/>
                          }}/>
                            <Route path="/NMSDC-event" component={() => {
                                return <EventNMSDC menuClicked={menuClicked}/>
                            }}/>
                            <Route path="/ProcureCon" component={() => {
                                return <ProcureconCS menuClicked={menuClicked}/>
                            }}/>
                            <Route path="/FBIA-event" component={() => {
                                return <EventFBIA menuClicked={menuClicked}/>
                            }}/>
                            <Route path="/*" component={NotFound}/>
                        </Switch>
                    </section>
                </CSSTransition>
            </TransitionGroup>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  .fade-enter {
    opacity: 0.00;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.00;
    transition: opacity 200ms ease-in;
  }

  // div.transition-group {
  //   position: relative;
  //   float: right;
  // }

/*
  section.route-section {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
  */
`;

export default withRouter(appContainer);