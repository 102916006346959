import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import SquareBtnMain from './baseComponent/squareBtnMain';
import {HashLink as Link} from 'react-router-hash-link';

const style = theme => ({
    font: {
        width: 172,
        padding: '32px 32px 0px',
        textAlign: 'left',
    },
    icon: {
        padding: '0px 32px 32px',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: 32,
        height: 32
    }
})

const SqrBtnMain = props => {
    const{classes, icons, content, onClick, link, color} = props;

    return(
        <SquareBtnMain onClick={onClick}>
            <p className={classes.font}>{content}</p>
            <img src={icons} alt="icon" className={classes.icon}></img>  
        </SquareBtnMain>
    )
}

export default withStyles(style)(SqrBtnMain);