import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import SocialIcon from './socialIcon';
import linkedin from '../assets/icons/linkedin.svg';
import Card from '@material-ui/core/Card';
import { Color } from '../assets/color';

const style = theme => ({
    person: {
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            height: 184,
            maxWidth: 390
        },
        [theme.breakpoints.up('desktop')]: {
            width: 256,
            minWidth: 256,
            maxWidth: 'unset',
            height: 464,
        },
    },
    outer: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            height: 184,
            maxWidth: 390
        },
        // [theme.breakpoints.up('tabletv')]: {
        //     width: 'calc(33% )',
        //     height: 488,
        // },
        // [theme.breakpoints.up('tableth')]: {
        //     width: 'calc(33%)',
        //     height: 496,
        // },
        [theme.breakpoints.up('desktop')]: {
            width: 256,
            minWidth: 256,
            maxWidth: 'unset',
            height: 464,
        },
        [theme.breakpoints.up('largedesktop')]: {

        }
    },
    root: {
        border: '1.5px solid #E8E8E8',
        display: 'flex',
        height: '100%',
        alignItems: 'flex-start',
        // justifyContent: 'center',
        boxShadow: 'none',
        [theme.breakpoints.up('mob')]: {
            // width: 'calc(100% - 24px)',
            // height: 'calc(100% - 24px)',
            // margin: 12,
        },
        [theme.breakpoints.up('tabletv')]: {

        },
        [theme.breakpoints.up('tableth')]: {
            // width: 'calc(100% - 32px)',
            // height: 'calc(100% - 32px)',
            // margin: 16
        },
        [theme.breakpoints.up('desktop')]: {
            flexDirection: 'column',
            // margin: 20,
            // width: 'calc(100% - 40px)',
            // height: 'calc(100% - 40px)',
        },
        [theme.breakpoints.up('largedesktop')]: {

        },
    },
    img: {
        objectFit: 'cover',
        [theme.breakpoints.up('mob')]: {
            height: '100%',
            width: 160
        },
        [theme.breakpoints.up('desktop')]: {
            width: '100%',
            height: 256
        },
    },
    detail: {
        flex: 1,
        [theme.breakpoints.up('mob')]: {
            padding: 16,
            height: '100%',
            boxSizing: 'border-box'
            // width: 'calc(100% - 0px)',
            // height: 'calc(100% - 0px)',
        },
        [theme.breakpoints.up('tabletv')]: {
            padding: 24,
            // width: 'calc(100% - 24px)',
            // height: 'calc(100% - 24px)',
        },
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
    },
    detailTitle: {
        [theme.breakpoints.up('mob')]: {
            marginBottom: 10
        },
        [theme.breakpoints.up('desktop')]: {
            marginBottom: 10
        },
    },
    social: {
        [theme.breakpoints.up('mob')]: {
            display: 'none'
        },
        [theme.breakpoints.up('desktop')]: {
            display: 'flex',
            position: 'absolute',
            bottom: 16,
            left: 10
        },
    },
    socialImg: {
        [theme.breakpoints.up('mob')]: {
            display: 'flex',
            position: 'absolute',
            bottom: 16,
            right: 16
        },
        [theme.breakpoints.up('desktop')]: {
            display: 'none'
        },
        '&:hover': {
            cursor: 'pointer'
        }
    },
    contentBlock: {
        [theme.breakpoints.up('mob')]: {
            width: 'calc(100%)',
        },
        [theme.breakpoints.up('desktop')]: {
            width: 'calc(100% - 296px)'
        },

    },
    para: {
        padding: 20
    },
    question: {
        fontFamily: 'sofia-pro',
        fontWeight: 800,
        fontSize: 24,
        lineHeight: '32px',
        letterSpacing: '0.04rem',
        color: Color.navy
    }
})

class PersonCard extends React.Component {
    constructor() {
        super()
    }

    render() {
        const { classes, img, name, title, link, ans1, ans2, ans3 } = this.props;

        return (
            <div className={classes.person}>
                <div className={classes.outer}>
                    <div className={classes.root}>
                        <img src={img} alt="person photo" className={classes.img}></img>
                        <div className={classes.detail}>
                            <h3 className={classes.detailTitle}>{name}</h3>
                            <p className="cap">{title}</p>
                            <div className={classes.social}>
                                <SocialIcon img={linkedin} link={link} />
                            </div>
                            <a href={link} target="_blank" rel="noopener noreferrer" className={classes.socialImg} title="social icon">
                                <img src={linkedin} alt="social icon"></img>
                            </a>
                        </div>
                    </div>
                </div>
                {/* <div className={classes.contentBlock}>
                    <div className={classes.para}>
                        <p className={classes.question} role="heading" aria-level="2">What is the best part of your job?</p>
                        <p className={`normal`} role="heading" aria-level="3">{ans1}</p>
                    </div>
                    <div className={classes.para}>
                        <p className={classes.question} role="heading" aria-level="2">Coffee, tea or something else?</p>
                        <p className={`normal`} role="heading" aria-level="3">{ans2}</p>
                    </div>
                    <div className={classes.para}>
                        <p className={classes.question} role="heading" aria-level="2">What is the best piece of advice you have ever received?</p>
                        <p className={`normal`} role="heading" aria-level="3">{ans3}</p>
                    </div>
                </div> */}
            </div>


        )
    }
}

export default withStyles(style)(PersonCard);