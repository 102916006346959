import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { Color } from '../assets/color';
import { HashLink as Link } from 'react-router-hash-link';
import ipgSDK, { parseJDNotToken } from '../ipg-sdk/api';
import { type as Contract } from '../constant/index';
import CircularProgress from '@material-ui/core/CircularProgress';
import md5 from 'md5';
import ico_success from '../assets/icons/ico_success.svg';
import ico_error from '../assets/icons/ico_error.svg';
import Adobe from '../assets/jobCompany/Adobe_Symbol.jpg';
import Airbnb from '../assets/jobCompany/Airbnb_Symbol.jpg';
import Google from '../assets/jobCompany/Google_Symbol.jpg';
import Intellipro from '../assets/jobCompany/IntelliPro_Symbol.jpg';
import LinkedIn from '../assets/jobCompany/LinkedIn_Symbol.jpg';
import Twitter from '../assets/jobCompany/Twitter_Symbol.jpg';
import checkbox from '../assets/icons/ico_check_box.svg';
import checked from '../assets/icons/ico_check_box_checked.svg';
import JobListCard from './similarJobCard';
import moment from 'moment';
import EditDetilasFrom from './editDetilasFrom'

const style = theme => ({
    header: {

        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: Color.intelligentBlue,
        [theme.breakpoints.up('mob')]: {

            display: 'none'
        },
        [theme.breakpoints.up('jobDetail')]: {
            height: 480,
            padding: '0px 32px',
            display: 'flex',
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '0px 32px'
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '0px 120px'
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '0px 240px',
        },
    },
    mobHeader: {
        flexWrap: 'wrap',
        position: 'relative',
        justifyContent: 'center',
        backgroundColor: Color.intelligentBlue,
        [theme.breakpoints.up('mob')]: {
            height: 374,
            padding: '0px 24px',
            display: 'flex'
        },
        [theme.breakpoints.up('jobDetail')]: {
            display: 'none',
        },

    },
    headerInfo: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            paddingTop: 60
        },
        [theme.breakpoints.up('jobDetail')]: {
            maxWidth: 288
        },
        [theme.breakpoints.up('tableth')]: {
            maxWidth: 364
        },
        [theme.breakpoints.up('desktop')]: {
            maxWidth: 546
        },
    },
    delimiter: {
        marginBottom: 16,
        [theme.breakpoints.up('mob')]: {
            marginTop: 24,
            width: 52
        },
        [theme.breakpoints.up('tableth')]: {
            marginTop: 40,
            width: 66
        },
        [theme.breakpoints.up('desktop')]: {
            width: 88
        },
        height: 1,
        backgroundColor: Color.white
    },
    logo: {

        [theme.breakpoints.up('mob')]: {
            position: 'absolute',
            bottom: -196,
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
            width: 240,
            height: 240,
        },
        [theme.breakpoints.up('jobDetail')]: {
            position: 'relative',
            bottom: 'unset',
            boxShadow: 'none',
            paddingTop: 0,
            width: 244,
            height: 244,
        },
    },

    main: {
        [theme.breakpoints.up('mob')]: {
            padding: '0px 24px',
            marginTop: 122,
        },
        [theme.breakpoints.up('tabletv')]: {
            padding: '0px 40px'
        },
        [theme.breakpoints.up('jobDetail')]: {
            marginTop: 0,
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '0px 40px'
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '0px 120px'
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '0px 240px',
        },
    },
    block: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    cardBlock: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('mob')]: {
            paddingTop: 80,
        },
        [theme.breakpoints.up('tableth')]: {
            paddingTop: 120
        },
        [theme.breakpoints.up('desktop')]: {
            paddingTop: 200,
        },
    },
    similarJobBlock: {
        [theme.breakpoints.up('mob')]: {
            paddingBottom: 80,
        },
        [theme.breakpoints.up('tableth')]: {
            paddingBottom: 120
        },
    },
    jobDetail: {
        [theme.breakpoints.up('mob')]: {
            paddingTop: 20,
        },
        [theme.breakpoints.up('tabletv')]: {
            paddingTop: 0,
        },
        [theme.breakpoints.up('tableth')]: {
            paddingTop: 40
        },
        [theme.breakpoints.up('desktop')]: {
            paddingTop: 120,
        },
        [theme.breakpoints.up('largedesktop')]: {
            paddingTop: 80,
        },
        display: 'flex',
        justifyContent: 'space-between'
    },
    detail: {
        [theme.breakpoints.up('mob')]: {
            width: '100%'
        },
        [theme.breakpoints.up('desktop')]: {
            width: '50vw',
            maxWidth: 1024
        },
    },
    apply: {
        width: 296,
        [theme.breakpoints.up('mob')]: {
            display: 'none'
        },
        [theme.breakpoints.up('desktop')]: {
            display: 'block',
            marginTop: 168,
        },
    },
    jobSubtitle: {

        [theme.breakpoints.up('mob')]: {
            paddingTop: 60,
            paddingBottom: 32,
        },
        [theme.breakpoints.up('tabletv')]: {
            paddingTop: 80,
            paddingBottom: 56,
        },
        [theme.breakpoints.up('largedesktop')]: {
            paddingTop: 120,
        },
    },
    applySubtitle: {
        marginTop: 48,
        marginBottom: 16
    },
    title: {

        [theme.breakpoints.up('mob')]: {
            paddingBottom: 40,
        },
        [theme.breakpoints.up('tabletv')]: {
            paddingBottom: 60,
        },
    },
    button: {
        backgroundColor: Color.intelligentBlue,
        borderRadius: 0,
        border: 0,
        color: Color.white,
        fontFamily: 'sofia-pro',
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: 600,
        width: 240,
        height: 64,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
        '&:active': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #377DED'
        },
        '&:focus': {
            background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #377DED'
        },
        '&:hover': {
            cursor: 'pointer',
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #377DED',
            boxShadow: '0px 8px 24px rgba(55, 125, 237, 0.1)',
        },
        '&:disabled': {
            color: Color.grey,
            backgroundColor: Color.borderGrey
        }
    },
    formInputField: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            padding: '12px 0px'
        },
        [theme.breakpoints.up('tableth')]: {
            width: 'calc(50% - 40px)',
            padding: '30px 0px'
        },
        maxWidth: 512,
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'sofia-pro',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.02em',
        '& label': {
            fontWeight: 700,
            color: '#000000'
        },
        '& input': {
            borderRadius: 0,
            border: '2px solid #E8E8E8',
            height: 64,
            marginTop: 24,
            paddingLeft: 24,
            fontFamily: 'sofia-pro',
            fontSize: '16px',
            lineHeight: '24px',
            '&:hover': {
                border: '2px solid #377DED'
            },
            '&:focus': {
                border: '2px solid #377DED',
                boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)'
            },
            '&:active': {
                border: '2px solid #377DED',
            },
        }
    },
    upload: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'sofia-pro',
        [theme.breakpoints.up('mob')]: {
            paddingTop: 12
        },
        [theme.breakpoints.up('tableth')]: {
            paddingTop: 30
        },
    },
    dialogUpload: {
        backgroundColor: Color.lightBlue,
        borderRadius: 0,
        border: 0,
        color: Color.intelligentBlue,
        fontFamily: 'sofia-pro',
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: 600,
        width: 240,
        height: 64,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
        '&:active': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #377DED',
            boxShadow: '0px 2px 8px rgba(55, 125, 237, 0.08)'
        },
        '&:focus': {
            background: Color.white,
            border: '2px solid #377DED'
        },
        '&:hover': {
            cursor: 'pointer',
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), #F6F9FD',
            boxShadow: '0px 8px 24px rgba(55, 125, 237, 0.1)',
        }
    },
    checkbox: {
        padding: 0,
        '& input': {
            width: 32,
            height: 32
        },

    },
    icon: {
        borderRadius: 0,
        width: 32,
        height: 32,
        boxShadow: 'none',
        backgroundImage: `url(${checkbox})`,
        'input ~ &': {
            margin: '0px 16px 0px 0px',
            padding: 0,
            '&:hover': {
                backgroundColor: 'none',
            }
        },
    },
    checkedIcon: {
        backgroundColor: Color.white,
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 32,
            height: 32,
            backgroundImage: `url(${checked})`,
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: 'none',
        },
    },
    submit: {
        [theme.breakpoints.up('mob')]: {
            marginTop: 40
        },
        [theme.breakpoints.up('tableth')]: {
            marginTop: 60
        },
        marginRight: 40,
        marginBottom: 16,
    },
    newJdText: {
        marginTop: -150,
        '& p': {
            display: 'block'
        }
    },
    jdText: {
        marginTop: 0,
        '& p': {
            display: 'block'
        }
    },


})

export const typeMapping = {
    DIRECT_PLACEMENT: 'Direct Placement',
    CONTRACT: 'Contract',
    CONTRACT_THREE_TO_SIX: 'Contract (3-6 Months\' Project)',
    CONTRACT_SIX_TO_TWELVE: 'Contract (6-12 Months\' Project)',
    CONTRACT_TWELVE_OR_ABOVE: 'Contract (12 Months\' Project or above)',
    RIGHT_TO_HIRE: 'Right to Hire',
    FULL_TIME: 'Fulltime',
    PART_TIME: 'Parttime',
    INTERNSHIP: 'Internship',
    FULL_TIME_OR_PART_TIME: 'Fulltime / Partime'
};

class JobDetail extends React.Component {

    constructor() {
        super();
        this.state = {
            job: {},
            similar: [],
            location: '',
            province: '',
            city: '',
            title: '',
            logo: '',
            company: '',
            type: '',
            contract: '',
            number: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            file: null,
            fileInfo: null,
            err: '',
            success: '',
            date: '',
            timer: null,
            check: false,
            flagOption: {
                firstNameFlag: false,
                lastNameFlag: false,
                emailFlag: false,
                phoneFlag: false,
            },
            aboutUs: 
            `
              <li style="margin:0px 0px 50px 40px;text-indent: -1.5em;"> Founded in 2009, IntelliPro is a global leader in talent acquisition and HR solutions. Our commitment to delivering unparalleled service to clients, fostering employee growth, and building enduring partnerships sets us apart. With a dynamic presence in the USA, China, Canada, Singapore, Philippines, UK, India, Netherlands, and Germany, we continue leading global talent solutions.</li>
              <li style="margin:0px 0px 50px 40px;text-indent: -1.5em;"> IntelliPro, a global leader connecting individuals with rewarding employment opportunities, is dedicated to understanding your career aspirations. As an Equal Opportunity Employer, IntelliPro values diversity and does not discriminate based on race, color, religion, sex, sexual orientation, gender identity, national origin, age, genetic information, disability, or any other legally protected group status. Moreover, our Inclusivity Commitment emphasizes embracing candidates of all abilities and ensures that our hiring and interview processes accommodate the needs of all applicants. Learn more about our commitment to diversity and inclusivity at <a style="color:#377ded" href="https://intelliprogroup.com/">https://intelliprogroup.com/.</a> </li>
              <li style="margin:0px 0px 50px 40px;text-indent: -1.5em;"> Compensation: The pay offered to a successful candidate will be determined by various factors, including education, work experience, location, job responsibilities, certifications, and more. Additionally, IntelliPro provides a comprehensive benefits package, all subject to eligibility.</li>
            `
          
        }
    }

    componentDidMount() {
        const jobId = window.location.href.slice(window.location.href.indexOf('=') + 1);
        ipgSDK.getJob(jobId)
            .then(result => this.setState({ job: result, }, () => {
                const hasCity = result.ipgLocation.city !== null;
                const hasProvince = result.ipgLocation.province !== null;
                const locationResult = this.setLocation(result.ipgLocation, hasCity, hasProvince);
                this.setState({
                    location: locationResult,
                    city: hasCity ? result.ipgLocation.city : "",
                    province: hasProvince ? result.ipgLocation.province : "",
                    title: result.title,
                    logo: result.logo,
                    company: result.company,
                    type: result.jobFunctionDisplays !== null && result.jobFunctionDisplays !== undefined ? result.jobFunctionDisplays[0] : "",
                    contract: Array.isArray(result.type) ? this.titleCase(result.type.join(' ').replaceAll("_", " ")) : this.titleCase(result.type.replaceAll("_", " ")),
                    number: result.id,
                    date: moment(result.postingTime).format('ll'),

                }, () => { this.getJobs() })
            }))
            .catch(error => console.log('error:', error));
    }

    setLocation = (locationObject, hasCity, hasProvince) => {
        let location = "";
        if (hasCity && hasProvince) {
            location = locationObject.city + ', ' + locationObject.province;
        }
        if (hasCity && !hasProvince) {
            location = locationObject.city;
        }
        if (hasProvince && !hasCity) {
            location = locationObject.province;
        }
        return location;
    }

    getJobs = async () => {
        let current = Date.now();
        if (localStorage.searchTime === undefined || localStorage.searchTime === null || current - localStorage.searchTime > 360000) {
            localStorage.searchTime = current;
            ipgSDK.getJobs().then(res => {
                localStorage.jobs_0 = JSON.stringify(res);
                this.setState({ similar: res.filter(job => ((job.jobFunctions !== null && job.jobFunctions[0] === this.state.type) && (job.id !== window.location.href.slice(window.location.href.indexOf('=') + 1)))).splice(0, 4) })
            }).catch(err => {
                console.log(err);
            });
        }
        else {
            this.setState({ similar: JSON.parse(localStorage.jobs_0).filter(job => ((job.jobFunctions !== null && job.jobFunctions[0] === this.state.type) && (job.id !== window.location.href.slice(window.location.href.indexOf('=') + 1)))).splice(0, 4) })
        }
    }


    titleCase = (str) => {
        var newStr = str.split(" ");
        for (var i = 0; i < newStr.length; i++) {
            newStr[i] = newStr[i].slice(0, 1).toUpperCase() + newStr[i].slice(1).toLowerCase();
        }
        return newStr.join(" ");
    }

    handleUpload = (e) => {
        let file = e.target.files[0]
        this.setState({ filePending: true })
        clearTimeout(this.state.timer)
        if (file.size / 1024 / 1024 > 10) {
            let timer = setTimeout(() => {
                this.setState({ err: 'The files must not exceed 10 MB', filePending: false });
                this.input.value = ''
            }, 100)
            this.setState({
                timer: timer
            })
            return
        } else {
            parseJDNotToken(file).then((res) => {
                const { firstName, lastName, email, phone } = this.state;
                const profile = res && res.parseOutput
                let emailContacts = profile && profile.contacts && profile.contacts.length > 0 && profile.contacts.filter((item) => {
                    return item.type == 'EMAIL'
                })
                let phoneContacts = profile && profile.contacts && profile.contacts.length > 0 && profile.contacts.filter((item) => {
                    return item.type == 'PHONE'
                })
                let fileEmail = (emailContacts && emailContacts.length > 0 && emailContacts[0].contact) || ''
                let filePhone = (phoneContacts && phoneContacts.length > 0 && phoneContacts[0].contact) || ''
                let fileFirstName = profile.firstName || ''
                let fileLastName = profile.lastName || ''
                let flagOption = {}
                let flag = false
                if (!firstName && !lastName && !phone && !email) {//都为空 直接回填
                    return this.setState({
                        filePending: false,
                        file: e.target.files,
                        fileInfo: res,
                        firstName: fileFirstName,
                        lastName: fileLastName,
                        email: fileEmail,
                        phone: filePhone,
                    })

                }
                if (firstName != fileFirstName) {
                    flag = true
                    flagOption.firstNameFlag = true
                }
                if (lastName != fileLastName) {
                    flag = true
                    flagOption.lastNameFlag = true
                }

                if (phone != filePhone) {
                    flag = true
                    flagOption.phoneFlag = true
                }

                if (email != fileEmail) {
                    flag = true
                    flagOption.emailFlag = true
                }


                if (flag) {
                    this.setState({
                        filePending: false,
                        file: e.target.files,
                        fileInfo: res,
                        openEditTipFlag: true,
                        flagOption,
                    })
                } else {
                    this.setState({
                        filePending: false,
                        file: e.target.files,
                        fileInfo: res,
                    })
                }

            })
                .catch(err => {
                    clearTimeout(this.state.timer)
                    this.setState({ err: 'Server error, please try again', success: '', filePending: false });
                });
        }
    }


    editFrom = (params) => {
        this.setState({
            firstName: params.firstName,
            lastName: params.lastName,
            email: params.email,
            phone: params.phone,
            openEditTipFlag: false
        })
    }

    handleSubmit = () => {
        clearTimeout(this.state.timer)
        let err = ";", valid = true
        if (this.state.firstName.trim() === "") {
            err += "First Name Required; ";
            valid = false;
        }
        if (this.state.lastName.trim() === "") {
            err += "Last Name Required; ";
            valid = false;
        }
        if (this.state.email.trim() === "") {
            err += "Email Required; ";
            valid = false;
        }
        else {
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(this.state.email.trim())) {
                err += "Invalid Email;\n";
                valid = false;
            }
        }
        if (this.state.phone.trim() === "") {
            valid = false;
            err += "Phone Number required; ";
        }
        if (!this.state.check) {
            err += "Agreement Required; ";
            valid = false;
        }

        if (!this.state.file) {
            err += "Resume Required; ";
            valid = false;
        }

        if (valid) {
            let address = this.state.city ? this.state.location : this.state.province
            this.setState({ submitLoading: true })
            ipgSDK.applyJob({
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                jobId: this.state.job.id,
                jobName: this.state.title,
                uuid: this.state.fileInfo.uuid,
                address,
                type: typeMapping[this.state.job.type],
                phone: this.state.phone
            }).then(response => {
                this.setState({ submitLoading: false })
                if (response.status >= 200 && response.status <= 206) {
                } else {
                    clearTimeout(this.state.timer)
                    let timer = setTimeout(() => {
                        this.setState({
                            loading: false
                        })
                    }, 200)
                    this.setState({
                        timer: timer
                    })
                }
                return response.json();
            })
                .then(result => {
                    if (result) {
                        let { code, message } = result
                        if (code == '-10000') {
                            this.setState({ valid: false, err: "You've already applied for this job. Thank you.", success: '' });
                        } else if (code == 200) {
                            this.setState({ success: 'You resume has been sent. Thanks for your interest.', err: '' })
                        } else {
                            this.setState({ err: 'Error, please proofread your resume', success: '' });
                        }
                    }
                })
                .catch(error => {
                    clearTimeout(this.state.timer)
                    this.setState({ err: 'Server error, please try again', success: '', submitLoading: false });
                    console.log('error', error)
                });

        } else {
            this.setState({ err: err.substring(1) });
        }
    }

    handleRemove = () => {
        this.setState({
            file: null,
        })
    }

    handleFirstName = (e) => {
        this.setState({ firstName: e.target.value })
    }

    handleLastName = (e) => {
        this.setState({ lastName: e.target.value })
    }

    handleEmail = (e) => {
        this.setState({ email: e.target.value })
    }


    handlePhone = (e) => {
        this.setState({ phone: e.target.value })
    }

    handleCheck = e => {
        this.setState({ check: !this.state.check })
    }

    setSalaryText = ()=>{
      const {job} = this.state
      const {ipgJobSalaryDTO} = job 

      const {billRange,currency,payType} = ipgJobSalaryDTO || {}
      const {gte,lte} = billRange || {}
      const {enumId} = currency || {}

      let currencyEnum = {
        0:{
          notation:"$",
          label:"USD"
        },
        1:{
          notation:"¥",
          label:"CNY"
        },
        2:{
          notation:"€",
          label:"EUR"
        },
        3:{
          notation:"C$",
          label:"CAD"
        },
        4:{
          notation:"£",
          label:"GBP"
        },
        12:{
          notation:"HK$",
          label:"HKD"
        },
        15:{
          notation:"₹",
          label:"INR"
        },
        36:{
          notation:"RM",
          label:"MYR"
        },
        24:{
          notation:"₱",
          label:"PHP"
        },
        28:{
          notation:"S$",
          label:"SGD"
        },
        
        7:{
          notation:"A$",
          label:"AUD"
        },
        16:{
          notation:"JP¥",
          label:"JPY"
        },
      }

      let payTypeEnmu = {
        HOURLY:'Hourly',
        DAILY:"Daily",
        WEEKLY:"Weekly",
        MONTHLY:"Monthly",
        YEARLY:"Annual Salary"
      }
      console.log("ipgJobSalaryDTO_",ipgJobSalaryDTO);

      let text = ``

      let currencyNotation =  currencyEnum[enumId]?.notation 
      let currencyLabel =  currencyEnum[enumId]?.label 
      let payTypeLabel = payType && payTypeEnmu[payType]
      if(ipgJobSalaryDTO){

        if(gte && lte){
          // `$35-$50 per hour (USD)`
          text = `${currencyNotation}${gte}-${currencyNotation}${lte} ${payTypeLabel} (${currencyLabel})`
        }
        // 只有gte(最小值)
        if((gte && !lte)){
          text = `more than ${currencyNotation}${gte} ${payTypeLabel} (${currencyLabel})`
        }
        // 只有lte(最大值)
        if((!gte && lte)){
          text = `less than ${currencyNotation}${lte} ${payTypeLabel} (${currencyLabel})`
        }
  
      }

      return text || '-'
    }

    render() {
        const { classes } = this.props;
        const { filePending, submitLoading, job } = this.state
        console.log('job', job)
        return (
            <div>
                <div className={classes.header}>
                    <div className={classes.headerInfo}>
                        <div style={{ color: Color.white }} className={`h1`} role="heading" aria-level="2">
                            {this.state.title}
                        </div>
                        <div className={classes.delimiter}></div>
                        <p className='normal' style={{ color: Color.white }}>{this.titleCase(this.state.type).replaceAll('_', ' ')} | {this.state.city} | {this.state.contract}</p>
                    </div>
                    <img src={this.state.logo} alt="company icon" className={classes.logo}></img>
                </div>
                <div className={classes.mobHeader}>
                    <div className={classes.headerInfo}>
                        <div style={{ color: Color.white }} className={`h1`} role="heading" aria-level="2">{this.state.title}</div>
                        <div className={classes.delimiter}></div>
                        <p className='normal' style={{ color: Color.white }}>{this.titleCase(this.state.type).replaceAll('_', ' ')} | {this.state.city} | {this.state.contract}</p>
                    </div>
                    <img src={this.state.logo} alt="company icon" className={classes.logo}></img>
                </div>
                <div className={classes.main}>
                    <div className={classes.jobDetail}>
                        <div className={classes.detail}>
                           
                            {
                                this.state.job.publicDesc ? <>
                                <h2 className={`${classes.jobSubtitle} hro`}> Job Description</h2>
                                    <p className={`normal`} style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', fontSize: 24, lineHeight: '40px' }}>
                                        {
                                            this.state.job.publicDesc &&
                                            <div
                                                className={this.state.job.publicDesc.slice(0, 9) === '<!DOCTYPE' ? classes.newJdText : classes.jdText}
                                                dangerouslySetInnerHTML={{ __html: this.state.job.publicDesc }}
                                            // style={this.state.job.publicDesc.slice(0, 9) =   == '<!DOCTYPE' ? { marginTop: -150 } : { marginTop: 0 }}
                                            ></div>
                                        }
                                    </p> 
                                      </>:
                                    <>
                                      <h2 className={`${classes.jobSubtitle} hro`}> Job Description</h2>
                                        <p className={`normal`} style={{ 
                                          // whiteSpace: 'pre-wrap', 
                                        wordBreak: 'break-word', fontSize: 24, lineHeight: '40px' }}>
                                            {
                                                this.state.job.ipgJobResponsibilities &&
                                                <div
                                                    className={this.state.job.ipgJobResponsibilities.slice(0, 9) === '<!DOCTYPE' ? classes.newJdText : classes.jdText}
                                                    dangerouslySetInnerHTML={{ __html: this.state.job.ipgJobResponsibilities }}
                                                ></div>
                                            }
                                        </p>
                                        <h2 className={`${classes.jobSubtitle} hro`}> Job Responsibilities</h2>
                                        <p className={`normal`} style={{ 
                                          // whiteSpace: 'pre-wrap', 
                                          wordBreak: 'break-word', fontSize: 24, lineHeight: '40px' }}>
                                            {
                                                this.state.job.ipgJobRequirements &&
                                                <div
                                                    className={this.state.job.ipgJobRequirements.slice(0, 9) === '<!DOCTYPE' ? classes.newJdText : classes.jdText}
                                                    dangerouslySetInnerHTML={{ __html: this.state.job.ipgJobRequirements }}
                                                ></div>
                                            }
                                        </p>
                                        <h2 className={`${classes.jobSubtitle} hro`}> Experience / Skill Requirements</h2>
                                        <p className={`normal`} style={{ 
                                          // whiteSpace: 'pre-wrap', 
                                          wordBreak: 'break-word', fontSize: 24, lineHeight: '40px' }}>
                                            {
                                                this.state.job.ipgJobSummary &&
                                                <div
                                                    className={this.state.job.ipgJobSummary.slice(0, 9) === '<!DOCTYPE' ? classes.newJdText : classes.jdText}
                                                    dangerouslySetInnerHTML={{ __html: this.state.job.ipgJobSummary }}
                                                ></div>
                                            }
                                        </p>
                                    </>
                            }

                            <h2 className={`${classes.jobSubtitle} hro`}> About Us</h2>
                            <p className={`normal`} style={{ 
                                          // whiteSpace: 'pre-wrap', 
                                          wordBreak: 'break-word', fontSize: 24, lineHeight: '40px' }}>
                                         
                                          <div
                                              className={  classes.jdText }
                                              dangerouslySetInnerHTML={{ __html: this.state.aboutUs }}
                                          ></div>
                                           
                              </p>

                              {
                                this.state.job.ipgIncludeDefaultContent &&     <p className={`normal`}  style={{ 
                                  // whiteSpace: 'pre-wrap', 
                                  wordBreak: 'break-word', fontSize: 16, lineHeight: '40px',marginTop:50, fontStyle: 'italic' }}> 
                                                      {this.state.job.ipgDefaultContent}
                                           </p>
                              }
                          
                        </div>
                        <div className={classes.apply}>
                            {/* <h3 className={classes.applySubtitle}>Company</h3>
                            <p className={`normal`}>{this.state.company}</p> */}
                            <h3 className={classes.applySubtitle}>Job Title</h3>
                            <p className={`normal`}>{this.state.title}</p>
                            <h3 className={classes.applySubtitle}>Location</h3>
                            <p className={`normal`}>{this.state.location}</p>
                            <h3 className={classes.applySubtitle}>Position Type</h3>
                            <p className={`normal`}>
                                {Contract.hasOwnProperty(this.state.contract) ? Contract[this.state.contract] : this.state.contract}
                            </p>
                            {/* <h3 className={classes.applySubtitle}>Number</h3>
                            <p className={`normal`}>{this.state.number}</p> */}
                            {/* <h3 className={classes.applySubtitle}>Posted</h3>
                            <p className={`normal`}>{this.state.date}</p> */}
 
                            <h3 className={classes.applySubtitle}>Salary Range / Rate (Currency)</h3>
                            {/* <p className={`normal`}>$35-$50 per hour (USD)</p>  */}

                            {
                              this.state.job &&  <p className={`normal`}>{this.setSalaryText()}</p> 
                            }
                           
                            
                            <h3 className={classes.applySubtitle}>Job ID#</h3>
                            <p className={`normal`}>{this.state.job.id}</p>
                            <Link to={'/jobDetail?id=' + this.state.job.id + '#apply'}>
                                <button className={classes.button} style={{ marginTop: 80 }}>Apply</button>
                            </Link>
                        </div>
                    </div>
                    <div className={`${classes.card} ${classes.cardBlock}`} id="apply">
                        <div className={`h1 ${classes.title}`} role="heading" aria-level="1">Apply for this Job</div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                            <div className={classes.formInputField}>
                                <label htmlFor="firstname">
                                    <span>First Name </span>
                                    <span style={{ color: Color.intelligentBlue }}>*</span>
                                </label>
                                <input type="text" required placeholder="First Name" id="firstname" name="firstname" onChange={e => this.handleFirstName(e)} value={this.state.firstName}></input>
                            </div>
                            <div className={classes.formInputField}>
                                <label htmlFor="lastname">
                                    <span>Last Name </span>
                                    <span style={{ color: Color.intelligentBlue }}>*</span>
                                </label>
                                <input type="text" required placeholder="Last Name" id="lastname" name="lastname" onChange={e => this.handleLastName(e)} value={this.state.lastName}></input>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                            <div className={classes.formInputField}>
                                <label htmlFor="email">
                                    <span>Email</span>
                                    <span style={{ color: Color.intelligentBlue }}>*</span>
                                </label>
                                <input type="email" required placeholder="Email" id="email" name="email" onChange={e => this.handleEmail(e)} value={this.state.email}></input>
                            </div>

                            <div className={classes.formInputField}>
                                <label htmlFor="phone">
                                    <span>Phone Number</span>
                                    <span style={{ color: Color.intelligentBlue }}>*</span>
                                </label>
                                <input type="text" required placeholder="Phone Number" id="phone" name="phone" onChange={e => this.handlePhone(e)} value={this.state.phone}></input>
                            </div>
                        </div>

                        <div className={classes.upload}>
                            <label htmlFor="resume" style={{ marginBottom: 16 }}>
                                <span>Resume</span>
                                <span style={{ color: Color.intelligentBlue }}>*</span>
                            </label>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <div style={this.state.file !== null ? { display: 'flex', border: '2px solid #E8E8E8', height: 16, width: 200, padding: '24px 20px', marginRight: 24 } : { display: 'none' }}>
                                    <p className="cap" style={{ color: Color.darkGrey, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{this.state.file !== null ? this.state.file[0].name : ''}</p>
                                </div>
                                {this.state.file !== null ?
                                    <button className={classes.dialogUpload} onClick={this.handleRemove}>Remove</button> :
                                    <div>
                                        <input
                                            style={{ display: 'none' }}
                                            name="file" type="file"
                                            ref={el => this.input = el}
                                            onChange={this.handleUpload}
                                            id="contained-button-file"
                                            accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        >
                                        </input>
                                        <label htmlFor="contained-button-file">
                                            <span className={classes.dialogUpload} role="button" tabIndex={0}>
                                                {
                                                    filePending ? <CircularProgress size={60} thickness={5} /> : 'Upload a Resume'

                                                }
                                            </span>

                                        </label>
                                    </div>
                                }

                            </div>

                            <div style={{ marginTop: 60, display: 'flex', }}>
                                <Checkbox
                                    checked={this.state.check}
                                    style={{ marginRight: 8, height: 32 }}
                                    className={classes.checkbox}
                                    onChange={this.handleCheck}
                                    icon={<span className={classes.icon} />}
                                    checkedIcon={<span className={classes.checkedIcon} />}
                                    tabIndex={0}
                                />
                                <p className={`normal ${classes.term}`}>
                                    I give my express written consent for IntelliPro (including its vendors and affiliates) to contact me using texting tools. I understand that I may withdraw my consent at any time.
                                </p>
                            </div>
                            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                {
                                    submitLoading ? <div className={classes.dialogUpload}> <CircularProgress size={60} thickness={5} /></div> : <button className={`${classes.button} ${classes.submit}`} disabled={!this.state.check || this.state.success !== ""} onClick={this.handleSubmit} >Apply</button>
                                }

                            </div>


                            <p className="normal" style={{ alignItems: 'flex-start' }}>
                                <img
                                    style={this.state.success !== "" || this.state.err !== "" ? { display: 'flex', width: 32, paddingRight: 8 } : { display: "none" }}
                                    src={this.state.success !== "" ? ico_success : this.state.err !== "" ? ico_error : ""}
                                    alt="icon"
                                ></img>
                                {this.state.success}{this.state.err}
                            </p>

                        </div>

                    </div>
                    <div className={`${classes.card} ${classes.cardBlock} ${classes.similarJobBlock}`}>
                        <div className={`h1 ${classes.title}`} role="heading" aria-level="1">Similar Jobs</div>
                        <div style={{ display: 'flex', overflow: 'scroll', margin: '0px -20px' }}>
                            {this.state.similar.map(l => {
                                return (
                                    <JobListCard id={l.id} key={l.id} company={l.company} name={l.title} logo={l.logo} type={Array.isArray(l.type) ? this.titleCase(l.type.join(' ').replaceAll("_", " ")) : ''}
                                        location={this.setLocation(l.ipgLocation, l.ipgLocation.city !== null, l.ipgLocation.province !== null)}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>
                {
                    this.state.openEditTipFlag && (<EditDetilasFrom
                        openEditTipFlag={this.state.openEditTipFlag}
                        fileInfo={this.state.fileInfo}
                        firstName={this.state.firstName}
                        lastName={this.state.lastName}
                        email={this.state.email}
                        phone={this.state.phone}
                        onClose={() => {
                            this.setState({ openEditTipFlag: false })
                        }
                        }
                        flagOption={this.state.flagOption}
                        editFrom={this.editFrom}
                    />)
                }

            </div>
        )
    }

}

export default withStyles(style)(JobDetail)