import TuSimple from '../assets/jobCompany/Untitled-1-01.png';
import Adobe from '../assets/jobCompany/Untitled-1-02.png';
import Cruise from '../assets/jobCompany/Untitled-1-03.png';
import Lime from '../assets/jobCompany/Untitled-1-04.png';
import Auryc from '../assets/jobCompany/Untitled-1-05.png';
import TikTok from '../assets/jobCompany/Untitled-1-06.png';
import Google from '../assets/jobCompany/Untitled-1-07.png';
import Oppo from '../assets/jobCompany/Untitled-1-08.png';
import Nio from '../assets/jobCompany/Untitled-1-09.png';
import Circle from '../assets/jobCompany/Untitled-1-10.png';
import Tal from '../assets/jobCompany/Untitled-1-11.png';
import CBMG from '../assets/jobCompany/Untitled-1-12.png';
import Inceptio from '../assets/jobCompany/Untitled-1-13.png';

import Kwai from '../assets/jobCompany/ipgoffer-14.png';
import Tencent from '../assets/jobCompany/ipgoffer-16.png';
import FutuInc from '../assets/jobCompany/ipgoffer-19.png';
import MDAlly from '../assets/jobCompany/ipgoffer-15.png';
import Phoenix from '../assets/jobCompany/ipgoffer-18.png';
import DiDi from '../assets/jobCompany/ipgoffer-20.png';
import Falcon from '../assets/jobCompany/ipgoffer-17.png';
import Finpeak from '../assets/jobCompany/ipgoffer-21.png';
import Petuum from '../assets/jobCompany/ipgoffer-22.png';
import Circle1 from '../assets/jobCompany/ipgoffer-23.png';
import WeRide from '../assets/jobCompany/ipgoffer-24.png';
import ZaiLab from '../assets/jobCompany/ipgoffer-25.png';
import AliPay from '../assets/jobCompany/ipgoffer-26.png';

import Instacart from '../assets/jobCompany/ipgoffer-27.png';
import Wish from '../assets/jobCompany/ipgoffer-28.png';
import Coursera from '../assets/jobCompany/ipgoffer-29.png';
import Coinbase from '../assets/jobCompany/ipgoffer-30.png';
import NewsBreak from '../assets/jobCompany/ipgoffer-31.png';
import Intuit from '../assets/jobCompany/ipgoffer-32.png';
import Finastra from '../assets/jobCompany/ipgoffer-33.png';
import Particle  from '../assets/jobCompany/ipgoffer-34.png';

export const type = {
    "Contract Six To Twelve": "Contract: 6-12 months",
    "Contract Three To Six":"Contract: 3-6 months"
}

export const recentJobs = [
    { title: 'Instacart', content:'Senior Full Stack Software Engineer', img: Instacart },
    { title: 'Wish', content:'Senior SRE', img: Wish },
    { title: 'Coursera ', content:'Tech Manager', img: Coursera },
    { title: 'Intuit', content:'Principal Software Engineer', img: Intuit },
    { title: 'Coinbase ', content:'Senior Software Engineer', img: Coinbase },
    { title: 'NewsBreak', content:'Senior Mobile Engineer', img: NewsBreak },
    { title: 'Finastra ', content:'Project Control Officer', img: Finastra },
    { title: 'Particle  ', content:'IOS Developer', img: Particle  },

    { title: 'Cruise', content:'Senior EMC Engineer', img: Cruise },
    { title: 'Petuum', content:'Senior Product Manager/Group Product Manager', img: Petuum },
    { title: 'Circle', content:'Backend Software Engineer', img: Circle1 },
    { title: 'WeRide', content:'Staff Senior Software Engineer', img: WeRide },
    { title: 'Zai Lab', content:'Medical Writer', img: ZaiLab },
    { title: 'OPPO', content:'IP Counsel', img: Oppo},
    { title: 'Alipay', content:'Customer Service Specialist', img: AliPay },

    { title: 'Kwai', content:'HR Specialist', img: Kwai },
    { title: 'Tencent', content:'CSIG/Senior Cloud Architect ', img: Tencent },
    { title: 'Tencent', content:'Principal, Health/Biotech', img: Tencent },
    { title: 'Futu Inc.', content:'Public Relations Manager', img: FutuInc },
    { title: 'MD Ally', content:'Head of Engineering', img: MDAlly },
    { title: 'Phoenix Technology', content:'Customer BMC Engineer', img: Phoenix},
    { title: 'DiDi Labs', content:'Senior HR Generalist', img: DiDi},
    { title: 'Falcon Brands', content:'Salesforce Developer', img: Falcon},
    { title: 'Finpeak Inc', content:'IOS Engineer', img: Finpeak},

    { title: 'Cruise', content:'Senior Bringup Engineer', img: Cruise },
    { title: 'TuSimple', content:'Senior Automotive System Engineer', img: TuSimple },
    { title: 'Lime', content:'Backend Software Engineer', img: Lime },
    { title: 'Auryc', content:'Customer Success Manager', img: Auryc },
    { title: 'Inceptio', content:'Embedded Software Engineer', img: Inceptio },
    { title: 'OPPO', content:'SoC Power Architect', img: Oppo },
    { title: 'Circle', content:'Backend Software Engineer', img: Circle },
    // { title: 'Google', content:'Data Analyst', img: Google },
    { title: 'Adobe', content:'Financial Analyst', img: Adobe },
    { title: 'TuSimple ', content:'System Engineer', img: TuSimple },
    { title: 'NIO', content:'Principal Software Engineer', img: Nio },
    { title: 'Auryc ', content:'Solution Architect', img: Auryc },
    { title: 'TAL', content:'HRBP', img: Tal },
    { title: 'CBMG ', content:'Cell Manufacturing Associate', img: CBMG },

   
]