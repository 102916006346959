import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Color } from '../../assets/color';

const SquareBtnMain = withStyles({
    root: {
        width: 240,
        height: 240,
        fontSize: 24,
        boxShadow: 'none',
        textTransform: 'none',
        padding: 0,
        color: Color.white,
        alignItems:'flex-start',
        boxShadow:'0px 2px 8px rgba(0, 0, 0, 0.08)',
        justifyContent:'end',
        borderRadius: 0,
        lineHeight: '32px',
        letterSpacing: '0.01em',
        fontStyle: 'normal',
        fontWeight: 600,
        fontFamily: 'sofia-pro',
        backgroundColor: Color.intelligentBlue,
        transition: 'transform .2s',
        '&:active': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #377DED'
        },
        '&:focus': {
            background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #377DED'
        },
        '&:hover': {
            cursor: 'pointer',
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #377DED',
            boxShadow:'0px 8px 24px rgba(55, 125, 237, 0.1)',
            transform: 'scale(1.1)',
            zIndex:10
        },
        '&:disabled': {
            background: Color.borderGrey,
            color: Color.darkGrey
        }
    }
})(Button);

export default SquareBtnMain;