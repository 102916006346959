import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Color } from '../assets/color';

import ico_right from '../assets/icons/ico_arrow_right_b.svg';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        border: '1.5px solid #E8E8E8',
        justifyContent: 'center',
        [theme.breakpoints.up('mob')]: {
            margin: 24,
            width: 'calc(100% - 48px)',
        },
    },
    card:{
        [theme.breakpoints.up('mob')]: {
            padding: 24,
        },
        [theme.breakpoints.up('tableth')]: {
            padding: 32,
        },
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    benefit: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            marginBottom: 24
        },
        [theme.breakpoints.up('tableth')]: {
            width: '27%',
            marginBottom: 0
        },
        
    },
    benefitImg: {
        backgroundColor: Color.lightBlue,
        padding: 24,
        width: 'calc(100% - 48px)'
       
    },
    benefitContent: {
        [theme.breakpoints.up('mob')]: {
            width: '100%'
        },
        [theme.breakpoints.up('tableth')]: {
            width: '65%'
        },
    },
    inclusionList: {
        listStyleType: 'square',
        margin: 0,
        paddingInlineStart: 22,
        padding: '12px 0px'
    },
    inclusion: {
        color: Color.navy,
        fontWeight: 400,
        fontSize: 20,
        letterSpacing: '0.04em',
        lineHeight: '32px',
        '&::marker': {
            color: Color.intelligentBlue
        },
    },
    link: {
        display: 'flex',
        paddingInlineStart: 22,
        alignItems: 'flex-start',
        '&:hover': {
            cursor: 'pointer'
        }
    }
})

class BenefitCard extends React.Component {
    constructor() {
        super()
    }

    render() {
        const { classes, img, title, para, content } = this.props;

        return(
            <div className={classes.root}>
                <div className={classes.card}>
                    <div className={classes.benefit}>
                        <div className={classes.benefitImg}>
                            <img src={img} alt="benefit img" style={{ width: '100%'}}></img>
                        </div>
                    </div>
                    
                    <div className={classes.benefitContent}>
                        <h3>{title}</h3>
                        <p className={`normal`} style={{paddingTop: 12}}>{para}</p>
                        {
                            content.map(c => {return(
                                <div>
                                    <ul className={classes.inclusionList}>
                                        <li className={classes.inclusion}>{c.list}</li>
                                    </ul>
                                    <a className={classes.link} href={c.link} target="_blank">
                                        <img src={ico_right} alt="icons" style={{width:24, paddingTop: 5}}></img>
                                        <p className="boldcap" style={{color: Color.intelligentBlue, paddingLeft: 8, lineHeight: '32px'}}>{c.redirect}</p>        
                                    </a>
                                </div>
                            )})
                        }
                        
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(BenefitCard);