import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Color } from '../assets/color';

import ico_right from '../assets/icons/ico_arrow_right_b.svg';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        border: '1.5px solid #E8E8E8',
        [theme.breakpoints.up('mob')]: {
            margin: 16,
            width: 'calc(100% - 35px)',
        },
        [theme.breakpoints.up('tableth')]: {
            margin: 20,
            width: 'calc(50% - 43px)',
        },
    },
    card:{
        [theme.breakpoints.up('mob')]: {
            padding: '32px 24px',
        },
        [theme.breakpoints.up('tableth')]: {
            padding: 48,
        },
        position: 'relative',
       
    },
    cardTitle: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            paddingBottom: 24
        },
        [theme.breakpoints.up('tableth')]: {
            minWidth: '318px',
            width:'unset'
        },
    },
})

class VerificatioonProcess extends React.Component {

    constructor() {
        super()
    }

    render() {
        const { classes, title, para, wid} = this.props;

        return(
            <div className={classes.root}>
                 <div className={classes.card}>
                     <div style={{position:'absolute', left: -1, top: -1, width: wid, backgroundColor: Color.intelligentBlue, height: 8}}></div>
                     <div style={{display:'flex', alignItems:'center', flexWrap: 'wrap' }}>
                        <h2 className={`${classes.cardTitle}`}>{title}</h2>
                     </div>
                    <p className={`normal`}>{para}</p>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(withRouter(VerificatioonProcess));