import React from 'react';
import {  withStyles } from '@material-ui/core/styles';
import { Color } from '../assets/color';


const style = {
    root: {
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
        textTransform: 'none',
        lineHeight: '24px',
        letterSpacing: '0.02em',
        fontStyle: 'normal',
        fontFamily: 'sofia-pro',
        borderRadius: 48,
        width: 48,
        height: 48,
        display: 'flex',
        justifyContent: 'center',
        margin: 12, 
        alignItems: 'center',
        backgroundColor: Color.white,
        '&:active': {
            backgroundColor: Color.intelligentBlue
        },
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: Color.lightBlue
        },
        '&:focus': {
            backgroundColor: Color.lightBlue
        }
    }
}


const SocialIcon = props => {
    const { classes, img, link } = props;

    return(
        <div className={classes.root}>
            <a href={link} target="_blank" rel="noopener noreferrer"  title="social icon" style={{height: 20}}>
                <img style={{height: 20}} src={img} alt="social icon"></img>
            </a>
        </div>
    )
} 

export default withStyles(style)(SocialIcon);