import React from 'react';

import { Route } from 'react-router-dom';
import BlogList from './blog';
import BlogDetail from './blogdetail';

class BlogRoute extends React.Component {

    render() {

        return(
            <>  
                <Route exact path={'/blog'} component={BlogList} />
                <Route exact path={'/blog/:id'} component={BlogDetail} />
                <Route exact path={'/blog/:id/:slogan'} component={BlogDetail} />
            </>
        )
    }
}

export default BlogRoute;

                        