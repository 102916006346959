import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { Color } from '../assets/color';
import mapPin from '../assets/icons/ico_map_pin.svg'

const style = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        border: '1.5px solid #E8E8E8',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up('mob')]: {
            margin: 12,
            width: 'calc(100% - 24px)',
            height: 120
        },
        [theme.breakpoints.up('tableth')]: {
            margin: 15,
            width: 'calc(33% - 32px)',
            height: 160
        },
        [theme.breakpoints.up('desktop')]: {
            margin: 20,
            width: 'calc(33% - 40px)'
        }
    }
})

class PlaceCard extends React.Component {

    constructor() {
        super()
    }

    render() {
        const { classes, place, country } = this.props;

        return(
            <div className={classes.root}>
                <h2>{place}</h2>
                <div style={country !== null && country!== undefined && country!== '' ? {display: 'flex', paddingTop: 8, justifyContent:'center', alignItems:'center'} : {display: 'none'}}>
                    <img src={mapPin} alt="map_ pin_icon" style={{width:24, height: 24, paddingRight: 8}}></img>
                    <p className="cap">{country}</p>
                </div>
            </div>
        )
    }

}

export default withStyles(style)(PlaceCard);