import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import BlogCard from '../../components/blogCard';
import BlogCover from '../../assets/blog/Cover.jpg';
import { blogList }from './data';

const styles = theme => ({
    header:{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        [theme.breakpoints.up('mob')]: {
            height: 518
        },
        [theme.breakpoints.up('tabletv')]: {
            height: 480
        },
        [theme.breakpoints.up('tableth')]: {
            height: 500
        },
        [theme.breakpoints.up('desktop')]: {
            height: 640
        },
        // [theme.breakpoints.up('largedesktop')]: {
        //     height: 640
        // },
    },
    headerImg: {
        objectFit: 'cover',
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            height: 288
        },
        [theme.breakpoints.up('tabletv')]: {
            width: '50%',
            height: 480
        },
        [theme.breakpoints.up('tableth')]: {
            height: 500
        },
        [theme.breakpoints.up('desktop')]: {
            height: 640
        },
    },
    headerTitle: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
       
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            height: 200,
            justifyContent: 'center',
        },
        [theme.breakpoints.up('tabletv')]: {
            width: '50%',
            height: 480
        },
        [theme.breakpoints.up('tableth')]: {
            height: 500,
            justifyContent: 'center',
        },
        // [theme.breakpoints.up('desktop')]: {
        //     height: 800,
        //     justifyContent: 'flex-start',
        // },
    },
    title1:{
        [theme.breakpoints.up('mob')]: {
            padding: '60px 24px',
        },
        [theme.breakpoints.up('tabletv')]: {
            padding: '0px 32px',
            width: 'calc(100% - 64px)'
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '0px 32px',
            width: 360
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '208px 120px',
            width: '70%',
            maxWidth: 480
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '240px 240px 0px',
        },
    },
    title2:{
        [theme.breakpoints.up('mob')]: {
            padding: '60px 22px',
            width: 240
        },
        [theme.breakpoints.up('tabletv')]: {
            padding: '0px 28px',
            width: 'calc(100% - 64px)'
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '0px 28px',
            width: 360
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '208px 110px',
            width: '70%',
            maxWidth: 480
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '240px 220px 0px',
        },
    },
    main: {
        [theme.breakpoints.up('mob')]: {
            padding: '0px 24px',
        },
        [theme.breakpoints.up('tabletv')]: {
            padding: '0px 32px'
        },
        [theme.breakpoints.up('jobDetail')]: {
            marginTop: 0,
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '0px 32px'
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '0px 120px'
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '0px 240px',
        },
    },
    block: {
        display:'flex', 
        flexDirection:'row', 
        justifyContent:'space-between', 
    },
    cardBlock: {
        display:'flex',
        flexDirection:'column',
        [theme.breakpoints.up('mob')]: {
            paddingTop: '80px',
        },
        [theme.breakpoints.up('tableth')]: {
            paddingTop: '120px',
        },
        [theme.breakpoints.up('desktop')]: {
            paddingTop: '200px',
        },
    },
    cardBlockBottom: {
        [theme.breakpoints.up('mob')]: {
            paddingBottom: '80px',
        },
        [theme.breakpoints.up('tableth')]: {
            paddingBottom: '120px',
        },
        [theme.breakpoints.up('desktop')]: {
            paddingBottom: '200px',
        },
    },
    blogTitle: {
        boxSizing: 'border-box',
        paddingRight: 40,
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            paddingBottom: 32,
        },
        [theme.breakpoints.up('tabletv')]: {
            width: '100%',
            paddingBottom: 72,
            // maxWidth: 400
        },
        [theme.breakpoints.up('desktop')]: {
            width: '50%',
        },
    },
    blogPara: {
        [theme.breakpoints.up('mob')]: {
            width: '100%'
        },
        [theme.breakpoints.up('desktop')]: {
            width:  '50%'
        }
    },
    blogGroup: {
        margin: '-24px',
        display: 'flex',
        flexDirection: 'column-reverse',
        [theme.breakpoints.up('mob')]: {
            paddingBottom: 80,
        },
        [theme.breakpoints.up('tableth')]: {
            paddingBottom: 120
        },
        [theme.breakpoints.up('desktop')]: {
            paddingBottom: 200,
        },
    }
});


class Blog extends React.Component {

    constructor() {
        super()
    }

    render() {

        const{ classes } = this.props;
        return(
            <div>
                <Helmet>
                    <title>Blog - Jobs and More</title>
                    <meta name="title" content="Intellipro Group - Jobs and More" />
                    <meta property="og:title" content="recruitment, outsourcing, consulting, artificial intelligence, AI based talent acquisition, matching and recommendations algorithm, career advising, career opportunities, latest career trends"/>
                    <meta property="og:description" content="This recruiting and staffing industry blog features expert opinions on the latest job market trends and offers advice on how to put your career on the right track."/>
                    <meta name="description" content="This recruiting and staffing industry blog features expert opinions on the latest job market trends and offers advice on how to put your career on the right track."/>
                </Helmet>
                <div className={classes.header}>
                    <div className={classes.headerTitle}>
                        <title className={classes.title1}>Blog List</title>
                    </div>
                    <img src={BlogCover} alt="Company Blog" className={classes.headerImg}></img>
                </div>
                <div className={classes.main}>
                    <div className={`${classes.block} ${classes.cardBlock}`} style={{flexDirection:'row', flexWrap:'wrap', alignItems: 'flex-start'}}>
                        <div className={`h1 ${classes.blogTitle}`} role="heading" aria-level="1">
                            Welcome to IntelliPro Blog  Center
                        </div>
                        <p className={`normal ${classes.blogPara}`}>
                            This blog offers unique perspectives on trends in the recruiting and staffing industry. 
                            Learn from our seasoned recruiters, leaders, and guest speakers about the job market, the best ways to secure top talent, and how to set your career on the right track. Stay tuned! Subscribe to our blog.
                        </p>
                    </div>
                    <div className={`${classes.block} ${classes.cardBlock}`} style={{flexDirection:'row', flexWrap:'wrap', alignItems: 'flex-start'}}>
                        <div className={`h1 ${classes.blogTitle}`} role="heading" aria-level="1">Blog List</div>
                    </div>
                    <div className={classes.blogGroup}>
                        {
                            blogList.map(data => {
                                return (
                                    <BlogCard 
                                        title={data.title}
                                        pic={data.img}
                                        author={data.author}
                                        datetime={data.datetime}
                                        id={data.id}
                                        key={data.id}
                                        slogan={data.slogan}
                                        imgContain={data.imgContain}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(Blog);

                        