import React, {useEffect, useState } from 'react';
import Styles from './index.module.css';
import EmailIcon from './assets/email.png';
import PhoneIcon from './assets/phone.png';
import SuccessTip from './assets/successTip.png';
import Erweima from './assets/erweima.png';
import { Button, Input, Checkbox, Divider, message } from 'antd';
const { TextArea } = Input;
const contactUsForm=(data)=>new Promise((resolve)=>{
    console.log(data);
    resolve()
})
const Image = (props)=>{
    return <img {...props} />
}
const ContactUs = props => {
    const { language } = props;
    const [errMessage, setErrMessage] = useState({});
    const [successFlag, setSuccessFlag] = useState(true);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [wechat, setWechat] = useState('');
    const [staffing, setStaffing] = useState(false);
    const [hr, setHr] = useState(false);
    const [bpo, setBpo] = useState(false);
    const [dei, setDei] = useState(false);
    const [other, setOther] = useState(false);
    const [note, setNote] = useState('');
    const [confirmLoading, setConfirmLoading] = useState(false);

    const submitForm = () => {
        const isPhone = /^([\d+(-][-\d+\s\/)(*.·]{8,25}(\s*ext\s*\d{3,})?)$/i;
        const isEmail = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        const isWechat =
            /^[a-z_-][a-z0-9_-]{5,19}$|^[\d+(-][-\d+\s\/)(*.·]{8,25}$|https:\/\/wechat-qrcode.s3.[-\w]*.amazonaws.com\/\w*/i;
        let errmessage = {};
        if (!name.trim()) {
            errmessage.name = language ? '(请填写姓名)' : 'This is A Required Field'
        }
        if (!phone.trim()) {
            errmessage.phone = language ? '(请填写手机号码)' : 'This is A Required Field'
        } else {
            if (!isPhone.test(phone)) {
                errmessage.phone = language ? '(手机号码格式不正确)' : 'Phone Format is Not Correct'
            }
        }
        if (!email.trim()) {
            errmessage.email = language ? '(请填写邮箱)' : 'This is A Required Field'
        } else {
            if (!isEmail.test(email)) {
                errmessage.email = language ? '(邮箱格式不正确)' : 'Email Format is Not Correct'
            }
        }
        if (!language && !wechat.trim()) {
            errmessage.company = 'This is A Required Field'
        }
        // if (!staffing && !hr && !bpo && !dei && !other  ) {
        //     errmessage.service = language ? '(请至少选择一项您关注的服务)' : 'This is A Required Field'
        // }
        if (note.length > 5000) {
            errmessage.note = language ? '长度不可大于5000' : 'The Length Should be Less Than 5000'
        }
        if (language && wechat.trim() && !isWechat.test(wechat)) {
            errmessage.company = '(微信格式不正确)'
        }
        setErrMessage(errmessage);
        if (errmessage.company || errmessage.email || errmessage.name || errmessage.note || errmessage.phone || errmessage.service) {
            return
        }
        let salt = "thisissalt";
        let now = Date.now();
        let CryptoJS = require('crypto-js');
        let hash = CryptoJS.MD5(salt + now).toString();
        let services = [];

        if (staffing) {
            services.push({ companyConsultService: 'STAFFING_RECRUITING' })
        }
        if (hr) {
            services.push({ companyConsultService: 'GLOBAL_HR_SOLUTIONS' })
        }
        if (bpo) {
            services.push({ companyConsultService: 'BPO' })
        }if (dei) {
            services.push({ companyConsultService: 'DEI_ADVISORY' })
        }if (other) {
            services.push({ companyConsultService: 'OTHER' })
        }
        setConfirmLoading(true)
        let obj = {
            timestamp: now,
            checkSum: hash,
            contactForm: {
                language: language ? 'CHINESE' : 'ENGLISH',
                name: name,
                phone: phone,
                email: email,
                services: services,
                comment: note ? note.split('\n').join('<br/>') : ''
            }
        }
        if (language) {
            obj.contactForm.wechat = wechat
        } else {
            obj.contactForm.company = wechat
        }

        fetch('https://ips-api.hitalentech.com/ips/api/v1/contact/nmsdc-event',{
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(obj)
        })
            .then((response) => {
                // console.log('_handleResponseToJson');

                if (!response.ok) {
                    if (response.status >= 500) {
                        throw {
                            status: response.status,
                            statusText: response.statusText,
                            endpoint: new URL(response.url).pathname,
                        };
                    }

                    if (response.status >= 400) {
                        return response.json().then(
                            (json) => ({ response: json, headers: response.headers }),
                            () => 'OK'
                        );
                    }
                }

                if (response.status === 204) {
                    return 'OK';
                }

                return response.json().then(
                    (json) => ({ response: json, headers: response.headers }),
                    () => 'OK'
                );
            })
            .then((res) => {
            setConfirmLoading(false);
            setSuccessFlag(false)
        }).catch((err) => {
            setConfirmLoading(false)
            console.error(err)
        })
    }


    useEffect(() => {
        window.scrollTo(0,40);
        console.log(successFlag);
    }, [successFlag])

    return (
        <div className={Styles.root}>
            <div className={Styles.container}>
                <div className={Styles.contactTitle}>ProcureCon</div>
                {
                    successFlag ?
                        <div className={Styles.contactForm}>
                            <div className={Styles.formFlex}>
                                <div>
                                    <div className={errMessage.name ? Styles.formTitleErr : Styles.formTitle}>
                                        {language ? '姓名 *' : 'Contact Name *'}
                                        &nbsp;&nbsp;
                                        <span className={Styles.formErrTip}>{errMessage.name}</span>
                                    </div>
                                    <Input
                                        value={name}
                                        onChange={(e) => { setName(e.target.value) }}
                                        onBlur={() => {
                                            let copyerrMessage = { ...errMessage };
                                            delete copyerrMessage.name;
                                            setErrMessage(copyerrMessage)
                                        }}
                                        placeholder={language ? '姓名' : 'your name'}
                                        className={Styles.formInput}
                                        status={errMessage.name ? 'error' : null}
                                    />
                                </div>
                                <div>
                                    <div className={errMessage.phone ? Styles.formTitleErr : Styles.formTitle}>
                                        {language ? '手机 *' : 'Contact Number *'}
                                        &nbsp;&nbsp;
                                        <span className={Styles.formErrTip}>{errMessage.phone}</span>
                                    </div>
                                    <Input
                                        value={phone}
                                        onChange={(e) => { setPhone(e.target.value) }}
                                        onBlur={() => {
                                            let copyerrMessage = { ...errMessage };
                                            delete copyerrMessage.phone;
                                            setErrMessage(copyerrMessage)
                                        }}
                                        placeholder={language ? '手机号' : 'phone number'}
                                        className={Styles.formInput}
                                        status={errMessage.phone ? 'error' : null}
                                    />
                                </div>
                            </div>
                            <div className={Styles.formFlex}>
                                <div>
                                    <div className={errMessage.email ? Styles.formTitleErr : Styles.formTitle}>
                                        {language ? '邮箱 *' : 'Work Email *'}
                                        &nbsp;&nbsp;
                                        <span className={Styles.formErrTip}>{errMessage.email}</span>
                                    </div>
                                    <Input
                                        value={email}
                                        onChange={(e) => { setEmail(e.target.value) }}
                                        onBlur={() => {
                                            let copyerrMessage = { ...errMessage };
                                            delete copyerrMessage.email;
                                            setErrMessage(copyerrMessage)
                                        }}
                                        placeholder={language ? '邮箱地址' : 'email address'}
                                        className={Styles.formInput}
                                        status={errMessage.email ? 'error' : null}
                                    />
                                </div>
                                <div>
                                    <div className={errMessage.company ? Styles.formTitleErr : Styles.formTitle}>
                                        {language ? '微信' : 'Company Name *'}
                                        &nbsp;&nbsp;
                                        <span className={Styles.formErrTip}>{errMessage.company}</span>
                                    </div>
                                    <Input
                                        value={wechat}
                                        onChange={(e) => { setWechat(e.target.value) }}
                                        onBlur={() => {
                                            let copyerrMessage = { ...errMessage };
                                            delete copyerrMessage.company;
                                            setErrMessage(copyerrMessage)
                                        }}
                                        placeholder={language ? '微信' : 'company name'}
                                        className={Styles.formInput}
                                        status={errMessage.company ? 'error' : null}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className={errMessage.service ? Styles.formTitleErr : Styles.formTitle}>
                                    {language ? '您关注的服务（可多选）*' : 'Service Interested (select multiple If needed）'}
                                    &nbsp;&nbsp;
                                    <span className={Styles.formErrTip}>{errMessage.service}</span>
                                </div>
                                <div className={Styles.formFlexTwo}>
                                    <Checkbox
                                        className={Styles.checkBox}
                                        checked={staffing}
                                        onChange={(e) => {
                                            let copyerrMessage = { ...errMessage };
                                            delete copyerrMessage.service;
                                            setErrMessage(copyerrMessage)
                                            setStaffing(e.target.checked)
                                        }}
                                    >
                                        Staffing & Recruiting
                                                                            </Checkbox>
                                    <Checkbox
                                        className={Styles.checkBox}
                                        checked={hr}
                                        onChange={(e) => {
                                            let copyerrMessage = { ...errMessage };
                                            delete copyerrMessage.service;
                                            setErrMessage(copyerrMessage)
                                            setHr(e.target.checked)
                                        }}
                                    >
                                        Global HR Solutions
                                                                       </Checkbox>
                                    <Checkbox
                                        className={Styles.checkBox}
                                        checked={bpo}
                                        onChange={(e) => {
                                            let copyerrMessage = { ...errMessage };
                                            delete copyerrMessage.service;
                                            setErrMessage(copyerrMessage)
                                            setBpo(e.target.checked)
                                        }}
                                    >

                                        Business Process Outsourcing (BPO)
                                    </Checkbox>
                                </div>
                                <div className={Styles.formFlexTwo}>
                                    <Checkbox
                                        className={Styles.checkBox}
                                        checked={dei}
                                        onChange={(e) => {
                                            let copyerrMessage = { ...errMessage };
                                            delete copyerrMessage.service;
                                            setErrMessage(copyerrMessage)
                                            setDei(e.target.checked)
                                        }}
                                    >
                                        DEI Advisory
                                    </Checkbox>
                                    <Checkbox
                                        className={Styles.checkBox}
                                        checked={other}
                                        onChange={(e) => {
                                            let copyerrMessage = { ...errMessage };
                                            delete copyerrMessage.service;
                                            setErrMessage(copyerrMessage)
                                            setOther(e.target.checked)
                                        }}
                                    >
                                        Other
                                    </Checkbox>
                                </div>
                            </div>
                            <div>
                                <div className={errMessage.note ? Styles.formTitleErr : Styles.formTitle}>
                                    {language ? '留言板' : 'Message'}
                                    &nbsp;&nbsp;
                                    <span className={Styles.formErrTip}>{errMessage.note}</span>
                                </div>
                                <TextArea
                                    value={note}
                                    onChange={(e) => { setNote(e.target.value) }}
                                    rows={4}
                                    placeholder={language ? "请在此处留言" : 'Please leave your message here'}
                                    status={errMessage.note ? 'error' : null}
                                />
                            </div>
                            {
                                language ?
                                    <div className={Styles.formTips}>{'为保证信息安全性，信息仅同步给您的专属客户经理'}</div>
                                    : null
                            }
                            <div className={Styles.formFlexThree}>
                                <Button className={Styles.formBtn} onClick={submitForm} loading={confirmLoading}>
                                    {language ? '立刻咨询' : 'Submit'}
                                </Button>
                            </div>
                        </div> :
                        <div className={Styles.formSuccessBox}>
                            <Image src={SuccessTip} alt={'提交成功'} loading='lazy' className={Styles.successTipImg} />
                            <div className={Styles.successTitle}>{language ? '提交成功' : 'Submission Successful'}</div>
                            {/*<div className={Styles.successSubTitle}>{language ? '我们的专业团队将尽快与您取得联系' : 'Our experts will reach out to you within 24hrs.'}</div>*/}
                            {/*<Divider className={Styles.successLine} />*/}
                            {/*<div className={Styles.successFont}>{language ? '扫码关注公众号' : 'Follow Us on WeChat'}</div>*/}
                            {/*<Image src={Erweima} alt={'提交成功'} loading='lazy' className={Styles.wechatImg} />*/}
                        </div>
                }
            </div>
        </div>
    )
};

export default ContactUs;
