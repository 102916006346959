import React from 'react'

class NotFound extends React.Component {
    constructor() {
        super()
    }

    render() {
        return(
            <div>
                Page not found!
            </div>
        )
    }

}

export default NotFound;