import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Color } from '../assets/color';

import Jobs from '../data/career';

import md5 from 'md5';
import checkbox from '../assets/icons/ico_check_box.svg';
import checked from '../assets/icons/ico_check_box_checked.svg';
import JobListCard from './similarJobCard';

const style = theme => ({
    header: {

        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: Color.intelligentBlue,
        [theme.breakpoints.up('mob')]: {
            display: 'flex',
            height: 374,
            padding: '0px 24px',
        },
        [theme.breakpoints.up('jobDetail')]: {
            height: 480,
            padding: '0px 32px',

        },
        [theme.breakpoints.up('tableth')]: {
            padding: '0px 32px'
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '0px 120px'
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '0px 240px',
        },
    },
    mobHeader: {
        flexWrap: 'wrap',
        position: 'relative',
        justifyContent: 'center',
        backgroundColor: Color.intelligentBlue,
        [theme.breakpoints.up('mob')]: {
            height: 374,
            padding: '0px 24px',
            display: 'flex'
        },
        [theme.breakpoints.up('jobDetail')]: {
            display: 'none',
        },

    },
    headerInfo: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
        },
        [theme.breakpoints.up('jobDetail')]: {
            maxWidth: 288
        },
        [theme.breakpoints.up('tableth')]: {
            maxWidth: 364
        },
        [theme.breakpoints.up('desktop')]: {
            maxWidth: 546
        },
    },
    delimiter: {
        marginBottom: 16,
        [theme.breakpoints.up('mob')]: {
            marginTop: 24,
            width: 52
        },
        [theme.breakpoints.up('tableth')]: {
            marginTop: 40,
            width: 66
        },
        [theme.breakpoints.up('desktop')]: {
            width: 88
        },
        height: 1,
        backgroundColor: Color.white
    },
    logo: {
        width: 244,
        height: 244,
        [theme.breakpoints.up('mob')]: {
            position: 'absolute',
            bottom: -122,
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)'
        },
        [theme.breakpoints.up('jobDetail')]: {
            position: 'relative',
            bottom: 'unset',
            boxShadow: 'none',
            paddingTop: 0
        },
    },

    main: {
        [theme.breakpoints.up('mob')]: {
            padding: '0px 24px',
        },
        [theme.breakpoints.up('tabletv')]: {
            padding: '0px 32px'
        },
        [theme.breakpoints.up('jobDetail')]: {
            marginTop: 0,
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '0px 32px'
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '0px 120px'
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '0px 240px',
        },
    },
    block: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    cardBlock: {
        [theme.breakpoints.up('mob')]: {
            paddingTop: 80,
        },
        [theme.breakpoints.up('tableth')]: {
            paddingTop: 120
        },
        [theme.breakpoints.up('desktop')]: {
            paddingTop: 200,
        },
    },
    cardBlockBottom: {
        [theme.breakpoints.up('mob')]: {
            paddingBottom: '80px',
        },
        [theme.breakpoints.up('tableth')]: {
            paddingBottom: '120px',
        },
        [theme.breakpoints.up('desktop')]: {
            paddingBottom: '200px',
        },
    },
    similarJobBlock: {
        [theme.breakpoints.up('mob')]: {
            paddingBottom: 80,
        },
        [theme.breakpoints.up('tableth')]: {
            paddingBottom: 120
        },
    },
    jobDetail: {
        [theme.breakpoints.up('mob')]: {
            marginTop: -60,
        },
        [theme.breakpoints.up('tabletv')]: {
            marginTop: -80,
        },
        [theme.breakpoints.up('largedesktop')]: {
            marginTop: -120,
        },
        display: 'flex',
        justifyContent: 'space-between',
        gap: 30
    },
    detail: {
        [theme.breakpoints.up('mob')]: {
            width: '100%'
        },
        [theme.breakpoints.up('desktop')]: {
            width: '50vw',
            maxWidth: 1024
        },
    },
    apply: {
        width: 296,
        [theme.breakpoints.up('mob')]: {
            display: 'none'
        },
        [theme.breakpoints.up('desktop')]: {
            display: 'block',
            marginTop: 168,
        },
    },
    jobSubtitle: {

        [theme.breakpoints.up('mob')]: {
            paddingTop: 60,
            paddingBottom: 32,
        },
        [theme.breakpoints.up('tabletv')]: {
            paddingTop: 80,
            paddingBottom: 56,
        },
        [theme.breakpoints.up('largedesktop')]: {
            paddingTop: 120,
        },
    },
    applySubtitle: {
        marginTop: 48,
        marginBottom: 16
    },
    title: {

        [theme.breakpoints.up('mob')]: {
            paddingBottom: 40,
        },
        [theme.breakpoints.up('tabletv')]: {
            paddingBottom: 60,
        },
    },
    button: {
        backgroundColor: Color.intelligentBlue,
        borderRadius: 0,
        border: 0,
        color: Color.white,
        fontFamily: 'sofia-pro',
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: 600,
        width: 240,
        height: 64,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
        '&:active': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #377DED'
        },
        '&:focus': {
            background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #377DED'
        },
        '&:hover': {
            cursor: 'pointer',
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #377DED',
            boxShadow: '0px 8px 24px rgba(55, 125, 237, 0.1)',
        },
        '&:disabled': {
            color: Color.grey,
            backgroundColor: Color.borderGrey
        }
    },
    bottomButton: {
        [theme.breakpoints.up('mob')]: {
            display: 'flex'
        },
        [theme.breakpoints.up('desktop')]: {
            display: 'none'
        },
    },
    formInputField: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            padding: '12px 0px'
        },
        [theme.breakpoints.up('tableth')]: {
            width: 'calc(50% - 40px)',
            padding: '30px 0px'
        },
        maxWidth: 512,
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'sofia-pro',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.02em',
        '& label': {
            fontWeight: 700,
            color: '#000000'
        },
        '& input': {
            borderRadius: 0,
            border: '2px solid #E8E8E8',
            height: 64,
            marginTop: 24,
            paddingLeft: 24,
            fontFamily: 'sofia-pro',
            fontSize: '16px',
            lineHeight: '24px',
            '&:hover': {
                border: '2px solid #377DED'
            },
            '&:focus': {
                border: '2px solid #377DED',
                boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)'
            },
            '&:active': {
                border: '2px solid #377DED',
            },
        }
    },
    upload: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'sofia-pro',
        [theme.breakpoints.up('mob')]: {
            paddingTop: 12
        },
        [theme.breakpoints.up('tableth')]: {
            paddingTop: 30
        },
    },
    dialogUpload: {
        backgroundColor: Color.lightBlue,
        borderRadius: 0,
        border: 0,
        color: Color.intelligentBlue,
        fontFamily: 'sofia-pro',
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: 600,
        width: 240,
        height: 64,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
        '&:active': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #377DED',
            boxShadow: '0px 2px 8px rgba(55, 125, 237, 0.08)'
        },
        '&:focus': {
            background: Color.white,
            border: '2px solid #377DED'
        },
        '&:hover': {
            cursor: 'pointer',
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), #F6F9FD',
            boxShadow: '0px 8px 24px rgba(55, 125, 237, 0.1)',
        }
    },
    checkbox: {
        padding: 0,
        '& input': {
            width: 32,
            height: 32
        },

    },
    icon: {
        borderRadius: 0,
        width: 32,
        height: 32,
        boxShadow: 'none',
        backgroundImage: `url(${checkbox})`,
        'input ~ &': {
            margin: '0px 16px 0px 0px',
            padding: 0,
            '&:hover': {
                backgroundColor: 'none',
            }
        },
    },
    checkedIcon: {
        backgroundColor: Color.white,
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 32,
            height: 32,
            backgroundImage: `url(${checked})`,
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: 'none',
        },
    },
    submit: {
        [theme.breakpoints.up('mob')]: {
            marginTop: 40
        },
        [theme.breakpoints.up('tableth')]: {
            marginTop: 60
        },
        marginRight: 40,
        marginBottom: 16,
    },
    inclusionList: {
        listStyleType: 'square',
        margin: 0,
        paddingInlineStart: 22,
        '& $inclusionList':{
            listStyleType:'circle'
        }
    },
    inclusion: {
        color: Color.navy,
        fontWeight: 400,
        fontSize: 24,
        letterSpacing: '0.04em',
        lineHeight: '40px',
        '&::marker': {
            color: Color.intelligentBlue
        },
    },
    contactDetail: {
        [theme.breakpoints.up('mob')]: {
            display: 'none'
        },
        [theme.breakpoints.up('tableth')]: {
            display: 'block'
        },
        letterSpacing: '0.04em',
        color: '#5C5E6B'
    },
    contactDetailMobile: {
        [theme.breakpoints.up('mob')]: {
            display: 'block'
        },
        [theme.breakpoints.up('tableth')]: {
            display: 'none'
        },
        letterSpacing: '0.04em',
        color: '#5C5E6B'
    }
})

export const typeMapping = {
    DIRECT_PLACEMENT: 'Direct Placement',
    CONTRACT: 'Contract',
    CONTRACT_THREE_TO_SIX: 'Contract (3-6 Months\' Project)',
    CONTRACT_SIX_TO_TWELVE: 'Contract (6-12 Months\' Project)',
    CONTRACT_TWELVE_OR_ABOVE: 'Contract (12 Months\' Project or above)',
    RIGHT_TO_HIRE: 'Right to Hire',
    FULL_TIME: 'Fulltime',
    PART_TIME: 'Parttime',
    INTERNSHIP: 'Internship',
    FULL_TIME_OR_PART_TIME: 'Fulltime / Partime'
};

class jobDetail extends React.Component {

    constructor() {
        super();
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }


    render() {
        const { classes } = this.props;
        const jobList = Jobs;
        const id = new URLSearchParams(window.location.search).get('id');
        const jobDetail = jobList.find(job => String(job.id) === id);
        if (!jobDetail) {
            return null
        }

        return (
            <div>
                <div className={classes.header}>
                    <div className={classes.headerInfo}>
                        <div className={`h1`} style={{ color: Color.white }} role="heading" aria-level="1">
                            {jobDetail.name}
                        </div>
                        <div className={classes.delimiter}></div>
                        <p className='normal' style={{ color: Color.white }}>{jobDetail.location} | {jobDetail.type}</p>
                    </div>
                </div>
                <div className={classes.main}>
                    <div className={`${classes.jobDetail} ${classes.cardBlock} ${classes.cardBlockBottom}`}>
                        <div className={classes.detail}>
                            {
                                jobDetail.aboutUs &&
                                <>
                                    <h2 className={`${classes.jobSubtitle} hro`}>About Us</h2>
                                    <p className={`normal`} style={{
                                        whiteSpace: 'pre-wrap',
                                        wordBreak: 'break-word',
                                        fontSize: 24,
                                        lineHeight: '40px'
                                    }}>
                                        {jobDetail.aboutUs}
                                    </p>
                                </>
                            }
                            {
                                jobDetail.customContents && jobDetail.customContents.map((section) => {
                                    return <>
                                        <h2 className={`${classes.jobSubtitle} hro`}>{section.subtitle}</h2>
                                        <p className={`normal`} style={{
                                            whiteSpace: 'pre-wrap',
                                            wordBreak: 'break-word',
                                            fontSize: 24,
                                            lineHeight: '40px'
                                        }}>
                                            {section.summary}
                                        </p>
                                        <ul className={classes.inclusionList}>
                                            {section.contents && section.contents.map(content =>
                                                content.detailList ?
                                                    <li className={classes.inclusion}>
                                                        <div>{content.summary}</div>
                                                        <ul className={classes.inclusionList}>
                                                            {
                                                                content.detailList.map(detail =>
                                                                    <li className={classes.inclusion}>{detail}</li>
                                                                )}
                                                        </ul>
                                                    </li>
                                                    : <li className={classes.inclusion}>{content}</li>
                                            )}
                                        </ul>
                                    </>
                                })
                            }
                            {jobDetail.dutyDetails &&
                            <><h2 className={`${classes.jobSubtitle} hro`}>Responsibilities</h2>
                                <p className={`normal`} style={{
                                    whiteSpace: 'pre-wrap',
                                    wordBreak: 'break-word',
                                    fontSize: 24,
                                    lineHeight: '40px'
                                }}>
                                    {jobDetail.duties}
                                </p>
                                <ul className={classes.inclusionList}>
                                    {jobDetail.dutyDetails.map(duty =>
                                        duty.detailList ?
                                            <li className={classes.inclusion}>
                                                <div>{duty.summary}</div>
                                                <ul className={classes.inclusionList}>
                                                    {
                                                        duty.detailList.map(detail =>
                                                            <li className={classes.inclusion}>{detail}</li>
                                                        )}
                                                </ul>
                                            </li>
                                            : <li className={classes.inclusion}>{duty}</li>
                                    )}
                                </ul>
                            </>}

                            {jobDetail.requirement && <> <h2 className={`${classes.jobSubtitle} hro`}>Job
                                Requirement</h2>
                                <ul className={classes.inclusionList}>
                                    {
                                        jobDetail.requirement.map(item =>
                                            item.detailList ?
                                                <li className={classes.inclusion}>
                                                    <div>{item.summary}</div>
                                                    <ul className={classes.inclusionList}>
                                                        {
                                                            item.detailList.map(detail =>
                                                                <li className={classes.inclusion}>{detail}</li>
                                                            )}
                                                    </ul>
                                                </li>
                                                : <li className={classes.inclusion}>{item}</li>
                                        )}
                                </ul>
                            </>}

                            {
                                jobDetail.whatWeOffer &&
                                <>
                                    <h2 className={`${classes.jobSubtitle} hro`}>What We Offer</h2>
                                    <ul className={classes.inclusionList}>
                                        {
                                            jobDetail.whatWeOffer.map(item =>
                                                <li className={classes.inclusion}>{item}</li>)
                                        }
                                    </ul>
                                </>
                            }
                            {
                                jobDetail.salary &&
                                <>
                                    <h2 className={`${classes.jobSubtitle} hro`}>Salary</h2>
                                    <p className={`normal`} style={{
                                        whiteSpace: 'pre-wrap',
                                        wordBreak: 'break-word',
                                        fontSize: 24,
                                        lineHeight: '40px'
                                    }}>
                                        {jobDetail.salary}
                                    </p>
                                </>
                            }
                            {
                                jobDetail.hours &&
                                <>
                                    <h2 className={`${classes.jobSubtitle} hro`}>Hours</h2>
                                    <p className={`normal`} style={{
                                        whiteSpace: 'pre-wrap',
                                        wordBreak: 'break-word',
                                        fontSize: 24,
                                        lineHeight: '40px'
                                    }}>
                                        {jobDetail.hours}
                                    </p>
                                </>
                            }
                            <h2 className={`${classes.jobSubtitle} hro`}>Job Location</h2>
                            <p className={`normal`} style={{
                                whiteSpace: 'pre-wrap',
                                wordBreak: 'break-word',
                                fontSize: 24,
                                lineHeight: '40px'
                            }}>
                                {jobDetail.locationDetail}
                            </p>
                            <h2 className={`${classes.jobSubtitle} hro`}>Contact</h2>
                            <p className={`normal`} style={{
                                whiteSpace: 'pre-wrap',
                                wordBreak: 'break-word',
                                fontSize: 24,
                                lineHeight: '40px',
                                paddingBottom: 32
                            }}>
                                {jobDetail.contact}
                            </p>
                            <p className={` ${classes.contactDetailMobile}`} style={{
                                whiteSpace: 'pre-wrap',
                                wordBreak: 'break-word',
                                fontSize: 24,
                                lineHeight: '40px'
                            }}>
                                {jobDetail.contactMobileDetail11}
                            </p>
                            <p className={` ${classes.contactDetailMobile}`} style={{
                                whiteSpace: 'pre-wrap',
                                wordBreak: 'break-word',
                                fontSize: 24,
                                lineHeight: '40px'
                            }}>
                                {jobDetail.contactMobileDetail12}
                            </p>
                            <p className={` ${classes.contactDetail}`} style={{
                                whiteSpace: 'pre-wrap',
                                wordBreak: 'break-word',
                                fontSize: 24,
                                lineHeight: '40px'
                            }}>
                                {jobDetail.contactMobileDetail1}
                            </p>
                            <p className={`normal`} style={{
                                whiteSpace: 'pre-wrap',
                                wordBreak: 'break-word',
                                fontSize: 24,
                                lineHeight: '40px'
                            }}>
                                {jobDetail.contactMobileDetail2}
                            </p>
                            <p className={`normal`} style={{
                                whiteSpace: 'pre-wrap',
                                wordBreak: 'break-word',
                                fontSize: 24,
                                lineHeight: '40px'
                            }}>
                                {jobDetail.contactMobileDetail3}
                            </p>
                            <a href="mailto:hr@intelliprogroup.com">
                                <button className={`${classes.button} ${classes.bottomButton}`}
                                        style={{ marginTop: 80 }}>Apply
                                </button>
                            </a>
                        </div>
                        <div className={classes.apply}>
                            <h3 className={classes.applySubtitle}>Company</h3>
                            <p className={`normal`}>{jobDetail.company}</p>
                            <h3 className={classes.applySubtitle}>Job Title</h3>
                            <p className={`normal`}>{jobDetail.name}</p>
                            {
                                jobDetail.type &&
                                <>
                                    <h3 className={classes.applySubtitle}>Job Type</h3>
                                    <p className={`normal`}>{jobDetail.type}</p>
                                </>
                            }
                            <h3 className={classes.applySubtitle}>Job Location</h3>
                            <p className={`normal`}>{jobDetail.locationDetail}</p>
                            <h3 className={classes.applySubtitle}>Job Code</h3>
                            <p className={`normal`}>{jobDetail.code}</p>
                            <a href="mailto:talent@intelliprogroup.com">
                                <button className={classes.button} style={{ marginTop: 80 }}>Apply</button>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

}

export default withStyles(style)(withRouter(jobDetail))