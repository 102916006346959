const Jobs = [
    {
        id: 1,
        name: 'Human Resources Specialist',
        duties: '',
        dutyDetails: [
            {
                summary: 'Responsible for HR policy drafting and execution. Work with LinkedIn Talent Solution, ADP, Jobdiva, Greenhouse, Workable, Deel, SQL.',
                detailList: [
                    'Draft employee corrective actions, performance improvement plans, and termination documentation for assigned employees. ',
                    'Make appropriate recommendations for corrective action that align with company policies, practices, and culture. '
                ],
            },
            {
                summary: 'Perform intake of employee inquiries and complaints.',
                detailList: [
                    'Investigate formal and informal employee inquires and complaints.',
                    'Review contract, agreement, HR policies, and/or relevant rules associated with wage, work hours, benefits, working condition and other related matters from employee relation perspective to provide answers/resolutions to employees.',
                    'Assists with research regarding employee grievances and internal investigations.'
                ],
            },
            {
                summary: 'Process and advise management on the employee grievances and disciplinary procedures.',
                detailList: [
                    'Work closely with internal and external stakeholders, perform labor relations research for development of workforce management programs, and for preparation of negotiations, and prepare studies and reports in support of programmatic recommendations and conduct of activities of the HR department. ',
                    'Monitor workforce status including compensation, diversity, work hours, employee complaints leveraging on inhouse HRIS system to ensure proper compliance.',
                    'Participate in discussions with hiring manager and higher-up leaders to find out the company’s business plan, workforce planning and the needs of HR functions to ensure the ultimate success of business operation.'
                ],
            },
            {
                summary: 'Provide Support on Off-boarding process.',
                detailList: [
                    'Responsible for the drafting of termination related documentation according to legal requirement and in avoidance of potential legal issue.',
                ],
            },
            {
                summary: 'Develop Recruitment campaign strategies.',
                detailList: [
                    'Conduct regular sync-up meetings with internal hiring manager and team leaders to analyze their talent acquisition needs.',
                    'Provide talent market insights including but not limited to compensation benchmark, talent mapping, talent intensity in different regions, and potential time-to-fill estimation.',
                    'Solidify talent acquisition objective and streamline talent acquisition process.',
                    'Design and set up job post on major job board and social network platforms such as LinkedIn and Monster.',
                    'Design and conduct structured interviews based on job orders to identify the most suitable candidate.',
                    'Conduct offer negotiation and bring in talent in timely manner.'
                ],
            },
            'Review employment applications and job orders to match applicants with job requirements.',
            {
                summary: 'Hire employees and process hiring-related paperwork.',
                detailList: [
                    'Help with onboarding process using internal applicant tracking system, HRIS and external benefits vendor system.',
                    'Use internal Applicant Tracking System and set candidate’s status to onboard.',
                    'Help with completion of Form I-9 alongside with onboarding employees.'
                ],
            },
            {
                summary: 'Conduct Onboarding Orientation to interpret and explain human resources policies, procedures, laws, standards, or regulations.',
                detailList: [
                    'Design and organize orientation sessions for new employees in order to facilitate the ice-breaking stage between newcomers and existing workforce.',
                    'Interpret HR policies such as compensation structure, payment schedule, California state regulations on employment relationship and healthy workplace.',
                    'Explain U.S. HR compliance requirements such as FMLA, ADA and the Title VII of the Civil Rights Act of 1964.',
                    'Explain and educate employees on how to file complaint when a workplace harassment or discrimination take place.',
                    'Advise employees on how to request or use various company resources including but not limited to company laptop, printer, snack bar, gym perks, business travel reimbursement.'
                ],
            },
            'Reach out to potential candidates via phone/emails to conduct screenings.',
            'Organize/attend meetings with hiring managers to navigate and prioritize hiring requirement.'
        ],
        requirement: [
            'Bachelor’s degree in Labor Relations',
            'Human Resources or a related field plus 2 years of job-related experience',
            'Foreign degree equivalent is acceptable',
            'Experience with HR policy drafting and execution as well as handling employee grievances and disciplinary procedures',
            'Work with LinkedIn Talent Solution, ADP, Jobdiva, Greenhouse, Workable, Deel, SQL'
        ],
        type: 'Full-Time',
        location: 'Santa Clara, CA',
        locationDetail: '3120 Scott Boulevard, Suite 301, Santa Clara, CA 95054',
        salary: '$66,000.00 per year',
        hours: '40 hours per week, M-F, 9:00 a.m. – 6:00 p.m',
        contact: 'Mail resume referencing job code #223JZ to HR Manager, Intellipro Group, Inc. 3120 Scott Boulevard, Suite 301, Santa Clara, CA 95054',
        company: 'IntelliPro',
        code: '223JZ',
    },
    {
        id: '1a',
        name: 'Financial Analyst',
        duties: 'Analyzing financial statements and predicting the company’s future performance, including forecasting future revenues and expenditures, as well as budgeting and financial data modeling. Specific duties include:',
        dutyDetails: [
            'Budget forecasting, financial reporting, conducting financial analytical projects, analyzing business operations and trends, developing financial objectives, researching economic trends and analyzing research results.',
            'Execute regular payroll reporting as required and assist with financial audits related to pay records and associated general ledger. Analyze pay practices related to FLSA, fringe benefit reporting and other payroll areas and determine how those practices apply to advise management.',
            'Reconcile accounts monthly to ensure accurate reporting and ledger maintenance, collaborate with the accounting team on the monthly and quarterly financial close process and drive P& L forecasting on a quarterly and annual basis.',
            'Analyze and interpret financial data by comparing key costs such as salaries and wages, business insurance, taxes and permits and legal expenses and compiles statistical reports, charts and graphs on current financial developments.',
            'Track monthly and quarterly sales and gross margin performance through comparison of actuals versus expectations and enable account managers and directors to handle their resources efficiently.',
            'Build key assumptions for HR service revenue projection and maintain financial models for payroll and overhead cost budgeting, forecasting, and long-range planning to help drive all associated reporting deliverables to the executive leadership team.',
            'Monitor current Intellipro’s customer sales trend and build future sales projections by customer, region, product etc., work with account management leaders to uncover areas of revenue opportunity in our customer and prospect base.',
            'Explain and understand internal and external drivers such as competition, customer demand, local unemployment rates, governmental regulations and environmental conditions that impact job market trending to produce forecast of business, industry, and economic conditions for use in managerial decision-making.',
            'Provide financial planning and analysis support to the Corporate HR Talent team and Finance team around monthly/quarterly reporting and planning/forecasting mechanisms.',
            'Develop the Annual Operating Plan (AOP) and monthly forecasts, focusing on operating expenses and headcount reporting.',
            'Develop and refine Intellipro’s key business metrics and drives robust analysis to identify Intellipro’s strengths, weaknesses, and opportunities by conducting industry, customer, and competitive analysis.',
            'Gather and analyze data to identify areas for improvement and/or develop solutions to keep projects on budget and on time.',
            'Collaborate with stakeholders to develop financial assumptions, and analyze valuation, including revenue forecasts and NPV Report and provide in-depth analysis including monthly Actuals vs Forecast/Budget, Risks and Opportunities, variance analysis and help update monthly financial reporting package to Senior Management.',
            'Review spending on a monthly basis and compare to forecast.',
            'Provide reports and insights to operational team on drivers.',
            'Work with CRM software, Zoho Platform, QuickBooks and Excel.'
        ],
        requirement: [
            'Bachelor’s degree in Finance, or closely related fields plus 2 years of experience. Foreign degree equivalent is acceptable.',
            'Experience to include budget forecasting, financial reporting, conducting financial analytical projects, analyzing business operations and trends, developing financial objectives, researching economic trends and analyzing research results.',
            'Must have experience working with CRM software, Zoho Platform, QuickBooks and Excel.'
        ],
        type: 'Full-Time',
        location: 'Santa Clara, CA',
        locationDetail: '3120 Scott Boulevard, Suite 301, Santa Clara, CA 95054',
        salary: '$81K to $100K per year ',
        hours: '40 hours per week, M-F, 9:00 a.m. – 6:00 p.m',
        contact: 'Mail resume referencing job code #323YH to HR Manager, Intellipro Group, Inc. 3120 Scott Boulevard, Suite 301, Santa Clara, CA 95054.',
        company: 'IntelliPro',
        code: '323YH',
    },
    {
        id: 2,
        name: 'Business Development Manager - BPO',
        aboutUs: 'Founded in 2009, IntelliPro is one of the fastest-growing talent acquisition and HR solutions firms with a high-quality commitment to continue delivering high-quality service to our clients. We develop and support our employees to reach their career goals and build long-lasting and trusted relationships with our business partners. We now have business in the USA, China, Canada, Singapore, Philippines, India, UK, Netherlands, and Germany. ',
        duties: '',
        dutyDetails: [
            `End-to-end responsibility for designing and executing the strategic sales plan for securing new clients. This includes but is not limited to prospect targeting, engagement, proposal development, negotiation, closing, and post-closing participation in ongoing account management and optimization activities.`,
            `Understanding the marketplace and competitor offerings will drive the company’s growth strategy and investments.`,
            `Be responsible for significantly growing the company presence and revenues in the BPO market in North America.`,
            `Build a predictable pipeline of new business to generate repeatable and profitable revenues across the various Business Units. `,
            `Propose - Submit and handle proposals with full ownership and accountability for the opportunity. Work closely with the sales enablement teams to ensure high-quality proposals`,
            `Establish lasting relationships with senior executives and decision-makers.`,
            `Collaborate with other members of the team - marketing, and operations - to build strategic plans for customers.`,
            `Attending conferences and industry events.`
        ],
        requirement: [
            'Prefer 3 years of business development experience with a minimum of 1 year of BPO sales experience. Prefer but not limited to IT, life science, customer service/call center, trust & safety, etc., with a proven track record of success. The current network of relevant business contacts can be utilized as a means to promote IntellliPro. ',
            'Experience managing a pipeline, negotiations, and closing contracts.',
            'Ability to manage multiple opportunities simultaneously at various stages of the buying process.',
            `Ability to develop and maintain senior-level relationships quickly and effectively.`,
            'Solid time management skills and strong deadline orientation.',
            `Solid problem-solving skills.`,
            `Strategic thinker, creative, persuasive, and adaptable.`
        ],
        type: 'Full-time Permanent',
        location: 'Remote in the US, CANADA',
        locationDetail: 'Remote in the US, CANADA',
        salary: '$70k -150k base and Commission',
        contact: 'Please apply here or email talent@intelliprogroup.com',
        company: 'IntelliPro',
    },
    {
        id: 3,
        name: 'Business Engagement Manager - National Accounts',
        aboutUs: 'Founded in 2009, IntelliPro is one of the fastest-growing talent acquisition and HR solutions firms with a high-quality commitment to continue delivering high-quality service to our clients. We develop and support our employees to reach their career goals and build long-lasting and trusted relationships with our business partners. We now have business in the USA, China, Canada, Singapore, Philippines, India, UK, Netherlands, and Germany. ',
        duties: '',
        dutyDetails: [
            `Responsible for developing and executing a consultative sales strategy against prospects representing the entire Intellipro capability.`,
            `Increase the number of new opportunities in the sales pipeline, effectively drive the opportunities through the funnel, to a closed contract.`,
            `Owns and is accountable for the entire sales cycle while coordinating and or engaging support from appropriate internal stakeholders to execute against pursuit strategies when necessary.`,
            `Create sales plans to grow market share differentiating Intellipro against competitors to acquire new business.`,
            `Work with Client Leads/Verticals on account expansion and new business opportunities within large accounts such as acquisitions, new solutions, etc.`,
            `Initiate and drive senior level engagement from Intellipro`,
            `Influence decisions of high-level stakeholders to create a win/win opportunity for Intellipro and the client.`,
            `Apply the knowledge of labor, community, and industry awareness to a business development opportunity, and educate the client while bringing innovative ideas to the table.`,
            `Work closely and consult with partners in operations, product groups and support groups to leverage Intellipro’s capabilities and close sales.`,
            `Develop client and industry networks that are both deep and wide to create demonstrated, active relationships within these networks.`,
            `Continue to be knowledgeable of competitor positions.`,
            `Work closely with pricing and finance to develop profitable solutions.`,
            `Responsible for managing pipeline within Salesforce.com and reporting to management.`
        ],
        customContents: [
            {
                subtitle: 'About the role: ',
                summary: 'The Sr. Business Engagement Manger – Enterprise Sales role is responsible for business development and sales of Intellipro entire suite of workforce solutions to prospective clients across multiple geographies. This is a professional sales executive experienced in industry vertical and or MSP sales and understands the issues their prospective clients are facing and brings disruptive insight that challenges their thinking.',
                contents: []
            },
            {
                subtitle: 'Duties and Responsibilities',
                summary: '',
                contents: [
                    `Responsible for developing and executing a consultative sales strategy against prospects representing the entire Intellipro capability.`,
                    `Increase the number of new opportunities in the sales pipeline, effectively drive the opportunities through the funnel, to a closed contract.`,
                    `Owns and is accountable for the entire sales cycle while coordinating and or engaging support from appropriate internal stakeholders to execute against pursuit strategies when necessary.`,
                    `Create sales plans to grow market share differentiating Intellipro against competitors to acquire new business.`,
                    `Work with Client Leads/Verticals on account expansion and new business opportunities within large accounts such as acquisitions, new solutions, etc.`,
                    `Initiate and drive senior level engagement from Intellipro`,
                    `Influence decisions of high-level stakeholders to create a win/win opportunity for Intellipro and the client.`,
                    `Apply the knowledge of labor, community, and industry awareness to a business development opportunity, and educate the client while bringing innovative ideas to the table.`,
                    `Work closely and consult with partners in operations, product groups and support groups to leverage Intellipro’s capabilities and close sales.`,
                    `Develop client and industry networks that are both deep and wide to create demonstrated, active relationships within these networks.`,
                    `Continue to be knowledgeable of competitor positions.`,
                    `Work closely with pricing and finance to develop profitable solutions.`,
                    `Responsible for managing pipeline within Salesforce.com and reporting to management.`
                ],
            },
            {
                subtitle: 'Abilities',
                summary: '',
                contents: [
                    `Challenge conventional thinking: Uses deep understanding of the client’s business to manage and drive their talent journey and push their thinking. Approaches every prospective client interaction in a tailored manner, aligning insight to each stakeholder’s specific needs and priorities. Is assertive in respectfully pushing back when stakeholders express skepticism about the insight or are resistant to pricing.`,
                    `Client relationship focus: Develops strong personal and professional relationships and advocates across the client organization. Facilitates cooperation between key internal and external resources to meet the client’s articulated needs.`,
                    `Makes decisions with results in mind: Focuses on post-sales follow-up to ensure service issues related to implementation and execution are addressed quickly and thoroughly. Willingly deals with ambiguity and makes decisions when they need to be made.`,
                    `Accepts personal responsibility for achieving Intellipro’s goals: Consistently puts forth extra effort. Understands the critical role of responsibility in the workplace, and uses it to drive Intellipro’s success.`,
                    `Independent thinker: Self-confident and independent. Isn’t constrained by looking to others for their opinions or direction.`,
                ],
            },
            {
                subtitle: 'Qualifications',
                summary: "",
                contents: [
                    `5+ years' experience proven sales success in a consultative and professional services environment.`,
                    `Selling deals across the Americas, may include multiple solutions.`,
                    `Selling the in to MSP such as: short term/project-based, one solution, limited geography`
                ]
            }

        ],
        type: 'Full-time Permanent',
        location: 'Remote in the SF Bay Area',
        locationDetail: 'Remote in the SF Bay Area',
        salary: '$75k -100k base and Commission',
        contact: 'Please apply here or email talent@intelliprogroup.com',
        company: 'IntelliPro',
    },
    {
        id: 4,
        name: 'Recruitment Team Manager',
        aboutUs: 'Founded in 2009, IntelliPro is one of the fastest-growing talent acquisition and HR solutions firms with a high-quality commitment to continue delivering high-quality service to our clients. We develop and support our employees to reach their career goals and build long-lasting and trusted relationships with our business partners. We now have business in the USA, China, Canada, Singapore, Philippines, India, UK, Netherlands, and Germany. ',
        customContents: [
            {
                subtitle: 'About the role: ',
                summary: `The Recruitment Leader will be an individual with excellent organizational skills. S/he will be responsible for both overseeing the Recruitment and Sourcing team in the Philippines as well as doing the Recruitment role: consulting with hiring managers/area to understand their recruitment needs, developing sourcing strategies to leverage diverse high caliber candidates, pre-screen candidates to assess their qualifications through a combination of behavioral and competency-based interviewing, monitoring requisition status to ensure an efficient recruitment process. `,
                contents: []
            },
            {
                subtitle: 'Responsibilities:',
                summary: 'The role includes the following:',
                contents: [
                    `Doing daily standups with the Recruitment team, and relay reports to the executive team`,
                    `Foster and motivate the team to outperform their KPI and Targets`,
                    `Assist with hiring and interviewing for the internal IPG team`,
                    `Successful candidates will be recruiting candidates globally`,
                    `Manage the full cycle recruiting process for internal needs, ensuring a positive candidate experience`,
                    `Screen resumes, interview qualified candidates, and facilitate interviews and hiring discussions with interviewers and hiring managers`,
                    `Create talent pipelines with high-potential candidates for future job opportunities`,
                    `Keep detailed records of past applicants’ information, including resumes, assignments and interview evaluations with in our internal ATS "APN"`,
                    `Publish job adwithinreers pages, job boards and social media`,
                    `Source and contact passive candidates online (e.g. via LinkedIn)`,
                    `Prepare candidates before interviews with clients`,
                    `Drive the offer process--including extending offers, closing candidates, and generating offer letters`,
                    `Work closely with hiring managers to develop and execute tactical sourcing strategies to create appropriate candidate flows and pipelines to meet overall recruiting goals`
                ],
            },
            {
                subtitle: 'Requirements:',
                summary: '',
                contents: [
                    `1+ years in leading a team of Recruiters and Sourcers`,
                    `4+ years of full cycle recruiting experience | experience working in a staffing agency highly preferred`,
                    `Experience in recruiting for North America (candidates in N.A) | Global recruitment experience is Nice-to-Have`,
                    `Exceptional ability to stay organized, communicate, interview, and a passion for finding exceptional candidates`,
                    `Recruiting experience across multiple departments`,
                    `Fluent in English / Bilingual in Spanish is a Nice-to-Have`,
                    `IT /TECH / ENGINEER experience`
                ],
            },

        ],

        type: 'Full-time Permanent',
        location: 'Remote in the Philippines',
        locationDetail: 'Remote in the Philippines',
        salary: 'Open',
        contact: 'Please apply here or email talent@intelliprogroup.com',
        company: 'IntelliPro',
    },
    {
        id: 5,
        name: 'Full-Cycle Technical Recruiter',
        aboutUs: 'Founded in 2009, IntelliPro is one of the fastest-growing talent acquisition and HR solutions firms with a high-quality commitment to continue delivering high-quality service to our clients. We develop and support our employees to reach their career goals and build long-lasting and trusted relationships with our business partners. We now have business in the USA, China, Canada, Singapore, Philippines, India, UK, Netherlands, and Germany. ',
        customContents: [
            {
                subtitle: 'Responsibilities:',
                summary: '',
                contents: [
                    `Establish a strong relationship with candidates, hiring managers, and clients`,
                    {
                        summary: `Build an effective network with candidates through different channels and approaches to enhance the company talent database`,
                        detailList: [
                            `Create methods effectively to execute talent competencies and potential`,
                            `Proactively build talent pipelines to meet future development requirements`],
                    },
                    `Conduct and improve the end-to-end recruitment procedure and align with client needs`,
                    `Use business development techniques such as referrals, networking, and cold-calling to generate business leads`,
                    `Build lasting relationships with candidates, clients, and colleagues`
                ],
            },
            {
                subtitle: 'Requirements:',
                summary: '',
                contents: [
                    `Experience with various interview formats, including phone screening calls, in-person interviews, group interviews, potential assessments, etc.`,
                    `Able to be creative and find "Purple squirrel" effectively and fast`,
                    {
                        summary:
                            `Ability to work in a fast-paced and high-pressure environment with multi-task delivery`,
                        detailList: [
                            `Strong follow-up, follow through`,
                            `Sense of urgency and ownership`,
                            `Enjoys collaboration and sharing ideas`
                        ],
                    },
                    `Able to actively mix sourcing, resume review, interviewing, and closing candidates`,
                    `Works with ambiguity, asks questions and builds trust`,
                    `Ability to effectively and comfortably communicate with stakeholders at all levels, from entry to senior `,
                    `Prefer Bachelor’s degree or above `
                ],
            },
            {
                subtitle: "What we offer",
                summary: '',
                contents: [
                    `Fast-track professional training programs`,
                    `H1B sponsorship for international applicants (for the US)`,
                    `Work from home with flexible working schedules`,
                    `Diverse, equity and inclusive environment`,
                    `Global, dynamic teams`
                ]
            }
        ],
        type: 'Full-time Permanent',
        location: 'Remote in the US, CANADA',
        locationDetail: 'Remote in the US, CANADA',
        salary: '$55k - $100k base and Commission',
        contact: 'Please apply here or email talent@intelliprogroup.com',
        company: 'IntelliPro',
    },
    {
        id: 6,
        name: 'Entry Level Technical Recruiter',
        aboutUs: 'Founded in 2009, IntelliPro is one of the fastest-growing talent acquisition and HR solutions firms with a high-quality commitment to continue delivering high-quality service to our clients. We develop and support our employees to reach their career goals and build long-lasting and trusted relationships with our business partners. We now have business in the USA, China, Canada, Singapore, Philippines, India, UK, Netherlands, and Germany. ',
        customContents: [
            {
                subtitle: "Responsibilities:",
                summary: "",
                contents: [
                    `Build strong relationships with candidates, colleagues, and account managers`,
                    `Conduct and improve the end-to-end recruitment procedure and align with key stakeholders`,
                    `On top of traditional ways of publishing job ads on careers pages, job boards, and social media, expected to be creative with the campaigns`,
                    `Build an effective network with candidates through different channels and approaches to enhance the company talent database`
                ]
            },
            {
                subtitle: "Requirements:",
                summary: "",
                contents: [
                    `Bachelor’s degree or above is preferred`,
                    `Passion for making connections and helping others.  Agency experience is a plus.`,
                    `Fast learner and ambitions`,
                    `Ability to work in a fast-paced and high-pressure environment with multi-task delivery`,
                    `Excellent communication and interpersonal skills `]
            },
            {
                subtitle: "What we offer",
                summary: "",
                contents: [
                    `Fast-track professional training programs`,
                    `Fast-track professional training programs`,
                    `Working from home with flexible working schedules`,
                    `Diverse, and inclusive environment`,
                    `Global, dynamic teams`
                ]
            }
        ],
        type: 'Full-time Permanent',
        location: 'Remote in the US, CAN, UK, and Globally',
        locationDetail: 'Remote in the US, CAN, UK, and Globally',
        salary: '$32.5k - $45.3k base and Commission',
        contact: 'Please apply here or email talent@intelliprogroup.com',
        company: 'IntelliPro',
    },
    {
        id: 7,
        name: 'Business Development Director',
        aboutUs: 'Founded in 2009, IntelliPro is one of the fastest-growing talent acquisition and HR solutions firms with a high-quality commitment to continue delivering high-quality service to our clients. We develop and support our employees to reach their career goals and build long-lasting and trusted relationships with our business partners. We now have business in the USA, China, Canada, Singapore, Philippines, India, UK, Netherlands, and Germany. ',
        customContents: [
            {
                subtitle: 'Responsibilities:',
                summary: '',
                contents: [
                    `Handling and developing big projects while managing key accounts`,
                    `Developing new business through advertisements, agencies, industry media, referrals, networking, online research, outbound emails, outbound calls, and other channels`,
                    `Developing and building a relationship with new contacts in select markets`,
                    `Qualifying leads from marketing campaigns as sales opportunities`,
                    `Understanding market trends to identify potential areas for new businesses`,
                    `Researching and updating the contact details of potential customers in the customer database`,
                    `Communicating effectively, establishing and fostering relationships with clients and account executives`,
                    `Assisting in the consultative sales process by learning the industry, uncovering their business needs, and sharing that information with the sales team`,
                    `Utilizing our ATS and sales acceleration tools to support the sales team in managing the pipeline and maintaining accurate information about prospects and target accounts`,
                    `Meeting and striving to exceed monthly quarterly and yearly goals`
                ]
            },
            {
                subtitle: 'Requirements:',
                summary: '',
                contents: [
                    `5+ years of experience doing B2B business development in staffing/recruitment agency`,
                    `Demonstrated excellent performance record of winning larger projects, +10M`,
                    `Experience with handling key accounts; has ideally worked with Fortune 500 clients`,
                    `Ability to work in a fast-paced and high-pressure environment and competing priorities`,
                    `Hands-on experience with multiple sales techniques`,
                    `Understanding of sales performance metrics`,
                    `Track record of supporting sales team to achieve sales quotas`,
                    `Excellent communication and negotiation skills`,
                    `Excellent organizational skills to meet goals and set priorities`,
                    `Able to work independently in an entrepreneurial, start-up environment`,
                    `Bachelor's degree or equivalent experience required`,
                    `Ability to mentor, coach, and provide direction to a team of employees`,
                    `Excellent leadership, communication and interpersonal skcommunication`

                ]
            }
        ],
        type: 'Full-time Permanent',
        location: 'Remote in the SF Bay Area',
        locationDetail: 'Remote in the SF Bay Area',
        salary: '$80k -200k base and Commission',
        contact: 'Please apply here or email talent@intelliprogroup.com',
        company: 'IntelliPro',
    },
    {
        id: 8,
        name: 'Business Development Manager',
        aboutUs: 'Founded in 2009, IntelliPro is one of the fastest-growing talent acquisition and HR solutions firms with a high-quality commitment to continue delivering high-quality service to our clients. We develop and support our employees to reach their career goals and build long-lasting and trusted relationships with our business partners. We now have business in the USA, China, Canada, Singapore, Philippines, India, UK, Netherlands, and Germany. ',
        customContents: [
            {
                subtitle: 'Responsibilities:',
                summary: '',
                contents: [
                    `Develop new business through advertisements, agencies, industry media, referrals, networking, online research, outbound emails, outbound cal, ls and, other channels`,
                    `Develop and build relationships with new contacts in select markets`,
                    `Communicate effectively, establish and foster relationships with clients and account executives`,
                    `Assist in the consultative sales process by learning about the industry, uncovering their business needs, and sharing that information with the sales team`,
                    `Meet and strive to exceed monthly, quarterly, and yearly goals`
                ]
            },
            {
                subtitle: 'Requirements:',
                summary: '',
                contents: [
                    `3+ years of experience doing B2B business development in staffing/recruitment agency`,
                    `Demonstrate an excellent performance record of completing larger projects`,
                    `Excellent communication and negotiation skills`,
                    `Excellent organizational skills to meet goals and set priorities`,
                    `Able to work independently in an entrepreneurial, start-up environment`,
                    `Bachelor's degree or equivalent experience required`,

                ]
            }
        ],
        type: 'Full-time Permanent',
        location: 'Remote in the US',
        locationDetail: 'Remote in the US',
        salary: '$60k -150 base and Commission',
        contact: 'Please apply here or email talent@intelliprogroup.com',
        company: 'IntelliPro',
    },
    {
        id: 9,
        name: '360 Recruiter',
        aboutUs: 'Founded in 2009, IntelliPro is one of the fastest-growing talent acquisition and HR solutions firms with a high-quality commitment to continue delivering high-quality service to our clients. We develop and support our employees to reach their career goals and build long-lasting and trusted relationships with our business partners. We now have business in the USA, China, Canada, Singapore, Philippines, UK, India, Ireland, Netherlands, France and Germany.',
        customContents: [
            {
                subtitle: 'Responsibilities:',
                summary: '',
                contents: [
                    `Establish a strong relationship with candidates, hiring managers, and clients`,
                    {
                        summary: `Build an effective network with candidates through different channels and approaches to enhance the company talent database`,
                        detailList: [
                            `Create methods effectively to execute talent competencies and potential`,
                            `Proactively build talent pipelines to meet future development requirements`
                        ],
                    },
                    `Conduct and improve the end-to-end recruitment procedure and align with client needs`,
                    `Use business development techniques such as referrals, networking, and cold-calling to generate business leads`,
                    `Build lasting relationships with candidates, clients, and colleagues`
                ]
            },
            {
                subtitle: 'Requirements:',
                summary: '',
                contents: [
                    `Experience with various interview formats, including phone screening calls, in-person interviews, group interviews, potential assessments, etc.`,
                    `Able to be creative and find "Purple Squirrel" effectively and fast`,
                    {
                        summary: `Ability to work in a fast-paced and high-pressure environment with multi-task delivery`,
                        detailList: [
                            `Strong follow-up, follow through`,
                            `Sense of urgency and ownership`,
                            `Enjoys collaboration and sharing ideas`
                        ],
                    },
                    `Able to actively mix sourcing, resume review, interviewing, and closing candidates`,
                    `Works with ambiguity, asks questions, and builds trust`,
                    `Ability to effectively and comfortably communicate with stakeholders at all levels, from entry to senior`,
                    `Prefer Bachelor’s degree or above `,

                ]
            },
            {
                subtitle: "What we offer",
                summary: '',
                contents: [
                    `Fast-track professional training programs`,
                    `Work from home with flexible working schedules`,
                    `Diverse, equity and inclusive environment`,
                    `Global, dynamic teams`
                ]
            }
        ],
        type: 'Full-time Permanent',
        location: 'Remote in the UK, Germany, Ireland, Netherlands and Canada',
        locationDetail: 'Remote in the UK, Germany, Ireland, Netherlands and Canada',
        salary: 'Depends on experience',
        contact: 'Please apply here or email talent@intelliprogroup.com',
        company: 'IntelliPro',
    },
    {
        id: 10,
        name: "Business Engagement Manager",
        aboutUs: 'Founded in 2009, IntelliPro is one of the world\'s fastest-growing talent acquisition and HR solutions firms. We commit to continue delivering high-quality service to our clients. We develop and support our employees to reach their career goals. We also build long-lasting and trusted relationships with our business partners. We now have business in the USA, China, Canada, Singapore, Philippines, India, UK, Netherlands, and Germany.',
        customContents: [
            {
                subtitle: 'About the role:',
                summary: 'The Business Engagement Manager role is responsible for business development and sales of Intellipro\'s entire suite of workforce solutions to prospective clients across multiple geographies. This is a professional sales executive experienced in industry vertical and or MSP sales and understands the issues their prospective clients are facing and brings disruptive insight that challenges their thinking.',
            },
            {
                subtitle: "Duties and Responsibilities",
                summary: '',
                contents: [
                    `Increase the number of new opportunities in the sales pipeline, effectively drive the opportunities through the funnel, to a closed contract.`,
                    `Owns and is accountable for the entire sales cycle while coordinating and or engaging support from appropriate internal stakeholders to execute against pursuit strategies when necessary.`,
                    `Influence decisions of high-level stakeholders to create a win/win opportunity for Intellipro and the client.`,
                    `Apply the knowledge of labor, community, and industry awareness to a business development opportunity, and educate the client while bringing innovative ideas to the table.`,
                    `Work closely and consult with partners in operations, product groups, and support groups to leverage Intellipro’s capabilities and close sales.`,
                    `Develop client and industry networks that are both deep and wide to create demonstrated, active relationships within these networks.`,
                    `Continue to be knowledgeable of competitor positions.`,
                    `Responsible for managing pipelines within Salesforce.com and reporting to management.`
                ]
            },
            {
                subtitle: "Qualifications",
                summary: '',
                contents: [
                    `3+ years' experience in proven sales success in a consultative and professional services environment.`,
                    `Selling deals across the Americas may include multiple solutions.`
                ]

            }
        ],
        type: 'Full-time Permanent',
        location: 'Remote in the US',
        locationDetail: 'Remote in the US',
        salary: '$65k -80k base and Commission',
        contact: 'Please apply via email to talent@intelliprogroup.com',
        company: 'IntelliPro',
    },
    {
        id: 11,
        name: "Human Resources Specialist",
        customContents: [
            {
                subtitle: 'DUTIES:',
                summary: `Develop targeted sourcing strategies, conduct market and competitor research, build a comprehensive database of potential candidates, work with HRMS and ATS. Specific duties include: Build strong relationships with CEO, Regional Business Directors and leaders to anticipate talent demand, develop hiring strategies, and execute the talent hire in an effective manner to meet the rapid growing needs of the company. Conduct and improve the end-to-end recruitment procedure and align with key stakeholders. On top of traditional ways of publishing job ads on careers pages, job boards and social media, the candidate is expected to be creative with the campaigns. Build an effective network with candidates through different channels and approaches to enhance the company talent database. Create methodology to effectively evaluate candidates' competences and potential, proactively build talent pipelines to meet future development requirements. `
            },
            {
                subtitle: "JOB REQUIREMENT:",
                summary: `Bachelor’s degree with a focus/concentration/major in HR management or a related field plus 1 year experience. Foreign degree equivalent based on education, training, and/or experience is acceptable. Must have experience in developing targeted sourcing strategies, conducting market and competitor research, building a comprehensive database of potential candidates, working with HRMS and ATS.  `,
            },
        ],
        type: 'Full-time Permanent',
        location: 'Santa Clara, CA',
        locationDetail: '3120 Scott Boulevard, Suite 301, Santa Clara, CA 95054.',
        salary: '$68,474.00 per year',
        contact: 'E-mail resume referencing job code# 724JT to Intellipro Group, Inc. at intelliprotalents@intelliprogroup.com.',
        company: 'IntelliPro',
        code: '724JT',

    },
]



export default Jobs;