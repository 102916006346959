import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { Color } from '../../assets/color';



const style = theme => ({
    header: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: Color.intelligentBlue,
        [theme.breakpoints.up('mob')]: {
            height: 200,
            padding: '0px 24px',
        },
        [theme.breakpoints.up('tabletv')]: {
            height: 288,
            padding: '0px 32px'
        },
        [theme.breakpoints.up('tableth')]: {
            height: 384,
            padding: '0px 32px'
        },
        [theme.breakpoints.up('desktop')]: {
            height: 480,
            padding: '0px 120px'
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '0px 240px',
        },
    },
    main: {
        [theme.breakpoints.up('mob')]: {
            padding: '0px 24px',
            marginTop: 122,
        },
        [theme.breakpoints.up('tabletv')]: {
            padding: '0px 32px'
        },
        [theme.breakpoints.up('jobDetail')]: {
            marginTop: 0,
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '0px 32px'
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '0px 120px'
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '0px 240px',
        },
    },
    block: {
        display:'flex', 
        flexDirection:'row', 
        justifyContent:'space-between', 
    },
    cardBlock: {
        display:'flex',
        flexDirection:'column',
        [theme.breakpoints.up('mob')]: {
            padding: '80px 0px',
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '120px 0px',
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '200px 0px',
        },
    },
    para: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
        },
        [theme.breakpoints.up('desktop')]: {
            maxWidth: 1024
        },
    },
    subTitle: {
        [theme.breakpoints.up('mob')]: {
           paddingTop: 40,
           paddingBottom: 32
        },
        [theme.breakpoints.up('tableth')]: {
            paddingTop: 60
        },
        [theme.breakpoints.up('desktop')]: {
            paddingTop: 120,
            paddingBottom: 48
        },
    },
    subPara: {
        [theme.breakpoints.up('mob')]: {
            paddingBottom: 32
         },
         [theme.breakpoints.up('desktop')]: {
             paddingBottom: 48
         },
    }
})

class Term extends React.Component {

    constructor() {
        super()
    }

    render() {

        const{classes} = this.props;

        return(
            <div>
                <div className={classes.header}>
                        <div className={`h1`} style={{color: Color.white}} role="heading" aria-level="1">Terms Of Use</div>
                </div>
                <div className={classes.main}>
                    <div className={`${classes.cardBlock} ${classes.block}`}>
                        <div className={classes.para}>
                            <p className="term">
                                This website, located at https://www.intelliprogroup.com/ (the "Site"), is provided by IntelliPro Group and its affiliates (“IntelliPro Group,” "we," "our," or "us") to the person accessing the Site ("you," or "your").
                            </p>
                            <h2 className={classes.subTitle}>
                                1. You Agree to These Terms by Using the Site
                            </h2>
                            <p className="term">
                                Your access to, and use of, the Site is subject to the following Website Terms of Use and all applicable laws and regulations. By accessing and using the Site, you accept, without limitation or qualification, these Terms of Use. IntelliPro Group may, at any time and without notice, modify these Terms of Use by revising them on the Site. Your continued use of the Site constitutes your acceptance of any such revisions. You should therefore periodically visit this page to review the current Terms of Use. You may not modify these Terms of Use except in a writing signed by IntelliPro Group. If you do not agree and accept, without limitation or qualification, these Terms of Use, you must exit the Site immediately.
                            </p>
                            <h2 className={classes.subTitle}>
                                2. Permitted Use of the Site
                            </h2>
                            <p className="term">
                                The information and materials on the Site are provided for general informational purposes for your personal, noncommercial use. You may use the Site solely for the purpose of learning about IntelliPro Group services or products. You may not use, reproduce or distribute the text, graphics, downloads, tools or any other content on the Site for any other purpose.
                            </p>
                            <h2 className={classes.subTitle}>
                                3. Notice; Electronic Communications
                            </h2>
                            <p className="term">
                                When you visit the Site or send e-mails to IntelliPro Group, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you by e-mail or by posting notices on the Site. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.
                            </p>
                            <h2 className={classes.subTitle}>
                                4. Privacy
                            </h2>
                            <p className="term">
                                IntelliPro Group 's Privacy Policy is incorporated and made part of these Terms of Use. You hereby agree to our Privacy Policy. Any personal data (for example, your name, address, telephone number or e-mail address) that you transmit to the Site by electronic mail or otherwise will be used by IntelliPro Group in accordance with the Privacy Policy. Any other communication or material you transmit to the Site, such as questions, comments, suggestions or the like, will be treated as non-confidential and non-proprietary.
                            </p>
                            <h2 className={classes.subTitle}>
                                5. Disclaimer – Warranty
                            </h2>
                            <p className={`term ${classes.subPara}`}>
                                The site, its content, materials, SERVICES and products on the site are provided "as is." To the fullest extent permissible by applicable law, IntelliPro Group HEREBY disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose and non-infringement.
                            </p>
                            <p className={`term`}>IntelliPro Group does not represent or warrant that the functions contained in the Site will be uninterrupted or error-free, that the defects will be corrected, or that the Site or the server that makes the Site available are free of viruses or other harmful components. IntelliPro Group does not make any warranties or representations regarding the use of the materials in the Site in terms of their correctness, accuracy, adequacy, usefulness, timeliness, reliability or otherwise. IntelliPro Group does not make any warranties or representations regarding the security of your personal information. Some states do not permit limitations or exclusions on warranties, so the above limitations may not apply to you.</p>
                            <h2 className={classes.subTitle}>
                                6. Indemnification
                            </h2>
                            <p className={`term`}>
                                You agree to indemnify, defend, and hold harmless IntelliPro Group and its affiliates, and their respective officers, directors, employees, agents, licensors and partners from and against all losses, expenses, damages and costs, including reasonable attorneys' fees, resulting from or arising out of any violation of these Terms of Use by you or any other person accessing the Site using your Internet account.
                            </p>
                            <h2 className={classes.subTitle}>
                                7. Limitations of Liability
                            </h2>
                            <p className={`term`}>
                                YOUR USE OF THE SITE IS AT YOUR OWN RISK. NEITHER IntelliPro Group, NOR ANY OF ITS SUBSIDIARIES, AFFILIATES, OFFICERS OR DIRECTORS, NOR ANY OF ITS AGENTS OR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SITE OR ITS CONTENT, ARE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR OTHER DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF THE SITE WHETHER BASED ON CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF ANY SUCH DAMAGES. Some jurisdictions do not permit limitations of liability, so the above limitations may not apply to you.
                            </p>
                            <h2 className={classes.subTitle}>
                                8. Third-Party Links
                            </h2>
                            <p className={`term ${classes.subPara}`}>
                                We may link to sites operated by third parties as a way to try and provide increased value to you. We have no control over these linked sites, all of which may have separate privacy and data collection practices, independent of and different from IntelliPro Group. These linked sites are only for your convenience and therefore you access them at your own risk. Without limiting the foregoing, IntelliPro Group expressly disclaims any responsibility for such sites.
                            </p>
                            <p className={`term`}>IntelliPro Group does not endorse the content, or any products or services available, on any third party sites. Nonetheless, we always want to protect the integrity of our Site, including any links on the Site. Therefore, we hope that you will provide us with feedback not only on our Site, but also on these third party sites that we may link to (including whether a specific link does not work).</p>
                            <h2 className={classes.subTitle}>
                                9. Trademarks
                            </h2>
                            <p className={`term`}>
                                IntelliPro Group trademarks may not be used without our consent on any product or service, except our own.
                            </p>
                            <h2 className={classes.subTitle}>
                                10. Copyright
                            </h2>
                            <p className={`term ${classes.subPara}`}>
                                Copyright © {new Date().getFullYear()}, IntelliPro Group, Inc.
                            </p>
                            <p className={`term`}>All content included on the Site, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of IntelliPro Group or its content suppliers and protected by copyright laws. The compilation of all content on the Site is the exclusive property of IntelliPro Group and protected by copyright laws. All software used on the Site is the property of IntelliPro Group or its software suppliers and protected by copyright and other laws. You agree not to change or delete any proprietary notices from materials printed or downloaded from the Site.</p>
                            <h2 className={classes.subTitle}>
                                11. Copyright Complaints
                            </h2>
                            <p className={`term`}>
                                IntelliPro Group respects the intellectual property of others. If you believe that your work has been copied in a way that constitutes copyright infringement, please review our Copyright Policy.
                            </p>
                            <h2 className={classes.subTitle}>
                                12. Export Control
                            </h2>
                            <p className={`term ${classes.subPara}`}>
                                Software and other materials downloaded or otherwise made available from the Site may be subject to United States Export Control. The United States Export Control laws prohibit the export of certain technical data and software to certain territories. No software from the Site may be downloaded or exported: into (or to a national or resident of) Cuba, Iraq, Libya, North Korea, Iran, Sudan, Syria, or any other country to which the United States has embargoed goods; or anyone on the United States Treasury Department's list of Specially Designated Nationals or the United States Commerce Department's Table of Deny Orders.
                            </p>
                            <p className={`term`}>IntelliPro Group does not authorize the downloading or exportation of any software or technical data from the Site to any jurisdiction prohibited by the United States Export Control Laws.</p>
                            <h2 className={classes.subTitle}>
                                13. Law and Jurisdiction
                            </h2>
                            <p className={`term`}>
                                These Terms of Use and your use of the Site are governed by the laws of the State of California, without regard to its choice of law provisions. The courts of general jurisdiction located within the State of California, will have exclusive jurisdiction over any and all disputes arising out of, relating to or concerning these Terms of Use and/or the Site or in which these Terms of Use and/or the Site are a material fact.
                            </p>
                            <h2 className={classes.subTitle}>
                                14. Questions and Feedback
                            </h2>
                            <p className={`term`}>
                                We welcome your questions, comments, and concerns about the Site. Please send any and all feedback pertaining to the Site to legal@intelliprogroup.com. If you choose to submit questions, comments, ideas, feedback or the like, you agree that we are free to use them for any purpose without any restriction or compensation to you. Our use of this information may include but is not limited to developing, marketing and selling products. By accepting your submission, IntelliPro Group does not give up any rights to use similar or related feedback, ideas, concepts, know-how, techniques or other information previously known to us, or developed by our employees, or obtained from sources other than you.
                            </p>
                        </div>  
                       
                    </div>
                </div>
            </div>
        )

    }

}

export default withStyles(style)(Term)