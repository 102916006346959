import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Clear from '@material-ui/icons/Clear';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Radio } from '@material-ui/core';
const styles = theme => ({
    box: {
        '& .MuiRadio-colorPrimary.Mui-checked': {
            color: '#377ded'
        },
        '& .MuiButton-containedPrimary': {
            background: '#377ded',
            textTransform: 'capitalize',
            fontFamily: 'SofiaPro',
        }
    },
    title: {
        fontSize: '56px',
        fontWeight: '800',
        color: '#333',
        letterSpacing: ' 0.56px',
        lineHeight: 1.14,
        fontFamily: 'SofiaPro',
        margin: '40px 0'
    },
    tip: {
        width: 940,
        objectFit: 'contain',
        fontFamily: 'SofiaPro',
        fontSize: '24px',
        lineHeight: 1.33,
        letterSpacing: '0.96px',
        color: '#5c5e6b',
        marginBottom: 20,
    },


    button: {
        padding: '10px 0px',
        width: '160px'
    },
    changeBox: {
        width: 830,
        border: 'solid 1.5px #e8e8e8',
        padding: 18,
        marginBottom: 32,
        display: 'flex',
        justifyContent: 'space-between'
    }
});

class EditDetilasFrom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstNameFlag: true,
            lastNameFlag: true,
            emailFlag: true,
            phoneFlag: true,
        };
    }



    handleSubmit = e => {
        const { fileInfo, firstName, lastName, email, phone } = this.props;
        const { emailFlag, phoneFlag, firstNameFlag, lastNameFlag } = this.state
        const profile = fileInfo && fileInfo.parseOutput || {};
        let emailContacts = profile && profile.contacts && profile.contacts.length > 0 && profile.contacts.filter((item) => {
            return item.type == 'EMAIL'
        })
        let phoneContacts = profile && profile.contacts && profile.contacts.length > 0 && profile.contacts.filter((item) => {
            return item.type == 'PHONE'
        })
        let fileEmail = (emailContacts && emailContacts.length > 0 && emailContacts[0].contact) || ''
        let filePhone = (phoneContacts && phoneContacts.length > 0 && phoneContacts[0].contact) || ''
        let fileFirstName = profile.firstName || ''
        let fileLastName = profile.lastName || ''
        let params = {
            firstName: firstNameFlag ? firstName : fileFirstName,
            lastName: lastNameFlag ? lastName : fileLastName,
            email: emailFlag ? email : fileEmail,
            phone: phoneFlag ? phone : filePhone,

        }
        this.props.editFrom(params)
    };

    onChangeFrom = (key, value) => {
        const { emailFlag, phoneFlag, firstNameFlag, lastNameFlag } = this.state
        switch (key) {
            case 'firstName':
                if (value != firstNameFlag) {
                    this.setState({
                        firstNameFlag: value
                    })
                }

                break;
            case 'lastName':
                if (value != lastNameFlag) {
                    this.setState({
                        lastNameFlag: value
                    })
                }
                break;
            case 'email':
                if (value != emailFlag) {
                    this.setState({
                        emailFlag: value
                    })
                }
                break;
            case 'phone':
                if (value != phoneFlag) {
                    this.setState({
                        phoneFlag: value
                    })
                }
                break;
            default:
                break;
        }
    }


    componentDidMount() {

    }

    componentWillUnmount() {

    }



    render() {
        const { classes, openEditTipFlag, fileInfo, firstName, lastName, email, phone, flagOption } = this.props;
        const { emailFlag, phoneFlag, firstNameFlag, lastNameFlag } = this.state
        const profile = fileInfo && fileInfo.parseOutput || {};
        let emailContacts = profile && profile.contacts && profile.contacts.length > 0 && profile.contacts.filter((item) => {
            return item.type == 'EMAIL'
        })
        let phoneContacts = profile && profile.contacts && profile.contacts.length > 0 && profile.contacts.filter((item) => {
            return item.type == 'PHONE'
        })
        let fileEmail = (emailContacts && emailContacts.length > 0 && emailContacts[0].contact) || ''
        let filePhone = (phoneContacts && phoneContacts.length > 0 && phoneContacts[0].contact) || ''
        let fileFirstName = profile.firstName || ''
        let fileLastName = profile.lastName || ''
        return (
            <Dialog
                open={openEditTipFlag}
                className={classes.box}
                maxWidth={940}
                onClose={() => { this.props.onClose() }}
            >
                <DialogTitle>
                    <div className={classes.title}>Update Contact Information</div>
                </DialogTitle>
                <DialogContent>

                    <div className={classes.tip}>We found contact information in your resume. If it looks right, we can update the field with your resume information.</div>


                    {
                        flagOption.firstNameFlag && (
                            <div className={classes.changeBox}>
                                <div>
                                    <p>First Name Entered</p>
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={firstNameFlag}
                                                onChange={e => {
                                                    this.onChangeFrom(
                                                        'firstName',
                                                        true
                                                    );
                                                }}
                                                color="primary"
                                            />}
                                        label={firstName || 'N/A'}
                                    />

                                </div>
                                <div>
                                    <p>First Name in Resume</p>

                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={!firstNameFlag}
                                                onChange={e => {
                                                    this.onChangeFrom(
                                                        'firstName',
                                                        false
                                                    );
                                                }}
                                                color="primary"
                                            />}
                                        label={fileFirstName || 'N/A'}
                                    />

                                </div>
                            </div>
                        )
                    }

                    {
                        flagOption.lastNameFlag && (
                            <div className={classes.changeBox}>
                                <div>
                                    <p>Last Name Entered</p>
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={lastNameFlag}
                                                onChange={e => {
                                                    this.onChangeFrom(
                                                        'lastName',
                                                        true
                                                    );
                                                }}
                                                color="primary"
                                            />}
                                        label={lastName || 'N/A'}
                                    />

                                </div>
                                <div>
                                    <p>Last Name in Resume</p>
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={!lastNameFlag}
                                                onChange={e => {
                                                    this.onChangeFrom(
                                                        'lastName',
                                                        false
                                                    );
                                                }}
                                                color="primary"
                                            />}
                                        label={fileLastName || 'N/A'}
                                    />

                                </div>
                            </div>
                        )
                    }


                    {
                        flagOption.phoneFlag && (
                            <div className={classes.changeBox}>
                                <div>
                                    <p>Phone Entered</p>
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={phoneFlag}
                                                onChange={e => {
                                                    this.onChangeFrom(
                                                        'phone',
                                                        true
                                                    );
                                                }}
                                                color="primary"
                                            />}
                                        label={phone || 'N/A'}
                                    />

                                </div>
                                <div>
                                    <p>Phone in Resume</p>
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={!phoneFlag}
                                                onChange={e => {
                                                    this.onChangeFrom(
                                                        'phone',
                                                        false
                                                    );
                                                }}
                                                color="primary"
                                            />}
                                        label={filePhone || 'N/A'}
                                    />

                                </div>
                            </div>
                        )
                    }


                    {
                        flagOption.emailFlag && (
                            <div className={classes.changeBox}>
                                <div>
                                    <p>Email Entered</p>
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={emailFlag}
                                                onChange={e => {
                                                    this.onChangeFrom(
                                                        'email',
                                                        true
                                                    );
                                                }}
                                                color="primary"
                                            />}
                                        label={email || 'N/A'}
                                    />


                                </div>
                                <div>
                                    <p>Email in Resume</p>
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={!emailFlag}
                                                onChange={e => {
                                                    this.onChangeFrom(
                                                        'email',
                                                        false
                                                    );
                                                }}
                                                color="primary"
                                            />}
                                        label={fileEmail || 'N/A'}
                                    />

                                </div>
                            </div>
                        )
                    }



                </DialogContent>

                <DialogActions style={{ padding: '20px', justifyContent: 'left' }}>
                    <Button
                        variant="contained" color="primary"
                        className={classes.button}
                        onClick={() => this.handleSubmit()}
                    >
                        Confirm
                    </Button>

                </DialogActions>
            </Dialog >
        );
    }
}

export default (withStyles(styles)(EditDetilasFrom));
