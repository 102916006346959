import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { Color } from '../assets/color';


const style = theme => ({
    outer:{
        [theme.breakpoints.up('mob')]: {
            width:'100%',
            height: 168
        },
        [theme.breakpoints.up('tabletv')]: {
            width: '50%',
            height: 264,

        },
        [theme.breakpoints.up('tableth')]: {
            width: '50%',
            height: 348,

        },
        [theme.breakpoints.up('desktop')]: {
            width: '33%',
            height: 352,
        },
        // [theme.breakpoints.up('largedesktop')]: {
        //     height: 376,
        //     width: '25%',
        // }
    },
    root: {
        backgroundColor: Color.lightBlue,
        boxShadow: 'none',
        borderRadius: 0,
        
        [theme.breakpoints.up('mob')]: {
            margin: '12px 0px',
        },
        [theme.breakpoints.up('tabletv')]: {
            margin: 12,
            height:'calc(100% - 24px)',
        },
        [theme.breakpoints.up('tableth')]: {
            margin: 16,
            height:'calc(100% - 32px)',
        },
        [theme.breakpoints.up('desktop')]: {
            margin: 20,
            height:'calc(100% - 40px)',
        }
    },
    cardContent:{
        display:'flex',
        [theme.breakpoints.up('mob')]: {
            padding: '32px 16px',
            flexDirection: 'row'
        },

        [theme.breakpoints.up('tabletv')]: {
            padding: 32,
            flexDirection: 'column'
        },

    },
    icon: {
        paddingBottom: 32,
        [theme.breakpoints.up('mob')]: {
            width: 64,
            paddingBottom: 0,
            paddingRight: '1.5%'
        },
        [theme.breakpoints.up('tabletv')]: {
            width: 80,
            paddingBottom: 8
        },
    }
})

const ServiceCard = props => {
    const { classes, icons, content } = props;

    return(
        <div className={classes.outer}>
            <Card classes={{root: classes.root}}>
                <div className={classes.cardContent}>
                    <img src={icons} alt="icon" className={classes.icon}></img>
                    <h2 style={{overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 4}}>{content}</h2>
                </div>
                
            </Card>
        </div>
        
    )
}

export default withStyles(style)(ServiceCard);