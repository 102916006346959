import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails, Divider } from '@material-ui/core';
import { Color } from '../../assets/color';
import SqrBtnMain from '../../components/squareBtnMain';
import HiringForm from '../../components/hiringForm';
import ServiceDetailSmallCard from '../../components/serviceDetailSmallCard';

import ServiceImg from '../../assets/service/Service.jpeg';
import ico_down from '../../assets/icons/ico_arrow_down.svg';
import human from '../../assets/icons/human.png';
import ico_fold from '../../assets/icons/ico_fold_in.svg';
import ico_recruitment from '../../assets/icons/ico_recruitment.svg';
import ico_business_process from '../../assets/icons/ico_business_process.svg';
import ico_manage_service from '../../assets/icons/ico_manage_service.svg';
import { Helmet } from 'react-helmet';

const style = theme =>({
    header:{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        [theme.breakpoints.up('mob')]: {
            height: 518
        },
        [theme.breakpoints.up('tabletv')]: {
            height: 480
        },
        [theme.breakpoints.up('tableth')]: {
            height: 640
        },
        [theme.breakpoints.up('desktop')]: {
            height: 800
        },
        [theme.breakpoints.up('largedesktop')]: {
            
        },
    },
    headerImg: {
        objectFit: 'cover',
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            height: 288
        },
        [theme.breakpoints.up('tabletv')]: {
            width: '50%',
            height: 480
        },
        [theme.breakpoints.up('tableth')]: {
            height: 640
        },
        [theme.breakpoints.up('desktop')]: {
            height: 800
        },
    },
    headerTitle: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
       
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            height: 288,
            justifyContent: 'center',
        },
        [theme.breakpoints.up('tabletv')]: {
            width: '50%',
            height: 480
        },
        [theme.breakpoints.up('tableth')]: {
            height: 640
        },
        [theme.breakpoints.up('desktop')]: {
            height: 800,
            justifyContent: 'flex-start',
        },
    },
    title:{
        [theme.breakpoints.up('mob')]: {
            padding: '60px 24px',
            width: 240
        },
        [theme.breakpoints.up('tabletv')]: {
            padding: '0px 32px',
            width: 'calc(100% - 64px)'
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '0px 32px',
            width: 360
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '208px 120px',
            width: '70%',
            maxWidth: 480
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '240px 240px 0px',
        },
    },
    serviceTitle: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            paddingBottom: 32,
        },
        [theme.breakpoints.up('desktop')]: {
            width:  '50%',
            paddingBottom: 0,
            maxWidth: 480
        },
        [theme.breakpoints.up('largedesktop')]: {
            width:  '50%',
            maxWidth: 600
        }
    },
    servicePara: {
        [theme.breakpoints.up('mob')]: {
            width: '100%'
        },
        [theme.breakpoints.up('desktop')]: {
            width:  '50%'
        }
    },
    button: {
        [theme.breakpoints.up('mob')]: {
            display:'none'
        },
        [theme.breakpoints.up('desktop')]: {
            display:'flex',
            position: 'absolute',
            bottom: 0,
            right: 0
        }
    },
    main: {
        [theme.breakpoints.up('mob')]: {
            padding: '0px 24px'
        },
        [theme.breakpoints.up('tabletv')]: {
            padding: '0px 32px'
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '0px 32px'
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '0px 120px'
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '0px 240px',
        },
    },
    block: {
        display:'flex', 
        flexDirection:'row', 
        justifyContent:'space-between', 
    },
    cardBlock: {
        display:'flex',
        flexDirection:'column',
        [theme.breakpoints.up('mob')]: {
            paddingTop: 80,
        },
        [theme.breakpoints.up('tableth')]: {
            paddingTop: 120
        },
        [theme.breakpoints.up('desktop')]: {
            paddingTop: 200,
        },
    },
    hrTitle: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
        },
        [theme.breakpoints.up('tableth')]: {
            width: '50%',
        },
    },
    hroBlock: {
        border: '1px solid #E8E8E8',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        [theme.breakpoints.up('mob')]: {
            marginTop: 40,
            padding: 24,
            width: 'calc(100%- 48px)',
        },
        [theme.breakpoints.up('tableth')]: {
            marginTop: 80,
            padding: 32,
            width: 'calc(100% - 64px)',
        },
    },
    hroTitle: {
        [theme.breakpoints.up('mob')]: {
            padding: '24px 0px'
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '32px 0px'
        },
    },
    hroPara:{
        [theme.breakpoints.up('mob')]: {
            paddingTop: 24
        },
        [theme.breakpoints.up('tableth')]: {
            paddingTop: 32
        },
        [theme.breakpoints.up('desktop')]: {
            paddingTop: 48
        },
    },
    hroCard:{
        [theme.breakpoints.up('mob')]: {
            paddingTop: 32,
            display: 'none',
        },
        [theme.breakpoints.up('tableth')]: {
            paddingTop: 48,
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
        },
    },
    hroAccordion: {
        [theme.breakpoints.up('mob')]: {
            paddingTop: 32,
            display: 'flex',
            flexDirection: 'column'
        },
        [theme.breakpoints.up('tableth')]: {
            paddingTop: 48,
            display: 'none',
        },
    },
    accordion: {
        boxShadow: 'none',
        '&::before': {
            backgroundColor: Color.white
        }
    },
    accordionList: {
        paddingInlineStart: 25.5
    },
    accordionDetail: {
        padding: 0
    },
    hroTags: {
        margin: '-16px 0px',
        minWidth: 320,
        display:'flex',
        position:'relative'
    },
    hroTagList: {
        padding: 0,
        margin: 0
    },
    hroTagDelimiter: {
        height: 392,
        border: '1px solid #E8E8E8'
    },
    hroTag: {
        padding: '16px 0px',
        display: 'flex',
        color: Color.darkGrey,
        fonrWeight: 600,
        fontSize: 24,
        letterSpacing: '0.01em',
        lineHeight: '32px',
    },
    tagSpan: {
        '&:hover': {
            cursor: 'pointer',
            color: Color.intelligentBlue
        },
    },
    slide:{
        height: 24,
        width: 0,
        border: '4px solid #377DED',
        position:'absolute',
        right: 0,
        transition:'bottom 0.5s'
    },
    hroContentList: {
        transition:'display .8s'
    },
    hroList: {
       margin: '-4px 0px',
    },
    hro: {
        color: Color.midGrey,
        fontWeight: 400,
        fontSize: 24,
        letterSpacing: '0.04em',
        lineHeight: '32px',
        padding: '4px 0px',
        '&::marker': {
            color: Color.intelligentBlue
        }
    },
    hrosquare: {
        color: Color.midGrey,
        fontWeight: 400,
        fontSize: 24,
        letterSpacing: '0.04em',
        lineHeight: '32px',
        listStyleType:'square',
        '&::marker': {
            color: Color.intelligentBlue,

        }
    },
    serviceCardBlock: {
        [theme.breakpoints.up('mob')]: {
            margin: '12px -14px'
        },
        [theme.breakpoints.up('tableth')]: {
            margin: '16px -18px'
        },
        [theme.breakpoints.up('desktop')]: {
            margin: '20px -22px'
        },
       
    },
    highlight: {
        color: Color.grey,
        backgroundColor: Color.lightBlue,
        padding: '10px 18px 10px 15px',
        marginTop: 40,
        marginBottom: 30,
        display: 'block !important'
    }
})

class Service extends React.Component {
    constructor() {
        super()
        this.state = {
            show: 0,
            showBPO: 0
        }
    }

    menuSelected = (active) => {
        this.setState({
            show: active
        })
    }  

    menuSelectedBPO = (active) => {
        this.setState({
            showBPO: active
        })
    }

    render() {
        const { classes, menuClicked, history } = this.props;
        
        return(
            <div>
                <Helmet>
                    <title>Managed Services</title>
                    <meta property="og:title" content="End-to-end services, high volume hiring, human resources outsourcing, payroll processing, payroll management, corporate tax filing, W2 administration, benefits administration, risk management, management consulting services"/>
                    <meta property="og:description" content="IntelliPro can optimize all your corporate end-to-end services including recruiting, and managing non-essential operations and processes. "/>
                    <meta name="description" content="IntelliPro can optimize all your corporate end-to-end services including recruiting, and managing non-essential operations and processes. "/>
                </Helmet>
                <div className={classes.header}>
                    <div className={classes.headerTitle}>
                        <title className={classes.title}>Managed Services You Can Trust</title>
                        <div className={classes.button}>
                            <a href='/service#form'>
                                <SqrBtnMain icons={ico_down} content={'Tell Us Your Needs'} />
                            </a>
                        </div>
                    </div>
                    <img src={ServiceImg} alt="Managed Services" className={classes.headerImg}></img>
                </div>
                <div className={classes.main}>
                <div className={`${classes.block} ${classes.cardBlock}`} style={{flexDirection:'row', flexWrap:'wrap', alignItems: 'flex-start'}}>
                    <div className={`h1 ${classes.serviceTitle}`} role="heading" aria-level="1">Elegant Solutions,<br/> End-to-End Service</div>
                    <p className={`normal ${classes.servicePara}`}>IntelliPro is more than equipped to conduct high-volume hiring, but we can also completely take over and optimize your most arduous and time-consuming business functions. We can handle all responsibilities related to recruiting or managing your non-core operations and processes.</p>
                </div>
                <div className={`${classes.block} ${classes.cardBlock}`} style={{ flexDirection:'column'}}>
                        <h1 className={classes.hrTitle}>Our Comprehensive HR Solutions</h1>
                       {/* <div className={classes.hroBlock}>
                           <div style={{display:'flex', flexWrap: 'wrap'}}>
                                <img src={ico_human} alt="icon_human_resource" style={{width: 80, height: 80}}></img>
                                <h2 className={`${classes.hroTitle}`}>Human Resources Outsourcing (HRO)</h2>
                           </div>
                           <p className={`normal ${classes.hroPara}`}>Setting up and managing human resources is hard. We make it a seamless and straightforward process by offering an array of easily tailored solutions. Our experienced team can consult on your most complicated HR problems or oversee your entire HR function and all the countless complexities that come with it.</p>
                           <div className={classes.hroCard}>
                                <div className={classes.hroTags}>
                                    <ul className={classes.hroTagList}>
                                        <li className={classes.hroTag} style={this.state.show === 0 ? { color: Color.intelligentBlue } : { color: Color.darkGrey }} onClick={() => this.menuSelected(0)}>
                                            <span className={classes.tagSpan}>Payroll Administration</span>
                                        </li>
                                        <li className={classes.hroTag} style={this.state.show === 1 ? { color: Color.intelligentBlue } : { color: Color.darkGrey }} onClick={() => this.menuSelected(1)}>
                                            <span className={classes.tagSpan}>Benefits Administration</span>
                                        </li>
                                        <li className={classes.hroTag} style={this.state.show === 2 ? { color: Color.intelligentBlue } : { color: Color.darkGrey }} onClick={() => this.menuSelected(2)}>
                                            <span className={classes.tagSpan}>Risk Management</span>
                                        </li>
                                        <li className={classes.hroTag} style={this.state.show === 3 ? { color: Color.intelligentBlue } : { color: Color.darkGrey }} onClick={() => this.menuSelected(3)}>
                                            <span className={classes.tagSpan}>Online HR Platforms</span>
                                        </li>
                                    </ul>
                                    <div 
                                        className={classes.slide} 
                                        style={this.state.show === 0 ? {bottom: 208} : this.state.show === 1 ? {bottom:144} : this.state.show === 2 ? { bottom:80} : {bottom: 16}}
                                    ></div>
                                </div>
                                <div className={classes.hroTagDelimiter}></div>
                                <div className={classes.hroContentList} style={this.state.show === 0 ? {display: 'flex'} : {display: 'none'}}>
                                    <ul className={classes.hroList}>
                                        <li className={classes.hro}>Payroll processing and management</li>
                                        <li className={classes.hro}>Online paystubs</li>
                                        <li className={classes.hro}>Federal, state, and local  employment tax filing and remittance</li>
                                        <li className={classes.hro}>W-2 administration</li>
                                        <li className={classes.hro}>Wage garnishment</li>
                                        <li className={classes.hro}>Payroll reporting</li>
                                        <li className={classes.hro}>Direct deposit</li>
                                    </ul>
                                </div>
                                <div className={classes.hroContentList} style={this.state.show === 1 ? {display: 'flex'} : {display: 'none'}}>
                                    <ul className={classes.hroList}>
                                        <li className={classes.hro}>Medical (PPO, HMO, HDHP)</li>
                                        <li className={classes.hro}>Dental</li>
                                        <li className={classes.hro}>Vision</li>
                                        <li className={classes.hro}>Health Savings Account administration</li>
                                        <li className={classes.hro}>Life/AD&D</li>
                                        <li className={classes.hro}>Short-term disability</li>
                                        <li className={classes.hro}>COBRA administration</li>
                                        <li className={classes.hro}>401k setup and administration</li>
                                        <li className={classes.hro}>Payroll contributions</li>
                                        <li className={classes.hro}>Benefits enrollment and administrative access</li>
                                        <li className={classes.hro}>Employee Assistance Program (EAP)</li>
                                        <li className={classes.hro}>Commuter benefits</li>
                                    </ul>
                                </div>
                                <div className={classes.hroContentList} style={this.state.show === 2 ? {display: 'flex'} : {display: 'none'}}>
                                    <ul className={classes.hroList}>
                                        <li className={classes.hro}>Workers’ compensation</li>
                                        <li className={classes.hro}>Employment Practices Liability Insurance (EPLI)</li>
                                        <li className={classes.hro}>Compliance expertise for applicable federal/state employment laws</li>
                                        <li className={classes.hro}>Sexual harassment awareness training</li>
                                        <li className={classes.hro}>Unemployment insurance claim administration</li>
                                        <li className={classes.hro}>Claims management</li>
                                        <li className={classes.hro}>Affordable Care Act compliance</li>
                                        <li className={classes.hro}>Standard Termination guidance</li>
                                        <li className={classes.hro}>Workplace employment posters</li>
                                        <li className={classes.hro}>Wage and hour guidance</li>
                                    </ul>
                                </div>
                                <div className={classes.hroContentList} style={this.state.show === 3 ? {display: 'flex'} : {display: 'none'}}>
                                    <ul className={classes.hroList}>
                                        <li className={classes.hro}>Employee self-service platform</li>
                                        <li className={classes.hro}>Online onboarding</li>
                                        <li className={classes.hro}>Online benefits administration</li>
                                        <li className={classes.hro}>PTO tracking</li>
                                        <li className={classes.hro}>Database management and custom reporting</li>
                                        <li className={classes.hro}>Expense management</li>
                                        <li className={classes.hro}>Total comp statements</li>
                                        <li className={classes.hro}>HR reports (census, payroll, deductions, benefits, and more)</li>
                                    </ul>
                                </div>
                           </div>
                           <div className={classes.hroAccordion}>
                                <Accordion classes={{root: classes.accordion}}>
                                    <AccordionSummary style={{padding: 0}}
                                        expandIcon={<img src={ico_fold} alt="icons" style={{width:32}} />}
                                    >
                                        <p className={`normal`}>Payroll Administration</p>
                                    </AccordionSummary>
                                    <AccordionDetails classes={{root: classes.accordionDetail}}>
                                        <ul className={classes.accordionList}>
                                            <li className={classes.hrosquare}>Payroll processing and management</li>
                                            <li className={classes.hrosquare}>Online paystubs</li>
                                            <li className={classes.hrosquare}>Federal, state, and local  employment tax filing and remittance</li>
                                            <li className={classes.hrosquare}>W-2 administration</li>
                                            <li className={classes.hrosquare}>Wage garnishment</li>
                                            <li className={classes.hrosquare}>Payroll reporting</li>
                                            <li className={classes.hrosquare}>Direct deposit</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion classes={{root: classes.accordion}}>
                                    <AccordionSummary style={{padding: 0}}
                                        expandIcon={<img src={ico_fold} alt="icons" style={{width:32}} />}
                                    >
                                        <p className={`normal`}>Benefits Administration</p>
                                    </AccordionSummary>
                                    <AccordionDetails classes={{root: classes.accordionDetail}}>
                                            <ul className={classes.accordionList}>
                                            <li className={classes.hrosquare}>Medical (PPO, HMO, HDHP)</li>
                                            <li className={classes.hrosquare}>Dental</li>
                                            <li className={classes.hrosquare}>Vision</li>
                                            <li className={classes.hrosquare}>Health Savings Account administration</li>
                                            <li className={classes.hrosquare}>Life/AD&D</li>
                                            <li className={classes.hrosquare}>Short-term disability</li>
                                            <li className={classes.hrosquare}>COBRA administration</li>
                                            <li className={classes.hrosquare}>401k setup and administration</li>
                                            <li className={classes.hrosquare}>Payroll contributions</li>
                                            <li className={classes.hrosquare}>Benefits enrollment and administrative access</li>
                                            <li className={classes.hrosquare}>Employee Assistance Program (EAP)</li>
                                            <li className={classes.hrosquare}>Commuter benefits</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion classes={{root: classes.accordion}}>
                                    <AccordionSummary style={{padding: 0}}
                                        expandIcon={<img src={ico_fold} alt="icons" style={{width:32}} />}
                                    >
                                        <p className={`normal`}>Risk Management</p>
                                    </AccordionSummary>
                                    <AccordionDetails classes={{root: classes.accordionDetail}}>
                                        <ul className={classes.accordionList}>
                                            <li className={classes.hrosquare}>Workers’ compensation</li>
                                            <li className={classes.hrosquare}>Employment Practices Liability Insurance (EPLI)</li>
                                            <li className={classes.hrosquare}>Compliance expertise for applicable federal/state employment laws</li>
                                            <li className={classes.hrosquare}>Sexual harassment awareness training</li>
                                            <li className={classes.hrosquare}>Unemployment insurance claim administration</li>
                                            <li className={classes.hrosquare}>Claims management</li>
                                            <li className={classes.hrosquare}>Affordable Care Act compliance</li>
                                            <li className={classes.hrosquare}>Standard Termination guidance</li>
                                            <li className={classes.hrosquare}>Workplace employment posters</li>
                                            <li className={classes.hrosquare}>Wage and hour guidance</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion classes={{root: classes.accordion}}>
                                    <AccordionSummary style={{padding: 0}}
                                        expandIcon={<img src={ico_fold} alt="icons" style={{width:32}} />}
                                    >
                                        <p className={`normal`}>Online HR Platforms</p>
                                    </AccordionSummary>
                                    <AccordionDetails classes={{root: classes.accordionDetail}}>
                                        <ul className={classes.accordionList}>
                                            <li className={classes.hrosquare}>Employee self-service platform</li>
                                            <li className={classes.hrosquare}>Online onboarding</li>
                                            <li className={classes.hrosquare}>Online benefits administration</li>
                                            <li className={classes.hrosquare}>PTO tracking</li>
                                            <li className={classes.hrosquare}>Database management and custom reporting</li>
                                            <li className={classes.hrosquare}>Expense management</li>
                                            <li className={classes.hrosquare}>Total comp statements</li>
                                            <li className={classes.hrosquare}>HR reports (census, payroll, deductions, benefits, and more)</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                           </div>
                       </div> */}
                       <div className={classes.hroBlock}>
                            <div style={{display:'flex', flexWrap: 'wrap'}}>
                                    <img src={ico_recruitment} alt="icon_recruitment" style={{width: 80, height: 80}}></img>
                                    <h2 className={`${classes.hroTitle}`}>Recruitment Process Outsourcing (RPO)</h2>
                            </div>
                            <p className={`normal ${classes.hroPara}`}>Save time, money and the headaches of hiring with IntelliPro’s recruiting process outsourcing (RPO) program. This is what we do every hour of the working week (and then some!). Our holistic hiring service is designed with the singular goal of finding the best candidates who can help your company achieve its goals and add to the culture of your organization. We do this at scale and handle the whole talent acquisition lifecycle.</p>
                            <div className={classes.hroCard}>
                                    <div className={classes.hroTags}>
                                        <ul className={classes.hroTagList}>
                                            <li className={classes.hroTag} style={{ color: Color.intelligentBlue }} onClick={() => this.menuSelected(0)}>
                                                <span className={classes.tagSpan}>What We Offer</span>
                                            </li>
                                        </ul>
                                        <div 
                                            className={classes.slide} 
                                            style={{bottom: 16}}
                                        ></div>
                                    </div>
                                    <div className={classes.hroTagDelimiter}></div>
                                    <div className={classes.hroContentList} style={{display: 'flex'}}>
                                        <ul className={classes.hroList}>
                                            <li className={classes.hro}>Mutually arranged service-level agreements</li>
                                            <li className={classes.hro}>Thorough examination of your brand</li>
                                            <li className={classes.hro}>Review of benefits package</li>
                                            <li className={classes.hro}>Pre-screened, highly qualified candidates</li>
                                            <li className={classes.hro}>Manage preliminary testing process (e.g., HackerRank)</li>
                                            <li className={classes.hro}>Oversee candidate experience</li>
                                            <li className={classes.hro}>An applicant tracking system partnership to fit your needs</li>
                                        </ul>
                                    </div>
                                    
                            </div>
                            <div className={classes.hroAccordion}>
                                    <Accordion classes={{root: classes.accordion}}>
                                        <AccordionSummary style={{padding: 0}}
                                            expandIcon={<img src={ico_fold} alt="icons" style={{width:32}} />}
                                        >
                                            <p className={`normal`}>What We Offer</p>
                                        </AccordionSummary>
                                        <AccordionDetails classes={{root: classes.accordionDetail}}>
                                            <ul className={classes.accordionList}>
                                                <li className={classes.hrosquare}>Mutually arranged service-level agreements</li>
                                                <li className={classes.hrosquare}>Thorough examination of your brand</li>
                                                <li className={classes.hrosquare}>Review of benefits package</li>
                                                <li className={classes.hrosquare}>Pre-screened, highly qualified candidates</li>
                                                <li className={classes.hrosquare}>Manage preliminary testing process (e.g., HackerRank)</li>
                                                <li className={classes.hrosquare}>Oversee candidate experience</li>
                                                <li className={classes.hrosquare}>An applicant tracking system partnership to fit your needs</li>
                                            </ul>
                                        </AccordionDetails>
                                    </Accordion>
                            </div>
                       </div>
                       <div className={classes.hroBlock}>
                            <div style={{display:'flex', flexWrap: 'wrap'}}>
                                    <img src={ico_business_process} alt="icon_business_process" style={{width: 80, height: 80}}></img>
                                    <h2 className={`${classes.hroTitle}`}>Business Process Outsourcing (BPO)</h2>
                            </div>
                            <p className={`normal ${classes.hroPara}`}>Ready to relieve the stress of back-office tasks? IntelliPro’s BPO program offers an efficient, cost-effective strategy for gaining scalable subject-matter expertise and 24/7 support. We believe communication is the key to success and will work to understand your company’s specific goals and challenges and build a team with the right industry experience and skillset to fit your organizational needs.</p>
                                <div className={classes.hroCard}>
                                    <div className={classes.hroTags}>
                                        <ul className={classes.hroTagList}>
                                            <li className={classes.hroTag} style={this.state.showBPO === 0 ? { color: Color.intelligentBlue } : { color: Color.darkGrey }} onClick={() => this.menuSelectedBPO(0)}>
                                                <span className={classes.tagSpan}>IT Help Desk Support</span>
                                            </li>
                                            <li className={classes.hroTag} style={this.state.showBPO === 1 ? { color: Color.intelligentBlue } : { color: Color.darkGrey }} onClick={() => this.menuSelectedBPO(1)}>
                                                <span className={classes.tagSpan}>Content Moderation</span>
                                            </li>
                                            <li className={classes.hroTag} style={this.state.showBPO === 2 ? { color: Color.intelligentBlue } : { color: Color.darkGrey }} onClick={() => this.menuSelectedBPO(2)}>
                                                <span className={classes.tagSpan}>Administration Roles</span>
                                            </li>
                                            <li className={classes.hroTag} style={this.state.showBPO === 3 ? { color: Color.intelligentBlue } : { color: Color.darkGrey }} onClick={() => this.menuSelectedBPO(3)}>
                                                <span className={classes.tagSpan}>User Support</span>
                                            </li>
                                        </ul>
                                        <div 
                                            className={classes.slide} 
                                            style={this.state.showBPO === 0 ? {bottom: 208} : this.state.showBPO === 1 ? {bottom:144} : this.state.showBPO === 2 ? { bottom:80} : {bottom: 16}}
                                        ></div>
                                    </div>
                                    <div className={classes.hroTagDelimiter}></div>
                                    <div className={classes.hroContentList} style={this.state.showBPO === 0 ? {display: 'flex'} : {display: 'none'}}>
                                        <ul className={classes.hroList}>
                                            <li className={classes.hro}>Lowering transaction costs</li>
                                            <li className={classes.hro}>Fluctuating demand</li>
                                            <li className={classes.hro}>Staff turnover</li>
                                            <li className={classes.hro}>IT help desk support </li>
                                            <li className={classes.hro}>Process improvement</li>
                                            <li className={classes.hro}>24/7 coverage</li>
                                        </ul>
                                    </div>
                                    <div className={classes.hroContentList} style={this.state.showBPO === 1 ? {display: 'flex'} : {display: 'none'}}>
                                        <ul className={classes.hroList}>
                                            <li className={classes.hro}>Flexibility for coverage you need, when you need it</li>
                                            <li className={classes.hro}>Proven on-boarding and agent nesting services</li>
                                            <li className={classes.hro}>Moderators who love your brand</li>
                                            <li className={classes.hro}>Efficient workflow</li>
                                            <li className={classes.hro}>Workforce integrity with smart and talented moderators</li>
                                        </ul>
                                    </div>
                                    <div className={classes.hroContentList} style={this.state.showBPO === 2 ? {display: 'flex'} : {display: 'none'}}>
                                        <ul className={classes.hroList}>
                                            <li className={classes.hro}>Clerical and administrative BPO</li>
                                            <li className={classes.hro}>Administrative assistant coverage for multiple functions</li>
                                            <li className={classes.hro}>Dedicated engagement manager, if needed</li>
                                            <li className={classes.hro}>Process refinement</li>
                                            <li className={classes.hro}>Efficient and timely onboarding and offboarding</li>
                                        </ul>
                                    </div>
                                    <div className={classes.hroContentList} style={this.state.showBPO === 3 ? {display: 'flex'} : {display: 'none'}}>
                                        <ul className={classes.hroList}>
                                            <li className={classes.hro}>Customer Service BPO</li>
                                            <li className={classes.hro}>Multiple tiers of escalation</li>
                                            <li className={classes.hro}>Dedicated project management</li>
                                            <li className={classes.hro}>Solid brand representation in multiple languages</li>
                                        </ul>
                                    </div>
                            </div>
                            <div className={classes.hroAccordion}>
                                    <Accordion classes={{root: classes.accordion}}>
                                        <AccordionSummary style={{padding: 0}}
                                            expandIcon={<img src={ico_fold} alt="icons" style={{width:32}} />}
                                        >
                                            <p className={`normal`}>IT Help Desk Support</p>
                                        </AccordionSummary>
                                        <AccordionDetails classes={{root: classes.accordionDetail}}>
                                            <ul className={classes.accordionList}>
                                                <li className={classes.hrosquare}>Lowering transaction costs</li>
                                                <li className={classes.hrosquare}>Fluctuating demand</li>
                                                <li className={classes.hrosquare}>Staff turnover</li>
                                                <li className={classes.hrosquare}>IT help desk support</li>
                                                <li className={classes.hrosquare}>Process improvement</li>
                                                <li className={classes.hrosquare}>24/7 coverage</li>
                                            </ul>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion classes={{root: classes.accordion}}>
                                        <AccordionSummary style={{padding: 0}}
                                            expandIcon={<img src={ico_fold} alt="icons" style={{width:32}} />}
                                        >
                                            <p className={`normal`}>Content Moderation</p>
                                        </AccordionSummary>
                                        <AccordionDetails classes={{root: classes.accordionDetail}}>
                                                <ul className={classes.accordionList}>
                                                <li className={classes.hrosquare}>Flexibility for coverage you need, when you need it</li>
                                                <li className={classes.hrosquare}>Proven on-boarding and agent nesting services</li>
                                                <li className={classes.hrosquare}>Moderators who love your brand</li>
                                                <li className={classes.hrosquare}>Efficient workflow</li>
                                                <li className={classes.hrosquare}>Workforce integrity with smart and talented moderators</li>
                                            </ul>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion classes={{root: classes.accordion}}>
                                        <AccordionSummary style={{padding: 0}}
                                            expandIcon={<img src={ico_fold} alt="icons" style={{width:32}} />}
                                        >
                                            <p className={`normal`}>Administration Roles</p>
                                        </AccordionSummary>
                                        <AccordionDetails classes={{root: classes.accordionDetail}}>
                                            <ul className={classes.accordionList}>
                                                <li className={classes.hrosquare}>Clerical and administrative BPO</li>
                                                <li className={classes.hrosquare}>Administrative assistant coverage for multiple functions</li>
                                                <li className={classes.hrosquare}>Dedicated engagement manager, if needed</li>
                                                <li className={classes.hrosquare}>Process refinement</li>
                                                <li className={classes.hrosquare}>Efficient and timely onboarding and offboarding</li>
                                            </ul>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion classes={{root: classes.accordion}}>
                                        <AccordionSummary style={{padding: 0}}
                                            expandIcon={<img src={ico_fold} alt="icons" style={{width:32}} />}
                                        >
                                            <p className={`normal`}>User Support</p>
                                        </AccordionSummary>
                                        <AccordionDetails classes={{root: classes.accordionDetail}}>
                                            <ul className={classes.accordionList}>
                                                <li className={classes.hrosquare}>Customer Service BPO</li>
                                                <li className={classes.hrosquare}>Multiple tiers of escalation</li>
                                                <li className={classes.hrosquare}>Dedicated project management</li>
                                                <li className={classes.hrosquare}>Solid brand representation in multiple languages</li>
                                            </ul>
                                        </AccordionDetails>
                                    </Accordion>
                            </div>
                        </div>
                        {/* <div className={classes.hroBlock}>
                            <div style={{display:'flex', flexWrap: 'wrap'}}>
                                    <img src={ico_business_process} alt="icon_business_process" style={{width: 80, height: 80}}></img>
                                    <h2 className={`${classes.hroTitle}`}>HR services (PEO/ HRO / EOR)</h2>
                            </div>
                            <p className={`normal ${classes.hroPara}`}>We also offer different HR services models via our subsidiary company, Intellipro Services, our PEO, HRO, and EOR services are designed to fuel your global expansion.
                            </p>
                                <div className={classes.hroCard}>
                                    <div className={classes.hroTags}>
                                        <ul className={classes.hroTagList}>
                                            <li className={classes.hroTag} style={this.state.showBPO === 0 ? { color: Color.intelligentBlue } : { color: Color.darkGrey }} onClick={() => this.menuSelectedBPO(0)}>
                                                <span className={classes.tagSpan}>PEO Model</span>
                                            </li>
                                            <li className={classes.hroTag} style={this.state.showBPO === 1 ? { color: Color.intelligentBlue } : { color: Color.darkGrey }} onClick={() => this.menuSelectedBPO(1)}>
                                                <span className={classes.tagSpan}>HRO Model</span>
                                            </li>
                                            <li className={classes.hroTag} style={this.state.showBPO === 2 ? { color: Color.intelligentBlue } : { color: Color.darkGrey }} onClick={() => this.menuSelectedBPO(2)}>
                                                <span className={classes.tagSpan}>EOR Model</span>
                                            </li>
                                        </ul>
                                        <div 
                                            className={classes.slide} 
                                            style={this.state.showBPO === 0 ? {bottom: 144} : this.state.showBPO === 1 ? {bottom:80}  : {bottom: 16}}
                                        ></div>
                                    </div>
                                    <div className={classes.hroTagDelimiter}></div>
                                    <div className={classes.hroContentList} style={this.state.showBPO === 0 ? {display: 'flex'} : {display: 'none'}}>
                                        <ul className={classes.hroList}>
                                            <li className={classes.hro}> Let IntelliPro legally employs talents on behalf of you in 160+ countries and regions, helping you manage legal and HR responsibilities</li>
                                        </ul>
                                    </div>
                                    <div className={classes.hroContentList} style={this.state.showBPO === 1 ? {display: 'flex'} : {display: 'none'}}>
                                        <ul className={classes.hroList}>
                                            <li className={classes.hro}>Flexibility for coverage you need, when you need it</li>
                                            <li className={classes.hro}>Proven on-boarding and agent nesting services</li>
                                            <li className={classes.hro}>Moderators who love your brand</li>
                                            <li className={classes.hro}>Efficient workflow</li>
                                            <li className={classes.hro}>Workforce integrity with smart and talented moderators</li>
                                        </ul>
                                    </div>
                                    <div className={classes.hroContentList} style={this.state.showBPO === 2 ? {display: 'flex'} : {display: 'none'}}>
                                        <ul className={classes.hroList}>
                                        <li className={classes.hro}>EOR (Employer Of Record) model : Let IntelliPro legally employs talents on behalf of you in 160+ countries and regions, helping you manage legal and HR responsibilities</li>
                                        </ul>
                                    </div>
                                  
                            </div>
                            <div className={classes.hroAccordion}>
                                    <Accordion classes={{root: classes.accordion}}>
                                        <AccordionSummary style={{padding: 0}}
                                            expandIcon={<img src={ico_fold} alt="icons" style={{width:32}} />}
                                        >
                                            <p className={`normal`}>PEO Model</p>
                                        </AccordionSummary>
                                        <AccordionDetails classes={{root: classes.accordionDetail}}>
                                            <ul className={classes.accordionList}>
                                                <li className={classes.hrosquare}>Lowering transaction costs</li>
                                                <li className={classes.hrosquare}>Fluctuating demand</li>
                                                <li className={classes.hrosquare}>Staff turnover</li>
                                                <li className={classes.hrosquare}>IT help desk support</li>
                                                <li className={classes.hrosquare}>Process improvement</li>
                                                <li className={classes.hrosquare}>24/7 coverage</li>
                                            </ul>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion classes={{root: classes.accordion}}>
                                        <AccordionSummary style={{padding: 0}}
                                            expandIcon={<img src={ico_fold} alt="icons" style={{width:32}} />}
                                        >
                                            <p className={`normal`}>HRO Model</p>
                                        </AccordionSummary>
                                        <AccordionDetails classes={{root: classes.accordionDetail}}>
                                                <ul className={classes.accordionList}>
                                                <li className={classes.hrosquare}>Flexibility for coverage you need, when you need it</li>
                                                <li className={classes.hrosquare}>Proven on-boarding and agent nesting services</li>
                                                <li className={classes.hrosquare}>Moderators who love your brand</li>
                                                <li className={classes.hrosquare}>Efficient workflow</li>
                                                <li className={classes.hrosquare}>Workforce integrity with smart and talented moderators</li>
                                            </ul>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion classes={{root: classes.accordion}}>
                                        <AccordionSummary style={{padding: 0}}
                                            expandIcon={<img src={ico_fold} alt="icons" style={{width:32}} />}
                                        >
                                            <p className={`normal`}>EOR Model</p>
                                        </AccordionSummary>
                                        <AccordionDetails classes={{root: classes.accordionDetail}}>
                                            <ul className={classes.accordionList}>
                                                <li className={classes.hrosquare}>Clerical and administrative BPO</li>
                                                <li className={classes.hrosquare}>Administrative assistant coverage for multiple functions</li>
                                                <li className={classes.hrosquare}>Dedicated engagement manager, if needed</li>
                                                <li className={classes.hrosquare}>Process refinement</li>
                                                <li className={classes.hrosquare}>Efficient and timely onboarding and offboarding</li>
                                            </ul>
                                        </AccordionDetails>
                                    </Accordion>

                            </div>
                        </div> */}
                        <div className={classes.hroBlock}>
                            <div style={{display:'flex', flexWrap: 'wrap'}}>
                                    <img src={ico_manage_service} alt="icon_manage_service" style={{width: 80, height: 80}}></img>
                                    <h2 className={`${classes.hroTitle}`}>Management Consulting Services</h2>
                            </div>
                            <p className={`normal ${classes.hroPara}`}>Seeking specialized executive coaching to supercharge your ability to secure top talent? Utilizing our vast knowledge of AI, IntelliPro talent management consultants can conduct detailed analysis and formulate strategic plans to help you recruit and deploy the right talent, speed up your recruitment process and optimize the overall structure of your teams.</p>
                            <div className={classes.hroCard}>
                                    <div className={classes.hroTags}>
                                        <ul className={classes.hroTagList}>
                                            <li className={classes.hroTag} style={{ color: Color.intelligentBlue }} onClick={() => this.menuSelected(0)}>
                                                <span className={classes.tagSpan}>What We Offer</span>
                                            </li>
                                        </ul>
                                        <div 
                                            className={classes.slide} 
                                            style={{bottom: 16}}
                                        ></div>
                                    </div>
                                    <div className={classes.hroTagDelimiter}></div>
                                    <div className={classes.hroContentList} style={{display: 'flex'}}>
                                        <ul className={classes.hroList}>
                                            <li className={classes.hro}>Organizational design, structure and management improvement</li>
                                            <li className={classes.hro}>Talent management </li>
                                            <li className={classes.hro}>People analytics including industry skills and demographics</li>
                                            <li className={classes.hro}>Engineering optimization</li>
                                            <li className={classes.hro}>HR strategy including performance appraisals and compensation consulting</li>
                                            <li className={classes.hro}>Leadership development and coaching</li>
                                            <li className={classes.hro}>Product management</li>
                                        </ul>
                                    </div>
                                    
                            </div>
                            <div className={classes.hroAccordion}>
                                    <Accordion classes={{root: classes.accordion}}>
                                        <AccordionSummary style={{padding: 0}}
                                            expandIcon={<img src={ico_fold} alt="icons" style={{width:32}} />}
                                        >
                                            <p className={`normal`}>What We Offer</p>
                                        </AccordionSummary>
                                        <AccordionDetails classes={{root: classes.accordionDetail}}>
                                            <ul className={classes.accordionList}>
                                                <li className={classes.hrosquare}>Organizational design, structure and management improvement</li>
                                                <li className={classes.hrosquare}>Talent management</li>
                                                <li className={classes.hrosquare}>People analytics including industry skills and demographics</li>
                                                <li className={classes.hrosquare}>Engineering optimization</li>
                                                <li className={classes.hrosquare}>HR strategy including performance appraisals and compensation consulting</li>
                                                <li className={classes.hrosquare}>Leadership development and coaching</li>
                                                <li className={classes.hrosquare}>Product management</li>
                                            </ul>
                                        </AccordionDetails>
                                    </Accordion>
                            </div>
                        </div>
                        <div className={classes.hroBlock}>
                            <div style={{display:'flex', flexWrap: 'wrap', alignItems: 'center'}}>
                                    <img src={human} alt="icon_manage_service" style={{width: 65, height: 65, marginRight: 10}}></img>
                                    <h2 className={`${classes.hroTitle}`}>HR services</h2>
                            </div>
                            <p className={`normal ${classes.hroPara}`} >
                                We also offer different HR services models via our subsidiary company, Intellipro Services, including PEO model, HRO model and EOR model. 
                            </p>
                            <Divider variant="middle" style={{margin: '40px auto', width: '100%'}}/>
                            <div className={classes.hroContentList} style={{display: 'flex'}}>
                                <ul className={classes.hroList}>
                                    <li className={classes.hrosquare} style={{marginBottom: 15}}>PEO (Professional Employer Organization) model </li>
                                    <li className={classes.hrosquare} style={{marginBottom: 15}}>HRO (Human Resource Outsourcing) model </li>
                                    <li className={classes.hrosquare}>EOR (Employer Of Record) model</li>
                                </ul>
                            </div>

                            <p className={`normal ${classes.highlight}`}>
                                <span style={{marginRight: 3}}>{'For more information on the HR services model. Please visit'} </span>
                                <a href={'https://www.intelliproservices.com/'} style={{color: 'rgb(55, 125, 237)'}} target="_blank" rel="noopener noreferrer" aria-label="reference link">{'www.intelliproservices.com'}</a>
                            </p>
                        </div>
                       {/* <div className={`${classes.block} ${classes.serviceCardBlock}`} style={{ flexWrap:'wrap'}}>
                            <ServiceDetailSmallCard icons={ico_recruitment} title="Recruiting Process Outsourcing (RPO)" content="Tired of all the hiring hassles? We can handle it all. Hand off your entire recruitment process to us through our recruiting process outsourcing (RPO) program. This holistic hiring solution saves clients significant time, money and headaches."/>
                            <ServiceDetailSmallCard icons={ico_business_process} title="Business Process Outsourcing (BPO)" content="As a global firm, our BPO program is an efficient, cost-effective strategy for gaining scalable subject-matter expertise and 24/7 support. From IT help desk support and content moderation to payroll processing and administrative support, we can mange entire business units or processes for you. "/>
                            <ServiceDetailSmallCard icons={ico_manage_service} title="Management Consulting" content="Seeking specialized executive coaching to supercharge your ability to secure top talent? Utilizing our vast knowledge of AI, IntelliPro talent management consultants can conduct detailed analysis and formulate strategic plans to help you recruit and deploy the right talent, speed up your recruitment process and optimize the overall structure of your teams."/>
                        </div> */}
                </div>

                       
               
            </div>
            <HiringForm title={'Interested? Tell us your needs'} />
        </div>
        )
    }

}

export default withStyles(style)(withRouter(Service));