import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { Color } from '../assets/color';

const style = theme => ({
    root:{
        backgroundColor: Color.lightBlue,
        [theme.breakpoints.up('mob')]: {
            padding: '80px 24px',
            marginTop: 80,
        },
        [theme.breakpoints.up('tabletv')]: {
            padding: '80px 32px'
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '120px 32px',
            marginTop: 120,
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '200px 120px',
            marginTop: 200,
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '200px 240px',
        },
        display:'flex',
        flexDirection:'column',
    },
    recruitingTitle: {
        [theme.breakpoints.up('mob')]: {
            width: '100%'
        },
        [theme.breakpoints.up('tableth')]: {
            width: 600
        },
        [theme.breakpoints.up('desktop')]: {
            width: 480
        }
    },
    form: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        [theme.breakpoints.up('mob')]: {
            marginTop: 16
        },
        [theme.breakpoints.up('tabletv')]: {
            marginTop: 0
        },
        [theme.breakpoints.up('tableth')]: {
            marginTop: 20
        }
    },
    formInputField: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            padding: '24px 0px'
        },
        [theme.breakpoints.up('tabletv')]: {
            width: 'calc(50% - 20px)',
            padding: '60px 0px'
        },
        [theme.breakpoints.up('tableth')]: {
            width: 'calc(50% - 30px)'
        },
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'sofia-pro',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.02em',
        '& label': {
            fontWeight: 700,
            color: '#000000'
        },
        '& input': {
            borderRadius: 0,
            border: '2px solid #E8E8E8',
            height: 64,
            marginTop: 24,
            paddingLeft: 24,
            fontFamily: 'sofia-pro',
            fontSize: '16px',
            lineHeight: '24px',
            '&:hover': {
                border: '2px solid #377DED'
             },
            '&:focus': {
                border: '2px solid #377DED',
                boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)'
            },
            '&:active': {
                border: '2px solid #377DED',
            },
        }
    },
    messageField:{
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            padding: '24px 0px'
        },
        [theme.breakpoints.up('tabletv')]: {
            padding: '60px 0px'
        },
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'sofia-pro',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.02em',
    },
    messageBox: {
        [theme.breakpoints.up('mob')]: {
            height: 160
         },
         [theme.breakpoints.up('tabletv')]: {
             height: 288
         },
        borderRadius: 0,
        border: '2px solid #E8E8E8',
        marginTop: 24,
        padding: '20px 24px',
        fontFamily: 'sofia-pro',
        fontSize: '16px',
        lineHeight: '24px',
        '&:hover': {
            border: '2px solid #377DED'
         },
        '&:focus': {
            border: '2px solid #377DED',
            boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)'
        },
        '&:active': {
            border: '2px solid #377DED',
        },
    },
    button: {
        backgroundColor: Color.intelligentBlue,
        borderRadius: 0,
        border: 0,
        color: Color.white,
        fontFamily: 'sofia-pro',
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: 600,
        width: 240,
        height: 64,
        display: 'flex',
        flexDirection:'column',
        justifyContent:'center',
        boxShadow:'0px 2px 8px rgba(0, 0, 0, 0.08)',
        '&:active': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #377DED'
        },
        '&:focus': {
            background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #377DED'
        },
        '&:hover': {
            cursor: 'pointer',
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #377DED',
            boxShadow:'0px 8px 24px rgba(55, 125, 237, 0.1)',
        }
    }
})

class HiringForm extends React.Component {
    constructor() {
        super()
    }

    render() {

        const { classes, title } = this.props;

        const FormInputField = withStyles({
            root: {
                '& label': {
                    fontFamily: 'sofia-pro',
                    fontWeight: 700,
                    fontSize: '16px',
                    lineHeight: '24px',
                    letterSpacing: '0.02em',
                    color: '#000000'
                },
                '& .MuiInputBase-input': {
                    fontFamily: 'sofia-pro',
                    fontWeight: 700,
                    fontSize: '16px',
                    lineHeight: '24px',
                    letterSpacing: '0.02em',
                    color: '#333333'
                },
                '& .MuiOutlinedInput-root': {
                    borderRadius: 0
                }
            }
        })(TextField);

        return(
            <div className={classes.root}>
                <div className={`h1 ${classes.recruitingTitle}`} role="heading" aria-level="2">{title}</div>
                <form className={classes.form} id="form">
                    
                    <div className={classes.formInputField}>
                        <label htmlFor="firstname">
                            <span>First Name </span>
                            <span style={{color: Color.intelligentBlue}}>*</span>
                        </label>
                        <input type="text" required placeholder="First Name" id="firstname" name="firstname"></input>
                    </div>
                
                
                    <div className={classes.formInputField}>
                        <label htmlFor="lastname">
                            <span>Last Name </span>
                            <span style={{color: Color.intelligentBlue}}>*</span>
                        </label>
                        <input type="text" required placeholder="Last Name" id="lastname" name="lastname"></input>
                    </div>

                    <div className={classes.formInputField}>
                        <label htmlFor="company">
                            <span>Company</span>
                            <span style={{color: Color.intelligentBlue}}>*</span>
                        </label>
                        <input type="text" required placeholder="Company Name" id="company" name="company"></input>
                    </div>

                    <div className={classes.formInputField}>
                        <label htmlFor="email">
                            <span>Email</span>
                            <span style={{color: Color.intelligentBlue}}>*</span>
                        </label>
                        <input type="email" required placeholder="Email" id="email" name="email"></input>
                    </div>

                    <div className={classes.formInputField}>
                        <label htmlFor="phone">
                            <span>Phone Number</span>
                            <span style={{color: Color.intelligentBlue}}>*</span>
                        </label>
                        <input type="text" required placeholder="Phone Number" id="phone" name="phone"></input>
                    </div>
                    <div className={classes.messageField}>
                        <label htmlFor="message">
                            <span>Message</span>
                            <span style={{color: Color.intelligentBlue}}>*</span>
                        </label>
                        <textarea type="text" cols="40" rows="5" className={classes.messageBox} required placeholder="Please put your message here..." id="message" name="message"></textarea>
                    </div>
                  
                    <button className={classes.button} type="submit">Submit</button>
                </form>
            </div>
        )
    }

}

export default withStyles(style)(HiringForm);

