import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { Color } from '../../assets/color';
import ico_fold from '../../assets/icons/ico_fold_in.svg';

import VerificationProcess from '../../components/verificationProcess';
import VerificationProcessExpand from '../../components/verificationProcessExpand';



const styles = theme => ({
    header: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: Color.intelligentBlue,
        [theme.breakpoints.up('mob')]: {
            height: 200,
            padding: '0px 24px',
        },
        [theme.breakpoints.up('tabletv')]: {
            height: 288,
            padding: '0px 32px'
        },
        [theme.breakpoints.up('tableth')]: {
            height: 384,
            padding: '0px 32px'
        },
        [theme.breakpoints.up('desktop')]: {
            height: 480,
            padding: '0px 120px'
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '0px 240px',
        },
    },
    main: {
        [theme.breakpoints.up('mob')]: {
            padding: '0px 24px',
        },
        [theme.breakpoints.up('tabletv')]: {
            padding: '0px 32px'
        },
        [theme.breakpoints.up('jobDetail')]: {
            marginTop: 0,
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '0px 32px'
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '0px 120px'
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '0px 240px',
        },
    },
    block: {
        display:'flex', 
        flexDirection:'row', 
        justifyContent:'space-between', 
    },
    cardBlock: {
        display:'flex',
        flexDirection:'column',
        [theme.breakpoints.up('mob')]: {
            paddingTop: '80px',
        },
        [theme.breakpoints.up('tableth')]: {
            paddingTop: '120px',
        },
        [theme.breakpoints.up('desktop')]: {
            paddingTop: '200px',
        },
    },
    cardBlockBottom: {
        [theme.breakpoints.up('mob')]: {
            paddingBottom: '80px',
        },
        [theme.breakpoints.up('tableth')]: {
            paddingBottom: '120px',
        },
        [theme.breakpoints.up('desktop')]: {
            paddingBottom: '200px',
        },
    },
    serviceTitle: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            paddingBottom: 32,
        },
        [theme.breakpoints.up('tabletv')]: {
            width: '100%',
            paddingBottom: 72,
            // maxWidth: 400
        },
        [theme.breakpoints.up('desktop')]: {
            width: '50%',
        },
    },
    servicePara: {
        [theme.breakpoints.up('mob')]: {
            width: '100%'
        },
        [theme.breakpoints.up('desktop')]: {
            width:  '50%'
        }
    },
    processBlock: {
        [theme.breakpoints.up('mob')]: {
            margin: '-16px',
           
        },
        [theme.breakpoints.up('tableth')]: {
            margin: '-20px',
        },
        display: 'flex',
        flexWrap: 'wrap'
    },
    accordion: {
        boxShadow: 'none',
        border: '1.5px solid #E8E8E8',
        flexWrap: 'wrap',
        marginTop: 32,
        [theme.breakpoints.up('mob')]: {
            padding: '24px 32px',
           
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '32px',
        },
        '&::before': {
            backgroundColor: Color.white
        }
    },
    content: {
        margin: 0
    },
    accordionList: {
        paddingInlineStart: 25.5
    },
    accordionDetail: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column'
    },
    accordionContent: {
        paddingTop: 32,
        display: 'flex',
        flexWrap: 'wrap'
    }
})

class Verification extends React.Component {

    constructor() {
        super()
    }

    render() {

        const{classes} = this.props;

        return(
            <div>
                <div className={classes.header}>
                        <div className={`h1`} style={{color: Color.white}} role="heading" aria-level="1">I-9 Verification</div>
                </div>
                <div className={classes.main}>
                    <div className={`${classes.block} ${classes.cardBlock}`} style={{flexDirection:'row', flexWrap:'wrap', alignItems: 'flex-start'}}>
                        <div className={`h1 ${classes.serviceTitle}`} role="heading" aria-level="1">Overview</div>
                        <p className={`normal ${classes.servicePara}`}>An I-9 form is used to verify the identity and employment authorization of individuals hired for employment in the United States. The USCIS and DHS require an I-9 form to be filled out completely for working purposes. In this page, you will find all the information you need to know about I-9 Verification, including the entire I-9 Verification process and commonly asked questions regarding I-9 Verification.</p>
                    </div>
                    <div className={`${classes.block} ${classes.cardBlock}`}>
                        <div className={`h1 ${classes.serviceTitle}`} role="heading" aria-level="1">I-9 Verification Process</div>
                        <div className={classes.processBlock}>
                            <VerificationProcess title={'Step 1'} para={'Fill out section 1 of the I-9 form. Make sure to check your status accordingly.'} wid={'25%'}/>
                            <VerificationProcess title={'Step 2'} para={'Print out page 2 of the I-9 form.'} wid={'50%'}/>
                        </div>
                        <div className={classes.processBlock} style={{marginTop: 20}}>
                            <VerificationProcessExpand title={'Step 3'} para={'Present acceptable documents for physical examination to a third party (friend/neighbor/notary; family member excluded) to fill out Section 2.'} wid={'75%'}/>
                            <VerificationProcess title={'Step 4'} para={'Send us a copy of the completed I-9 form (both page 1 and page 2) and your legal documents (both front and back of your documents, if applicable).'} wid={'100%'}/>
                        </div>
                    </div>
                    <div className={`${classes.block} ${classes.cardBlock} ${classes.cardBlockBottom}`}>
                        <div className={`h1 ${classes.serviceTitle}`} role="heading" aria-level="1" style={{marginBottom: -32}}>
                            Commonly Asked Questions
                        </div>
                        <Accordion classes={{root: classes.accordion}}>
                            <AccordionSummary style={{padding: 0}}
                                expandIcon={<img src={ico_fold} alt="icons" style={{width:32}} />}
                                classes={{
                                    content: classes.content
                                  }}
                            >
                                <h3>Why do I need to find a third party to complete the page 2 on behalf of my employer?</h3>
                            </AccordionSummary>
                            <AccordionDetails classes={{root: classes.accordionDetail}}>
                                <p className={`normal ${classes.accordionContent}`}>We are asking employees & the employees’ third party to fill out both page 1 and page 2 under the advice of  USCIS for remote working settings. You can present the following information to a third party (friend/neighbor/notary, etc.), explaining that USCIS does not require the authorized representative to have specific agreements or other documentation for Form I-9 purposes. Our company is still liable for any violations in connection with the form or the verification process.</p>
                                <p className={`normal ${classes.accordionContent}`} style={{fontWeight: 600}}>
                                    Completing Form I-9 for Remote Hire:
                                </p>
                                <p className={`normal ${classes.accordionContent}`}>Employers may designate an authorized representative to fill out Forms I-9 on behalf of their company, including personnel officers, foremen, agents or notary public. The Department of Homeland Security does not require the authorized representative to have specific agreements or other documentation for Form I-9 purposes. If an authorized representative fills out Form I-9 on behalf on the employer, the employer is still liable for any violations in connection with the form or the verification process.</p> 
                                <p className={`normal ${classes.accordionContent}`}> When completing Form I-9, the employer or authorized representative must physically examine, with the employee being physically present, each document presented to determine if it reasonably appears to be genuine and relates to the employee presenting it. Reviewing or examining documents via webcam is not permissible.</p> 
                                <p className={`normal ${classes.accordionContent}`}>If the authorized representative refuses to complete Form I-9 (including providing a signature) another authorized representative may be selected. If the employer hires a notary public, the notary public is acting as an authorized representative of the employer, not as a notary. The notary public must perform the same required actions as an authorized representative. When acting as an authorized representative, the notary public should not provide a notary seal on Form I-9.</p> 
                            </AccordionDetails>
                        </Accordion>
                        <Accordion classes={{root: classes.accordion}}>
                            <AccordionSummary style={{padding: 0}}
                                expandIcon={<img src={ico_fold} alt="icons" style={{width:32}} />}
                                classes={{
                                    content: classes.content
                                  }}
                            >
                                <h3>Where can I find more resources to help me complete the I-9 form?</h3>
                            </AccordionSummary>
                            <AccordionDetails classes={{root: classes.accordionDetail}}>
                                <p className={`normal ${classes.accordionContent}`}>
                                    <span style={{display:'inline'}}>Please feel free to check out this I-9 completion tutorial if you have any questions on how to complete the form: </span>
                                    <span>
                                        <a href="https://www.youtube.com/watch?v=tKSC_IrYeJY" target="_blank" style={{margin: 0, padding: 0, display:'inline'}} aria-label="video link">https://www.youtube.com/watch?v=tKSC_IrYeJY</a>   
                                    </span>
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion classes={{root: classes.accordion}}>
                            <AccordionSummary style={{padding: 0}}
                                expandIcon={<img src={ico_fold} alt="icons" style={{width:32}} />}
                                classes={{
                                    content: classes.content
                                  }}
                            >
                                <h3>When do I need to complete the whole I-9 Verification process?</h3>
                            </AccordionSummary>
                            <AccordionDetails classes={{root: classes.accordionDetail}}>
                                <p className={`normal ${classes.accordionContent}`}>
                                    <span style={{display:'inline'}}> You would need to complete it at least 3 days prior to your onboard date. You can start completing it as soon as you receive this I-9 Verification invitation from us. </span>
                                </p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion classes={{root: classes.accordion}}>
                            <AccordionSummary style={{padding: 0}}
                                expandIcon={<img src={ico_fold} alt="icons" style={{width:32}} />}
                                classes={{
                                    content: classes.content
                                  }}
                            >
                                <h3>Why can’t my family member be my Authorized Representative?</h3>
                            </AccordionSummary>
                            <AccordionDetails classes={{root: classes.accordionDetail}}>
                                <p className={`normal ${classes.accordionContent}`}>
                                    <span style={{display:'inline'}}> Unfortunately, according to the previous instruction we sent you, IPG does not allow family members to be the authorized third party as family members are the direct benefit relative of the employees. You would need to find a friend/neighbor/notary to be your Authorized Representative.</span>
                                </p>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(Verification);
