import React from 'react';
import { Link } from 'react-router-dom';
import {  withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import LinkButton from '../components/linkBtn';
import ico_right_b from '../assets/icons/ico_arrow_right_b.svg';

const style = theme => ({
    outer:{
        display: 'flex',
        width: '100%',
        marginBottom: 20,
        [theme.breakpoints.up('mob')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.up('tableth')]: {
            flexDirection: 'row',
        },
    },
    blogPic: {
        backgroundRepeat: 'no-repeat',
        marginTop: 20,
        marginLeft: 12,
        Height: 360,
        boxSizing: 'border-box',
        [theme.breakpoints.up('mob')]: {
            width:  '92%',
            backgroundPosition: 'center',
        },
        [theme.breakpoints.up('tabletv')]: { 
            width: '95%',
            height: 300,
        },
        [theme.breakpoints.up('tableth')]: {
            width: '45%',
            height: 360,
        },
    },
    blogPic1:{
        backgroundSize: 'contain',
    },
    blogPic2:{
        backgroundSize: 'cover',
    },
    blogCard: {
        border: '1.5px solid #E8E8E8', 
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 360,
        [theme.breakpoints.up('mob')]: {
            margin: '0 auto',
            width: '92%',
        },
        [theme.breakpoints.up('tabletv')]: {
            margin: 12,
            width: '95%',
        },
        [theme.breakpoints.up('tableth')]: {
            margin: 16,
            width: '55%',
        },
        [theme.breakpoints.up('desktop')]: {
            // margin: '20px 0',
            margin: 20,
        }
    },

    blogTitle:{
        [theme.breakpoints.up('mob')]: {
            padding: 24,
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '32px 32px 16px'
        }
    },

    blogTitle4:{
        [theme.breakpoints.up('mob')]: {
            padding: 24,
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '32px 32px 16px',
            width: 500,
        }
    },

    blogDescription: {
        [theme.breakpoints.up('mob')]: {
            padding: '0px 24px',
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '0px 32px'
        }
    },
    blogCardBottom: {
        display: 'flex',
        justifyContent: 'space-between',
    
        [theme.breakpoints.up('mob')]: {
            padding: '32px 24px',
            flexDirection: 'column',
        },
        [theme.breakpoints.up('tabletv')]: {
            padding: '32px 24px 16px',
            flexDirection: 'column',
        },
        [theme.breakpoints.up('desktop')]: {
            flexDirection: 'row',
        },
    },
    blogLinkText: {

    },
    detail: {
        display: 'flex',
        [theme.breakpoints.up('mob')]: {
            width: '100%',
           
        },
        [theme.breakpoints.up('tabletv')]: {
            width: '100%',
            alignSelf: 'flex-start'
        },
        [theme.breakpoints.up('desktop')]: {
            width: '50%',
            alignSelf: 'center'
        },
    }
})

const BlogCard = props => {
    const { classes, pic, title, author, datetime, id,slogan,imgContain } = props;
    const linkTo=`/blog/${id}`+(slogan?`/${slogan}`:'');
    return(
        <Link to={linkTo}>
            <div className={classes.outer}>
                <div className={`${imgContain ? classes.blogPic1: classes.blogPic2} ${classes.blogPic}`} style={{backgroundImage: 'url(' + pic + ')'}}>
                </div>
                <Card classes={{root: classes.blogCard}}>
                    <div>
                        <h2 className={`${id===4? classes.blogTitle4: classes.blogTitle}`}>{title}</h2>
                    </div>
                    <div className={classes.blogCardBottom}>
                        <LinkButton style={{display: 'flex', justifyContent: 'flex-start'}} >
                            <img src={ico_right_b} alt="icon" style={{height: 24, paddingRight: 0, paddingTop: 4}}></img>
                            <p className={classes.blogLinkText}>Read the Blog</p> 
                        </LinkButton>
                        <p className={classes.detail}>
                            {`by ${author} on ${datetime}`}
                        </p>
                    </div>
                </Card>
            </div>
        </Link>
    )
}

export default withStyles(style)(BlogCard);