import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { Color } from '../assets/color';

const style = theme => ({
    outer:{
        [theme.breakpoints.up('mob')]: {
            width: '33%',
            minWidth: 364,
            height: 964,
        },
        [theme.breakpoints.up('tableth')]: {
            width: '33%',
            // minWidth: 432,
            minHeight: 884,

        },
        [theme.breakpoints.up('desktop')]: {
            width:'33%',
            minHeight: 924,
            // minWidth: 413
        },
        [theme.breakpoints.up('largedesktop')]: {
            width:'33%',
            minHeight: 860,
        }
    },
    root: {
        border: '1.5px solid #E8E8E8', 
        boxShadow: 'none',
        [theme.breakpoints.up('mob')]: {
            margin: 12,
            height:'calc(100% - 24px)',
        },
        [theme.breakpoints.up('tabletv')]: {
            margin: 12,
            height:'calc(100% - 24px)',
        },
        [theme.breakpoints.up('tableth')]: {
            margin: 16,
            height:'calc(100% - 32px)',
        },
        [theme.breakpoints.up('desktop')]: {
            margin: 20,
            height:'calc(100% - 40px)',
        }
    },
    blogPic:{
        width: '100%'
    },
    blogTitle:{
        [theme.breakpoints.up('mob')]: {
            padding: 24,
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '32px 32px 16px'
        }
    },
    blogContent: {
        [theme.breakpoints.up('mob')]: {
            padding: '0px 24px',
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '0px 32px'
        }
    }
})

const HomeBlogCard = props => {
    const { classes, pic, title, content } = props;

    return(
        <div className={classes.outer}>
            <Card classes={{root: classes.root}}>
                <img src={pic} alt="blog pic" className={classes.blogPic}></img>
                <h2 className={classes.blogTitle}>{title}</h2>
                <p className={`${classes.blogContent} normal`}>{content}</p>
            </Card>
        </div>

    )
}

export default withStyles(style)(HomeBlogCard)