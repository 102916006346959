import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { Color } from '../assets/color';
import { withRouter } from 'react-router-dom';
import {HashLink as Link} from 'react-router-hash-link';

import Adobe from '../assets/jobCompany/Adobe_Symbol.jpg';
import Airbnb from '../assets/jobCompany/Airbnb_Symbol.jpg';
import Google from '../assets/jobCompany/Google_Symbol.jpg';
import Intellipro from '../assets/jobCompany/IntelliPro_Symbol.jpg';
import LinkedIn from '../assets/jobCompany/LinkedIn_Symbol.jpg';
import Twitter from '../assets/jobCompany/Twitter_Symbol.jpg';

import mapPin from '../assets/icons/ico_map_pin.svg';
import fullTime from '../assets/icons/ico_full_time.svg';



const style = theme => ({
    link: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            margin: 12
        },
        [theme.breakpoints.up('tabletv')]: {
            width: '50%',
        },
        [theme.breakpoints.up('desktop')]: {
            width: '33.33%',
            margin: 20
        },
        [theme.breakpoints.up('largedesktop')]: {
            width: '25%',
        },
        '&:hover': {
            cursor: 'pointer',
        }
    },
    root: {
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        padding: 32,
        height: 300,
        justifyContent: 'center',
        '&:hover': {
            cursor: 'pointer',
            boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)',
        }
    }
})

class CareerJobCard extends React.Component {
    constructor() {
        super()
        this.state = {
            card: false,
            tabletcard: false,
            mobcard: false,
            largedesktop: false,
            desktop: false,
            tableth: false,
            tabletv: false,
            mob: false  
        }
    }



    render() {
        const { classes, id, name, type, city, history } = this.props;


        return(
            <Link to={{pathname: "/careerDetail", search:`?id=${id}`}} className={classes.link} >  
                <div className={`${classes.root}`} style={{flexDirection: 'column', alignItems:'flex-start'}}>
                    <h3 style={{ marginBottom: 32, overflow:'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp:3}}>{name}</h3>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{display:'flex', paddingBottom: 16}}>
                            <img src={fullTime} alt="icon" style={{width: 24, height: 24, paddingRight: 8}}></img>
                            <p className='cap'>{type}</p>
                        </div>
                        <div style={{display:'flex',  paddingBottom: 32}}>
                            <img src={mapPin} alt="icon" style={{width: 24, height: 24, paddingRight: 8}}></img>
                            <p className='cap'>{city}</p>
                        </div>
                    </div>
                    <p className="boldcap" style={{color: Color.intelligentBlue}}>View Job Detail</p>        
                </div> 
            
            </Link>
           
        )
    }
}

export default withStyles(style)(withRouter(CareerJobCard));

