import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Color } from '../../assets/color';
import { Accordion, AccordionSummary, AccordionDetails, Checkbox, FormGroup, FormControlLabel, Menu, MenuItem } from '@material-ui/core';


import ResourceCard from '../../components/resourceCard';
import ResourceLargeCard from '../../components/resourceLargeCard';
import ResourceLinkCard from '../../components/resourceLinkCard';

import resourceImg from '../../assets/resource/resource.jpeg';
import ico_recruitment from '../../assets/icons/ico_recruitment.svg';
import ico_money from '../../assets/icons/ico_money.svg';
import ico_human_resource from '../../assets/icons/ico_human_resource.svg';
import ico_badge from '../../assets/icons/ico_badge.svg';
import ico_traning from '../../assets/icons/ico_training.svg';
import ico_fold from '../../assets/icons/ico_fold_out.svg';

import blog1 from '../../assets/home/blog1.jpeg';
import blog2 from '../../assets/home/blog2.jpeg';
import blog3 from '../../assets/home/blog3.jpeg';
import DownloadCard from '../../components/downloadCard';
import VideoBlogCard from '../../components/videoBlogCard';

import resource_detail_1 from '../../assets/resource/resource_detail_1.jpeg';
import resource_detail_2 from '../../assets/resource/resource_detail_2_2.png';
import resource_detail_3 from '../../assets/resource/resource_detail_3.jpeg';
import resource_detail_4 from '../../assets/resource/resource_detail_4_sm_2.png';
import resource_detail_5 from '../../assets/resource/resource_detail_5.jpeg';
import resource_detail_6 from '../../assets/resource/resource_detail_6.jpeg';


const style = theme => ({
    header:{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        [theme.breakpoints.up('mob')]: {
            height: 518
        },
        [theme.breakpoints.up('tabletv')]: {
            height: 480
        },
        [theme.breakpoints.up('tableth')]: {
            height: 640
        },
        [theme.breakpoints.up('desktop')]: {
            height: 800
        },
        [theme.breakpoints.up('largedesktop')]: {
            
        },
    },
    headerImg: {
        objectFit: 'cover',
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            height: 288
        },
        [theme.breakpoints.up('tabletv')]: {
            width: '50%',
            height: 480
        },
        [theme.breakpoints.up('tableth')]: {
            height: 640
        },
        [theme.breakpoints.up('desktop')]: {
            height: 800
        },
    },
    headerTitle: {
        position: 'relative',
        [theme.breakpoints.up('mob')]: {
            width: '100%',
        },
        [theme.breakpoints.up('tabletv')]: {
            width: '50%',
            height: 480
        },
        [theme.breakpoints.up('tableth')]: {
            height: 640
        },
        [theme.breakpoints.up('desktop')]: {
            height: 800
        },
    },
    title:{
        [theme.breakpoints.up('mob')]: {
            margin: '60px 24px',
            width: '100%'
        },
        [theme.breakpoints.up('tabletv')]: {
            padding: '80px 32px 0px',
            width: 288
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '256px 32px 0px',
            width: 360
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '208px 120px 0px',
            width: '80%',
            maxWidth: 480
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '240px 240px 0px',
        },
    },
    main: {
        [theme.breakpoints.up('mob')]: {
            padding: '0px 24px'
        },
        [theme.breakpoints.up('tabletv')]: {
            padding: '0px 32px'
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '0px 32px'
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '0px 120px'
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '0px 240px',
        },
    },
    block: {
        display:'flex', 
        flexDirection:'row', 
        justifyContent:'space-between', 
    },
    cardBlock: {
        display:'flex',
        flexDirection:'column',
        [theme.breakpoints.up('mob')]: {
            paddingTop: 60,
        },
        [theme.breakpoints.up('tableth')]: {
            paddingTop: 80
        },
        [theme.breakpoints.up('desktop')]: {
            paddingTop: 100,
            marginBottom: 70,
        },
    },
    serviceTitle: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            paddingBottom: 40,
        },
        [theme.breakpoints.up('desktop')]: {
            width:  '50%',
            paddingBottom: 80,
            maxWidth: 480
        },
        [theme.breakpoints.up('largedesktop')]: {
            width:  '50%',
            maxWidth: 600
        }
    },
    resourceTitle: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            paddingBottom: 32,
        },
        [theme.breakpoints.up('desktop')]: {
            width:  '50%',
            paddingBottom: 0,
            maxWidth: 480
        },
        [theme.breakpoints.up('largedesktop')]: {
            width:  '50%',
            maxWidth: 600
        }
    },
    resourcePara: {
        [theme.breakpoints.up('mob')]: {
            width: '100%'
        },
        [theme.breakpoints.up('desktop')]: {
            width:  '50%'
        }
    },
    resourceGroup: {
        margin: '-24px',
        [theme.breakpoints.up('mob')]: {
            paddingBottom: 80,
        },
        [theme.breakpoints.up('tableth')]: {
            paddingBottom: 120
        },
        [theme.breakpoints.up('desktop')]: {
            paddingBottom: 200,
        },
    },
    accordion: {
        boxShadow: 'none',
        '&::before': {
            backgroundColor: Color.white
        },
        [theme.breakpoints.up('mob')]: {
            width: 224,
            border: '2px solid #E8E8E8',
            margin: 12
        },
        [theme.breakpoints.up('desktop')]: {
            width: '100%',
            padding: '20px 0px',
            border: 'none',
            margin: 0
        },
        '& .MuiAccordionSummary-root': {
            minHeight: 32,
            [theme.breakpoints.up('mob')]: {
                height: 60,
            },
            [theme.breakpoints.up('desktop')]: {
                height: 32,
            }
        },
        '& .MuiAccordionSummary-content': {
            margin: 0,
        },
        '& .MuiIconButton-root': {
            padding: '0px 12px'
        }
    },
    accordionExpanded: {
       padding: '20px 0px 4px'
    },
    accordionDetail: {
        padding: 0
    },
    intelliproTitle:{
        marginBottom:'20px'
    }
})


class Respurce extends React.Component {
    constructor() {
        super()
    }

    render() {
        const { classes, menuClicked, history } = this.props;

        return(
            <div>
                <div className={classes.header}>
                    <div className={classes.headerTitle}>
                        <title className={classes.title}>New Hire Orientation & Resource</title>
                    </div>
                    <img src={resourceImg} alt="resource" className={classes.headerImg}></img>
                </div>
                <div className={classes.main}>
                   <div className={`${classes.block} ${classes.cardBlock}`} style={{flexDirection:'row', flexWrap:'wrap', alignItems: 'flex-start'}}>
                            <div className={`h1 ${classes.resourceTitle}`} role="heading" aria-level="1">Elegant Solutions,<br/> End-to-End Service</div>
                        <p className={`normal ${classes.resourcePara}`}>IntelliPro is more than equipped to conduct high-volume hiring, but we can also completely take over and optimize your most arduous and time-consuming business functions. We can handle all responsibilities related to recruiting or managing your non-core operations and processes.</p>
                    </div>
                    <div className={`${classes.block} ${classes.cardBlock}`} >
                            <div className={`h1 ${classes.resourceTitle}`} role="heading" aria-level="1">FQA</div>
                              <Accordion classes={{root: classes.accordion, expanded: classes.accordionExpanded}}>
                                    <AccordionSummary style={{padding: 0, margin: 0}}
                                        expandIcon={<img src={ico_fold} alt="icons" style={{width:32, padding: 0}} />}
                                    >
                                        <h3>This is a question Title</h3>
                                    </AccordionSummary>
                                    <AccordionDetails classes={{root: classes.accordionDetail}}>
                                        We can identify standout job candidates for your hardest-to-fill full-time role or source an entire team of highly skilled professionals for your most challenging projects. Thanks to our recruiting and staffing expertise - and our powerful proprietary database of more than 160 million job candidates - we have access to both passive and active job candidates for roles at all levels. From administrative staff to C-suite executives, we have you covered. When you partner with IntelliPro, you can confidently leave the recruiting to us so that you can focus on the core initiatives and operations driving your business.
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion classes={{root: classes.accordion, expanded: classes.accordionExpanded}}>
                                    <AccordionSummary style={{padding: 0, margin: 0}}
                                        expandIcon={<img src={ico_fold} alt="icons" style={{width:32, padding: 0}} />}
                                    >
                                        <h3>This is a question Title</h3>
                                    </AccordionSummary>
                                    <AccordionDetails classes={{root: classes.accordionDetail}}>
                                        We can identify standout job candidates for your hardest-to-fill full-time role or source an entire team of highly skilled professionals for your most challenging projects. Thanks to our recruiting and staffing expertise - and our powerful proprietary database of more than 160 million job candidates - we have access to both passive and active job candidates for roles at all levels. From administrative staff to C-suite executives, we have you covered. When you partner with IntelliPro, you can confidently leave the recruiting to us so that you can focus on the core initiatives and operations driving your business.
                                    </AccordionDetails>
                                </Accordion>
                    </div>
                    <div className={`${classes.cardBlock} ${classes.block}`} >
                        <div className={classes.intelliproTitle}>
                            <div className="h1" role="heading" aria-level="1">Video Tutorials </div>
                        </div>
                       <div className={`${classes.block} ${classes.blockGroup}`} style={{overflow:'auto'}}>
                            <VideoBlogCard pic={blog1} title={'Speed and Agility'} content={'short description'} />
                            <VideoBlogCard pic={blog2} title={'A Deep and Diverse Global Talent Pool'} content={'short description'} />
                            <VideoBlogCard pic={blog3} title={'A Personal Approach'} content={"short description"} />
                       </div>
                    </div>
                    <div className={`${classes.cardBlock} ${classes.block}`} >
                        <div className={classes.intelliproTitle}>
                            <div className="h1" role="heading" aria-level="1">Downloads </div>
                        </div>
                       <div className={`${classes.block} ${classes.cardGroup}`} style={{flexWrap:'wrap'}}>
                            <DownloadCard  title ={'PDF Title'} content={"Talent Acquisition"}/>
                            <DownloadCard  title ={'PDF Title'} content={"Consulting Solutions"}/>
                            <DownloadCard  title ={'PDF Title'} content={"Employer of Record (EOR)"}/>
                            <DownloadCard  title ={'PDF Title'} content={"Human Resources Outsourcing (HRO)"}/>
                            <DownloadCard  title ={'PDF Title'} content={"Professional Employer Organization (PEO)"}/>
                            <DownloadCard  title ={'PDF Title'} content={"Business Process Outsourcing (BPO)"}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(style)(withRouter(Respurce));