import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Color } from '../assets/color';

const LinkButton = withStyles({
    root: {
        fontSize: 16,
        boxShadow: 'none',
        textTransform: 'none',
        lineHeight: '32px',
        letterSpacing: '0.02em',
        fontStyle: 'normal',
        fontFamily: 'sofia-pro',
        color: Color.intelligentBlue,
        '&:active': {
            color: Color.intelligentBlue
        },
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#ffffff',
            color: Color.intelligentBlue
        },
        '&:disabled': {
            color: Color.borderGrey
        }
    }
})(Button);

export default LinkButton;