import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Color } from '../assets/color';

import ico_right from '../assets/icons/ico_arrow_right_b.svg';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        border: '1.5px solid #E8E8E8',
        justifyContent: 'flex-start',
        [theme.breakpoints.up('mob')]: {
            margin: 24,
            width: 'calc(100% - 48px)',
        },
        [theme.breakpoints.up('tableth')]: {
            width: 'calc(50% - 51px)',
        },
    },
    card:{
        [theme.breakpoints.up('mob')]: {
            padding: 24,
        },
        [theme.breakpoints.up('tableth')]: {
            padding: 32,
        },
    },
    iconImg: {
        width: 80, 
        height: 80,
        [theme.breakpoints.up('mob')]: {
            paddingRight: 24,
            paddingBottom: 24
        },
        [theme.breakpoints.up('tableth')]: {
            paddingRight: 32,
            paddingBottom: 32
        },
    },
    cardTitle: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
            paddingBottom: 24
        },
        [theme.breakpoints.up('tableth')]: {
            minWidth: '318px',
            width:'unset'
        },
    },
    resourceImg: {
        backgroundColor: Color.lightBlue,
        margin: '24px 0px',
        [theme.breakpoints.up('mob')]: {
            padding: 24
        },
        [theme.breakpoints.up('tableth')]: {
            padding: 32
        },
    },
    inclusionList: {
        listStyleType: 'square',
        margin: 0,
        paddingInlineStart: 22
    },
    inclusion: {
        color: Color.navy,
        fontWeight: 400,
        fontSize: 20,
        letterSpacing: '0.04em',
        lineHeight: '32px',
        '&::marker': {
            color: Color.intelligentBlue
        },
    },
    link: {
        display: 'flex',
        paddingInlineStart: 22,
        '&:hover': {
            cursor: 'pointer'
        }
    }
})

class ResourceCard extends React.Component {

    constructor() {
        super()
    }

    render() {
        const { classes, history, icon, title, para, image, redirect, linkPara, link } = this.props;

        return(
            <div className={classes.root}>
                 <div className={classes.card}>
                     <div style={{display:'flex', alignItems:'center', flexWrap: 'wrap' }}>
                        <img src={icon} alt="icon" className={classes.iconImg}></img>
                        <h2 className={`${classes.cardTitle}`}>{title}</h2>
                     </div>
                    <p className={`normal`}>{para}</p>
                    <div className={classes.resourceImg}>
                        <img src={image} style={{width: '100%'}} alt='img'></img>
                    </div>
                    <ul className={classes.inclusionList}>
                        <li className={classes.inclusion}>{redirect}</li>
                    </ul>
                    <div className={classes.link} onClick={() => {history.push(link)}}>
                        <img src={ico_right} alt="icons" style={{width:24}}></img>
                        <p className="boldcap" style={{color: Color.intelligentBlue, paddingLeft: 8, lineHeight: '32px'}}>{linkPara}</p>        
                    </div>
                   
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(withRouter(ResourceCard));