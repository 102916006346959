import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import {createMuiTheme, Toolbar, Button, IconButton, Menu, MenuItem, Typography} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import {HashLink as Link} from 'react-router-hash-link';
import {Color} from '../../assets/color';
import logo from '../../assets/nav/ipg_logo.png';
import LinkBtn from '../../components/linkBtn';
import menu from '../../assets/icons/ico_menu.svg';
import close from '../../assets/icons/ico_close.svg';
import TranslateIcon from '@material-ui/icons/Language';


const styles = (theme) => ({
    root: {
        [theme.breakpoints.up('mob')]: {
            padding: '24px 32px'
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '28px 120px'
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '28px 240px',
        },
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    desktopNav:{
        width: 785,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.up('mob')]: {
            display: 'none'
        },
        [theme.breakpoints.up('desktop')]: {
            display: 'flex'
        },
    },
    mobNav:{
        [theme.breakpoints.up('mob')]: {
            display: 'flex'
        },
        [theme.breakpoints.up('desktop')]: {
            display: 'none'
        },
    },
    linkBtn: {
        fontSize: '16px',
        lineHeight: '24px',
        letterSpace: '2%',
        fontWeight: 400,
        textDecoration: 'none',
        fontFamily: 'sofia-pro',
        display: 'flex',
        alignItems: 'center',
        color: 'inherit', 
    },
    linkBtnActive: {
        fontSize: '16px',
        lineHeight: '24px',
        letterSpace: '2%',
        textDecoration: 'none',
        fontFamily: 'sofia-pro',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 700,
        color: Color.black,
        height: 80
      
    },
    linkBtnMob: {
        fontSize: '16px',
        lineHeight: '24px',
        letterSpace: '2%',
        fontWeight: 400,
        textDecoration: 'none',
        fontFamily: 'sofia-pro',
        display: 'flex',
        alignItems: 'center',
        color: 'inherit', 
        height: 80
    },
    linkTxt: {
        fontWeight: 400,
        color: Color.midGrey
    },
    linkTxtActive: {
        fontWeight: 700,
        color: Color.black
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            backgroundColor: '#ffffff'
        }
    },
    logo: {
        width: 96
    },
    menu: {
        '& ul': {
            [theme.breakpoints.up('mob')]: {
                width: '100vw'
            },
            [theme.breakpoints.up('tabletv')]: {
                width: 414
            },
            padding: 0
        },
        '& .MuiMenu-paper': {
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)'

        },
        '& li': {
            padding: 0,
            color: Color.midGrey,
            '&:hover': {
                backgroundColor: Color.lightBlue,
                color: Color.intelligentBlue
            }
        },
        '& a': {
            width: '100%',
        },
        '& div': {
            maxWidth: '100%',
            maxHeight: '100%',
            minWidth: 0,
            minHeight: 0,
            right: 0,
           
        }
       
    },
    languageMenu: {
        '& .MuiMenu-paper': {
          // boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
          marginTop: '5px',
          marginLeft:'12px'
          
        },
     
      },
    menuItem: {
        width: '100%'
    },
    mobLink: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '0px 0px 0px 32px',
    },
    languageButton: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '-50px'
      },
      languageButtonDesktop: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '-50px',
        [theme.breakpoints.up('mob')]: {
          display: 'none',
        },
        [theme.breakpoints.up('desktop')]: {
          display: 'flex',
        },
      },
      languageButtonMob: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('mob')]: {
          display: 'flex',
        },
        [theme.breakpoints.up('desktop')]: {
          display: 'none',
        },
      },
})

class Nav extends React.Component {
    constructor() {
        super()
        this.state = {
            anchorEl: null,
            mobileMoreAnchorEl: null,
            largedesktop: false,
            desktop: false,
            tableth: false,
            tabletv:false,
            mob:false,
            languageAnchorEl: null, 
            language: 'English', 
        };
    }

    handleMenuClose = () => {
        this.setState({anchorEl: null});
        this.handleMobileMenu();
    };


    handleMobileMenu = event => {
        const {mobileMoreAnchorEl} = this.state;
        if(Boolean(mobileMoreAnchorEl)) {
            this.setState({mobileMoreAnchorEl: null});
        }
        else this.setState({mobileMoreAnchorEl: event.currentTarget});
    };

    handleLanguageMenuOpen = (event) => {
        this.setState({ languageAnchorEl: event.currentTarget });
      };
    
      handleLanguageMenuClose = () => {
        this.setState({ languageAnchorEl: null });
      };
    
      handleLanguageChange = (lang) => {
        this.setState({ language: lang });
        this.handleLanguageMenuClose();
        
        if (lang === 'Japanese') {
            window.location.href = 'https://www.intelliprogroup.jp/';
        }
    };

    componentDidMount() {
        
        this.getDimensions(); 
        
        //add dimensions listener for window resizing
        window.addEventListener('resize', this.getDimensions); 
    }
      
    //remove listener on page exit
    componentWillUnmount() {
        window.removeEventListener('resize', this.getDimensions); 
    }
    
    //actually set the state to the window dimensions
    getDimensions = () => {
        const query = ['(min-width: 1441px)', '(min-width: 1025px)', '(min-width: 769px)', '(min-width: 415px)', '(min-width: 0px)']
        const mql = query.map(q => window.matchMedia(q).matches) ;
        this.setState({ 
            largedesktop: mql[0],
            desktop: mql[1],
            tableth: mql[2],
            tabletv: mql[3],
            mob: mql[4] 
        });
    }


    render() {
        const { menuClicked } = this.props;
        const { classes, active } = this.props;
        const { mobileMoreAnchorEl, languageAnchorEl, language } = this.state;
        const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
        const isLanguageMenuOpen = Boolean(languageAnchorEl);


        const renderMobileMenu = (
            <Menu
                anchorEl={mobileMoreAnchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{vertical: 56, horizontal: 'right'}}
                transformOrigin={{vertical: 0, horizontal: 'right'}}
                open={isMobileMenuOpen}
                onClose={this.handleMenuClose}
                elevation={0}
                className={classes.menu}
                style={this.state.tabletv ? {zIndex:1, left: 32, height: 680} : {zIndex: 1, left: -16, height: 680}}
            >
                <MenuItem onClick={this.handleMobileMenuClose} className={classes.menuItem}>
                    <Link to="/" className={active === 0 ? classes.linkBtnActive : classes.linkBtnMob}> 
                        <p className={classes.mobLink} onClick={() => {menuClicked(0); this.handleMenuClose() }}>Home</p>              
                    </Link>
                </MenuItem>
                <MenuItem onClick={this.handleMobileMenuClose} className={classes.menuItem}>
                    <Link to="/recruiting" className={active === 1 ? classes.linkBtnActive : classes.linkBtnMob}>
                        <p className={classes.mobLink} onClick={() => {menuClicked(1); this.handleMenuClose() }}>Recruiting & Staffing</p>     
                    </Link>
                </MenuItem>
                <MenuItem onClick={this.handleMobileMenuClose} className={classes.menuItem}>
                    <Link to="/service" className={active === 2 ? classes.linkBtnActive : classes.linkBtnMob}>
                        <p className={classes.mobLink} onClick={() => {menuClicked(2); this.handleMenuClose() }}>Managed Services</p>     
                    </Link>
                </MenuItem>
                <MenuItem onClick={this.handleMobileMenuClose} className={classes.menuItem}>
                    <Link to="/jobseeker" className={active === 3 ? classes.linkBtnActive : classes.linkBtnMob}>
                        <p className={classes.mobLink} onClick={() => {menuClicked(3); this.handleMenuClose()}}>Job Seeker</p>     
                    </Link>
                </MenuItem>
                <MenuItem onClick={this.handleMobileMenuClose} className={classes.menuItem}>
                    <Link to="/jobseeker" className={active === 8 ? classes.linkBtnActive : classes.linkBtnMob}>
                        <p className={classes.mobLink} onClick={() => {menuClicked(8); this.handleMenuClose()}}>Join Us</p>     
                    </Link>
                </MenuItem>
                <MenuItem onClick={this.handleMobileMenuClose} className={classes.menuItem}>
                    <Link to="/aboutus" className={active === 4 ? classes.linkBtnActive : classes.linkBtnMob}>
                        <p className={classes.mobLink} onClick={() => {menuClicked(4); this.handleMenuClose()}}>About Us</p>     
                    </Link>
                </MenuItem>
                <MenuItem onClick={this.handleMobileMenuClose} className={classes.menuItem}>
                    <Link to="/contactus" className={active === 5 ? classes.linkBtnActive : classes.linkBtnMob}>
                        <p className={classes.mobLink} onClick={() => {menuClicked(5); this.handleMenuClose()}}>Contact</p>                         
                    </Link>
                </MenuItem>
                {/* <MenuItem onClick={this.handleMobileMenuClose} className={classes.menuItem}>
                    <Link to="/event" className={active === 6 ? classes.linkBtnActive : classes.linkBtnMob}>
                        <p className={classes.mobLink} onClick={() => {menuClicked(6); this.handleMenuClose()}}>Event</p>     
                    </Link>
                </MenuItem> */}
                <MenuItem onClick={this.handleMobileMenuClose} className={classes.menuItem}>
                    <Link to="/blog" className={active === 7 ? classes.linkBtnActive : classes.linkBtnMob}>
                        <p className={classes.mobLink} onClick={() => {menuClicked(7); this.handleMenuClose()}}>Blog</p>     
                    </Link>
                </MenuItem>
            </Menu>
        );

        const renderLanguageMenu = (
            <Menu
              anchorEl={languageAnchorEl}
              open={isLanguageMenuOpen}
              onClose={this.handleLanguageMenuClose}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              className={classes.languageMenu}
            >
              {/* <MenuItem onClick={() => this.handleLanguageChange('English')}>English</MenuItem> */}
              <MenuItem onClick={() => this.handleLanguageChange('Japanese')}>Japanese</MenuItem>
              {/* <MenuItem onClick={() => this.handleLanguageChange('Spanish')}>Spanish</MenuItem> */}
            </Menu>
          );

        return(
            <div>
                <StyledAppBar position="fixed" className={classes.root}>
                    <div>
                        <Link to="/" className={classes.linkBtn}>
                            <Button color="inherit" onClick={() => menuClicked(0)}>
                                <div  
                                    className={active === 0 ? classes.linkTxtActive : classes.linkTxt}
                                >
                                     <img src={logo} className={classes.logo} alt="IntelliPro"/>
                                </div>
                            </Button>
                        </Link>
                    </div>
                    <div className={classes.desktopNav}>
                        <Link to="/" className={classes.linkBtn}>
                            <LinkBtn onClick={() => menuClicked(0)} className={active === 0 ? classes.linkTxtActive : classes.linkTxt}>
                                Home
                            </LinkBtn>
                        </Link>
                        <Link to="/recruiting" className={classes.linkBtn}>
                            <LinkBtn onClick={() => menuClicked(1)} className={active === 1 ? classes.linkTxtActive : classes.linkTxt}>
                                Recruiting & Staffing
                            </LinkBtn>
                        </Link>
                        <Link to="/service" className={classes.linkBtn}>
                            <LinkBtn onClick={() => menuClicked(2)} className={active === 2 ? classes.linkTxtActive : classes.linkTxt}>
                                Managed Services
                            </LinkBtn>
                        </Link>
                        <Link to="/jobseeker" className={classes.linkBtn}>
                            <LinkBtn onClick={() => menuClicked(3)} className={active === 3 ? classes.linkTxtActive : classes.linkTxt}>
                                Job Seeker
                            </LinkBtn>
                        </Link>
                        <Link to="/join" className={classes.linkBtn}>
                            <LinkBtn onClick={() => menuClicked(8)} className={active === 8 ? classes.linkTxtActive : classes.linkTxt}>
                                Join Us
                            </LinkBtn>
                        </Link>
                        <Link to="/aboutus" className={classes.linkBtn}>
                            <LinkBtn onClick={() => menuClicked(4)} className={active === 4 ? classes.linkTxtActive : classes.linkTxt}>
                                About Us
                            </LinkBtn>
                        </Link>
                       
                        {/* <Link to="/event" className={classes.linkBtn}>
                            <LinkBtn onClick={() => menuClicked(6)} className={active === 6 ? classes.linkTxtActive : classes.linkTxt}>
                                Events
                            </LinkBtn>
                        </Link> */}
                        <Link to="/blog" className={classes.linkBtn}>
                            <LinkBtn onClick={() => menuClicked(7)} className={active === 7 ? classes.linkTxtActive : classes.linkTxt}>
                                Blog
                            </LinkBtn>
                        </Link>
                        <Link to="/contactus" className={classes.linkBtn}>
                            <LinkBtn onClick={() => menuClicked(5)} className={active === 5 ? classes.linkTxtActive : classes.linkTxt}>
                                Contact
                            </LinkBtn>
                        </Link>

                    </div>
                    <div className={classes.languageButtonDesktop} onClick={this.handleLanguageMenuOpen}>
                        <IconButton color="inherit"  className={classes.iconButton}>
                            <TranslateIcon />
                        </IconButton>
                        <Typography variant="body1">{language}</Typography>
                    </div>
                    <div className={classes.mobNav}>
                    <div className={classes.languageButtonMob} onClick={this.handleLanguageMenuOpen}>
                        <IconButton color="inherit"  className={classes.iconButton}>
                            <TranslateIcon />
                        </IconButton>
                        <Typography variant="body1">{language}</Typography>
                        </div>
                        <IconButton onClick={this.handleMobileMenu} className={classes.iconButton}>
                            <img src={isMobileMenuOpen ? close : menu } alt="menu icon" style={{width: 32}}/>
                        </IconButton>
                    </div>

                </StyledAppBar>
                {renderMobileMenu}
                {renderLanguageMenu}


            </div>
        )
    }

}

const StyledAppBar = withStyles({
    root: {
        borderRadius: 0,
        boxShadow: '0 0 0 0',
        color: '#666666',
        backgroundColor: '#ffffff',
        width: '100%',
        height: 80,
    }
})(AppBar);

export default withStyles(styles)(Nav);